import { useTranslation } from 'react-i18next';

function FilenameFilter({urlSearchParams}) {
    const { t } = useTranslation();
    // Event Handler
    function handleFilenameChange(event){
        if(event.target.value)
            urlSearchParams.current.set("filename", event.target.value);
        else
            urlSearchParams.current.delete("filename")
    }

    return (
        <>
            <p className='mb-2 fs-5'>{t("Search File by Filename")}</p>
            <input type="text" 
                    className="form-control" 
                    name="filename" 
                    id="filename"
                    placeholder='Enter Filename'
                    autoComplete='off' 
                    onChange={handleFilenameChange}
                />
        </>
    )
}

export default FilenameFilter