import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { APPLIED_PDF, HARVEST_PDF, LAYER_TYPES, RAW_YIELD, STANDARD, YIELD_RASTER } from '../constants';
import { requestParametersAddorChange } from '../../../features/RequestParametersSlice';

const RequestLayers = () => {
    
    const { t } = useTranslation();
    const { customer_request,  } = useSelector(state => state.requestParameters);
    
    return (
        <div className="mb-2 my-4">
            <label htmlFor="" className="form-label fs-5">{t('Select the layers for which you want PDFs')}:</label>
            <LayersTable customer_request={customer_request} />
        </div>
    )
}

function LayersTable({customer_request}){

    const dispatch = useDispatch();
    const [ layers, setLayers ] = useState([])
    const [ requestedLayers, setRequestedLayers ] = useState([])

    
    useEffect(() => {
        if(customer_request === HARVEST_PDF){
            setLayers(LAYER_TYPES.filter(layer => ![STANDARD].includes(layer)))
        } else if(customer_request === APPLIED_PDF ) {
            setLayers(LAYER_TYPES.filter(layer => ![RAW_YIELD, YIELD_RASTER].includes(layer)))
        }
    }, [customer_request])

    useEffect(() => {
        setRequestedLayers(layers);
    }, [layers])

    useEffect(() => {
        dispatch(requestParametersAddorChange({"requestedLayers": requestedLayers}));
    }, [requestedLayers])

    
    // Event Handler for select all click
    function handleSelectAll(event){
        const { checked } = event.target;
        if(checked){
            setRequestedLayers(layers);
        } else {
            setRequestedLayers([])
        }
    }

    return (
    <div className="table-responsive table-hover ">
        <table className="table">
            <thead className='table-dark'>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Layer Name</th>
                    <th scope="col">
                        <input type="checkbox" name="select-all" 
                            className='form-check-input p-2'
                            id="select-all-layers" 
                            onChange={(event) => {handleSelectAll(event)}}
                            checked={layers.length === requestedLayers.length ? "checked" : ""}
                        />
                    </th>
                </tr>
            </thead>
            <tbody className='table-light'>
                {
                    layers.map((layerName, index) => {
                        return (<LayerName 
                                    {...{layerName, index, requestedLayers, setRequestedLayers, key: index}} />)
                    })
                }
            </tbody>
        </table>
    </div>
    )
}

function LayerName({requestedLayers, setRequestedLayers, layerName, index}){
    
    // Event handler for single layer click
    function handleLayerClick(event, layername){
        const {name, checked} = event.target;
        if(checked){
            setRequestedLayers(selectedLayers => [...selectedLayers, name])
        } else {
            setRequestedLayers(selectedLayers => {
                return selectedLayers.filter(layer => layer !== layername)
            })
        }
    }
    

    return (
        <tr>
            <td>{index + 1}</td>
            <td>{layerName}</td>
            <td>
                <input type="checkbox" name={layerName} id={`layer-${layerName}`} 
                        className='form-check-input p-2' 
                        checked={requestedLayers?.includes(layerName) ? "checked" : ""}
                        onChange={(e) => {handleLayerClick(e, layerName)}}
                        />
            </td>
        </tr>
    )
}

export default RequestLayers;

