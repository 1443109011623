import {createSlice} from '@reduxjs/toolkit'

const initialState = {  }

const requestParametersSlice = createSlice({
    name: "requestParameters",
    initialState: initialState,
    reducers: {
        requestParametersAddorChange : (state , action) => {
            // Adds if the payload is new, changes if it already exist
            return {
                ...state,
                ...action.payload
            }
        },

        requestParameterRemove : (state , action) => {
            // Reletes the payload from the global state.
            const copy = {...state};
            delete copy[action.payload];
            return copy;
        },

        requestParametersReset : (state , action) => {
            // resets the state, sets to empty object.
            return {...initialState}  
        },
    },
})

export const { requestParametersAddorChange , requestParameterRemove, requestParametersReset } = requestParametersSlice.actions
export default requestParametersSlice.reducer;