import React, { useState } from 'react'
import {format} from 'date-fns'
import { capitalizeFirstLetter } from '../../../../utils/helpers'
import UpIcon from '../../../../assets/icons/UpIcon';
import DownIcon from '../../../../assets/icons/DownIcon';
import SingleAssignment from './SingleAssignment';

function RequestRow({index, request}) {

    const {id, date_created, runtype, grower, grower_label, taskIDs} = request

    // States
    const [expand, setExpand] = useState(false);

    return (
        <>
            <tr className=''>
                <td className='fw-bold py-4'>{index + 1}</td>
                <td className=''>{id}</td>
                <td>{grower_label ? grower_label : grower}</td>
                <td className=''>{format(new Date(date_created), 'dd-MM-yyyy')}</td>
                <td className=''>{runtype && capitalizeFirstLetter(runtype)}</td>
                <td className='expand'>
                    <button className='btn btn-outline-dark' onClick={() => {setExpand(expand => !expand)}}>
                        {expand ? <UpIcon /> : <DownIcon />}
                    </button>
                </td>
            </tr>

            <SingleAssignment expand={expand} setExpand={setExpand} taskIDs={taskIDs} runtype={runtype} />
        </>
    )
}

export default RequestRow