import HostSelection from './HostSelection';
import FilterLayers from './FilterLayers';

function FcLiteSettings({user_roles}) {
    return (
    <>
        {
            user_roles?.includes("Elvyra Tech") ?
            <>
                <HostSelection />
                <FilterLayers />
            </> 
            :
            <p className='d-flex align-items-center justify-content-center '>You don't have access to this</p>
            }
    </>
    )
}

export default FcLiteSettings