import React from 'react'
import {DateLegend, ElevationLegend, FuelUsageLegend, MoistureLegend, OverlapLegend, ProductivityLegend, RawYieldLegend, SpeedLegend, VehicleLegend, YieldRasterLegend, DefaultLegend, StandardLegend} from './LayerLegends'

function LayerLegend({id, layerType}) {

    return (
        <>
            { 
                (() => {
                    switch(layerType){
                    case "Date":
                        return <DateLegend layerType={layerType} id={id} key={id} />
                    case "Elevation":
                        return <ElevationLegend layerType={layerType} id={id} key={id} />
                    case "Fuel Usage":
                        return <FuelUsageLegend layerType={layerType} id={id} key={id} />
                    case "Overlap":
                        return <OverlapLegend layerType={layerType} id={id} key={id} />
                    case "Vehicle":
                        return <VehicleLegend layerType={layerType} id={id} key={id} />
                    case "Yield Raster":
                        return <YieldRasterLegend layerType={layerType} id={id} key={id} />
                    case "Speed":
                        return <SpeedLegend layerType={layerType} id={id} key={id} />
                    case "Raw Yield":
                        return <RawYieldLegend layerType={layerType} id={id} key={id} />
                    case "Moisture":
                        return <MoistureLegend layerType={layerType} id={id} key={id} />
                    case "Productivity":
                        return <ProductivityLegend layerType={layerType} id={id} key={id} />
                    case "Standard":
                        return <StandardLegend layerType={layerType} id={id} key={id} />
                    default:
                        return <DefaultLegend layerType={layerType} id={id} key={id} />
                    }
                })()
            }
                
        </>
    )
}

export default LayerLegend