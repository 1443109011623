const Category = ({ categoryComponent="", children }) => {

    return (
        <div className="text-dark">
            
            {/* <div className="fs-5 my-1"> */}
            <div className="d-flex align-items-center w-100 py-2 my-1 gap-3 menu-category">
                {categoryComponent}
            </div>
            
            <div>
                {children}
            </div>
        </div>
    )
}

export default Category