import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import GrowerSelector from './GrowerSelector';
import FilenameSearch from './FilenameSearch';
import FlagSource from './FlagSource';

function OffcanvasEnd() {

    const { t } = useTranslation();
    
    // States
    const [ selected_grower , set_selected_grower ] = useState([]);
    const [ filename, setFilename ] = useState(""); // local state to keep track of filename
    
    return (
      <div className="offcanvas offcanvas-end" tabindex="-5" id="offcanvas-end" style={{backgroundColor: "#ECECEC"}}>
        
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasResponsiveLabel">Filters</h5>
          <button type="button" id="close-offcanvas-end" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvas-end"></button>
        </div>
        
        <div className="offcanvas-body">

          {/* Filter By GrowerID */}
          <p className='mb-1'>{t("Search Files by Grower")}:</p>
          {/* Grower Selection */}
          <GrowerSelector selected_grower={selected_grower} set_selected_grower={set_selected_grower} setFilename={setFilename} />

          
            {/* Search by filename */}
            <p className='mb-1 mt-5'>{t("Search File by Filename")}:</p>
            <FilenameSearch  filename={filename} setFilename={setFilename} set_selected_grower={set_selected_grower} />
            
            {/* Filter by FlagSource */}
            <p className='mb-1 mt-5'>{t("Filter by Flag Source")}:</p>
            <FlagSource />

          </div>
      </div>
    )
}

export default OffcanvasEnd