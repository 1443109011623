import { createContext, useState, useEffect } from 'react';
import { generate_default_layout, generate_user_allowed_pages, generate_user_allowed_widgets } from '../utils';

export const DashboardContext = createContext();

export function DashboardContextProvider({ children }) {
  // State variables
  const [USER_ALLOWED_PAGES] = useState(generate_user_allowed_pages());
  const [DEFAULT_LAYOUT] = useState(generate_default_layout());
  const [USER_ALLOWED_WIDGETS] = useState(generate_user_allowed_widgets());

  // Break point
  const [breakpoint, setBreakpoint] = useState("lg");

  // Layout
  const [layouts, setLayouts] = useState(() => {
    if (sessionStorage.getItem("layouts")) {
      return JSON.parse(sessionStorage.getItem("layouts"));
    } else {
      return { ...generate_default_layout() };
    }
  });

  // User Widgets
  const [userWidgets, setUserWidgets] = useState(() => {
    if (sessionStorage.getItem("widgets")) {
      return JSON.parse(sessionStorage.getItem("widgets"));
    } else {
      return [...USER_ALLOWED_WIDGETS];
    }
  });


  return (
    <DashboardContext.Provider
      value={{
        layouts,
        setLayouts,
        breakpoint,
        setBreakpoint,
        DEFAULT_LAYOUT,
        USER_ALLOWED_PAGES,
        USER_ALLOWED_WIDGETS,
        userWidgets,
        setUserWidgets,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
}