import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { CODA_HOST } from '../../../../constants';
import { Spinner } from 'react-bootstrap';
import TableHeader from '../TableHeader';
import TableRow from '../TableRow';
import { ControllerDataContext } from '../../context/ControllerDataContext';
import { fetchData } from '../../utils';
import FilterButton from '../FilterButton';

function DataTableForGrower() {
  
  // Constants
  const growers_stored = JSON.parse(localStorage.getItem("growers"));
  const client_id = localStorage.getItem('client_id');
  const username = localStorage.getItem('username');
  
  // Context
  const {data, setData, loading, setLoading, message, setMessage, next, setNext, prev, setPrev} = useContext(ControllerDataContext);

  // States
  const [refresh, setRefresh] = useState(false);


  // Side Effects 
  // On Render
  useEffect(() => {
      if (refresh === true) {
        fetchCodaData();
      }
  }, [refresh, fetchCodaData]);

  // Handlers
  async function fetchCodaData(){
    setLoading(true);
    try {
      // If the grower has only 1 Grower_id
      if(growers_stored?.length === 1){
        // Fetch data under their client id and data for that grower id
        const grower_id = growers_stored[0]?.id;
        const grower_label = growers_stored[0]?.label;

        // Data Under their client_id
        let response_data = [];
        let url = `${CODA_HOST}/sourcefile/?client_id=${client_id}`
        let response = await axios.post("/coda/make_coda_call/", {"url": url})
        response_data = [...response.data?.results];


        // Data Under their grower_id
        url = `${CODA_HOST}/sourcefile/?grower_id=${grower_id}`
        response = await axios.post("/coda/make_coda_call/", {"url": url})
        response_data = [...response_data, ...response.data?.results];

        setData(response_data);
        setMessage(`Controller Files You Have Uploaded and the files under your Grower : ${grower_label}`)
      }
      else {
        // Files on there client_id alone
        let url = `${CODA_HOST}/sourcefile/?client_id=${client_id}`
        let response = await axios.post("/coda/make_coda_call/", {"url": url})
        setData(response.data?.results);
        setNext(response.data?.next);
        setPrev(response.data?.previous);
        setMessage(`Controller Files You Have Uploaded`)
      }

    } catch (error) {
      console.log(error);
    }
    finally{
      setLoading(false)
    }
  }


  if(loading){
    return (
      <>
        <div className="d-flex vh-100 align-items-center justify-content-center">
            <Spinner />
        </div>
      </>
    )
  }

  return (
    <>
      <p className='fs-4 text-center mt-4 mb-2'>{message}</p>

      {/* next previous refresh buttons */}
      <div className="d-flex p-3">
        { 
            (message === "Controller Files You Have Uploaded") && 
            <p className='fs-5 my-auto'>
              {username ? `Username: ${username}` : `User ID: ${client_id}`}
            </p> 
          } 
          <div className="ms-auto">
                {prev && <button className="mx-2 btn btn-primary" onClick={() => {fetchData(prev, setData, setNext, setPrev, setLoading)}} >Prev</button>}
                {next && <button className="mx-2 btn btn-success" onClick={() => {fetchData(next, setData, setNext, setPrev, setLoading)}} >Next</button>}
                {/* <button title='Refresh' className="btn btn-secondary" onClick={() => {dispatch(tempParametersAddorChange({"reset": true})); fetchData(`${CODA_HOST}/sourcefile/?client_id=18387`)}}> <i className="fa-solid fa-rotate-right"></i> </button> */}
                <button title='Refresh' className="btn btn-secondary" onClick={() => {setRefresh(refresh => !refresh)}}> <i className="fa-solid fa-rotate-right"></i> </button>
                <FilterButton />
          </div>
        </div>


        <figure className='wrapper' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto"}}>
          <table className="table table-hover">
              <TableHeader />

              <tbody>
                  {
                    data.map(( sourcefile , index ) => {
                          return (<>
                              <TableRow data={sourcefile} key={index} id={index} />
                          </>)
                      })
                  }
              </tbody>
          </table>
        </figure>


    </>
  )
}

export default DataTableForGrower