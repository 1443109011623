import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap';

import Navbar from './components/Navbar'
import Offcanvas from '../../components/Offcanvas'
import RequestTable from './components/your_assignments/RequestTable'

function YourAssignments() {

    const [loading, setLoading] = useState(true);

    return (
        <>
        <Navbar pageName={"Your Assignments"} />
        <Offcanvas />

        {
            loading &&                
            <div className="min-vh-100 d-flex align-items-center justify-content-center">
                <Spinner />
            </div>
        }
        
        <div className='page-content d-flex flex-column px-3 my-3 mb-5'>
            <RequestTable setLoading={setLoading} />
        </div>
        </>
    )
}

export default YourAssignments