import React, { useEffect, useState, useContext } from 'react'
import { useTable, useGroupBy, useSortBy, useGlobalFilter, usePagination, useFilters } from 'react-table';
import {Spinner} from 'react-bootstrap'
import axios from 'axios';
import TableFooter from './TableFooter';
import Download from './Download';
import Delete from './Delete';
import Refresh from './Refresh'
import {FileUploaderContext} from '../../context/FileUploaderContext';
import {format} from 'date-fns'

const COLS = ["filename", "category",  "asset_category", "date_uploaded", "asset_label"]

function DataTable() {
    
    const client_id = localStorage.getItem("client_id");

    // Context
    const {
            DATA, SET_DATA, 
            COLUMNS, SET_COLUMNS, 
            loading, setLoading
    } = useContext(FileUploaderContext)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state:  { globalFilter },
        setGlobalFilter,
        nextPage, canNextPage,
        previousPage, canPreviousPage,
        pageOptions,
        state,
        setPageSize,
        gotoPage,
        setFilter
    } = useTable(
    {
        columns: COLUMNS,
        data: DATA// Extract the data arrays
    },
        useFilters,
        useGroupBy,
        useGlobalFilter,
        useSortBy,
        usePagination,
    );
    const {pageIndex, pageSize, filters} = state;



    useEffect(() => {
        fetchUploadedFiles()
    }, [])

    async function fetchUploadedFiles(){
        setLoading(true)
        try {
            const response = await axios.get(`/api/file_uploader/upload/?client_id=${client_id}`);
            if([200,201].includes(response.status)){
                if(response.data?.length > 0) {
                    SET_COLUMNS(COLS.map(e => {return {"accessor": e, "Header": e}}))
                    SET_DATA(response.data);
                } else {
                    SET_DATA([]);
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }


    return (
    <>
    {
        loading ? <div className="d-flex align-items-center justify-content-center my-5"><Spinner /></div> :
        <div className='px-4 my-4'>
            <div className="d-flex justify-content-end ms-auto gap-2">
                <Refresh fetchUploadedFiles={fetchUploadedFiles} />        
            </div>
            <div className="table-responsive table-wrapper-scroll-y my-custom-scrollbar">
                <table {...getTableProps()} className="table table-striped align-middle">
                    <thead className='' style={{zIndex: -9}}>
                        {
                        headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} className='sticky-top pointer text-white'>
                                <th className='bg-dark text-center'>#</th>
                                {
                                    headerGroup.headers.map( column => {
                                    return (
                                        <th className='justify-content-center p-2 bg-dark text-center'>
                                            <span className=' p-4 text-center'>
                                                {column.render('Header')}
                                            </span>
                                        </th>
                                    )
                                    })
                                }
                                <th className='bg-dark text-center'>#</th>
                                <th className='bg-dark text-center'>#</th>
                            </tr>
                        ))
                        }
                    </thead>

                    <tbody {...getTableBodyProps()} style={{maxHeight: "80vh"}}>
                        {page.map((row, index) => {
                        prepareRow(row);
                        const uuid = DATA[index]?.id;
                        return (
                            <tr {...row.getRowProps()}>
                                <td className='text-center'>{index + 1}</td>
                                {row.cells.map(cell => {
                                    return (
                                        <>
                                            <td className='text-center' {...cell.getCellProps()}>
                                                    { cell.column.Header === "filename" && <span title={cell.value}>{cell.value.substring(0,30)}</span> }
                                                    { cell.column.Header === "date_uploaded" && <span>{format(new Date(cell.value), "MMM dd, yyyy")}</span> }
                                                    {!["filename", "date_uploaded"].includes(cell.column.Header) &&  cell.render('Cell') }
                                            </td>
                                        </>
                                    );
                                })}
                                <Download uuid={uuid} />
                                <Delete uuid={uuid} index={index} DATA={DATA} fetchUploadedFiles={fetchUploadedFiles} />
                            </tr>
                        );
                        })}
                    </tbody>
                </table>
            </div>

            <TableFooter 
                pageSize={pageSize} pageIndex={pageIndex} pageOptions={pageOptions} canPreviousPage={canPreviousPage} canNextPage={canNextPage} gotoPage={gotoPage} previousPage={previousPage} nextPage={nextPage} setPageSize={setPageSize} 
            />
        </div>
    }
    </>
    )
}

export default DataTable