import axios from 'axios';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Fade, Spinner } from 'react-bootstrap';
import { download_raw, download_geo_json, download_agi, copy_file_link } from '../utils';
import { useNavigate } from 'react-router-dom';
import { ControllerDataContext } from '../context/ControllerDataContext';
import {format} from 'date-fns'
import GrowerLabel from '../components/GrowerLabel'
import ClientLabel from './ClientLabel';
import PointCount from './PointCount';
import CodaOutput from './CodaOutput';
import { IoCopyOutline } from "react-icons/io5";
import { FiDownload } from "react-icons/fi";
import { useTranslation } from 'react-i18next';

function TableRow(props) {
  
  const { t } = useTranslation();
  const { searchType } = useContext(ControllerDataContext);

  const user_roles = localStorage.getItem("user_roles");
  const controllerFileInfo = useRef(null);

  const navigate = useNavigate();

  const [open , setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
    
  const [ showPointCount , setShowPointCount ] = useState(false)
  const [ pointCountData , setPointCountData ] = useState(false);

  const { id: sourcefile_id, filename, flag_source, creation_time, grower_id, client_id, status_upload, agi_status, status_geojson } = props?.data;

  // Helpers
  // On table row click event
  async function handleExpandRow(){
      setOpen((prev) => {return !prev})
  }

  useEffect(() => {
    if(open)
      controllerFileInfo.current?.classList.add("active");
    else  
      controllerFileInfo.current?.classList.remove("active");
  }, [open])

  // On get_point_count button click event
  async function handleShowPointCount(sourcefile_id){
    setShowPointCount((prev) => {return !prev});
    setPointCountData(false);
    setLoading(true);
    // fetching output....
    try {
      const url = `https://coda.granduke.net/sourcefile/${sourcefile_id}/count_points/`
      const response = await axios.post( "/coda/make_coda_call/" , {"url" : url})
      setPointCountData(response.data);
      setLoading(false);  
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  }

  function show_product_table(){
    navigate("/product_table/", {
      state: {
        sourcefile_id: sourcefile_id,
        filename: filename,
        grower_id: grower_id
      },
      replace: false
    })
  }

  return (
    <>
              <tr >
                <td className='cursor-pointer bg-dark text-white' onClick={handleExpandRow}>{props.id + 1}</td>
                <td className='cursor-pointer bg-dark text-white' onClick={handleExpandRow}>{format(new Date(creation_time), "MMM dd, yyyy hh:mm")}</td>
                <td className='bg-dark text-white' title={filename}>{filename?.substring(0, 40)}</td>
                <td className='cursor-pointer bg-dark text-white' onClick={handleExpandRow}><button className='btn btn-outline-primary bg-gradient' onClick={(event) => {download_raw(event, props.data)}}>Download Raw</button></td>
                <td className='cursor-pointer bg-dark text-white' onClick={handleExpandRow}>{flag_source}</td>
                {
                  ["filename", "default"].includes(searchType) ?
                    <td className='cursor-pointer bg-dark text-white' onClick={handleExpandRow}> <GrowerLabel grower_id={grower_id} /> </td> :
                    <td className='cursor-pointer bg-dark text-white' onClick={handleExpandRow}> <ClientLabel client_id={client_id} /> </td> 
                }

                <td className='cursor-pointer bg-dark text-white' onClick={handleExpandRow}>{status_upload}</td>
                <td className='cursor-pointer bg-dark text-white' onClick={handleExpandRow}>{String(agi_status)}</td>

                <td className='bg-dark text-white'>  {(grower_id && (String(status_geojson) === 'Processed')) && <button className='btn btn-outline-primary bg-gradient' onClick={show_product_table}>{t("Get Products")}</button> }  </td>
                {/* <td className='bg-dark text-white'>  {String(props.data?.client_id) === localStorage.getItem("client_id") && (String(props.data?.agi_status) === 'Complete') && <button className='btn btn-outline-secondary' onClick={show_product_table}>Get Products</button> }  </td> */}

                <td className='bg-dark text-white' onClick={() => {handleShowPointCount(props.data?.id)}}><button className="btn btn-outline-primary bg-gradient">{t("Get Point Count")}</button></td>
                
                {/* Download GeoJSONSummary & Download AGI */}
                <td className='bg-dark text-white'>
                  {
                    String(agi_status) === 'Complete' &&
                    <>
                      <button className="btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false"> <i className="fa-solid fa-ellipsis-vertical"></i> </button>
                      <ul className="dropdown-menu text-start">

                        <DropdownItem 
                            onClick={(event) => {download_geo_json(event, props.data)}}
                            text={t("Download Summary Shapefile")}
                            icon={<FiDownload size={20} />}
                          />
                      
                        <DropdownItem 
                            onClick={(event) => {download_raw(event, props.data)}}
                            text={t("Download RAW")}
                            icon={<FiDownload size={20} />}
                          />
                        
                        <DropdownItem 
                            onClick={(event) => {download_agi(event, props.data)}}
                            text={t("Download AGI")}
                            icon={<FiDownload size={20} />}
                          />

                        <DropdownItem 
                            onClick={(event) => {copy_file_link(event, props.data)}}
                            text={t("Copy File Link")}
                            icon={<IoCopyOutline size={20} />}
                          />
                        
                        { !user_roles?.includes("Grower") && <li><button className="dropdown-item" ref={controllerFileInfo} onClick={handleExpandRow}>{t("Controller File Info")}</button></li> }
                      </ul>
                    </>
                  }
                </td>
              </tr>


              {/* Displays Coda Output  */}
              <CodaOutput 
                  open={open} setOpen={setOpen}
                  data={props.data}
                  />

              
              {/* Show Point Count */}
              <PointCount 
                  pointCountData={pointCountData} 
                  showPointCount={showPointCount} setShowPointCount={setShowPointCount}
                  loading={loading}
                  handleShowPointCount={handleShowPointCount} 
                />
                
        </>
  )
}

function DropdownItem({ text, icon, onClick, }){
  
  return (
    <li>
      <button className="dropdown-item" onClick={onClick}>
        {icon} {text}
      </button>
    </li>
  )
}

export default TableRow