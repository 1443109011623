import axios from 'axios'
import {TextColumnFilter, DateRangeColumnFilter} from './Filters'
import {format} from 'date-fns'

export async function fetchData(SET_DATA, SET_COLUMNS, SET_KEYS, setLoading){
    try {
        setLoading(true)
        const response = await axios.get("/api/smartreporting/get_report/")
        const DATA = response.data;

        // Customize Cols
        let keys = new Set();
        SET_COLUMNS(
            Object.keys(DATA[0]).map( key => {
                const [colHeader, colName] = key.split("-");
                keys.add(colHeader)

                // Grower Label
                const format_selectors = ["grower_label", "Cliente"]
                if( format_selectors.includes(colName) ){
                    let Cell = ({value}) => (value ? <p className='my-auto text-center rounded-2 p-2' style={{backgroundColor: "#ffc69b"}}>{value}</p> : "")
                    return constructColObject(colHeader, colName, key, Cell, TextColumnFilter, 'text' )
                }

                // field_label & hub_label
                const text_col_filter_selectors = ["field_label", "hub_label", "Cliente", "Central", "municipality"]
                if( text_col_filter_selectors.includes(colName) ){
                    return constructColObject(colHeader, colName, key, null, TextColumnFilter, 'text' )
                }

                else if( colName === "seeding_slope") {
                    return constructColObject(colHeader, colName, key, null, TextColumnFilter, 'includes' )
                }
                
                // Date Cols
                else if(colName.includes("date") || colName.includes("Data")){
                    let Cell = ({value}) => ((value && isDateTimeFormat(value)) ? <span>{format(new Date(value), 'dd-MM-yyyy')}</span> : "")
                    return constructColObject(colHeader, colName, key, Cell, null, null )
                }
                
                // Other Cols
                else{
                    return constructColObject(colHeader, colName, key, null, null, null);
                }
        }));
        SET_KEYS(Array.from(keys));
        
        SET_DATA(DATA)

    } catch (error) {
        console.log(error);
    } finally {
        setLoading(false)
    }
}

function constructColObject(colHeader, colName, key, Cell, Filter, filter){
        return {
            Header :  colHeader,
            columns: [
                {
                    "Header": colName,
                    "accessor": key,
                    ...(Cell) && {Cell: Cell},
                    ...(Filter) ? {Filter: Filter} : {disableFilters: true},
                    ...(filter) && {filter: filter}
                }
            ]
        }
}

function isDateTimeFormat(inputString) {
    // Define a regular expression pattern to match the datetime format
    var dateTimePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}$/;

    // Use the test() method to check if the inputString matches the pattern
    return dateTimePattern.test(inputString);
}
