import React from 'react'
import { Accordion } from 'react-bootstrap'
import JobsTable from './JobsTable'

const JobsModule = ({children}) => {
    return (
    <Accordion >
        <Accordion.Item className='rounded mb-4 mt-1'>
            <Accordion.Header className='custom-header fs-4'>{"Jobs Module"}</Accordion.Header>
            <Accordion.Body>
                <JobsTable />
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
    )
}

export default JobsModule