import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'

import {useDispatch} from 'react-redux'
import {requestParametersAddorChange} from '../../../features/RequestParametersSlice'
import { useTranslation } from 'react-i18next'

function Seasons({visibility}) {

    const { t } = useTranslation();
    const dispatch = useDispatch()
    
    const [seasons , setSeasons] = useState([]); // stores the seasons returned from api
    const [selectedSeason, setSelectedSeason] = useState(null) // to track season that has been selected

    async function fetchSeasons(){
        // Makes api call to fetch seasons, [returned seasons] are set to seasons state.
        try{
            let response = await axios("/api/getSeasons/");
            setSeasons(response.data.map((e) => { return {"value" : e.id , "label" : e.label}}));
        }
        catch(err){
            console.log('Error while fetching seasons')
        }
    }

    // Side Effects

    // Initial Render
    useEffect(() => {
        // Fetching seasons.. when it is rendered...
        fetchSeasons();
    }, []); 

    // On Every Visibility change
    useEffect(() => {
        setSelectedSeason(null)
    }, [visibility])

    function handleChange(event){
        // Adding selected season to the local & global state.
        setSelectedSeason(event)
        dispatch(requestParametersAddorChange({ season :  event.value }))
    }

    return (
        <>
            <Select placeholder={t("Select a season")} options={seasons} value={selectedSeason} onChange={handleChange} />
        </>
    )
}

export default Seasons