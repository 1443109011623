import { MdDownloadForOffline } from "react-icons/md";
import { format } from 'date-fns'
import { toast } from "react-toastify";
import axios from "axios";
import { API_ENDPOINTS, STATUS } from "../../constants";
import SRInfo from './SRInfo';
import { useState } from "react";


const TableRow = ({index, instance}) => {

    const [ open, setOpen ] = useState(false);

    const { 
        sr_key, operation, customer_request, 
        creation_time, status, gcs_path
    } = instance;

    async function handleDownload(event, gcs_path){
        try{
            toast("Downloading..")
            const response = await axios.post(`${API_ENDPOINTS.GENERATE_BLOB_URL}/`, {"gcs_path": gcs_path})
            let a = document.createElement('a');
            a.href = response.data?.blob_url;
            document.body.appendChild(a); 
            a.click();    
            a.remove(); 
            toast("Downloaded..")
        } catch {
            toast("Download Failed..")
        }
    }

    function expandRow(){
        setOpen(open => (!open));
    }

    return (
        <>
            <tr className="table-light align-middle">
                <td onClick={expandRow}>{index + 1}</td>
                <td onClick={expandRow}>{sr_key}</td>
                <td onClick={expandRow}>{customer_request}</td>
                <td onClick={expandRow}>{operation}</td>
                <td onClick={expandRow}>{creation_time && format(new Date(creation_time), "dd-MMM-yy hh:mm aa")}</td>
                <td onClick={expandRow}>{status}</td>
                <td>
                    {
                        status === STATUS.COMPLETED &&
                        <button className="btn btn-outline-dark py-2" onClick={(event) => {handleDownload(event, gcs_path)}} title="download">
                            <MdDownloadForOffline size={24} />
                        </button>
                    }
                </td>
            </tr>
            <SRInfo open={open} setOpen={setOpen} data={instance} key={sr_key} />
        </>
    )
}

export default TableRow