import { useRef } from 'react'
import {Accordion} from 'react-bootstrap'
import ImplementTable from './ImplementTable'

const ImplementModule = ({children}) => {
    const implementAccordion = useRef();
    return (
        <>
        <Accordion >
            <Accordion.Item className='rounded mb-4 mt-1' ref={implementAccordion}>
                <Accordion.Header className='custom-header fs-4'>{"Implement Module"}</Accordion.Header>
                <Accordion.Body>
                    <ImplementTable implementAccordion={implementAccordion} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        </>
    )
}

export default ImplementModule