import React from 'react'
import FarmCommandLogoGrey from '../assets/images/FarmCommandLogoGrey.png'
import { useNavigate } from 'react-router-dom'

function NavbarWithBack({pageName, backUrl}) {

    const navigate = useNavigate();

    // Event Handler
    function handleBackClick(event){
        if(backUrl)
            navigate(backUrl)
        else
            navigate(-1);
    }

    return (
        <>
            <nav className="navbar navbar-expand-sm navbar-white" style={{background: "#02559c"}}>

                <button className="ms-3 btn btn-outline-light" type="button"  data-bs-toggle="offcanvas" href="#offcanvasExample">
                    <i className="fa-solid fa-bars"></i>
                </button>

                {/* FARMERSEDGE | DPS GUI */}
                {/* <img src={FarmCommandLogoGrey} alt="" className="navbar-brand mx-auto d-none d-md-block" height={25} style={{filter: "brightness(200)"}} /> */}
                <span className='text-white' style={{marginLeft: "2.4rem"}}>{pageName}</span>
                <img src={FarmCommandLogoGrey} alt="" className="navbar-brand d-flex text-center mx-auto d-none d-md-block" height={23} style={{filter: "brightness(200)"}} />

                <button className='btn btn-outline-light mx-md-2 ms-auto me-3 p-2 fw-bold' onClick={ handleBackClick } title="Back"><i className="fa-solid fa-arrow-left-long"></i></button>

            </nav>
        </>
    )
}

export default NavbarWithBack