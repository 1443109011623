import { MdDownloadForOffline, MdOutlineReplay } from "react-icons/md";
import { format } from 'date-fns'
import { toast } from "react-toastify";
import axios from "axios";
import { useState } from "react";
import { Fade } from "react-bootstrap";
import { t } from 'i18next'


const TableRow = ({ index, instance, onResubmit }) => {

    const [ open, setOpen ] = useState(false);
    const { creation_time, status, gcs_path, attributes, ref_no, modified_time } = instance;
    const attributesObj = JSON.parse(attributes);
    async function handleDownload(event, gcs_path, ref_no){
        try{
            toast("Downloading..")
            const response = await axios.post(`/reporting/sail/generate-blob-url/`, {"gcs_path": gcs_path, "ref_no": ref_no});
            let a = document.createElement('a');
            a.href = response.data?.blob_url;
            document.body.appendChild(a); 
            a.click();    
            a.remove(); 
            toast("Downloaded..")
        } catch {
            toast("Download Failed..")
        }
    }
    
    async function handleResubmit() {
      try {
          toast("Resubmitting..");
          const response = await axios.post('/reporting/sail/resubmit/', { id: instance.id });
          if (response.status === 200) {
              onResubmit();
              toast("Resubmitted successfully.");
          }
      } catch (error) {
          toast("Resubmission Failed..");
          console.log('Error while resubmitting the task...', error);
      }
    }
    const isResubmitDisabled = !["completed", "failure", "processing", "resubmitted"].includes(status) || !attributesObj["version"];
    const showResubmit = attributesObj["version"] ? attributesObj["version"] : false;
    function expandRow(){
        setOpen(open => (!open));
    }

    return (
        <>
            <tr className="table-light align-middle">
                <td className='col-1'onClick={expandRow}>{index + 1}</td>
                <td className='col-2 hide-mobile'onClick={expandRow}>{creation_time && format(new Date(creation_time), "dd-MMM-yy hh:mm aa")}</td>
                <td className='col-2 hide-mobile'onClick={expandRow}>{modified_time && format(new Date(modified_time), "dd-MMM-yy hh:mm aa")}</td>
                <td className='col-5' onClick={expandRow}>
                  {showResubmit
                    ? ('Village: ' + attributesObj["village"] +', Loan Ref. No: ' + attributesObj["ref_no"] )
                    : ('Village: ' + attributesObj["Village"] +', Loan Ref. No: ' + attributesObj["Farm Name"])
                  }
                </td>
                <td className='col-1'onClick={expandRow}>{status}</td>
                <td className='col-1 text-center'>
                {
                  showResubmit &&
                  <button className="btn btn-outline-dark py-2 w-50"  onClick={handleResubmit} title="resubmit" disabled={isResubmitDisabled}>
                      <MdOutlineReplay size={24}  />
                  </button>
                }
                </td>
                <td className='col-1 text-center'>
                    {
                        status === "completed" &&
                        <button className="btn btn-outline-dark py-2 w-75" onClick={(event) => {handleDownload(event, gcs_path, ref_no)}} title="download">
                            <MdDownloadForOffline size={24} />
                        </button>
                    }
                </td>
            </tr>
            <Info open={open} setOpen={setOpen} data={instance} key={index} />
        </>
    )
}
function Info({ open, setOpen, data }) {
    const { user_id, attributes, message } = data;

    let parsedAttributes;
  
    try {
      parsedAttributes = JSON.parse(attributes);
    } catch (error) {
      console.error('Failed to parse attributes JSON', error);
      parsedAttributes = null;
    }
  
    return (
      <>
        <Fade in={open} unmountOnExit={true}>
          <tr className="table-dark fs-5" onDoubleClick={() => { setOpen(false) }}>
            <td colSpan={7}>
              <div className="my-3 p-3">
                <p>{t("Attributes")}:</p>
                {parsedAttributes ? (
                  <div className="ps-3">
                    {Object.entries(parsedAttributes).map(([key, value]) => (
                      <div key={key}>{key}: {value}</div>
                    ))}
                  </div>
                ) : (
                  <pre><code>{attributes}</code></pre>
                )}
                <p>{t("User ID")}: {user_id}</p>
                <p>{t("Message")}: {message}</p>
              </div>
            </td>
          </tr>
        </Fade>
      </>
    );
  }
export default TableRow