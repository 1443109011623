import axios from "axios"
import {toast} from 'react-toastify'
import { useTranslation } from "react-i18next"
const Delete = ({uuid, index, fetchUploadedFiles, DATA}) => {

    const stored_client_id = localStorage.getItem("client_id");
    const {client_id, filename} = DATA[index];

    // Helper
    async function handleDeleteClick(){
        toast("Deleting");
        try {
            const response = await axios.delete(`/api/file_uploader/upload/?id=${uuid}`)
            if(response.status === 204){
                toast("Deleted");
            }
        } catch (error) {
            console.log(error)
            toast("Delete Failed");
        }
        fetchUploadedFiles();
    }

    return (
        <>
            {
                String(stored_client_id) === String(client_id) &&
                <td className='text-center'>
                    <button className="btn btn-outline-danger" data-bs-toggle="modal" href="#delete-confirmation-modal" >
                        <i className="fa-solid fa-trash"></i>
                    </button>
                </td>
            }
            <DeleteConfirmationModal handleDeleteClick={handleDeleteClick} filename={filename} />
        </>
    )
}

function DeleteConfirmationModal({filename, handleDeleteClick}){

    const { t } = useTranslation();

    return (
        <div className="modal" tabindex="-1" id="delete-confirmation-modal">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">{t("Delete Confirmation")}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <p>{t("Are Your Sure You want to delete")} <span className="fw-bold">{filename}</span>.</p>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-primary" data-bs-dismiss="modal"><i className="fa-solid fa-arrow-left"></i> {t("Cancel")}</button>
                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={handleDeleteClick}>{t("Delete")} <i className="fa-solid fa-trash"></i></button>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Delete