import React , { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {requestParametersAddorChange} from '../features/RequestParametersSlice'
import {ticketParametersAddorChange} from '../features/TicketParametersSlice'
import { useTranslation } from 'react-i18next'

function Runtype() {

  const { t } = useTranslation();
  // Dispatcher
  const dispatch = useDispatch()  

  useEffect(() => {
    // setting default runtype as applied to global state
    dispatch(requestParametersAddorChange({ 'runtype' : "applied" }))
    dispatch(ticketParametersAddorChange({ 'runtype': "Applied"}))
  } , [])

  function handleChange(event){
    // Adding selected runtype to the global state.
    const selected_runtype = event.target.value;
    dispatch(requestParametersAddorChange({'runtype' : selected_runtype}))
    dispatch(ticketParametersAddorChange({'runtype': selected_runtype.charAt(0).toUpperCase() + selected_runtype.slice(1)})) // Capitalize first letter
  }

  return (
    <>
        <select name="layerType" id="layerType" className='form-control shadow-lg' onChange={handleChange}>
            <option value="applied" className='form-control' defaultValue>{t("Applied")}</option>
            <option value="harvest" className='form-control'>{t("Harvest")}</option>
        </select>
    </>
  )
}

export default Runtype