import React, { useContext, useEffect, useState } from 'react'
import Seasons from '../Seasons'
import { useDispatch, useSelector } from 'react-redux';
import { requestParametersAddorChange, requestParametersReset } from '../../../../features/RequestParametersSlice';
// import { addOrChange } from '../../../../features/Globals';
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { MapsContext } from '../../context/MapsContext'

function ById({visibility}) {

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const requestParameters = useSelector(state => state.requestParameters)

    const [assetIDs, set_assetIDs] = useState(""); // Local state for fieldIDs
    
    // Context
    const {
        assetsLoading, setAssetsLoading,
        error_message, set_error_message
    } = useContext(MapsContext)

    // Side Effects
    useEffect(() => {
        // Reset requestParams on every render & set runtype as ['applied', 'harvest']
        set_assetIDs("")
        dispatch(requestParametersAddorChange({"runtype": ["applied", "harvest"]}));
    }, [visibility])

    // Event Handlers
    function handleAssetIDChange(event){
        set_assetIDs(event.target.value)    
    }

    function handleRuntypeChange(event){
        let runtype = event.target.value;
        let runtypes = [...requestParameters["runtype"]]

        if(!event.target.checked){
            dispatch(requestParametersAddorChange({"runtype": runtypes?.filter(e => e !== runtype)}))
        } 
        else {
            if(!runtypes.includes("harvest") && runtype === "harvest"){
                runtypes.push("harvest");
            }
            if(!runtypes.includes("applied") && runtype === "applied"){
                runtypes.push("applied");
            }
            dispatch(requestParametersAddorChange({"runtype": runtypes}))
        }
    }

    async function handleGenerateClick(event){
        set_error_message("")
        dispatch(requestParametersAddorChange({"field_ids": [], "field_labels": []}))
        
        setAssetsLoading(true);
        try {

            let asset_ids = assetIDs.split(",").map( e => Number(e)).filter(e => {
                return !isNaN(e);
            }).filter(e => e >= 1);

            const response = await axios.post("/api/getFieldsFromAsset/", {
                "fieldOrFarmOrGrowerIDs": asset_ids,
                "seasonID": requestParameters.season,
                "host": localStorage.getItem("FARMCOMMAND_HOST")
            });

            if(response.data?.fields && response.data?.fields.length > 0) {
                let field_ids = [], field_labels = [];
                
                response.data?.fields?.forEach(field => {
                    field_ids.push(field?.id);
                    field_labels.push(field?.label);
                })
                dispatch(requestParametersAddorChange({"field_ids": field_ids, "field_labels": field_labels}))
            }
            else{
                set_error_message("No fields found, please check the assetID")
            }
            
            document.querySelector("#offcanvas-end-close-button")?.click();
        } catch (error) {
            set_error_message("Oops! Something went wrong")
        } finally {
            setAssetsLoading(false);
            dispatch(requestParametersAddorChange({"current_field_id": ""}));
        }
    }
    
    return (
        <div className={!visibility ? 'd-none' : ""}>
            <Seasons key={"seasons"} visibility={visibility}/>

            {/* Asset ID */}
            <input type="text" className="form-control my-3" placeholder={t("Enter Asset ID(s)")} id='assetIDs' value={assetIDs} onChange={handleAssetIDChange} autoComplete='off'/>

            {/* Runtype */}
            <div className="d-flex justify-content-center my-2">
                <div className="flex-item mx-2">
                    <input className="form-check-input" type="checkbox" name="runtype" id="applied" value="applied" onChange={handleRuntypeChange} checked={requestParameters?.runtype?.includes("applied")}/>
                    <label className="form-check-label mx-1" for="applied"> {t("Applied")} </label>
                </div>
                
                <div className="flex-item mx-2">
                    <input className="form-check-input" type="checkbox" name="runtype" id="harvest" value="harvest" onChange={handleRuntypeChange} checked={requestParameters?.runtype?.includes("harvest")} />
                    <label className="form-check-label mx-1" for="harvest"> {t("Harvest")} </label>
                </div>
            </div>


            {
                assetsLoading &&
                <div className="d-flex my-5 align-items-center justify-content-center">
                    <Spinner />
                </div>
            }

            {
                error_message &&
                <p className="my-5 text-danger text-center fs-5">{error_message}</p>
            }

            {/* Generate Button */}
            {
                ( assetIDs && requestParameters.season && requestParameters.runtype && requestParameters.runtype.length >= 1) &&
                <button className="btn btn-outline-success mt-5 w-100" onClick={(event) => {handleGenerateClick(event)}}>{t("Generate")}</button>
            }
        </div>
    )
}

export default ById