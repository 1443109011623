import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'

import { useDispatch, useSelector } from 'react-redux'
import { requestParametersAddorChange } from '../features/RequestParametersSlice'
import { ticketParametersAddorChange } from '../features/TicketParametersSlice'
import { useTranslation } from 'react-i18next'

function Farms() {
    
    const { t } = useTranslation();
    // Dispatcher
    const dispatch = useDispatch()

    // Global State
    const requestParameters = useSelector(state => state.requestParameters);
    
    const [ farms , setFarms ] = useState([]) // used to store farms fetched from api call
    const [ show , setShow ] = useState(false) // used to control display of the farms selector

    async function fetchFarms(){
        // makes api call to fetchFarms for the selected grower & selected season
        setShow(false);
        setFarms([]);
        
        try{
            console.log(`/api/getFarms/?growerID=${requestParameters.grower}&seasonID=${requestParameters.season}`)
            let response = await axios(`/api/getFarms/?growerID=${requestParameters.grower}&seasonID=${requestParameters.season}`);
            setFarms(response.data.features.map((e) => { return {"value" : e.properties.farm , "label" : e.properties.farm_label}}));
            setShow(true)
          }
        catch(err){
            console.log('Error while fetching farms...')
        }
    }

    // Fetch farms, when grower/hub/season changes
    useEffect(() => {
        setFarms([]);
        fetchFarms();
    }, [requestParameters.grower, requestParameters.season]);

    function handleChange(event){
        // adding selected farm to the global state.
        let farms = event.map(e => e.label);
        let farm_ids = event.map(e => e.value);

        dispatch(requestParametersAddorChange({farm: event.map((e) => {return e.value}) , fields: '' , runtype: '' , datatype: ''}))
        dispatch(ticketParametersAddorChange( {'farm': farms?.toString(), 'farm_id': farm_ids?.toString(), field: '', field_id: '', runtype: '' , datatype: ''}))
    }


    return (
        <>
             {show && <Select placeholder={t("Select farms")} options={farms} onChange={handleChange} isMulti closeMenuOnSelect={false} />}
        </>
    )
}

export default Farms