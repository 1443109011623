import React, { useEffect, useState } from 'react'
import axios from 'axios' 
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import {FARM_COMMAND_HOST} from '../../constants'
import Offcanvas from '../../components/Offcanvas'
import Navbar from '../settings/components/Navbar'
import {  DashboardContextProvider } from './context/DashboardContext'
import './styles.css'
import { allowedPagesFunction } from 'components/Offcanvas/allowedPages.js'
import WidgetLayout from './components/widgets/WidgetLayout'
import GridLayout from "./components/GridLayout";
import { fetch_growers, initializeLayouts } from '../home_page/utils';

function HomePage() {
    const { t } = useTranslation();

    const [userInfo , setUserInfo] = useState(false)
    const [loading , setLoading] = useState(false)
    const [preferredLanguage , setPreferredLanguage] = useState('')
    const [userName , setUsername] = useState("");
    const [pageName] = useState("Dashboard");
    const [layoutsInitialized, setLayoutsInitialized] = useState(false); // New state
    
    useEffect(() => {
        if(!localStorage.getItem('language')){ 
            setPreferredLanguage('en_US'); localStorage.setItem('language', 'en_US') }
        else {
            setPreferredLanguage(localStorage.getItem('language'));
        }

        
        if (!userInfo)  (async () => {
            try {
                setLoading(true);
                const jira_user_response = await axios.get("/api/getUserInfo/");
                if(jira_user_response.data?.userInfo){
                    localStorage.setItem("JIRAAuthenticated", JSON.stringify(true))
                }
                const current_user_response = await axios.get(`${FARM_COMMAND_HOST}/client/currentuser/` , {headers : {"Authorization" : `Token ${localStorage.getItem("token")}`}}); 
                if (current_user_response.status === 200) { 
                    setUserInfo(current_user_response.data);  
                    setUsername(`${current_user_response.data?.first} ${current_user_response.data?.last}`)
                    localStorage.setItem('client_id', current_user_response.data?.id);
                    localStorage.setItem("user_roles" , current_user_response.data?.roles);
                    localStorage.setItem("username", current_user_response.data?.user?.username);
                    localStorage.setItem("first", current_user_response.data?.first);
                    localStorage.setItem("last", current_user_response.data?.last);
                    localStorage.setItem("displayName", current_user_response.data?.first+', '+current_user_response.data?.last);
                    allowedPagesFunction();
                    initializeLayouts(); // Initialize layouts after successful login
                    setLayoutsInitialized(true); // Trigger re-render
                }
            } catch (error) {
                console.log(error);
            }
            finally{
                fetch_growers();
                setLoading(false);
            }
            
            })
        ();       
        }
    , [userInfo]);


    if(loading){
        return (<> 
        <div className="d-flex vh-100 align-items-center justify-content-center">
            <Spinner />
        </div>
        </>)
    }

    else{
        return (
        
        <DashboardContextProvider>
                <Navbar {...{pageName, userInfo}}/>
                <Offcanvas />
                <div className="d-flex min-vh-100 justify-content-center login-bg" >
                    <div className='container-fluid'>
                        <div className="home-page-content row">
                            {/* <UserInfo {...{userInfo, userName, preferredLanguage, setPreferredLanguage, loading}} /> */}
                            <div className="col-12 col-xxl-6 align-items-center mt-4">               
                                {!loading && <GridLayout /> }
                            </div>
                            <div className="col-12 col-xxl-6 align-items-center mt-4">
                                {!loading && <WidgetLayout /> }
                            </div>
                        </div>
                    </div>
                </div>


        </DashboardContextProvider>
    )
    }
}

export default HomePage