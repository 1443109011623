import {createSlice} from '@reduxjs/toolkit'

const initialState = {  }

const tempParametersSlice = createSlice({
    name: "tempParameters",
    initialState: initialState,
    reducers: {
        tempParametersAddorChange : (state , action) => {
            // Adds if the payload is new, changes if it already exist
            return {
                ...state,
                ...action.payload
            }
        },

        tempParametersAddorPushToObject : (state, action) => {
            let key = Object.keys(action.payload)[0];
            state[key] = {...state[key], ...action.payload[key]};
        },

        tempParametersDeleteKeyFromObject : (state, action) => {
            let object_key = action.payload?.object_key;
            let key_to_delete = action.payload?.key_to_delete;
            delete state[object_key][key_to_delete];
        },

        tempParameterRemove : (state , action) => {
            // Reletes the payload from the global state.
            const copy = {...state};
            delete copy[action.payload];
            return copy;
        },

        tempParametersReset : (state , action) => {
            // resets the state, sets to empty object.
            return {...initialState}  
        } 
    },
})

export const { tempParametersAddorChange, tempParameterRemove, tempParametersReset, tempParametersAddorPushToObject, tempParametersDeleteKeyFromObject  } = tempParametersSlice.actions
export default tempParametersSlice.reducer;