import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Accordion, Spinner } from 'react-bootstrap'
import { useSearchParams } from 'react-router-dom';

const PDFAccordion = ({ headerText, pageID, slug }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const search_slug = searchParams.get("instruction")

    const [pdf, setPDF] = useState(null);
    const [loading, setLoading] = useState(false);

    async function fetchPDF(){
        setLoading(true)
        try {
            const response = await axios.get(`/api/getConfluenceAttachment/?pageID=${pageID}`, {responseType: 'arraybuffer'})
            const data = new Blob([response.data], { type: 'application/pdf' });
            const pdfUrl = URL.createObjectURL(data);
            setPDF( pdfUrl );
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }
    
    useEffect(() => {
        fetchPDF()
    }, [])

    // Event Handler
    function handleAccordionClick(event, slug){
        if(slug === search_slug){
            searchParams.delete("instruction")
        } else{
            searchParams.set("instruction", slug)
        }
        setSearchParams(searchParams);
    }

    return (
        <Accordion activeKey={search_slug} id={slug}>
            <Accordion.Item eventKey={slug}  className='rounded my-4' onClick={(event) => {handleAccordionClick(event, slug)}}>
                <Accordion.Header className='fs-4'>{headerText}</Accordion.Header>

                <Accordion.Body>
                    {   
                    loading ? <Spinner /> 
                        : 
                        <div className="d-flex align-items-center" style={{background: "#d6eef2", border: "1px #ffc107 solid", "height": "500px" }}>
                            {pdf && <object className={`w-100 h-100`} data={pdf} type="application/pdf">
                                <p className='fs-5 mx-auto'>Unable to display, <a href={pdf} alt="asset" className="btn btn-outline-dark mx-1">Download</a> instead.</p>
                            </object>}
                        </div>
                    }
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default PDFAccordion