import { useDispatch, useSelector } from 'react-redux'
import { requestParametersAddorChange } from '../../../features/RequestParametersSlice'
import { t } from 'i18next'

const ContactName = () => {
    
    const { contact_name } = useSelector(state => state.requestParameters);
    const dispatch = useDispatch(); 

    return (
        <div>
            <input 
                id="contact-name"
                name="contact-name" 
                type="text" 
                placeholder={t("Enter Your Contact Name")}   
                value={contact_name}
                className='form-control'
                onChange={
                    e => dispatch(requestParametersAddorChange({ "contact_name": e.target.value }))
                }
                />

        </div>
    )
}

export default ContactName