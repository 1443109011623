import React, { useEffect, useState, useCallback} from 'react'
import { Spinner } from 'react-bootstrap'
import { IoMdRefresh } from "react-icons/io";
import { useTranslation } from 'react-i18next';
import { MdDownloadForOffline } from "react-icons/md";
import { format } from 'date-fns'
import { toast } from "react-toastify";
import axios from 'axios';
import SRInfo from './SRInfo'
import { capitalizeFirstLetter } from '../../../../utils/helpers'
import { API_ENDPOINTS, STATUS } from "../../constants";


const Datatable = () => {
        const { t } = useTranslation();
        const user_id = localStorage.getItem("client_id");
        const [loading, setLoading] = useState(false);
        const [data, setData] = useState([]);
        const [next, setNext] = useState(false);
        const [prev, setPrev] = useState(false);
        const [status, setStatus] = useState('initial'); // Add a status state variable
    
        const fetchData = useCallback(async (url) => {
            setData([]);
            setLoading(true);
            try {
                const response = await axios.get(url);
                if (response.status === 200) {
                    setData(response.data?.results);
    
                    if (document.location.protocol === 'http:') {
                        setPrev(response.data?.previous);
                        setNext(response.data?.next);
                    } else if (document.location.protocol === 'https:') {
                        setPrev(response.data?.previous ? response.data.previous.replace("http://", "https://") : false);
                        setNext(response.data?.next ? response.data.next.replace("http://", "https://") : false);
                    }
                    setStatus('loaded'); // Update status after data is fetched
                } else {
                    setStatus('failed'); // Update status if the response is not 200
                }
            } catch (error) {
                console.log('Error while fetching posted tasks...');
                setStatus('failed'); // Update status if there is an error
            } finally {
                setLoading(false);
            }
        }, []);
    
        const fetchSRs = useCallback(() => {
            try {
                const url = `${API_ENDPOINTS.SR_ADMIN}/`;
                fetchData(url);
            } catch (error) {
                console.log(error);
                setStatus('failed'); // Update status if there is an error
            }
        }, [fetchData]);
    
        useEffect(() => {
            if (status === 'initial') {
                fetchSRs();
            }
        }, [fetchSRs, status]); // Include status in the dependency array
    
        if (loading) {
            return (
                <div className="d-flex my-5 justify-content-center">
                    <Spinner />
                </div>
            );
        }
    
        if (status === 'failed') {
            return (
                <div className="d-flex my-5 justify-content-center">
                    <p>{t("Failed to fetch data. Please try again later.")}</p>
                </div>
            );
        }

    return (
        <>

        <div className="d-flex justify-content-end mb-2">
            {prev && <button className="mx-2 btn btn-primary" onClick={() => {fetchData(prev)}} >{t("Prev")}</button>}
            {next && <button className="mx-2 btn btn-success" onClick={() => {fetchData(next)}} >{t("Next")}</button>}
            <button className="btn btn-secondary" onClick={fetchSRs}> <IoMdRefresh size={24} /> </button>
        </div>

        <div className="table-responsive mx-2">
            <table className="table table-hover table-striped table-bordered shadow align-middle text-center">
                <thead className='table-dark'>
                    <tr>
                        <th>#</th>
                        <th>SR-ID</th>
                        <th>REQUEST TYPE</th>
                        <th>OPERATION</th>
                        <th>CREATED_ON</th>
                        <th>STATUS</th>
                        <th>#</th>
                    </tr>
                </thead>
                <tbody>
                        {
                            data && data.map((instance, index) => {
                                return (
                                    <TableRow instance={instance} key={index} index={index} />
                                )
                            })
                        }
                </tbody>
            </table>
        </div>
        </>
    )
}


export const TableRow = ({index, instance}) => {

    const [ open, setOpen ] = useState(false);

    const { 
        sr_key, operation, customer_request, 
        creation_time, status, gcs_path
    } = instance;

    async function handleDownload(event, gcs_path){
        try{
            toast("Downloading..")
            const response = await axios.post(`${API_ENDPOINTS.GENERATE_BLOB_URL}/`, {"gcs_path": gcs_path})
            let a = document.createElement('a');
            a.href = response.data?.blob_url;
            document.body.appendChild(a); 
            a.click();    
            a.remove(); 
            toast("Downloaded..")
        } catch {
            toast("Download Failed..")
        }
    }

    function expandRow(){
        setOpen(open => (!open));
    }

    return (
        <>
            <tr className="table-light align-middle">
                <td onClick={expandRow}>{index + 1}</td>
                <td onClick={expandRow}>{sr_key}</td>
                <td onClick={expandRow}>{customer_request}</td>
                <td onClick={expandRow}>{capitalizeFirstLetter(operation)}</td>
                <td onClick={expandRow}>{creation_time && format(new Date(creation_time), "dd-MMM-yy hh:mm aa")}</td>
                <td onClick={expandRow}>{status}</td>
                <td>
                    <div className="my-2">
                    {
                        status === STATUS.COMPLETED &&
                        <button className="btn btn-outline-dark" onClick={(event) => {handleDownload(event, gcs_path)}} title="download">
                            <MdDownloadForOffline size={24} />
                        </button>
                    }
                    </div>
                </td>
            </tr>
            <SRInfo open={open} setOpen={setOpen} data={instance} key={sr_key} />
        </>
    )
}

export default Datatable
