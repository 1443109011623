import React, { useState } from 'react'
import Select from 'react-select'

const FARMCOMMAND_HOSTS = [{"label": "https://qa.granduke.net", "value": "https://qa.granduke.net"}, {"label": "https://processing-api.farmcommand.com", "value": "https://processing-api.farmcommand.com"}]

function HostSelection() {

    const [FARMCOMMAND_HOST, SET_FARMCOMMAND_HOST] = useState(() => {
        const FC_HOST = localStorage.getItem("FARMCOMMAND_HOST");
        if(FC_HOST) return {"label": FC_HOST, "value": FC_HOST}
        else{
            localStorage.setItem("FARMCOMMAND_HOST", "https://processing-api.farmcommand.com");
            return {"label": "https://processing-api.farmcommand.com", "value": "https://processing-api.farmcommand.com"}   
        }
    })

    // Event Handler
    function onPamHostChange(event) {
        localStorage.setItem('FARMCOMMAND_HOST', event.value);
        SET_FARMCOMMAND_HOST(event)
    }

    return (
        <div className="w-50 my-3">
        {/* HOST */}
            <p className='fs-5 mb-1'>Change PAM HOST</p>
            <Select options={FARMCOMMAND_HOSTS} value={FARMCOMMAND_HOST} onChange={onPamHostChange}/>
    </div>
    )
}

export default HostSelection