import React, { useState , useEffect } from 'react'
import { Accordion } from 'react-bootstrap'

import {useSelector, useDispatch} from 'react-redux'
import {requestParametersAddorChange, requestParameterRemove} from '../../../features/RequestParametersSlice'
import { useTranslation } from 'react-i18next'

function DebugPointsParameter() {
    
    const { t } = useTranslation();
    // global state
    const requestParameters = useSelector(state => state.requestParameters)
    
    // Dispatcher
    const dispatch = useDispatch()

    // State
    const [ debugParameter , setDebugParameter ] = useState('')
    const [ debugParameterChecked , setDebugParameterChecked ] = useState(false)

    // Effects
    useEffect(() => {
        // datatype change event handler
        setDebugParameterChecked(false)
        setDebugParameter("debugPts")
    },[requestParameters.datatype])

    // Check box change event handler
    function handleCheckBoxChanges(event){
        const { name , checked } = event.target;
        if(checked){
            setDebugParameterChecked(true)
            
            // Delete can/con debugParam if exist
            dispatch(requestParameterRemove("debugPts"))
            
            // Adding latest debugParameter
            dispatch(requestParametersAddorChange({[name]: "shapefile"}))
        }
        else{
            // deleting debugParameter
            setDebugParameterChecked(false)
            dispatch(requestParameterRemove("debugPts"))
        }
    }

  return (
    <>
        <Accordion className='my-5'>
                <Accordion.Item eventKey="debugPts" className=''>
                    <Accordion.Header>{t("Get Shape File")}</Accordion.Header>
                        <Accordion.Body>
                            <div className="accordion-body ">
                                <div className="d-flex rounded p-2 bg-dark text-white">
                                    <div className="form-check fs-5" >
                                        <input className="form-check-input mx-3" type="checkbox" id="" name={debugParameter} onChange={handleCheckBoxChanges} checked={debugParameterChecked} />
                                        <label className="form-check-label" htmlFor={debugParameter}>{debugParameter}</label>
                                    </div>
                                </div>
                                <div className=''>
                                    <p className='my-3'><span className=''><strong>*{t("Description")}*:</strong></span>
                                    {t("Initiate a task that will download the Elastic Search points to a file and save it to GCS bucket (patricia-prod-tfb9df4ed9de24b5a4). Filetype can either be shapefile or geojson. Download files found at gcs under the directory /patricia_debug_points/")} 
                                    </p>
                                </div>
                            </div>
                        </Accordion.Body>
                </Accordion.Item>
        </Accordion>
    </>
  )
}

export default DebugPointsParameter