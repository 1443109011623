import {createContext , useState , useEffect} from 'react'

const AuthContext = createContext()

export const AuthProvider = ({children}) => {
    const [ token , setToken ] = useState(() => {
        const token  = window.localStorage.getItem('token');
        if (token){
            return token;
        } else{
            return false;
        }
    });

    const [ authStatus , setAuthStatus ] = useState(() => {
        const authStatus = window.localStorage.getItem("authStatus");
        if(authStatus !== null){ return eval(authStatus)}
        else{
            return false;
        }
    })

    useEffect( () => {
        window.localStorage.setItem("token" , token);
    } , [token])

    useEffect(() => { window.localStorage.setItem("authStatus" , eval(authStatus))} , [authStatus])

    return (
        <AuthContext.Provider value={ { token , setToken , authStatus , setAuthStatus } }>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;