import React from 'react'
import { Spinner } from 'react-bootstrap'

function Loading() {
    return (
        <>
            <div className="d-flex align-items-center justify-content-center my-3">
                <Spinner />
            </div>
        </>
    )
}

export default Loading