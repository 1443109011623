import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'

import { useDispatch, useSelector } from 'react-redux'
import { requestParametersAddorChange } from '../../../../features/RequestParametersSlice'

function Farms() {
    
    // Dispatcher
    const dispatch = useDispatch()

    // Global State
    const requestParameters = useSelector(state => state.requestParameters);
    
    const [ farms , setFarms ] = useState([]) // used to store farms fetched from api call
    const [ show , setShow ] = useState(false) // used to control display of the farms selector

    async function fetchFarms(){
        // makes api call to fetchFarms for the selected grower & selected season
        setShow(false);
        setFarms([]);
        
        try{
            let response = await axios(`/api/getFarms/?growerID=${requestParameters.grower}&seasonID=${requestParameters.season}`);
            setFarms(response.data.features.map((e) => { return {"value" : e.properties.farm , "label" : e.properties.farm_label}}));
            setShow(true)
        }
        catch(err){
            console.log('Error while fetching farms...')
        }
    }

    // Fetch farms, when grower/hub/season changes
    useEffect(() => {
        setFarms([]);
        fetchFarms();
    }, [requestParameters.grower , requestParameters.hub, requestParameters.season]);

    function handleChange(event){
        console.log(event)
        // adding selected farm to the global state.
        dispatch(requestParametersAddorChange({farm: event.value, rootAsset: event.value, asset_label: event.label, fields: '' , runtype: '' , datatype: ''}))
    }


    return (
        <div className='my-2'>
            {show && <Select placeholder="Select a farm" options={farms} onChange={handleChange} />}
        </div>
    )
}

export default Farms