export const config = {
    "elevation": [
            [40, 146, 199],
            [75, 156, 191],
            [98, 164, 181],
            [123, 176, 172],
            [143, 186, 163],
            [164, 196, 151],
            [182, 207, 140],
            [203, 219, 129],
            [223, 232, 121],
            [243, 245, 108],
            [250, 240, 95],
            [252, 216, 83],
            [252, 194, 76],
            [252, 171, 66],
            [250, 149, 55],
            [247, 127, 47],
            [245, 104, 39],
            [242, 81, 31],
            [237, 54, 26],
            [232, 21, 21],
        ],
    "speed": [
            [255, 255, 133],
            [245, 239, 135],
            [235, 223, 136],
            [224, 207, 137],
            [214, 192, 137],
            [204, 177, 137],
            [194, 163, 138],
            [184, 149, 138],
            [173, 135, 137],
            [163, 121, 136],
            [153, 109, 135],
            [145, 97, 137],
            [134, 85, 135],
            [124, 73, 133],
            [114, 62, 133],
            [104, 51, 130],
            [94, 40, 130],
            [84, 31, 128],
            [73, 19, 128],
            [62, 0, 125],
        ],
    "raw_yield": [[255, 0, 0], [255, 170, 0], [255, 255, 0], [139, 209, 0], [56, 168, 0]],
    "yield_rater" : [[255, 0, 0], [255, 170, 0], [255, 255, 0], [139, 209, 0], [56, 168, 0]],
    "moisture":[
            [255, 255, 204],
            [161, 218, 180],
            [65, 182, 196],
            [44, 127, 184],
            [37, 52, 148],
        ],
    "harvestMoisture":[
        [255, 255, 204],
        [161, 218, 180],
        [65, 182, 196],
        [44, 127, 184],
        [37, 52, 148],
    ],
    "default": [
        [165, 0, 38],
        [215, 48, 39],
        [244, 109, 67],
        [253, 174, 97],
        [254, 224, 139],
        [217, 239, 139],
        [166, 217, 106],
        [102, 189, 99],
        [26, 152, 80],
        [0, 104, 55],
    ]
}