import {createContext, useEffect, useReducer} from 'react'

export const ResponseContext = createContext();

const reducer = (state, action) => {
    switch(action.type){
        case "addOrChange": 
            return {
                ...state,
                ...action.payload
            }
            
        case "remove":
            const copy = {...state};
            delete copy[action.payload];
            return copy;
        
        case "reset":
            return {}

        default:
            throw new Error("Invalid action")
    }
}

export function ResponseContextProvider({children}){

    const [ storedResponse, _dispatch ] = useReducer(reducer, {})

    useEffect(() => {
        console.log(storedResponse)
    }, [ storedResponse ])
    
    return (
        <ResponseContext.Provider value={{storedResponse, _dispatch}}>
            {children}
        </ResponseContext.Provider>
    )
}