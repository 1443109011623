import React, { useState } from 'react'
import 'react-toastify/dist/ReactToastify.css';

import DataTable from './components/DataTable/DataTable'
import { ToastContainer } from 'react-toastify';

function SmartReportingMonitor() {

    return (
        <>            
            <DataTable />
            <ToastContainer />
        </>
        

    )
}

export default SmartReportingMonitor