import axios from "axios";
import {CODA_HOST, GET_DOWNLOAD_LINK_RAW, DOWNLOAD_GEO_GSON, DOWNLOAD_AGI} from '../../constants'
import { toast } from "react-toastify";

async function download_raw(event, { id, filename }){
    // fetching output....
    event.preventDefault();
    try {
      const url = `${CODA_HOST}/sourcefile/${id}/${GET_DOWNLOAD_LINK_RAW}/`
      const response = await axios.post( "/coda/make_coda_call/" , {"url" : url})
      
      console.log(response.data?.gcs_blob_path)
      
      var link = document.createElement("a");

      link.setAttribute('download', '');
      link.href = response.data?.gcs_blob_path;
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch (error) {
      console.log(error)
    }
}

async function download_geo_json(event, {id, filename}){
  event.preventDefault();
  try {

    const response = await axios.get("/coda/get_coda_token/")
    const token = response.data?.coda_token
    console.log(token)

    if(token){
      const url = `${CODA_HOST}/sourcefile/${id}/${DOWNLOAD_GEO_GSON}/`
      console.log(url)
      toast("Downloading Summary Shapefile");
      fetch(url , {headers: {"Authorization": `Token ${token}`}})
          .then(response => {
            if(response.status === 200){
              return response.blob();
            }
            else{
              toast("Something Wen't wrong")
              console.log('Something went wrong while downloading summary shapefile')
            }            
          })
          .then(blob => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download = "GeoJsonSummary.json.gz";
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();    
              toast("Downloaded...");
              a.remove();  //afterwards we remove the element again         
        });
    } else{
      toast("Something Wen't wrong")
      console.log('Something went wrong while downloading summary shapefile')
    }
    

  } catch (error) {
      console.log(error);
  }
}

async function download_agi(event, {id, filename}){
  event.preventDefault();
  try {

    const response = await axios.get("/coda/get_coda_token/")
    const token = response.data?.coda_token

    if(token){
      const url = `${CODA_HOST}/sourcefile/${id}/${DOWNLOAD_AGI}/`
      console.log(url)
      toast("Downloading AGI File");
      fetch(url , {headers: {"Authorization": `Token ${token}`}})
          .then(response => {
            if(response.status === 200){
              return response.blob();
            }
            else{
              toast("Something Wen't wrong")
              console.log('Something went wrong while downloading agi file')
            }   
          })
          .then(blob => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download = filename;
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();    
              toast("Downloaded...");
              a.remove();  //afterwards we remove the element again         
        });
    } else{
      toast("Something Wen't wrong")
      console.log('Something went wrong while downloading agi file')
    }
    

  } catch (error) {
      console.log(error);
  }
}

const copy_file_link = async (event, {id}) => {
    // fetching output....
    event.preventDefault();
    const first_toast = toast("Generating File link");
    try {
      const url = `${CODA_HOST}/sourcefile/${id}/${GET_DOWNLOAD_LINK_RAW}/`
      const response = await axios.post( "/coda/make_coda_call/" , {"url" : url})
      if (![200, 201].includes(response.status)){
        toast("Unable to generate file link")
        return;
      }
      const file_link = response.data?.gcs_blob_path;
      await navigator.clipboard.writeText(file_link);
      toast.dismiss(first_toast);
      toast("Copied to Clipboard");
    } catch (error) {
      console.log(error)
    }
}

const fetchData = async (url, setData, setNext, setPrev, setLoading) => {
  // Makes CODA Call and update the passed states accordingly
  setLoading(true);
  try {
    const response = await axios.post("/coda/make_coda_call/", {"url": url})
    if(response.data?.results){setData(response.data?.results);}
    setNext(response.data?.next);
    setPrev(response.data?.previous);  
  } catch (error) {
    console.log(`Error on fetching data from ${url}`, error)
  }
  finally{
    setLoading(false);
  }
}


export {download_raw, download_geo_json, download_agi, copy_file_link, fetchData}