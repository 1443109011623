import { useSelector } from "react-redux"
import {GROWER, FARM, FIELD, SUBFIELD} from '../constants'
import { useTranslation } from "react-i18next";

const UploadButton = ({uploadFiles}) => {
    
    const { t } = useTranslation();
    
    const {asset_category, category, grower, farm, field} = useSelector(state => state.requestParameters);
    let rootAssetToCheck = grower;
    if(asset_category === GROWER)
        rootAssetToCheck = grower;
    else if(asset_category === FARM)
        rootAssetToCheck = farm;
    else if(asset_category === FIELD)
        rootAssetToCheck = field
    else
        rootAssetToCheck = field

    return (
            <>
                { 
                (asset_category && rootAssetToCheck && category) &&
                    <button className="btn btn-outline-secondary w-100 mx-auto my-5" onClick={uploadFiles}>{t("Upload")}</button>
                }
            </>
    )
}

export default UploadButton