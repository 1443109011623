import React, { useContext, useEffect } from 'react'
import Navbar from '../settings/components/Navbar'
import Offcanvas from '../../components/Offcanvas'
import MainPage from './components/MainPage'
import ImplementModule from './components/modules/implement/ImplementModule'
import FieldsModule from './components/modules/fields/FieldsModule'
import JobsModule from './components/modules/jobs/JobsModule'

import { useDispatch, useSelector } from 'react-redux'
import { requestParametersReset } from '../../features/RequestParametersSlice'
import { useLocation } from 'react-router-dom'
import { ResponseContext } from './context/ResponseContext'

const CarbonCapture = () => {

    
    const dispatch = useDispatch();
    const { _dispatch } = useContext(ResponseContext)
    const requestParameters = useSelector(state => state.requestParameters);
    const {default_selected} = requestParameters;
    
    const location = useLocation();

    useEffect(() => {
        dispatch(requestParametersReset());
        _dispatch({"type": "reset"});
    }, [location])

    return (
        <>
            <Navbar pageName={"Carbon Capture Form"} />
            <Offcanvas />

                <div className='page-content px-4'>
                    <MainPage />
                        {/* Modules */}
                        {
                            default_selected && 
                            <>
                                <ImplementModule />
                                <FieldsModule />
                                <JobsModule />
                            </>
                        }
                </div>
        </>
    )
}

export default CarbonCapture