import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {ticketParametersAddorChange, ticketParameterRemove} from '../../features/TicketParametersSlice'
import Select from 'react-select'
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next'

function SelectController() {

  const { t } = useTranslation();
  const dispatch = useDispatch() // dispatcher
  const ticketParameters = useSelector(state => state.ticketParameters) // Global State

  const [loading , setLoading] = useState(false); // to control loading component
  const [controllers , setControllers] = useState([]) // to store all controller types received from API Response
  const [values, setValues] = useState(null);  // to keep track of selected controllers

  useEffect(() => {
    // fetching controller types and storing to state....
    setLoading(true);
    (async () => {
        try {
            const response = await axios.get("/api/getControllers/")
            setControllers(response.data.controllerFileTypes.map((e) => {return {"value" : e.id , "label" : e.label}}));
        } catch (error) {
            setLoading(false);
        }
        setLoading(false);
    })();
  }, [])

  useEffect(() => {
    // removing canplug related details from ticketParams context...
    dispatch(ticketParameterRemove('canplug'))
    dispatch(ticketParameterRemove('canplug_id'))
  }, [ticketParameters.datatype])

  
  // adding selected controller to global state
  useEffect(() => {
    if(values){
      let selectedControllers = values.map((e) => e.label)
      let selectedControllersID = values.map((e) => e.value)
      
      dispatch(ticketParametersAddorChange({"controller": selectedControllers.toString() , "controller_id": selectedControllersID.toString()}))
    }
  } ,[values])
 

  // selection event handler
  function handleChange(event){
    setValues(event)
  }

  return (
      <div className="my-4 mb-5">
      <p className='fs-4'>{t("Select a Controller file type you are having issues with")}:</p>
  
      {loading ? 
        <Spinner className='text-dark m-4' /> : 
        <>
          <Select isMulti options={controllers}  value={values}  closeMenuOnSelect={false} onChange={handleChange} />
        </>
      }
      </div>
    )
  
}

export default SelectController