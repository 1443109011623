import React from 'react';

const SelectedValuesDisplay = ({ selectedValues }) => {
    // Check if selectedValues is an object
    if (typeof selectedValues !== 'object' || selectedValues === null) {
        return null; // Render nothing if it's not an object or null
    }

    return (
        <div className="selected-values-display">
            <h4 className='p-3 text-center'>Report Data</h4>
            <div className='mb-1 w-100'>
                {/* Map over keys of selectedValues object */}
                {Object.entries(selectedValues).map(([key, value]) => (
                    <div className='text-container p-3 bg-light border my-1 text-break' key={key}>
                        <strong>{key}:</strong> 
                        <div className='sail-selections-font'>{value}</div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SelectedValuesDisplay;