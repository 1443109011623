import React from 'react'

function PageNotFound() {
  return (
    <>
        <div className="min-vh-100 d-flex align-items-center justify-content-center bg-dark text-white">
            <p className='fs-3'>404 | Page Not Found</p>
        </div>
    </>
  )
}

export default PageNotFound