
function Refresh({fetchUploadedFiles}) {
    
    function handleRefreshClick(event){
        fetchUploadedFiles();
    }

    return (
        <>
            <button className="btn btn-secondary my-2" onClick={handleRefreshClick}><i className="fa fa-rotate-right"></i></button>
        </>
    )
}

export default Refresh