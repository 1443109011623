import Select from 'react-select'
import {ROOT_ASSETS_OPTIONS} from '../../../constants'
import { useTranslation } from 'react-i18next';

function AssetCategory({urlSearchParams, setSelectedAssetCategory}) {

    const { t } = useTranslation();
    
    function handleChange(event){
        if(event){
            urlSearchParams.current.set("asset_category", event.value); 
            setSelectedAssetCategory(event.value)
        }
        else{
            urlSearchParams.current.delete("asset_category");
            setSelectedAssetCategory(null)
        }
    }
    
    return (
        <div className="">
            <p className="fs-5 my-1">{t("Asset Category")}</p>
            <Select 
                options={ROOT_ASSETS_OPTIONS} 
                onChange={handleChange}
                isClearable={true}    
            />
        </div>
    )
}

export default AssetCategory