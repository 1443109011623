import Select from 'react-select'
import useFetch from '../../../../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { requestParametersAddorChange } from '../../../../../features/RequestParametersSlice'
import { t } from 'i18next'

const District = ({ onChange }) => {

    const dispatch = useDispatch();
    const { country, state } = useSelector(state => state.requestParameters)

    const { data, error, loading} = useFetch({
        url: `/reporting/sail/district/?country=${country}&state=${state}`
    })

    error && 
        console.log(error)  

    const [ district, setDistrict ] = useState(null);
    const [ districts, setDistricts ] = useState([]);

    useEffect(() => {
        setDistricts( () => (data.map( e => ({"label" : e.district_english, "value": e.district_id}) )))
    }, [data])

    function handleChange(e){
        setDistrict(e);
        if (e) {
            dispatch(requestParametersAddorChange({"district_id": e.value, "district": e.label}))
            onChange(e.label); // Notify parent component of the change
        } else {
            dispatch(requestParametersAddorChange({"district_id": null, "district": null}))
            onChange(null, null); // Notify parent component of the reset

        }
    }

    return (
        <div>
            <label htmlFor="" className="form-label fs-5">{t('Select District')}:</label>

            <Select
                value={district}
                placeholder={t("Select a district")}
                onChange={handleChange}
                options={districts}
                isClearable
                isLoading={loading}
                />
        </div>
    )
}

export default District