import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
// import LightBox from "react-awesome-lightbox";
// import 'react-awesome-lightbox/build/style.css';
import LayerLegend from './LayerLegend';
import { capitalizeFirstLetter } from '../../../utils/helpers';
import LayerLightbox from './LayerLightbox';


function LayerImage({index, layerInfo}) {
    
    const [layer_image, set_layer_image] = useState('');
    const [preview, set_preview] = useState(false);
    const [show_legend, set_show_legend] = useState(false);
    const [loading , set_loading] = useState(false);
    const [refresh, set_refresh] = useState(false);
    const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
    
    const {layerType, label} = layerInfo;
    let layerName = layerType;
    try{
        if (layerType === "Standard")
            layerName = capitalizeFirstLetter(label.replace(" - Standard", "").split(" ").slice(1).join(" ")).substring(0,30)
    }catch {
        console.log(`unable to parse layerName from label: ${label}`)
    }

    // Helper
    const addImageDimensions = (event) => {
        const img = event.target;
        setImageDimensions({
            width: img.width,
            height: img.height
        });
    };

    async function getLayerImage(blob_url){
        set_refresh(false);
        set_loading(true);
        if(blob_url){
            try {
                const response = await axios.get(`${localStorage.getItem("FARMCOMMAND_HOST")}/${blob_url}`, {headers: {"Authorization": `Token ${localStorage.getItem("token")}`}, responseType: "arraybuffer"})

                const reader = new FileReader();
                reader.onload = () => {
                    const img = new Image();
                    img.onload = addImageDimensions;
                    img.src = reader.result;
                    set_layer_image(reader.result);
                }
                reader.readAsDataURL(new Blob([response.data]));
                set_loading(false);
                
            } catch (error) {
                set_refresh(true);
                console.log(error);
            }
            finally{
                set_loading(false);
            }
        }
    }

    useEffect(() => {
        getLayerImage(layerInfo?.url);
    }, [])

    // Event Handlers
    function handleShowLegendClick(event){
        set_show_legend(true);
        document.getElementById(`btn-layer-legend-${layerInfo.id}`)?.click();
    }

    return (
    <>
        <div className={`card btn m-1 shadow`} id={`layer-${layerInfo.id}`}>
            {
                loading && <div className="d-flex mx-auto p-3"><Spinner /></div>
            }
            <div className=''>
                <img className={`img card-img my-auto map-container`} src={layer_image} alt="layer_image"  onClick={() => {set_preview(prev => !prev)}} style={{maxHeight: "200px", maxWidth: "250px", width: "250px", height: "200px", minWidth: "250px", minHeight: "200px"}}/>
                { refresh === true && <div className="mx-auto d-flex align-items-center justify-content-center" style={{height:"300px"}}><button className="btn btn-outline-danger" style={{width:"fit-content", height:"fit-content"}} onClick={() => {getLayerImage(layerInfo?.url);}}><i className="fa-solid fa-rotate-right"></i></button></div> }
            </div>

            <div className="d-flex flex-column mt-2 align-items-center">
                <button className="btn btn-outline-dark card-text text-center d-flex flex-column align-items-center justify-content-center text-center" style={{width: "fit-content", blockSize: "fit-content"}} onClick={handleShowLegendClick}>
                    <span className='flex-item'>{layerName}</span>
                    <span className='flex-item'>{new Date(layerInfo?.imagery_date).toDateString().split(" ").splice(1, 3).toString()}</span>
                </button>
            </div>
        </div>

        {preview && <LayerLightbox layerLabel={layerInfo.label} image={layer_image} open={preview} onClose={() => {set_preview(false)}} imageDimensions={imageDimensions} />}
        {show_legend && <LayerLegend id={layerInfo.id} layerType={layerInfo.layerType} />}
    </>
    )
}

export default LayerImage