import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import LayerImage from './LayerImage';
import { capitalizeFirstLetter, sortByDateCreated, sortByLayerType } from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';

function Maps() {
    const { t } = useTranslation();
    const FILTERED_LAYERS = JSON.parse(localStorage.getItem("FILTERED_LAYERS")).map(e => e.value);
    const requestParameters = useSelector(state => state.requestParameters);

    const [LayersInfo, set_LayersInfo] = useState([]);
    const [error_message, set_error_message] = useState("");
    const [loading, set_loading] = useState(false);

    async function getLayersInfo(assetID) {
        set_error_message("");
        set_loading(true);
        try {
            let url = '';
            if (requestParameters?.runtype?.length === 2) {
                url = `${localStorage.getItem("FARMCOMMAND_HOST")}/layer/?assetID=${assetID}`;
            } else if (requestParameters?.runtype?.length === 1) {
                let runtype = capitalizeFirstLetter(requestParameters?.runtype[0]);
                url = `${localStorage.getItem("FARMCOMMAND_HOST")}/layer/?assetID=${assetID}&category=${runtype}`;
            }
    
            const response = await axios.get(url, { headers: { "Authorization": `Token ${localStorage.getItem("token")}` } });
            if (response.data?.length !== 0) {
                let result = {}; // { "Applied": { "DataSource1": { "Job1": [layers], "Job2": [layers] }, "Harvest": ... } }
                response.data?.forEach(e => {
                    if (FILTERED_LAYERS.includes(e.layerType) || ["Profit", "Treatment"].includes(e.category))
                        return;
    
                    if (!result[e.category]) {
                        result[e.category] = {};
                    }
    
                    if (!result[e.category][e.patricia_data_source]) {
                        result[e.category][e.patricia_data_source] = {};
                    }
    
                    if (!result[e.category][e.patricia_data_source][e.job]) {
                        result[e.category][e.patricia_data_source][e.job] = [];
                    }
    
                    result[e.category][e.patricia_data_source][e.job].push(e);
                });
    
                // Sort by Date and Type
                Object.entries(result).forEach(([runtype, dataSources]) => {
                    Object.entries(dataSources).forEach(([dataSource, jobs]) => {
                        Object.entries(jobs).forEach(([job, layers]) => {
                            layers.sort(sortByLayerType);
                        });
                    });
                });
    
                console.log(result);
                set_LayersInfo(result);
            } else {
                set_error_message("No Layers");
                set_LayersInfo([]);
            }
        } catch (error) {
            if (error?.response?.status === 404) {
                set_error_message(`${assetID} is not a valid field ID`);
            } else {
                set_error_message(`Something went wrong while fetching layerInfo for ${assetID}`);
            }
            console.log(error);
        } finally {
            set_loading(false);
        }
    }
    

    useEffect(() => {
        if (requestParameters?.current_field_id) {
            getLayersInfo(requestParameters?.current_field_id);
        }
    }, [requestParameters?.current_field_id]);

    return (
        <div className={`map d-flex flex-column`} id={`map-${requestParameters?.current_field_id}`}>
            {error_message ? (
                <p className='text-center text-danger'>{error_message}</p>
            ) : loading ? (
                <Spinner animation="border" />
            ) : (
                <>
                    {Object.entries(LayersInfo).map(([runtype, dataSources]) => (
                        <>
                            <p className='fs-5 text-center mt-5 mb-2 text-decoration-underline'>{runtype}</p>
                            {Object.entries(dataSources).map(([dataSource, jobs]) => (
                                <>
                                    <p className='fs-6 text-center mb-2 text-decoration-underline'>{runtype} - {dataSource} Layers</p>
                                    <figure className='wrapper' style={{ display: "flex", overscrollBehaviorX: "contain", overflowX: "auto" }}>
                                    {Object.entries(jobs).map(([jobId, jobData]) => (
                                        <>
                                            <div className='d-flex my-2'>
                                                {Array.isArray(jobData) ? jobData.map((layerInfo, index) => (
                                                    <LayerImage key={index} layerInfo={layerInfo} />
                                                )) : <p>Error: No layers data or not an array.</p>}
                                            </div>
                                        </>
                                    ))}
                                    </figure>
                                </>
                            ))}
                        </>
                    ))}
                </>
            )}
        </div>
    );
}

export default Maps;
