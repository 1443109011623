import { Routes, Route, useLocation } from 'react-router-dom'
import CreateSR from './CreateSR'
import YourSR from './YourSR'
import Admin from './Admin'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { requestParametersAddorChange, requestParametersReset } from '../../features/RequestParametersSlice'
import AdminAuthorizedRoutes from '../../utils/AdminAuthorizedRoutes'
import JiraAuthenticatedRoutes from '../../utils/JiraAuthenticatedRoutes'
const ServiceRequest = () => {

    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(requestParametersReset());
        dispatch(requestParametersAddorChange({"user_id": localStorage.getItem("client_id")}));
        dispatch(requestParametersAddorChange({"token": localStorage.getItem("token")}));
    }, [location, dispatch])

    return (
        <Routes>
            <Route element={<JiraAuthenticatedRoutes />}>
                <Route path='/' element={<CreateSR />} />
                <Route path='/your_requests' element={<YourSR />} />
            </Route>
            <Route element={<AdminAuthorizedRoutes />} >
                <Route path='/admin' element={<Admin />} />
            </Route>
        </Routes>
    )
}

export default ServiceRequest