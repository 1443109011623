import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import {requestParametersAddorChange} from '../../../features/RequestParametersSlice'
import { useTranslation } from 'react-i18next'


function Users({ onUserSelect }) {

    const { t } = useTranslation();
    // Dispatcher
    const dispatch = useDispatch()
    
    // Global State
    const requestParameters = useSelector(state => state.requestParameters);

    const [users , setUsers] = useState('') // used to store users fetched from api call
    const [show , setShow] = useState(false) // used to control display of grower selector

    async function fetchUsers(){
        // Makes api call to fetch users.
        console.log('fetching users....')
        setShow(false)
        setUsers([])
        try{
            let response = await axios('/leaf/get_users/');
            setUsers(response.data.map((e) => { return {"value" : e.user_id , "label" : e.user}}));
            setShow(true)
        }
        catch(err){
            console.log('Error while fetching users...')
        }
    }

    useEffect(() => {
        // Fetching growers by making api call, for the [selected season & hub]
        setUsers([]);
        fetchUsers()
    } , [])

    function handleChange(event){
        // Adds selected grower to the global state
        dispatch(requestParametersAddorChange({ 'user' : event.value, 'user_id': event.value.toString() }))
        onUserSelect(event.value); // Pass the selected user to the parent component

    }

    return (
        <>
            {show && 
            <div className="row">
                <label htmlFor="" className="form-label fs-5">{t('Select User')}:</label>
                <Select placeholder={t("Select a User")} options={users} onChange={handleChange} />
            </div>
            }
        </>
    )
}

export default Users