import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { IoLogoAppleAr } from "react-icons/io5";
import { FaUserAlt } from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";
import FarmCommandLogoGrey from '../../../assets/images/FarmCommandLogoGrey.png'
import { WHITELISTED_USERS } from '../../../constants';

function Navbar({pageName}) {

    const { t } = useTranslation(); 
    const [showSettings, setShowSettings] = useState(false);

    return (
        <>
            <nav className="navbar navbar-expand-sm navbar-white-2" style={{background: "#02559c"}}>

                <button className="ms-3 btn btn-outline-light" type="button"  data-bs-toggle="offcanvas" href="#offcanvasExample" style={{zIndex: 100}}>
                    <i className="fa-solid fa-bars"></i>
                </button>

                <span className='text-white py-2' style={{marginLeft: "1.5rem"}}>{pageName}</span>

                {/* FARMERSEDGE | DPS GUI */}
                <div className='w-100 position-absolute' style={{zIndex: 1}}>
                    <img src={FarmCommandLogoGrey} alt="fc support logo" className="navbar-brand d-none d-lg-block mx-auto" height={25} style={{filter: "brightness(200)"}} />
                </div>

                <div className="ms-auto" style={{zIndex: 1000}}>
                    <button className="btn me-2 text-light" type="button" onClick={() => {setShowSettings(showSettings => !showSettings)}}>
                        <i className="fa-solid fa-ellipsis-v" ></i>
                    </button>
                </div>
            </nav>

            {
                showSettings &&
                <div 
                    className='d-flex flex-column position-absolute align-items-center justify-content-center  my-2 p-2 shadow-lg bg-light text-dark rounded-2 text-decoration-none' 
                    style={{right: "15px", zIndex: 10000}}
                >
                    <NavLink to="/service_request/" className='btn btn-outline-dark my-2'> <IoLogoAppleAr /> {t("Service Request")} </NavLink>
                    <NavLink to="/service_request/your_requests/" className='btn btn-outline-dark my-2'> <FaUserAlt/> {t("Your Service Requests")} </NavLink>
                    
                    {
                        WHITELISTED_USERS.includes(localStorage.getItem("username")) && 
                        <NavLink to="/service_request/admin/" className='btn btn-outline-dark my-2'> <RiAdminFill /> {t("SR Admin")} </NavLink>
                    }
                </div>
            }
            </>
        )
}

export default Navbar