import Navbar from './components/Navbar'
import GlobalSettings from './components/GlobalSettings/GlobalSettings'
import FcLiteSettings from './components/FcLiteSettings/FcLiteSettings'
import Offcanvas from '../../components/Offcanvas'
import { Route, Routes } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function Settings() {
    
    const { t } = useTranslation();
    const user_roles = localStorage.getItem("user_roles");

    return (
        <>
            <Navbar pageName={t("Settings")} user_roles={user_roles} />
            <Offcanvas />
            <div className="d-flex min-vh-100 justify-content-center login-bg" >
                <div className='container-fluid'>
                    <div className="home-page-content row">
                        <Routes>
                            <Route path='/' element={<GlobalSettings />} />
                            <Route path='/map-settings' element={<FcLiteSettings user_roles={user_roles} />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Settings