import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {rxParametersAddorChange} from '../../../features/RxParametersSlice'
import { Fade } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function ApplicationTime() {

    const { t } = useTranslation();
    const dispatch = useDispatch() // dispatcher
    const [selected_application_times, set_selected_application_times] = useState([1,2,3,4,6]); // To track checked application times.
    const [appear, set_appear] = useState(true);
    const {download_rxs} = useSelector(state => state.rxParameters);

    // data for the table
    const [ application_time ] = useState([ 
        {'value' : 4, 'label': t('Seeding Application')},
        {'value' : 1, 'label': t('Pre Plant Fall Application')},
        {'value' : 2, 'label': t('Pre-Plant Spring Application')},
        {'value' : 3, 'label': t('In-Season Application')},
        {'value' : 6, 'label': t('Pre-Plant')},
    ]);

    
    useEffect(() => {
        dispatch( rxParametersAddorChange({ 'ApplicationTime': selected_application_times.map( selected_app_time =>
            application_time.find( app_time => app_time.value === selected_app_time).label
        )}));
    }, [selected_application_times])

    useEffect(() => {
        if(!download_rxs){
            dispatch( rxParametersAddorChange({"ApplicationTime": application_time.map(e => e.label)}) )
            set_selected_application_times([1,2,3,4,6])
        }
    }, [download_rxs])


    function TableRow({id, application_}){

        // Checkbox change event handler
        function handleChange(event){
            
            const { checked , value } = event.target;
            
            if(checked){
                set_selected_application_times(prev => [...prev, Number(value)]);
            }
            else {
                set_selected_application_times(selected_application_times.filter(e => e !== Number(value)));
            }
            
        }

        return (
            <>
                <tr className={!download_rxs ? "table-secondary" : ""}>
                    <th>{id + 1}</th>
                    <td className=''>{application_.label}</td>
                    <td>
                        <input type='checkbox' 
                                id={application_.value}
                                name={application_.label} 
                                value={application_.value} 
                                className="form-check-input p-2" 
                                onChange={
                                    !download_rxs ? null : handleChange
                                } 
                                checked={
                                        !download_rxs ? "checked" : selected_application_times.includes(application_.value) ? 'checked' : ''
                                } />
                    </td>
                </tr>
            </>
        )
    }

    // Event Handler
    function handleShowTable(event){
        set_appear(prev => {return !prev});
    }


    return (
        <>
            {/* <Select options={application_time} onChange={handleChange} placeholder={'Seeding Application Time'} isMulti defaultValue={4} /> */}
            <figure className='wrapper' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto"}}>
            <table className="table table-light table-responsive table-hover shadow">
                <thead className='table-dark' onClick={handleShowTable}>
                <tr>
                {
                        appear ? 
                        <>
                            <th scope="col">#</th>
                            <th scope="col">{t("Application Time")}</th>
                            <th scope="col" className='fw-bolder'>#</th>
                        </>
                        : 
                        <th scope="col" className='text-center'>{t("Select Application Time")}</th>
                }

                </tr>
                </thead>
                <tbody>
                {
                    application_time.map((application_ , key) => {
                    return (
                        <Fade in={appear} unmountOnExit={true} appear={appear}>
                            <TableRow key={key} id={key} application_={application_} />
                        </Fade>
                    )
                })
                }
                </tbody>
            </table>
            </figure>
        </>
    )
}

export default ApplicationTime