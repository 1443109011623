import { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'
import {useDispatch } from 'react-redux'
import { t } from 'i18next'
import { requestParametersAddorChange } from '../../../features/RequestParametersSlice'

function ServiceOffering() {

  const dispatch = useDispatch();
  const [ services, setServices ] = useState(null);   
  const [ loading, setLoading ] = useState(false); 
  

    async function fetchServices(){
      setLoading(true);
        try{
            const response = await axios("/api/service_offering_list/");
            setServices(() => (response.data?.map((e) => { return {"value" : e , "label" : e}})));
        }
        catch(err){
            console.log('Error while fetching seasons')
        }
        finally {
          setLoading(false);
        }
    }

    // Side Effects
    // Initial Render
    useEffect(() => {
        fetchServices();
    }, []); 

    function handleChange(event){
        // Adding selected service global state.
        dispatch(requestParametersAddorChange({ "service_offering":  event.value }))
    }

    return (
        <>
            <Select 
                placeholder={t("Select a service offering")} 
                options={services} 
                isLoading={loading}
                onChange={handleChange} />
        </>
    )
}

export default ServiceOffering;