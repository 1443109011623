import { NavLink, useLocation } from 'react-router-dom'
import { useEffect } from "react"
import {useDispatch, useSelector} from 'react-redux'
import Offcanvas from "../../components/Offcanvas"
import Seasons from "../../components/Seasons"
import Navbar from '../settings/components/Navbar'
import FileCategory from "./components/FileCategory"
import FileUpload from "./components/FileUpload"
import RootAsset from "./components/RootAsset"
import RootAssetCategory from "./components/RootAssetCategory"
import {requestParametersReset} from '../../features/RequestParametersSlice'
import { FARM, FIELD, SUBFIELD } from "./constants"
import { useTranslation } from "react-i18next"

const FileUploaderPage = () => {

    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {asset_category} = useSelector(state => state.requestParameters);
    const location = useLocation();

    useEffect(() => {
        dispatch(requestParametersReset())
    }, [location])

    return (
        <>
            <Navbar pageName={t("Asset File Uploader")} page={"Asset File Uploader"} />
            <Offcanvas />
            <div className="row w-100 justify-content-center mx-auto">
                <div className="col-lg-6">
                    <div className="mb-2 my-4">
                    <NavLink to="/instructions"><button className='btn btn-outline-primary mt-2'> <i className="fa-solid fa-circle-info mx-2"></i>{t('Instructions')}</button></NavLink>
                    </div>

                        <RootAssetCategory />
                        <RootAsset />
                        <FileCategory />

                        <FileUpload />
                        
                </div>
            </div>
        </>
    )
}

export default FileUploaderPage