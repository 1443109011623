import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { requestParametersAddorChange } from "../../../../../features/RequestParametersSlice";


const Confirmation = ({implementAccordion}) => {
    
    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    const implementAccordionHeader = implementAccordion.current?.childNodes[0]?.childNodes[0];

    // Event Handlers
    function checkAccordionHeader(){
        implementAccordionHeader?.setAttribute("aria-checked" , true);
    }

    function uncheckAccordionHeader(params) {
        implementAccordionHeader?.setAttribute("aria-checked" , false);
    }

    const handleCheckBoxChange = (event) => {
        const {checked} = event.target;
        dispatch(requestParametersAddorChange({"implements_selected": checked}))
        if(checked) {
            checkAccordionHeader();
        } else {
            uncheckAccordionHeader();
        }
    }
    
    return (
        <div className="d-flex justify-content-center ">
            <input 
                type="checkbox" 
                name="implements-completeness" 
                id="implements-completeness"
                className="form-check-input p-2"
                onChange={handleCheckBoxChange}
                    />
            <label htmlFor="implements-completeness" className="ms-3">
                {t("please click on this checkbox when you have completed your implements selection.")}
            </label>
        </div>
    )
}

export default Confirmation