import Select from 'react-select'
import {useDispatch} from 'react-redux'
import { requestParametersAddorChange } from '../../../../features/RequestParametersSlice';
import { useTranslation } from 'react-i18next';

function Seasons({seasons}) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    
    function handleChange(event){
        // Adding selected season to the global state.
        dispatch(requestParametersAddorChange({season :  event.value , grower: ''}))
    }

    return (
        <>
            <Select placeholder={t("Select a season")} options={seasons} onChange={handleChange} />
        </>
    )
}

export default Seasons