import React from 'react'
import { useTranslation } from 'react-i18next'

function Legend() {
    
    const { t } = useTranslation();
    
    const square = {
        "display": "inline-block",
        'width': "20px",
        'height': "20px",
        "border": "1px solid black"
    }
    return (
        <>
        <div className="ms-auto">
            <div className='d-flex align-items-center'>
                <span style={{...square, "backgroundColor": "#6F61C0"}} className='mx-2'> </span> {t("Assigned Defaults")}
                <span style={{...square, "backgroundColor": "#D7A108"}} className='mx-2'> </span> {t("Previously Assigned")}
                <span style={{...square, "backgroundColor": "rgb(0 157 36 / 63%)"}} className='mx-2'> </span> {t("Assigned")} 
                <span style={{...square, "backgroundColor": "#FFFFFF"}} className='mx-2'> </span> {t("Unassigned")}
            </div>
        </div>   
        </>
    )
}

export default Legend