import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { Farms, Fields, Offcanvas, Seasons } from '../../components'
import RequestType from './components/RequestType';
import Grower from './components/Grower';
import RequestLayers from './components/RequestLayers';
import Submit from './components/Submit';
import Operation from './components/Operation';
import Datatype from './components/Datatype';
import { HARVEST_APPLIED_PDFS, PROFIT_PDF, SHAPEFILE_EXPORT } from './constants';
import Disclaimer from './components/Disclaimer';
import FilteredSeasons from './components/FilteredSeasons';
import Navbar from '../settings/components/Navbar'


const CreateSR = () => {

    const { t } = useTranslation();

    const { season, grower, farm, fields, customer_request, operation }  = useSelector(state => state.requestParameters);

    return (
        <>
            <Navbar pageName={t("Service Request")} />
            <Offcanvas />

            <div className="page-content selectors px-4 mx-auto align-items-center ">
                <RequestType />


                {
                    ![SHAPEFILE_EXPORT].includes(customer_request) && 
                    <div className="mb-2 my-4">
                        <label htmlFor="" className="form-label fs-5">{t('Select Season')}:</label>
                        <Seasons />
                    </div>
                }

                {
                    [SHAPEFILE_EXPORT].includes(customer_request) &&
                    <>
                        <FilteredSeasons />
                        <Disclaimer />
                    </>
                }

                <div className="mb-2 my-4">
                    <label htmlFor="" className="form-label fs-5">{t('Select Grower')}:</label>
                    <Grower />
                </div>
                
                {
                (season && grower) && 
                    <div className="mb-2 my-4">
                        <label htmlFor="" className="form-label fs-5">{t('Select Farm')}:</label>
                        <Farms />
                    </div>
                }


                {
                (grower && farm) &&
                <div className="mb-2 my-4">
                    <label htmlFor="" className="form-label fs-5">{t('Select Fields')}:</label>
                    <Fields />
                </div>
                }

                {
                    ([SHAPEFILE_EXPORT].includes(customer_request)) &&
                    <div className="mb-2 my-4">
                        <label htmlFor="" className="form-label fs-5">{t('Select Operation')}:</label>
                        <Operation />
                    </div>
                }

                {
                    HARVEST_APPLIED_PDFS.includes(customer_request) &&
                    <>
                    <div className="mb-2 my-4">
                        <label htmlFor="" className="form-label fs-5">{t('Select Datatype')}:</label>
                        <Datatype />
                    </div>
                    <RequestLayers />
                    </>
                }

                {
                    [PROFIT_PDF].includes(customer_request) &&
                    <div className="mb-2 my-4">
                        <label htmlFor="" className="form-label fs-5">{t('Select Datatype')}:</label>
                        <Datatype isMulti={false} />
                    </div>
                }

                {
                    ( season && grower && farm && customer_request && fields && fields.length > 0 && operation ) &&
                    <Submit />
                }

            </div>
        </>
    )
}

export default CreateSR