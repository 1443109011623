import { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import {useDispatch} from 'react-redux'
import {requestParametersAddorChange} from '../../../features/RequestParametersSlice'

const FilteredSeasons = () => {
    
    const { t } = useTranslation();
    const dispatch = useDispatch()

    // Local State, it stores the seasons returned from api
    const [seasons , setSeasons] = useState([]);
    const [loading, setLoading] = useState(false);

    async function fetchSeasons(){
        // Makes api call to fetch seasons, [returned seasons] are set to seasons state.
        setLoading(true);
        try{
            const response = await axios("/api/getRelativeSeasons/?months=6");
            setSeasons(response.data.map((e) => { return {"value" : e.id , "label" : e.label}}));
        } catch(err){
            console.log('Error while fetching seasons')
        } finally {
            setLoading(false);
        }
    }
    
    function handleChange(event){
        // Adding selected season to the global state.
        dispatch(requestParametersAddorChange({ season :  event.value, farm: '' , fields: ''}))
    }

    useEffect(() => {
        // Fetching seasons.. when it is rendered...
        fetchSeasons();
    }, []); 


    return (
        <div className="mb-2 my-4">
            <label htmlFor="" className="form-label fs-5">{t('Select Season')}:</label>
            <Select 
            placeholder={t("Select a season")} 
                isLoading={loading}
                options={seasons}
                onChange={handleChange} 
                />
        </div>
    )
}

export default FilteredSeasons;