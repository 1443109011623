import React, { useContext } from 'react'
import { PrescriptionDataContext } from '../context/PrescriptionDataContext';
import { CODA_HOST, PRESCRIPTION_FILE } from '../../../../constants';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function FilenameSearch({filename, setFilename, set_selected_hub}) {

    const { t } = useTranslation();
    // Context
    const {setData, setLoading, setMessage, setNext, setPrev, setSearchType } = useContext(PrescriptionDataContext)

    // Event Handlers
    async function handleSearchByFilename(event){
        try {
            setLoading(true);
            let url = `${CODA_HOST}/${PRESCRIPTION_FILE}/?filename=${filename}`
            let response = await axios.post("/coda/make_coda_call/", {"url": url})
            setSearchType("filename")
            setData(response.data?.results);
            setNext(response.data?.next);
            setPrev(response.data?.previous);
            setMessage(`Prescription Files with filename: ${filename}`);
        } catch (error) {
            console.log("Error on Fetching data", error)
        } finally{
            setLoading(false)
        }
    }

    return (
        <div className="input-group">
            <input type="text" name="filename" id="filename" placeholder={t('Enter Filename')} className="form-control" autoComplete='off'
            onChange={(event) => {set_selected_hub(null); setFilename(event.target.value)}} 
            value={filename}  />
            <button className="btn btn-dark" onClick={handleSearchByFilename}><i className="fa-solid fa-magnifying-glass"></i></button>
        </div>
    )
}

export default FilenameSearch