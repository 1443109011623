import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Spinner } from 'react-bootstrap';


function YieldRasterLegend({id, layerType}) {
    const [layer_attributes, set_layer_attributes] = useState([]);
    const [isCalibrated, setIsCalibrated] = useState(false) 
    const [error_message, set_error_message] = useState("");
    const [loading, set_loading] = useState(true);

    const [key_index, set_key_index] = useState({});

    const [colors, set_colors] = useState([]);
    const [cutoffs, set_cutoffs] = useState([]);
    const [areas, set_areas] = useState([]);

    
    async function fetchLayerAttributes(layer_id){
        set_error_message("");
        set_loading(true);

        let url = "";
        try {
            url = `${localStorage.getItem("FARMCOMMAND_HOST")}/layer/attribute/?layer=${layer_id}`    
            const response = await axios.get(url, {headers: {"Authorization": `Token ${localStorage.getItem("token")}`}})
            if(response.data?.length !== 0){
                set_layer_attributes(response.data);
                response.data?.forEach((e, index) => {
                    set_key_index(prev => {
                        return {
                            ...prev,
                            [e?.key]: index
                        }
                    })
                });
            } else {
                set_error_message("No Data")
            }
            set_loading(false);
        } catch (error) {
            set_error_message(`Something went wrong`);
            console.log(error);
            set_loading(false);
        }
        set_loading(false);
    }

    // Fetch on render
    useEffect(() => {
        document.getElementById(`btn-layer-legend-${id}`).click();
        fetchLayerAttributes(id)
    }, []);

    useEffect(() => {
        try {
            const calibrationInfo = layer_attributes.filter(attribute => {return attribute.key === "calibrationInfo"});
            if(calibrationInfo.length === 1){
                setIsCalibrated(JSON.parse(calibrationInfo[0]?.value)?.calibrated)
            }
        } catch (error) {
            setIsCalibrated(false)
        }

    }, [layer_attributes])


    useEffect(()=>{
        // Repeatables
        if(Object.keys(key_index).includes("color") && Object.keys(key_index).includes("cutoffs") && Object.keys(key_index).includes("areas")){
            set_colors(JSON.parse(layer_attributes[key_index?.color].value.replace(/'/g, '"')));
            set_cutoffs(JSON.parse(layer_attributes[key_index?.cutoffs].value.replace(/'/g, '"')));
            set_areas(JSON.parse(layer_attributes[key_index?.areas].value.replace(/'/g, '"')));
        }
        
    },[key_index]);

    return (
        <>
        <button type="button" id={`btn-layer-legend-${id}`} className="d-none" data-bs-toggle="modal" data-bs-target={`#layer-legend-${id}`}></button>
        <div className="modal fade" id={`layer-legend-${id}`} tabindex="-1" role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                            <h5 className="modal-title" id="modalTitleId">{layerType}</h5> {/* layer_name */}
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                    </div>

                    <div className="modal-body">
                    {/* Data */}
                    {
                    loading ? <div className="d-flex justify-content-center"><Spinner /></div>
                    :
                    error_message ? <p className='text-center fs-5 text-danger'>{error_message}</p> :
                    <div className="container-fluid">
                        {/* Operation Summary */}
                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                    <span className='fw-bold text-decoration-underline'>OPERATION INFO</span>
                                    <tr>
                                        <th scope="col">Operation:</th>
                                        {layer_attributes?.length > 1 && <td>{layer_attributes[key_index?.operation]?.value}</td>}
                                    </tr>

                                    <tr>
                                        <th scope="col">Combines:</th>
                                        {layer_attributes?.length > 1 && <td>{layer_attributes[key_index?.numCombines]?.value}</td>}
                                    </tr>

                                    <tr>
                                        <th scope="col">Field Area(ha):</th>
                                        {layer_attributes?.length > 1 && <td>{Number(layer_attributes[key_index?.fieldArea]?.value).toFixed(2)}</td>}
                                    </tr>

                                    <tr>
                                        <th scope="col">Harvested Area(ha):</th>
                                        {layer_attributes?.length > 1 && <td>{Number(layer_attributes[key_index?.combinedArea]?.value).toFixed(2)}</td>}
                                    </tr>

                                    <tr>
                                        <th scope="col">Avg. Yield(kg/ha):</th>
                                        {layer_attributes?.length > 1 && <td>{Number(layer_attributes[key_index?.averageYield]?.value).toFixed(2)} {isCalibrated && <GreenCheckMark />} </td>}
                                    </tr>

                                    <tr>
                                        <th scope="col">Total Yield(ha):</th>
                                        {layer_attributes?.length > 1 && <td>{Number(layer_attributes[key_index?.totalYield]?.value).toFixed(2)} {isCalibrated && <GreenCheckMark />} </td>}
                                    </tr>

                                    <tr>
                                        <th scope="col">Avg. Moisture(%):</th>
                                        {layer_attributes?.length > 1 && <td>{Number(layer_attributes[key_index?.averageMoisture]?.value).toFixed(2)}</td>}
                                    </tr>

                                    <tr>
                                        <th scope="col">Intake (kg/hr):</th>
                                        {layer_attributes?.length > 1 && <td>{Number(layer_attributes[key_index?.intake]?.value).toFixed(2)}</td>}
                                    </tr>

                                    <tr>
                                        <th scope="col">Working Time(hr):</th>
                                        {layer_attributes?.length > 1 && <td>{Number(layer_attributes[key_index?.workingTime]?.value).toFixed(2)}</td>}
                                    </tr>

                                    <tr>
                                        <th scope="col">Productivity(ha/hr):</th>
                                        {layer_attributes?.length > 1 && <td>{Number(layer_attributes[key_index?.productivity]?.value).toFixed(2)}</td>}
                                    </tr>

                                    <tr>
                                        <th scope="col">Std. Dev.(kg/ha):</th>
                                        {layer_attributes?.length > 1 && <td>{Number(layer_attributes[key_index?.stdDev]?.value).toFixed(2)}</td>}
                                    </tr>

                                </thead>
                            </table>
                        </div>

                        {/* Layer Specifics */}
                        <div className="name my-2 fw-bolder">{layerType}</div>
                            {/* Repeatable */}
                            {
                                cutoffs.map((cutoff, index) => {
                                return (
                                    <div className="d-flex align-items-center gap-2 my-2">
                                        <span className="color" style={{display:"inline-block", width:"30px", height:"30px", backgroundColor: colors[index]}}></span>
                                        {index === 0 && <span className="value"> &le; {cutoffs[index]}</span> }
                                        {index === cutoffs.length - 1 && <span className="value"> {cutoffs[index]} &gt;</span> }
                                        { (index !== cutoffs.length - 1 && index !== 0) && <span className="value"> {cutoffs[index]}</span>}
                                        <span className="area ms-auto fw-bold">{Number(areas[index]).toFixed(2)} (ha)</span>
                                    </div>
                                )
                            })}

                    </div>
                    }
                    </div>
                    {/* Data Ends */}
                    
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

function GreenCheckMark(){
    return <span className='text-white bg-success rounded-circle p-1 mx-2'>&#x2713;</span>
}

export default YieldRasterLegend 