import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import { PrescriptionDataContext } from '../context/PrescriptionDataContext';
import { CODA_HOST, PRESCRIPTION_FILE } from '../../../../constants';
import { useTranslation } from 'react-i18next';

function GrowerSelector({ selected_hub, setFilename }) {

    const { t } = useTranslation();

    // Context
    const {setData, setLoading, setMessage, setNext, setPrev, setSearchType} = useContext(PrescriptionDataContext)


    // States
    const [ growers , set_growers ] = useState([]);
    const [isFetching , setIsFetching] = useState(false);
    const [ selected_grower , set_selected_grower ] = useState([]);

    // Side Effects
    useEffect(() => {
        fetchGrowers()
    }, [selected_hub])

    // Helpers
    // Fetches growers from backend
    async function fetchGrowers(){
        console.log('fetching growers....')
        set_growers([])
        try{
            setIsFetching(true)
            let response = await axios(`/api/getHGrowers/?hubID=${selected_hub?.value}`);
            set_growers(response.data.features.map((e) => { return {"value" : e.properties.grower , "label" : e.properties.grower_label}}));
            }
        catch(err){
            console.log('Error while fetching growers...')
        }
        finally{
            setIsFetching(false)
        }
    }

    // Event Handlers
    async function handleGrowerChange(event){
        set_selected_grower(event)
        const {value: grower_id, label: grower_label} = event;
        setLoading(true);
        try {
            let url = `${CODA_HOST}/${PRESCRIPTION_FILE}/?grower_id=${grower_id}`
            let response = await axios.post("/coda/make_coda_call/", {"url": url})
            setSearchType("grower")
            setData(response.data?.results);
            setNext(response.data?.next);
            setPrev(response.data?.previous);
            setMessage(`Prescription Files of the Grower: ${grower_label}`);
        } catch (error) {
            console.log("Error on Fetching data", error)
        } finally{
            setLoading(false);
            setFilename("");
        }
    }


    return (
        <div className="mb-3">
            {
                !isFetching && 
                <Select 
                    placeholder={t("Select Grower")}
                    options={growers} 
                    onChange={handleGrowerChange}
                    value={selected_grower}
                    styles={{menu: style => ({...style, zIndex: "10"}) }}
                />
            }
        </div>

    )
}

export default GrowerSelector