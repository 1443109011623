import { Fade, Spinner } from "react-bootstrap"

const CodaOutput = ({open, setOpen, loading, data}) => {

    const isGrower = localStorage.getItem("user_roles").split(",").includes("Grower");

    return (
        <>
        {
            !isGrower && <Fade in={open} unmountOnExit={true}>
            <tr>
                <td className='cursor-pointer bg-success text-white' colSpan={12} onDoubleClick={() => { setOpen(false) }} >
                    {loading && <Spinner className='m-4'/>}
                    <pre className='mx-5 p-2'>{JSON.stringify(data, null, 2)}</pre>
                </td>
            </tr>
            </Fade>
        }
        </>
    )
}

export default CodaOutput