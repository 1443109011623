import {ALL, GCS_RAW, FARM_COMMAND, CNHI_INTEGRATION, JOHN_DEERE_INTEGRATION} from './constants'

// Component for Flag Source Filters
export default function FlagSourceFilters(
    {sourcefiles, setFilteredSourcefiles, currentFilter, setCurrentFilter, currentSize}
){

    // Event Handler
    function handleFilterChange(event, e){
        event.preventDefault();
        setCurrentFilter(e);
        switch(e){
            case ALL:
                setFilteredSourcefiles(sourcefiles.slice(0, currentSize))
                break;
            case GCS_RAW:
                setFilteredSourcefiles(sourcefiles.filter(sourcefile => sourcefile?.flag_source === GCS_RAW))
                break;
            case JOHN_DEERE_INTEGRATION:
                setFilteredSourcefiles(sourcefiles.filter(sourcefile => sourcefile?.flag_source === JOHN_DEERE_INTEGRATION))
                break;
            case CNHI_INTEGRATION:
                setFilteredSourcefiles(sourcefiles.filter(sourcefile => sourcefile?.flag_source === CNHI_INTEGRATION))
                break;
            case FARM_COMMAND:
                setFilteredSourcefiles(sourcefiles.filter(sourcefile => sourcefile?.flag_source === FARM_COMMAND))
                setCurrentFilter("Support Portal Uploads");

                break;
            default:
                setFilteredSourcefiles(sourcefiles);
                break;
        }
    }

    return (
        <div className='d-flex justify-content-end my-2'>
            <div className="dropdown">
            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                {currentFilter}
            </button>
            <ul className="dropdown-menu btn-secondary">
                {[ALL, GCS_RAW, FARM_COMMAND, JOHN_DEERE_INTEGRATION, CNHI_INTEGRATION].map(e => {
                    if(e === FARM_COMMAND)
                        return <li key={e}><button className="dropdown-item" onClick={(event) => {handleFilterChange(event, e)}}>{"Support Portal Uploads"}</button></li>
                    return <li key={e}><button className="dropdown-item" onClick={(event) => {handleFilterChange(event, e)}}>{e}</button></li>
                })}
                
            </ul>
            </div>
        </div>
    )
}