import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Country from './form_components/Country';
import State from './form_components/State';
import District from './form_components/District';
import Tehsil from './form_components/Tehsil';
import Village from './form_components/Village';
import Khasra from './form_components/Khasra';
import RefNo from './form_components/RefNo';
import SelectedValuesDisplay from './form_components/SelectedValuesDisplay';
import { requestParametersAddorChange } from '../../../../features/RequestParametersSlice';

const PDFGeneration = () => {
    const dispatch = useDispatch();
    const {
        country,
        state,
        district_id,
        district,
        tehsil,
        tehsil_id,
        village,
        field_ids,
        parcel_status,
        parcel_nums,
        ref_no
    } = useSelector(state => state.requestParameters);

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    
    const selectedValues = {
        "Country": country,
        "State": state,
        "District": district,
        "Tehsil": tehsil,
        "Village": village,
        "Fields": field_ids?.join('\n') || '',
        "Parcel Number": parcel_nums?.join('\n') || '',
        "Ref No": ref_no,
    };

    const user_id = localStorage.getItem("client_id");

    async function handleDownload() {
        setLoading(true);
        setIsButtonDisabled(true);

        const assetList = field_ids;
        toast("Hold on, generating PDFs...");
        const url = `/reporting/sail/`;

        try {
            const response = await axios.post(url, {
                assetList,
                user_id,
                parcel_nums,
                ref_no,
                village,
                district,
                state,
                tehsil,
                parcel_status
            });
            if ([200, 201].includes(response.status)) {
                navigate("/reporting/sail/generated_pdfs/", {
                    state: { task_id: response.data.task_id }
                  });
            } else {
                toast("Failed to generate PDF.");
            }
        } catch (error) {
            toast("Failed to generate PDF.");
            console.error(error);
        } finally {
            setLoading(false);
            setIsButtonDisabled(false);
        }
    }

    const handleCountryChange = (value) => {
        dispatch(requestParametersAddorChange({ country: value }));
        dispatch(requestParametersAddorChange({ state: null }));
        dispatch(requestParametersAddorChange({ district: null }));
        dispatch(requestParametersAddorChange({ district_id: null }));
        dispatch(requestParametersAddorChange({ tehsil: null }));
        dispatch(requestParametersAddorChange({ tehsil_id: null }));
        dispatch(requestParametersAddorChange({ village: null }));
        dispatch(requestParametersAddorChange({ field_ids: [] }));
        dispatch(requestParametersAddorChange({ parcel_nums: [] }));
        dispatch(requestParametersAddorChange({ ref_no: null }));
    };

    const handleStateChange = (value) => {
        dispatch(requestParametersAddorChange({ state: value }));
        dispatch(requestParametersAddorChange({ district: null }));
        dispatch(requestParametersAddorChange({ district_id: null }));
        dispatch(requestParametersAddorChange({ tehsil: null }));
        dispatch(requestParametersAddorChange({ tehsil_id: null }));
        dispatch(requestParametersAddorChange({ village: null }));
        dispatch(requestParametersAddorChange({ field_ids: [] }));
        dispatch(requestParametersAddorChange({ parcel_nums: [] }));
        dispatch(requestParametersAddorChange({ ref_no: null }));
    };

    const handleDistrictChange = (value) => {
        dispatch(requestParametersAddorChange({ district: value }));
        dispatch(requestParametersAddorChange({ tehsil: null }));
        dispatch(requestParametersAddorChange({ tehsil_id: null }));
        dispatch(requestParametersAddorChange({ village: null }));
        dispatch(requestParametersAddorChange({ field_ids: [] }));
        dispatch(requestParametersAddorChange({ parcel_nums: [] }));
        dispatch(requestParametersAddorChange({ ref_no: null }));
    };

    const handleTehsilChange = (value) => {
        dispatch(requestParametersAddorChange({ tehsil: value }));
        dispatch(requestParametersAddorChange({ village: null }));
        dispatch(requestParametersAddorChange({ field_ids: [] }));
        dispatch(requestParametersAddorChange({ parcel_nums: [] }));
        dispatch(requestParametersAddorChange({ ref_no: null }));
    };

    const handleVillageChange = (value) => {
        dispatch(requestParametersAddorChange({ village: value }));
        dispatch(requestParametersAddorChange({ field_ids: [] }));
        dispatch(requestParametersAddorChange({ parcel_nums: [] }));
        dispatch(requestParametersAddorChange({ ref_no: null }));
    };

    const handleKhasraChange = (value) => {
        dispatch(requestParametersAddorChange({ field_ids: value }));
    };

    const handleRefNoChange = (value) => {
        dispatch(requestParametersAddorChange({ ref_no: value }));
    };

    return (
        <div className="row w-100 justify-content-center mx-auto">
            <div className='col-sm-4 hide-mobile'>
                <SelectedValuesDisplay selectedValues={selectedValues} />
            </div>
            <div className='col-sm-8'>
                <div className="legend align-middle hide-mobile">
                    <div className="color-box color-box-green"></div>
                    <span className="color-label">Analysis Complete</span>

                    <div className="color-box color-box-yellow"></div>
                    <span className="color-label">Analysis Not Complete</span>
                </div>

                {/* Country Selector */}
                <div className="mb-2 my-4">
                    <Country setLoading={setLoading} onChange={handleCountryChange} />
                </div> 

                {/* State Selector */}
                {country && (
                    <div className="mb-2 my-4">
                        <State setLoading={setLoading} onChange={handleStateChange} />
                    </div>
                )}

                {/* District Selector */}
                {state && (
                    <div className="mb-2 my-4">
                        <District setLoading={setLoading} onChange={handleDistrictChange} />
                    </div>
                )}

                {/* Tehsil Selector */}
                {district_id && (
                    <div className="mb-2 my-4">
                        <Tehsil setLoading={setLoading} onChange={handleTehsilChange} />
                    </div>
                )}

                {/* Village Selector */}
                {tehsil_id && (
                    <div className="mb-2 my-4">
                        <Village setLoading={setLoading} onChange={handleVillageChange} />
                    </div>
                )}

                {/* Khasra Selector */}
                {village && (
                    <div className="mb-2 my-4">
                        <Khasra setLoading={setLoading} onChange={handleKhasraChange} />
                    </div>
                )}

                {/* Farm Name */}
                {village && (
                    <div className="mb-2 my-4">
                        <RefNo setLoading={setLoading} onChange={handleRefNoChange} />
                    </div>
                )}

                {loading ? (
                    <Spinner className='d-block mx-auto my-4' />
                ) : (
                    (country && state && district_id && tehsil_id && village && ref_no) && (
                        <div className="mb-2 my-4 d-flex align-items-center justify-content-center">
                            <button className="btn btn-lg btn-outline-dark" disabled={isButtonDisabled} onClick={handleDownload}>
                                Generate
                            </button>
                        </div>
                    )
                )}
            </div>
        </div>
    )
}

export default PDFGeneration;
