import axios from 'axios';
import {
  GRID_LARGE_BREAKPOINT, GRID_SMALL_BREAKPOINT, GRID_COLUMNS, WIDGET_AUTH_MAP
} from './constants';
import { FARM_COMMAND_HOST } from '../../constants';
import { allowedPages } from 'components/Offcanvas/allowedPages.js'; // Import allowedPages array

export async function fetch_growers() {
  if (!localStorage.getItem('growers')) {
    try {
      const growers = await axios.get(`${FARM_COMMAND_HOST}/asset/?category=grower`, { headers: { "Authorization": `Token ${localStorage.getItem("token")}` } });
      if (growers.data && growers.data.length < 10) {
        localStorage.setItem('growers', JSON.stringify(
          growers.data.map(e => {
            return { id: e.id, label: e.label };
          })
        ));
      } else {
        localStorage.setItem('growers', 'false');
      }
    } catch (error) {
      console.log('Error while fetching all growers');
      console.log(error);
    }
  }
}

export function generate_user_allowed_pages() {
  const allowedUserPages = [];
  allowedPages.forEach(category => {
    // Filter the pages that are allowed for the current user
    var visiblePages = category.pages.filter(page => page.auth);
    visiblePages.forEach(page => {
      allowedUserPages.push({
        key: page.key,
        icon: page.icon,
        pageName: page.name,
        route: page.route,
        tooltip: page.name,
        description: page.description
      });
    });
  });
  return allowedUserPages;
}

export function generate_default_layout() {
  const LARGE_GRID_LAYOUT = [], SMALL_GRID_LAYOUT = [];

  const USER_ALLOWED_PAGES = generate_user_allowed_pages();

  // Check if the length of USER_ALLOWED_PAGES is less than 4
  const lg_max_width = USER_ALLOWED_PAGES.length < 4 ? 3 : GRID_COLUMNS[GRID_LARGE_BREAKPOINT];
  const sm_max_width = USER_ALLOWED_PAGES.length < 4 ? 3 : GRID_COLUMNS[GRID_SMALL_BREAKPOINT];

  // LG - consts
  const lg_gridWidth = 1, lg_gridHeight = 4;
  let lg_x = 0 - lg_gridWidth, lg_y = 0;

  // SM - consts
  const sm_gridWidth = 1, sm_gridHeight = 4;
  let sm_x = 0 - sm_gridWidth, sm_y = 0 - sm_gridHeight;

  USER_ALLOWED_PAGES.forEach(page => {
    lg_x += lg_gridWidth;
    if (lg_x >= lg_max_width) { // Use >= to ensure it resets correctly
      lg_x = 0;
      lg_y += lg_gridHeight;
    }

    sm_x += sm_gridWidth;
    if (sm_x >= sm_max_width) { // Use >= to ensure it resets correctly
      sm_x = 0;
      sm_y += sm_gridHeight;
    }

    LARGE_GRID_LAYOUT.push({
      i: page.pageName,
      w: lg_gridWidth,
      h: lg_gridHeight,
      x: lg_x,
      y: lg_y,
    });

    SMALL_GRID_LAYOUT.push({
      i: page.pageName,
      w: sm_gridWidth,
      h: sm_gridHeight,
      x: sm_x,
      y: sm_y,
    });
  });

  return {
    [GRID_LARGE_BREAKPOINT]: LARGE_GRID_LAYOUT,
    [GRID_SMALL_BREAKPOINT]: SMALL_GRID_LAYOUT
  };
}

export function generate_user_allowed_widgets() {
  const allowedUserWidgets = [];
  WIDGET_AUTH_MAP.forEach(widget => {
    // Check if the widget is allowed for the current user
    if (widget.auth) {
      allowedUserWidgets.push({ "name": widget.name, "label": widget.label });
    }
  });
  return allowedUserWidgets;
}

export function initializeLayouts() {
  const DEFAULT_LAYOUT = generate_default_layout();
  const USER_ALLOWED_WIDGETS = generate_user_allowed_widgets();
  sessionStorage.setItem("layouts", JSON.stringify(DEFAULT_LAYOUT));
  sessionStorage.setItem("widgets", JSON.stringify(USER_ALLOWED_WIDGETS));
}
