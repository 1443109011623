import Navbar from './components/Navbar'
import { Offcanvas } from '../../components'
import Datatable from "./components/admin/DataTable"
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'

const Admin = () => {

    const { t } = useTranslation();

    return (
        <>
        <Navbar pageName={t("SR Admin")} />
            <Offcanvas />

            <div className="page-content">
                <p className="text-center fs-5 my-3">{t("Service Requests")}</p>
                <Datatable />
            </div>

            <ToastContainer 
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark" />
        </>
    )
}

export default Admin