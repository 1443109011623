import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import CustomSelect from './form_components/CustomSelect';
import SelectedValuesDisplay from './form_components/SelectedValuesDisplay';
import { requestParametersAddorChange } from '../../../../features/RequestParametersSlice';

const PDFGeneration = () => {
    const dispatch = useDispatch();
    const { client, field_ids, source} = useSelector(state => state.requestParameters);

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const selectedValues = {
        "Insurance Provider": source,
        "Client": client,
        "Fields": field_ids?.join('\n') || '',
    };

    const user_id = localStorage.getItem("client_id");

    async function handleDownload() {
        setLoading(true);
        setIsButtonDisabled(true);

        const assetList = field_ids;
        toast("Hold on, generating PDFs...");
        const url = `/reporting/iris/`;

        try {
            const response = await axios.post(url, {
                assetList,
                user_id,
                client,
                source,
            });
            if ([200, 201].includes(response.status)) {
                navigate("/reporting/iris/generated_pdfs/", {
                    state: { task_id: response.data.task_id }
                });
            } else {
                toast("Failed to generate PDF.");
            }
        } catch (error) {
            toast("Failed to generate PDF.");
            console.error(error);
        } finally {
            setLoading(false);
            setIsButtonDisabled(false);
        }
    }

    const handleClientChange = (value) => {
        dispatch(requestParametersAddorChange({ client: value }));
    };

    const handleFieldIdsChange = (value) => {
        dispatch(requestParametersAddorChange({ field_ids: value }));
    };
    const handleInsuranceProviderChange = (value) => {
        dispatch(requestParametersAddorChange({ source: value }));
    };

    return (
        <div className="row w-100 justify-content-center mx-auto">
            <div className='col-sm-4 hide-mobile'>
                <SelectedValuesDisplay selectedValues={selectedValues} />
            </div>
            <div className='col-sm-8'>
                <div className="mb-2 my-4">
                    <CustomSelect onChange={handleInsuranceProviderChange} dropLabel="Insurance Provider" url={'/reporting/iris/insurance_providers/'}  closeMenuOnSelect={true}/>
                </div>
                { source && (
                <div className="mb-2 my-4">
                    <CustomSelect onChange={handleClientChange} dropLabel="Client" url={`/reporting/iris/clients/`} closeMenuOnSelect={true} />
                </div>
                )}
                { source && client && (
                <div className="mb-2 my-4">
                    <CustomSelect onChange={handleFieldIdsChange} dropLabel="Fields" url={`/reporting/iris/fields/?client=${client}`} multiselect={true} closeMenuOnSelect={false}/>
                </div>
                )}   

                {loading ? (
                    <Spinner className='d-block mx-auto my-4' />
                ) : (
                    source && client && field_ids && (
                        <div className="mb-2 my-4 d-flex align-items-center justify-content-center">
                            <button className="btn btn-lg btn-outline-dark" disabled={isButtonDisabled} onClick={handleDownload}>
                                Generate
                            </button>
                        </div>
                    )
                )}
            </div>
        </div>
    );
}

export default PDFGeneration;