import React , {useState, useEffect } from 'react'
import Dropzone , {useDropzone} from 'react-dropzone'
import file_image from '../../assets/images/file.png'
import { calculateMD5 } from '../../utils/CalculateMD5'
import axios from 'axios'
import 'animate.css';

import { useDispatch, useSelector } from 'react-redux'
import {requestParametersAddorChange, requestParameterRemove} from '../../features/RequestParametersSlice'

import { useNavigate } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function FileUpload() {
  import("../../styles/cch_index.css")
  const dispatch = useDispatch() // dispatcher
  const navigate = useNavigate(); // navigator

  const { t } = useTranslation();

  // Global State
  const requestParameters = useSelector(state => state.requestParameters);

  // States
  const [file, setFile] = useState([]); //file
  const [error , setErrorMessage] = useState("") //error message
  const [loading , setLoading] = useState(false); // to keep track of visibility
  const [PERCENT_UPLOADED , SET_PERCENT_UPLOADED] = useState(0); // to keep track of uploaded percentage

  const CHUNK_SIZE = (256 * 1024) * 10; // GCS Single Chunk Size
  
  
  // Creates checksum_raw if file selected, deletes checksum_raw from context if file is removed
  useEffect(() => {
    dispatch(requestParameterRemove('checksum_raw'));
    if(file.length === 0){
      dispatch(requestParameterRemove('filename'));
      dispatch(requestParameterRemove('file_size_raw'));
      dispatch(requestParameterRemove('checksum_raw'));
    }

    else{
      calculateMD5(file)
      .then(hash => {
        dispatch(requestParametersAddorChange({'checksum_raw': hash})) // add hash to global store
      })
      .catch(error => console.error(error));
  }
  } ,[file]);

  // If File has been uploaded completely
  useEffect(() => {
    if(PERCENT_UPLOADED === 100){
      // navigate("/uploaded_controller_files");
    }
  }, [PERCENT_UPLOADED])
  

  //Style for file thumb
  const dropzoneStyles = {cursor: "pointer", height: "150px", border: "2px solid rgba(0,0,0,0.3)", padding: "20px 20px", textAlign: "center", display:"flex" , background: "#ffffff"}

  // Dropzone settings, trigged when file is selected/dropped on dropzone
  const {getRootProps, getInputProps} = useDropzone({
    // accept: ['.png', '.jpg', '.jpeg'],// accept: ".zip, .tar.gz, .tgz, .tar",
    multiple: false,
    onDrop: acceptedFiles => {
      if(acceptedFiles.length === 1){
        const file_extension = acceptedFiles[0].name.split(".").at(-1);
        if (["zip", "gz", "tgz", "tar"].includes(file_extension)){
            setFile(() => {
              dispatch( requestParametersAddorChange({"filename": acceptedFiles[0].path, "file_size_raw" : acceptedFiles[0].size}) )
              return acceptedFiles[0];
            })
        } else {
          setErrorMessage("Unsupported File Format, you can upload only [.zip, tar.gz, tgz, tar] files");
          setTimeout(() => {setErrorMessage("")}, 4000)
        }
      }
      else{
        setErrorMessage("You Can Upload a maximum of 1 File only");
        setTimeout(() => {setErrorMessage("")}, 3000)
      }
    },
  });

  
  
  // Removes Selected file
  function handleRemoveFile(){
    console.log('clicked')
    console.log(file)
    setFile([])
  }

  // Uploader
  async function handleFileUpload(event){
    setLoading(true);
    event.target.classList.add('disabled')

    // Fetch Url & Set the url to localstorage
    let resumable_session_uri = '';
    let sourcefile_id = '';
    try {
      const response = await axios.post("/coda/initiate_gcs_upload/", { ...requestParameters })
      resumable_session_uri = response.data?.resumable_session_uri;
      sourcefile_id = response.data?.sourcefile_id;
    } catch (error) {
      setLoading(false);
      const coda_error_message = error?.response?.data?.coda_error_message
      if(coda_error_message.includes("already exists in Coda")){
        setErrorMessage(`
          You have tried to upload a duplicate file, please check uploaded files for errors. <br />
          If you are not seeing the expected data in FarmCommand, <a class='btn btn-primary' href=${document.location.origin + '/#/uploaded_controller_files'}>click here</a> submit a support ticket.
        `)
      }
      else if(coda_error_message){
        setErrorMessage(coda_error_message)
      } else{
        setErrorMessage("Error While Initiating GCS Upload, Refresh the page and try again after sometime")
        setTimeout(() => {setErrorMessage("")}, 5000)
      }
      return;
    }
    setLoading(false);
    
    console.log('resumable_session_uri => ', resumable_session_uri)
    if(resumable_session_uri){
      console.log('Setting uri to Local Storage')
      localStorage.setItem("resumable_session_uri" , resumable_session_uri); localStorage.setItem("file_size" , file.size); localStorage.setItem("file_name" , file.name); localStorage.setItem("sourcefile_id" , sourcefile_id);
    } 


    //Multi Chunk Upload to GCS
    console.log('Uploading...')
    const TOTAL_CHUNKS_REQUIRED = Math.ceil((file.size) / CHUNK_SIZE);
    let UPLOADED_CHUNKS  = 0;
    
    // Starts Multi Chunk Upload to GCS
    for (let i = 0; i < file.size; i += CHUNK_SIZE) {
        const chunk = file.slice(i, i + CHUNK_SIZE);
        const start = i;
        const end = Math.min(i + CHUNK_SIZE - 1, file.size - 1);
        const range = `bytes ${start}-${end}/${file.size}`;
    
        const chunkHeaders = {
          'Content-Type': file.type,
          'Content-Range': range,
        };
    
        try {
          await axios.put(resumable_session_uri, chunk, { headers: chunkHeaders });
        } 
        catch (error) {
          if(error?.response?.status === 308) {
              UPLOADED_CHUNKS ++;
              console.log(UPLOADED_CHUNKS,TOTAL_CHUNKS_REQUIRED)
              console.log(PERCENT_UPLOADED)
              SET_PERCENT_UPLOADED( (UPLOADED_CHUNKS/TOTAL_CHUNKS_REQUIRED) * 100 )
          }
          else{
              SET_PERCENT_UPLOADED(100);
              localStorage.removeItem("file_name"); localStorage.removeItem("file_type"); localStorage.removeItem("file_size"); localStorage.removeItem("resumable_session_uri");
              setFile([]);
              console.log('Upload complete!');
         
          }
      }
    }
  }

  return (
    <>
      <div className="my-5">
      <p className='h3'>{t("Controller File Upload")}</p>
      <p className='fs-5 mb-2'>{t("Please provide a the controller file (this will be uploaded to GCS)")}</p>
      <p className='mt-3 mb-4 fst-italic'>{t("Note: You Can Only Upload A Maximum Of 1 File only")}</p>

      <div className='' {...getRootProps({className: 'dropzone'})} style={ dropzoneStyles } >
        <input {...getInputProps()} multiple={false} /> {/* accept=".zip, .tar.gz, .tgz, .tar"  */}
        <p className='mx-auto my-auto fs-5 opacity-75'>{t("Drop or paste files here or Click to select upload!")}</p>
      </div>

      { PERCENT_UPLOADED === 100 && <p className='my-5 text-center fs-4 text-success animate__animated animate__bounce'>Uploaded Successfully</p>}

      {
        error &&
        <p className='text-capitalize my-3 fs-5 text-danger'><span dangerouslySetInnerHTML={{__html: error}}></span></p>
      }

      { 
        requestParameters.filename &&
        <>
        <div className="d-flex flex-column mx-3 my-2 p-2" style={{flex: ""}}>
        {
            <>  
            <div className="d-flex ">
                <img className='flex-item' alt="" src={file_image} style={{width:"120px" , height: "120px", borderRadius: "20px" }} data-bs-toggle="tooltip"  data-bs-placement="top" title={file.path} draggable='false' />
                
            </div>
            <p>{file.name}</p>

            {/* File Remove Button, appears only if percent uploaded is 0 */}
            { PERCENT_UPLOADED === 0 &&
              <div className="" onClick={handleRemoveFile}>
                <button className='flex-item my-2 mx-2  btn btn-danger p-2' style={{cursor: "pointer"}} onClick={handleRemoveFile}> <i className="fa-solid fa-trash"></i> Remove</button>
              </div>
            }
            
            {/* Progress Bar, will appear if upload progess value is between 1-100*/}
            {
              (PERCENT_UPLOADED > 0 && PERCENT_UPLOADED < 100) &&
              <div className="progress my-3 bg-dark">
                <div className="progress-bar progress-bar-striped" role="progressbar" style={{width: `${PERCENT_UPLOADED}%`}}>{PERCENT_UPLOADED.toPrecision(4)}%</div>
              </div>
            }

            {/* Upload Button */}
            <div className="d-flex my-4 flex-column">
              {
                loading && <div className="flex-item mb-4">
                  <Spinner className='d-block mx-auto text-primary'/>
                </div>
              }
              
              {/* Condition to bring upload button */}
              { 
                requestParameters.checksum_raw && 
                <button className='flex-item w-100 my-2 mx-2 btn btn-outline-primary p-2' style={{cursor: "pointer"}} onClick={(event) => {handleFileUpload(event)}}> <i className="fa-solid fa-cloud-arrow-up"></i> {t("Upload")}</button>
              }

              </div>
            </>
        }
        </div>
      </>
      }
    </div>
    </>
  );

  
}

export default FileUpload