import { useContext } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { AiOutlineDrag } from 'react-icons/ai';
import { DashboardContext } from '../context/DashboardContext';
import {
    GRID_BREAKPOINTS, GRID_COLUMNS,
    GRID_LAYOUT_OUTER_PADDING, GRID_LAYOUT_ROW_HEIGHT, GRID_LAYOUT_TILE_MARGIN,
} from '../constants';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const GridLayout = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { layouts, setLayouts, breakpoint, setBreakpoint, USER_ALLOWED_PAGES } = useContext(DashboardContext);

    const handleLayoutChange = (_layout, _layouts) => {
        setLayouts(_layouts);
        sessionStorage.setItem("layouts", JSON.stringify(_layouts));
    };

    return (
        <div>
            <ResponsiveReactGridLayout
                breakpoints={GRID_BREAKPOINTS}
                cols={GRID_COLUMNS}
                rowHeight={GRID_LAYOUT_ROW_HEIGHT}
                margin={GRID_LAYOUT_TILE_MARGIN}
                containerPadding={GRID_LAYOUT_OUTER_PADDING}
                onLayoutChange={handleLayoutChange}
                onBreakpointChange={setBreakpoint}
                layouts={layouts}
                isResizable={false}
                draggableHandle=".drag-handle"
            >
                {USER_ALLOWED_PAGES.map((page, index) => (
                    layouts[breakpoint].some(layout => layout.i === page.pageName) && (
                        <div
                            key={page.pageName}
                            onClick={() => navigate(page.route)}
                            className={`widget-menu box-style btn btn-outline-dark dashboard-navigation ${index}`}
                        >
                            <div className='align-items-center justify-content-center icon' onClick={() => navigate(page.route)}>
                                <span className='fs-5'>{page.icon}</span>
                            </div>
                            <div className='widget-header'>
                                <h3 className='px-2 mb-2'>{t(page.pageName)}</h3>
                                <p className='description px-2 mb-0'>{t(page.description)}</p>
                            </div>
                            <div className="position-absolute drag-handle p-2" style={{ right: 0, top: 0, zIndex: 10 }}>
                                <AiOutlineDrag size={18} color='#000' />
                            </div>
                        </div>
                    )
                ))}
            </ResponsiveReactGridLayout>
        </div>
    );
};

export default GridLayout;