import React from 'react'
import {Offcanvas } from '../../../../components'
import Navbar from '../../../settings/components/Navbar'

import { t } from 'i18next'
import Datatable from '../components/table_components/DataTable'

const GeneratedPdfs = () => {
    return (
        <>
            <Navbar pageName="Generated PDFs" />
            <Offcanvas />
            <div className="page-content my-4">
                <Datatable />
            </div>
        </>
    )
}

export default GeneratedPdfs