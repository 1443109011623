import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { t } from "i18next";
import { FARM_COMMAND_HOST } from 'utils/../constants';


const USER_FOR_THE_FIRST_TIME = "USER_FOR_THE_FIRST_TIME";
const EXISTING_USER = "EXISTING_USER";

const LeafForm = ({ setStatus, setUserData }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const searchToken = state?.token;

    // Local States
    const [userInfo, setUserInfo] = useState({});
    const [loading, setLoading] = useState(false);

    // Data States
    const [data, setData] = useState({
        client_id: "",
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        address: "",
    });

    async function checkUser(client_id) {
        console.log("checkUser called with client_id:", client_id);
        setLoading(true);
        try {
            const response = await axios.get(`/leaf/user/?client_id=${client_id}`);
            if (response.status === 200) {
                setData(response.data);
                setStatus(EXISTING_USER);
                navigate("/leaf-integration/", { state: { data: response.data } });
            }
        } catch (error) {
            setStatus(USER_FOR_THE_FIRST_TIME);
            navigate("/leaf-integration/form/", { state: { "token": searchToken } });
        } finally {
            setLoading(false);
        }
    }

    async function fetchUserInfo() {
        console.log("fetchUserInfo called");
        setLoading(true);
        const token = localStorage.getItem("token");
        const client_id = localStorage.getItem("client_id");
        try {
            const url = `${FARM_COMMAND_HOST}/client/currentuser/`;
            let headers = {};
            if (!searchToken) {
                headers = { "Authorization": `Token ${token}` };
            } else {
                headers = { "Authorization": `Token ${searchToken}` };
            }

            const response = await axios.get(url, { headers: headers });
            if (response.status === 200) {
                setUserInfo(response.data);
                checkUser(client_id);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (!userInfo.id) {
            fetchUserInfo();
        }
    }, [userInfo]);

    useEffect(() => {
        const { id, first, last, user } = userInfo;
        const email = user?.email;
        setData((prevData) => ({
            ...prevData,
            client_id: id || "",
            firstname: first || "",
            lastname: last || "",
            email: email || "",
        }));
    }, [userInfo]);

    if (loading) {
        return (
            <div className="d-flex vh-100 align-items-center justify-content-center">
                <Spinner />
            </div>
        );
    }

    // Event Handler
    function handleInputChange(event) {
        const { name, value } = event.target;
        setData((d) => ({
            ...d,
            [name]: value,
        }));
    }

    async function handleRegister() {
        setLoading(true);
        console.log(data);
        const { client_id, firstname, lastname, email, phone, address } = data;
        const payload = {
            name: `${firstname} ${lastname}`,
            email: `${email}`,
            phone: `${phone}`,
            address: `${address}`,
            client_id: client_id,
        };

        try {
            const response = await axios.post("/leaf/user/", payload);
            if ([200, 201].includes(response.status)) {
                setUserData(response.data); // Update userData in LeafIntegration
                setStatus(EXISTING_USER);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <div className="container my-5">
                <div className="row mb-4">
                    <div className="col">
                        <div className="form-outline">
                            <label className="form-label fw-bold" htmlFor="form3Example1">{t("First name")}*</label>
                            <input
                                id="firstname"
                                type="text"
                                name="firstname"
                                placeholder={t("Enter Your First Name")}
                                className="form-control"
                                value={data.firstname}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-outline">
                            <label className="form-label fw-bold" htmlFor="form3Example2">{t("Last name")}*</label>
                            <input
                                id="lastname"
                                type="text"
                                name="lastname"
                                placeholder={t("Enter Your Last Name")}
                                className="form-control"
                                value={data.lastname}
                                onChange={handleInputChange}
                                required
                            />
                        </div>
                    </div>
                </div>

                <div className="form-outline mb-4">
                    <label className="form-label fw-bold" htmlFor="form3Example3">{t("Email address")}*</label>
                    <input
                        id="email"
                        type="email"
                        name="email"
                        placeholder={t("Enter Your Email")}
                        className="form-control"
                        value={data.email}
                        onChange={handleInputChange}
                        required
                    />
                </div>

                <div className="form-outline mb-4">
                    <label className="form-label fw-bold" htmlFor="form3Example4">{t("Phone")}</label>
                    <input
                        id="phone"
                        type="text"
                        name="phone"
                        value={data.phone}
                        placeholder={t("Enter Your Phone Number")}
                        className="form-control"
                        onChange={handleInputChange}
                    />
                </div>

                <div className="form-outline mb-4">
                    <label className="form-label fw-bold" htmlFor="form3Example4">{t("Address")}</label>
                    <input
                        id="address"
                        type="text"
                        name="address"
                        value={data.address}
                        placeholder={t("Enter Your Address")}
                        className="form-control"
                        onChange={handleInputChange}
                    />
                </div>

                {data.firstname && data.lastname && data.email && (
                    <div className="text-center my-4">
                        <button
                            type="button"
                            className="btn btn-lg btn-primary btn-block mb-4"
                            onClick={handleRegister}
                        >
                            {t("Register")}
                        </button>
                    </div>
                )}
            </div>
        </>
    );
};

export default LeafForm;