import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import Navbar from '../../components/NavbarWithBack'
import Offcanvas from '../../components/Offcanvas'
import SeasonView from './components/season_view/SeasonView'
import DetailedView from './components/detailed_view/DetailedView'
import {FARM_COMMAND_HOST, FARM_COMMAND_HOST_QA} from '../../constants'
import { Spinner } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { tempParametersAddorChange } from '../../features/TempParametersSlice'
import { requestParametersReset } from '../../features/RequestParametersSlice'
import { constructState, constructState_ } from '../../features/RequestSlice'
import Legend from './components/Legend'
import { useTranslation } from 'react-i18next'


function ProductTable() {

    const { t } = useTranslation();

    const dispatch = useDispatch()
    const location = useLocation()

    const sourcefile_id  = location?.state?.sourcefile_id; // sourcefile_id, sent from uploaded_controller_files page
    const filename  = location?.state?.filename; // filename, sent from uploaded_controller_files page 
    const grower_id = location?.state?.grower_id; // Grower ID, sent from uploaded_controller_files page

    // Setting Sourcefile_id, filename, grower_id to global state
    dispatch(tempParametersAddorChange({"sourcefile_id": sourcefile_id, "filename": filename, "grower_id": grower_id}))

    const [region, set_region] = useState('') // to store region
    const [error_message, set_error_message] = useState();
    
    

    const [ product_details, set_product_details ] = useState([]) // to generate table from GeoJsonSummary.json
    const [ loading_1 , set_loading_1 ] = useState(true) // loading component visibility
    const [ loading_2 , set_loading_2 ] = useState(true) // loading component visibility

    // fetch product details
    async function fetch_product_details(sourcefile_id, filename){
      set_loading_1(true);
      try {
          const response = await axios.post("/coda/retrieve_products/", {
              "sourcefile_id" : sourcefile_id,
              "filename": filename,
              "grower_id": grower_id
          })
          set_product_details(response.data);
          set_loading_1(false)
      } catch (error) {
          console.log('Error occured while fetching product details...', error)
          set_error_message(error?.response?.data?.detail);
          set_loading_1(false)
      }
  }

  // Fetch all supplies data
  async function fetch_supplies_data(){
    set_loading_2(true);
    try {
        const [chemicalsupplies, fertilizersupplies, seedsupplies] = await Promise.all( [ axios.get(`${FARM_COMMAND_HOST}/supplies/chemicalsupply/?rootAsset=${grower_id}`, {headers : {"Authorization" : `Token ${localStorage.getItem("token")}`}}), axios.get(`${FARM_COMMAND_HOST}/supplies/fertilizersupply/?rootAsset=${grower_id}`, {headers : {"Authorization" : `Token ${localStorage.getItem("token")}`}}), axios.get(`${FARM_COMMAND_HOST}/supplies/seedsupply/?rootAsset=${grower_id}`, {headers : {"Authorization" : `Token ${localStorage.getItem("token")}`}}),  ] )
        
        // Fetch Existing Details from Attributes endpoint...
        const response = await axios.get(`${FARM_COMMAND_HOST}/attributes/${grower_id}`, {headers : {"Authorization" : `Token ${localStorage.getItem("token")}`}});
        if(response.data){
          response.data?.forEach( e => {
            if(e.key === "Sourcefile Data"){
              const existingData = JSON.parse(e['value'].replaceAll("'", '"'));
              dispatch(tempParametersAddorChange({ "Sourcefile Data": existingData }));
              
              const existing_sourcefiles = existingData.map(e => Object.keys(e)[0]);

              if(existing_sourcefiles && existing_sourcefiles.includes(sourcefile_id)){
                console.log('Supplies have been assigned previously for this sourcefile')
                const current_sourcefile_data = existingData.filter(e => Object.keys(e)[0] === sourcefile_id)[0]
                
                const current_assignments = {};

                current_sourcefile_data[sourcefile_id]?.by_MD5.forEach((item) => {
                  const filehash = Object.keys(item)[0];
                  const productList = item[filehash].productList;
                  const product = Object.keys(productList[0])[0];
                  const assigned_product = productList[0][product];

                  current_assignments[`${filehash}-${product}`] =  assigned_product;
                });

                dispatch(tempParametersAddorChange({"Current Sourcefile Data" : current_assignments}))
              }
              else{
                console.log('No supplies have been assigned previously for this sourcefile')
              }
            }
          })
        }

        let _chemical_supplies =  [...new Set(chemicalsupplies.data.map(  e => { return JSON.stringify({ 'label': e.name, 'value': e.name }) } ))].map(e => JSON.parse(e));
        let _fertilizer_supplies = [...new Set(fertilizersupplies.data.map( e => { return JSON.stringify({ 'label': e.name, 'value': e.name }) } ))].map(e => JSON.parse(e))
        let _seed_supplies = [...new Set(seedsupplies.data.map( e => { return JSON.stringify({ 'label': e.name, 'value': e.name }) } ))].map(e => JSON.parse(e))

        dispatch(tempParametersAddorChange({"chemical_supplies": _chemical_supplies, "fertilizer_supplies": _fertilizer_supplies, "seed_supplies": _seed_supplies}))
        set_loading_2(false)
    } catch (error) {
        console.log('Error while fetching supplies....', error)
        set_loading_2(false)
    }
  }

   // On Render
   useEffect(() => {
    dispatch(constructState_( sourcefile_id ));
    dispatch(constructState( sourcefile_id ));
    
    dispatch(requestParametersReset());    

    if(sourcefile_id && filename){
        fetch_supplies_data();
        fetch_product_details(sourcefile_id, filename);
        (async () => {
            const response = await axios.get(`${FARM_COMMAND_HOST}/asset/${grower_id}/ancestors/`, {headers: {"Authorization": `Token ${localStorage.getItem('token')}`}});
            let _region = response.data?.filter(item => item.category === "Region" )[0].label
            set_region( _region );
            dispatch(tempParametersAddorChange({"region": _region}))
        })();
    }
  }, [])

  // On season view button click event
  function show_season_view(event){
    document.querySelector(".season_view").classList.add("active")
    document.querySelector(".detailed_view").classList.remove("active")
    
    document.querySelector(".detailed-view-component")?.classList.add('d-none');
    document.querySelector(".season-view-component")?.classList.remove('d-none');
  }

  // On detailed view button click event
  function show_detailed_view(event){
    document.querySelector(".detailed_view").classList.add("active")
    document.querySelector(".season_view").classList.remove("active")
    
    document.querySelector(".season-view-component")?.classList.add('d-none');
    document.querySelector(".detailed-view-component")?.classList.remove('d-none');
  }

  return (
    <>
        <Navbar backUrl={"/uploaded_controller_files"} />
        <Offcanvas sidebar={false} />
        <div className="page-content">
        {
            (sourcefile_id && filename)  ?
            <>
                <div className='d-flex flex-column min-vh-100 my-4'>
                

                  {
                    !(loading_1 || loading_2) &&
                     <div className="d-flex flex-row" style={{width: "auto"}}>
                        <div className="d-flex flex-row my-1 ms-auto">
                            <button className="mx-2 btn btn-outline-primary active detailed_view" onClick={show_detailed_view}>Detailed View</button>
                            <button className="mx-2 btn btn-outline-dark season_view" onClick={show_season_view}>Season View</button>
                        </div>
                    </div>
                  }

                  <figure className='wrapper bg-light' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto"}}>
                    <div className="d-flex w-100 flex-item" style={{width: "auto"}}>
                          <span className="my-auto p-2 fw-bold">{t("Region")}: {region}</span>
                          <span className="mx-auto text-dark my-0 p-2 fw-bold">{t("Product Details of")} {filename}</span>
                          <span className='p-2 fw-bold'>{t("Grower")}: {grower_id}</span>
                      </div>
                  </figure>


                    {
                      (loading_1 || loading_2) 
                        ? 
                          <Spinner className='m-3 text-dark'/>
                        :
                        (
                          !error_message &&
                          <>
                            <Legend />
                            <div className="season-view-component d-none">
                              <SeasonView product_details={product_details} key={1} /> 
                            </div>
                            <div className="detailed-view-component">
                              <DetailedView product_details={product_details} key={2} />
                            </div>
                          </>
                        )
                          
                    }
                    
                    {
                      error_message && 
                      <p className='fs-5 text-danger text-center'>{error_message}</p>
                    }

                </div>                   
            </>        
         :
        <div className="min-vh-100 d-flex align-items-center justify-content-center bg-dark text-white">
            <p className='fs-3'>Hey Buddy! If you access in this way I'll give you 404 :)</p>
        </div>
    }
    </div>
    </>
  )
}

export default ProductTable