import React, { useRef, useState } from 'react'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Download from "yet-another-react-lightbox/plugins/download";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

const breakpoints = [4320, 2160, 1080, 640, 384, 256, 128];
const LayerLightbox = ({layerLabel, image, open, onClose, imageDimensions}) => {

    const {height, width} = imageDimensions;

    return (
        <Lightbox
            open={open}
            close={onClose}
            slides={[
                { 
                    src: image, downloadFilename: `${layerLabel}.png`, 
                    srcSet: breakpoints.map((breakpoint) => {
                        const breakpointHeight = Math.round((height / width) * breakpoint);
                        return {
                            src: image,
                            width: breakpoint,
                            height: breakpointHeight,
                        };
                    }),
                }
            ]}
            zoom={{
                scrollToZoom: true,
            }}
            animation={{ zoom: 500, fade: 300, swipe: 300, easing: { fade: "ease", swipe: "ease-out", navigation: "ease-in-out" } }}
            plugins={[Download, Zoom]}
            render={{buttonNext: () => {}, buttonPrev: () => {}}}
        />
    )
}

export default LayerLightbox