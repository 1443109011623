import { useEffect, useState } from "react"
import { Accordion, Spinner } from "react-bootstrap"
import axios from "axios"
import { useSearchParams } from "react-router-dom";
import {ICON_CLASS_MAPPING} from '../../constants'
import { useTranslation } from "react-i18next";

const HTMLAccordion = ({id, headerText, slug}) => {

    const {t} = useTranslation();
    const [ searchParams, setSearchParams ] = useSearchParams();
    const search_slug = searchParams.get("instruction")
    
    const [ loading, setLoading ] = useState(false);
    const [ content, setContent ] = useState("");
    
    let iconClass = ICON_CLASS_MAPPING[headerText.toLowerCase()]

    // Fetch Content from Confluence Page
    async function fetchContent(){
        setLoading(true);
        try {
            const response = await axios.get(`/api/getConfluencePageHTML/?pageID=${id}`);
            if(response.data?.content){
                let _content = response.data?.content;
                setContent(_content)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }


    useEffect(() => {
        fetchContent();
    }, [])

    // Accordion Header Click Event Handler
    function handleAccordionClick(event){
        if(slug === search_slug){
            searchParams.delete("instruction")
        } else{
            searchParams.set("instruction", slug)
        }
        setSearchParams(searchParams);
    }

    // Accordion
    return (
        <Accordion activeKey={search_slug} id={slug}>
            <Accordion.Item eventKey={slug} className='rounded mt-1 mb-4'>
                <Accordion.Header className='custom-header fs-4' onClick={handleAccordionClick}>
                    <i className={`me-2 ${iconClass}`}></i> {t(headerText)}
                </Accordion.Header>
                <Accordion.Body>
                    <div className="d-flex flex-column">
                        {loading && <Spinner />}
                        <span dangerouslySetInnerHTML={{__html: content}}></span>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default HTMLAccordion;