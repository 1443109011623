import React, { useContext, useState } from 'react'
import GrowerSelector from './GrowerSelector';
import { CODA_HOST } from '../../../../../constants';
import axios from 'axios';
import { PrescriptionDataContext } from '../../context/PrescriptionDataContext';
import { useTranslation } from 'react-i18next';

function OffcanvasForGrower() {
    
    const { t } = useTranslation();
    const {setData, setLoading, setMessage} = useContext(PrescriptionDataContext)

    const [filename, setFilename] = useState(""); // local state to keep track of filename
    const [errorMessage, setErrorMessage] = useState(""); // To Track Error Message
    
    async function handleSearchByGrowerID(event){
        const {value: grower_id, label: grower_label} = event;
        setLoading(true);
        try {
            let url = `${CODA_HOST}/prescriptionfile/?grower_id=${grower_id}`
            let response = await axios.post("/coda/make_coda_call/", {"url": url})
            setData(response.data?.results);
            setMessage(`Prescription Files of the Grower: ${grower_label}`);
        } catch (error) {
            console.log("Error on Fetching data", error)
        } finally{
            setLoading(false);
            setFilename("");
        }
    }
    
    async function handleSearchByFilename(event){
        const selected_grower = JSON.parse(document.querySelector(".selected-grower").value);
        if(!selected_grower){
            setErrorMessage("Select a grower before searching by filename"); setFilename("");
            setTimeout(() => {setErrorMessage("")}, 3000)
            return;
        }
        const {value: grower_id, label: grower_label} = selected_grower;

        try {
            setLoading(true);
            let url = `${CODA_HOST}/prescriptionfile/?filename=${filename}&grower_id=${grower_id}`
            let response = await axios.post("/coda/make_coda_call/", {"url": url})
            setData(response.data?.results);
            setMessage(`Prescription Files of the Grower: ${grower_label} & the files with filename: ${filename}`);
        } catch (error) {
            console.log("Error on Fetching data", error)
        } finally{
            setLoading(false)
        }
    }
    
    return (
        <div className="offcanvas offcanvas-end" tabindex="-5" id="offcanvas-end" style={{backgroundColor: "#ECECEC"}}>
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasResponsiveLabel">{t("Filters")}</h5>
                <button type="button" id="close-offcanvas-end" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvas-end"></button>
            </div>

            <div className="offcanvas-body">

                {/* Filter By GrowerID */}
                <p className='mb-1'>{t("Search Files by Grower")}:</p>
                <div className="mb-3">
                    {/* Grower Selection */}
                    <GrowerSelector handleSearchByGrowerID={handleSearchByGrowerID} />
                </div>
    
                <p className='mb-1 mt-5'>{t("Search File by Filename")}:</p>
                <div className="input-group">
                    <input type="text" name="filename" id="filename" placeholder={t("Enter Filename")} className="form-control"
                        onChange={(event) => {setFilename(event.target.value)}} 
                        value={filename} autoComplete='off' />
                    <button className="btn btn-dark" style={{zIndex: 1}} onClick={(event) => {handleSearchByFilename(event)}}><i className="fa-solid fa-magnifying-glass"></i></button>
                </div>

                {/* Error Message */}
                {errorMessage && <p className="fs-5 text-danger text-center my-5">{errorMessage}</p>}
            </div>
        </div>
    )
}

export default OffcanvasForGrower