import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter , Routes , Route } from 'react-router-dom'
import axios from 'axios';

import {store} from './app/store'
import {Provider} from 'react-redux'

import {AuthProvider} from './context/AuthProvider'  

import App from './App';

if(process.env.REACT_APP_ENV === 'dev'){
  axios.defaults.baseURL = "http://localhost:8000/"
}
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    
    <Provider store={store}>

        <AuthProvider>
            <HashRouter>
              <Routes>
                <Route path='/*' element={<App />} />
              </Routes>
            </HashRouter>
          </AuthProvider>

    </Provider>
);
