import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { changeAssignedProduct_, deleteAssignedProduct_, pushToby_MD5_and_productList_ } from '../../../../features/RequestSlice';

function TableRow({index, season_index, product, position}) {

    const dispatch = useDispatch();
    const request_season_view = useSelector(state => state.request_season_view);

    const tempParameters = useSelector(state => state.tempParameters); // global state
    // From Global Store, used for each row
    let sourcefile_id = tempParameters.sourcefile_id;
    let chemical_supplies = tempParameters.chemical_supplies;
    let fertilizer_supplies = tempParameters.fertilizer_supplies;
    let seed_supplies = tempParameters.seed_supplies;
    const default_structure = request_season_view[sourcefile_id]?.default?.length;


    const [ supply, set_supply ] = useState([]) // list of supplies either [chemical, seed, fertilizer]

    const [ selected_supply_type, set_selected_supply_type ]  = useState(null); // Selected Supply Type from the dropdown
    const [ selected_supply, set_selected_supply ] = useState(null);  // Selected Supply from the dropdown

    // Values for Supply Type - Dropdown
    const supply_types  = [
        {'value' : 'Chemical & Fertilizer Supply', 'label': 'Chemical & Fertilizer Supply'},
        {'value' : 'Chemical Supply', 'label': 'Chemical Supply'},
        {'value' : 'Fertilizer Supply', 'label': 'Fertilizer Supply'},
        {'value' : 'Seed Supply', 'label': 'Seed Supply'},
    ] // supply types


    useEffect(() => {
        // Global Store Structure Creation
        dispatch(pushToby_MD5_and_productList_({
            sourcefile_id, index: index, season_index: season_index,
            productList_data: {},
            by_MD5_data: {}
        }))

        // Supply Type filter.
        if(product?.Operation?.toLowerCase() === 'seeding'){
            set_selected_supply_type({'value' : 'Seed Supply', 'label': 'Seed Supply'})
            set_supply(seed_supplies)
        }
        else if( product?.Operation?.toLowerCase() === 'application' || product?.Operation?.toLowerCase() === 'asapplied' || product?.Operation?.toLowerCase() === 'plant' || product?.Operation?.toLowerCase() === 'planting' ){
            set_selected_supply_type({'value' : 'Chemical & Fertilizer Supply', 'label': 'Chemical & Fertilizer Supply'})
            set_supply([...chemical_supplies, ...fertilizer_supplies])
        }
    }, [])


    // Called When Changes made on Season View
    useEffect(() => {
        // Reflecting changes on default view, when supply assigned on season view
        if(tempParameters[season_index]){
            let assigned_products = Object.keys(tempParameters[season_index]);
            if(assigned_products.includes(product?.Product)){
                let supply = tempParameters[season_index][product?.Product];
                
                //  changing selected_supply & row color
                set_selected_supply({'value': supply, 'label': supply});
                document.getElementById(`row-dv-${position}`).classList.remove("prev-assinged"); document.getElementById(`row-dv-${position}`).classList.remove("assigned");
                document.getElementById(`row-dv-${position}`).classList.add("assinged-defaults");

                // Changing Global State for default_view

                dispatch(changeAssignedProduct_({
                    sourcefile_id, 
                    position, season_index, 
                    key: index,
                    current_filehash: product?.FileHash, 
                    product: product?.Product,
                    assigned_product: supply
                }))
            }
        }
    }, [request_season_view])

    useEffect(() => {
        // Assigning Previously assigned supplies
        if(tempParameters['Current Sourcefile Data'])
        {
            const filehash_products = Object.keys(tempParameters['Current Sourcefile Data']);
            const current_filehash_product = `${product.FileHash}-${product.Product}`;

            if(filehash_products.includes(current_filehash_product)){
                const supply = tempParameters['Current Sourcefile Data'][current_filehash_product]
                document.getElementById(`row-dv-${position}`).classList.add("prev-assinged");
                set_selected_supply( {"label": supply, "value" : supply} );

                dispatch(changeAssignedProduct_({
                    sourcefile_id, 
                    position, season_index, 
                    key: index,
                    current_filehash: product?.FileHash, 
                    product: product?.Product,
                    assigned_product: supply
                }))
            }
        }
    }, [default_structure])


    // EVENT HANDLER
    // supply type change event handler
    function supplyTypeChange(event){
        set_selected_supply(null)
        set_selected_supply_type(event);

        // Delete assigned supply from global request
        dispatch(deleteAssignedProduct_({
            sourcefile_id, 
            position,  season_index, 
            key:index,
            product: product?.Product,
            assigned_product: event.value
        }))

        // remove bgcolor of the row
        document.getElementById(`row-dv-${position}`).classList.remove("assinged-defaults"); document.getElementById(`row-dv-${position}`).classList.remove("assigned"); document.getElementById(`row-dv-${position}`).classList.remove("prev-assinged");
        
        if(event.value === 'Chemical & Fertilizer Supply'){
            set_supply([...chemical_supplies, ...fertilizer_supplies])
        }
        else if(event.value === 'Chemical Supply'){
            set_supply(chemical_supplies)
        }
        else if(event.value === 'Fertilizer Supply'){
            set_supply(fertilizer_supplies)
        }
        else if(event.value === 'Seed Supply'){
            set_supply(seed_supplies)
        }
    }
    
    // EVENT HANDLER
    // supply change event handler
    // X - Change on Global state
    function supplyChange(event){
        set_selected_supply(event) // set local state
        
        // Changing Color of the row if a supply is selected
        document.getElementById(`row-dv-${position}`).classList.remove("assinged-defaults");
        document.getElementById(`row-dv-${position}`).classList.remove("prev-assinged");
        document.getElementById(`row-dv-${position}`).classList.add("assigned");


        dispatch(changeAssignedProduct_({
            sourcefile_id, 
            position, season_index, 
            key: index,
            current_filehash: product?.FileHash, 
            product: product?.Product,
            assigned_product: event.value
        }))
    }

    return (
        <>
            <tr className="bg-light" id={`row-dv-${position}`}>
                <td>{position + 1}</td>
                <td>{product?.Date}</td>

                <td>{product?.Crop}</td>
                <td>{product?.Product}</td>
                {/* <td>{product?.FileHash}</td> */}
                
                <td>
                    <Select 
                        name="supply_type"
                        placeholder='Select Supply Type' 
                        value={selected_supply_type}
                        options={supply_types} 
                        onChange={supplyTypeChange} menuPortalTarget={document.body} /> 
                </td>
                
                <td>
                    <Select 
                        name="supply"
                        placeholder='Select Supply' 
                        value={selected_supply}
                        options={supply} 
                        onChange={supplyChange}  menuPortalTarget={document.body} 
                        />
                </td>

                <td>{product?.Operation}</td>
                <td>{product?.Field}</td>
                {/* <td>{product?.Farm}</td> */}
                <td>{product?.Grower}</td>
            </tr>
        </>
    )
}

export default TableRow