import React, { useState } from 'react'
import {useDropzone} from 'react-dropzone'
import { Spinner } from 'react-bootstrap'
import { formatSizeUnits } from '../../../utils/Utils';
import axios from 'axios';
import { useSelector } from 'react-redux';
import UploadButton from './UploadButton';
import { useTranslation } from 'react-i18next';

function FileUpload() {

    const {t} = useTranslation();

    // Request Payload
    const {rootAsset, asset_category, category, season, asset_label} = useSelector(state => state.requestParameters);
    const client_id = localStorage.getItem("client_id")

    const [files, set_files] = useState([]) // Files

    const [ upload_error_message, set_upload_error_message ] = useState(""); // Error message when upload fails 
    const [ loading, setLoading ] = useState(null); // Used to display loading component
    const [ success, setSuccess ] = useState(null); // Used to set success state

    // Styling for Dropzone
    const dropzoneStyles = {cursor: "pointer", height: "150px", border: "2px solid rgba(0,0,0,0.3)", padding: "20px 20px", textAlign: "center", display:"flex" , background: "#ffffff"}

    const {getRootProps, getInputProps} = useDropzone({
        onDrop: acceptedFiles => {
            set_files(acceptedFiles)
        },
        "multiple": false
    })

    // Remove file button click event handler
    function removeFile(event, index){
        set_files(files.filter((file, _index) => {return index !== _index}))
    }

    // Upload Button Click Event handler
    async function uploadFiles(event){
        setLoading(true);
        event.target.classList.add("disabled");
        document.querySelector('.file-uploader').classList.add("d-none");
        document.querySelectorAll('.remove-file-button').forEach(e => e.classList.add("disabled"));
        let file = files[0];
        try {
            const payload = {rootAsset, asset_category, asset_label, category, season, client_id}
            const response = await axios.post("/file_uploader/upload/", { "file": file, ...payload }, {headers: {"Content-Type": "multipart/form-data"}} )
            
            console.log({ "file": file, ...payload });
            if([200, 201].includes(response.status)){
                setSuccess(true);
            }
        } catch (error) {
            let error_message = "";
            if(error?.response?.data?.error_message){
                error_message = error?.response?.data?.error_message;
            }
            set_upload_error_message(prev => prev + `<br /> <p class="text-danger"> <span class="text-primary">${files[0].name}</span> failed to upload : <span class="text-warning">${error_message}</span> </p>`)
        } finally{
            setLoading(false);
        }
    }

    return (
        <>
        {/* File Uploader (Dropzone Component) */}
        <div className="my-4">
            <p className='fs-5'>{t("Please select the file that you want to upload")}.</p>
            <p className='fst-italic'>{t("Note: You Can Only Upload a Single file")}</p>
        </div>
        
        <div className="shadow file-uploader">
            <div className='' {...getRootProps({className: 'dropzone'})} style={ dropzoneStyles }>
                <input {...getInputProps()} />
                <p className='mx-auto my-auto fs-5 opacity-75'>{t("Drag and Drop here or Click to upload the files")} !</p>
            </div>
        </div>

        
        {/* Table Rows */}
        {
            files.length > 0 &&
            <div className='my-5'>
            <p className='text-success text-decoration-underline'>{t("Selected file")}:</p>

            <figure className='wrapper' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto"}}>
                <table className="table table-light table-responsive table-hover shadow">
                <thead className='table-dark'>
                    <tr >
                        <th scope="col">#</th>
                        <th scope="col">{t("File Name")}</th>
                        <th scope="col">{t("File Size")}</th>
                        <th scope="col">#</th>
                    </tr>
                </thead>
                <tbody>
                {
                    files.map((file, index) => {
                        return (
                            <>
                                <tr className="align-middle" key={index}>
                                    <td>{index + 1}</td>
                                    <td>{file.name}</td>
                                    <td>{formatSizeUnits(file.size)}</td>
                                    <td><button className="btn btn-sm px-2 btn-outline-danger remove-file-button" onClick={ (event) => removeFile(event, index) }>-</button></td>
                                </tr>      
                            </>
                        )
                    })
                }
                </tbody>
                </table>
            </figure>


            {/* Loading Component */}
            {loading && <div className="d-flex justify-content-center my-2"><Spinner /></div>}

            {/* Upload Button */}
            <UploadButton uploadFiles={uploadFiles} />
            </div>
        }

        {
            success &&
            <>
                <p className='animate__animated animate__bounceInDown text-center my-5 fs-5 text-primary'>{t("Uploaded Successfully")}</p>
            </>
        }

        {
            upload_error_message &&
            <>
                <p className='animate__animated animate__bounceInDown text-center my-5 fs-5 text-danger' dangerouslySetInnerHTML={{__html: upload_error_message}}></p>
            </>
        }

        </>
    )
}

export default FileUpload