import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { requestParametersAddorChange } from '../../../../../features/RequestParametersSlice'

const RefNo = ({ onChange }) => {
    const { ref_no } = useSelector(state => state.requestParameters);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        const value = e.target.value;
        dispatch(requestParametersAddorChange({ "ref_no": value }));
        onChange(value);
    }

    return (
        <>
            <label htmlFor="ref_no" className="form-label fs-5">{t('Enter Ref No')}:</label>
            <input 
                type="text" 
                name="ref_no" 
                id="ref_no" 
                placeholder={t("Enter Ref No")} 
                className='form-control'
                value={ref_no || ''}
                onChange={handleChange}
            />
        </>
    );
}

export default RefNo;
