import axios from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useCSRFTokenCheck = () => {
  const { t } = useTranslation();

  useEffect(() => {
    const checkCSRFToken = async () => {
      try {
        const response = await axios.get('/api/check_csrf_token/', {
          headers: { "Authorization": `Token ${localStorage.getItem("token")}` }
        });
        if (response.status !== 200) {
          throw new Error('Invalid CSRF token');
        }
      } catch (error) {
        console.log(error);
        alert(t("Your session has expired. Please log in again."));
        localStorage.clear();
        window.location.href = "/login"; // Redirect to login page
      }
    };

    checkCSRFToken();
  }, [t]);
};

export default useCSRFTokenCheck;