import React, { useEffect } from 'react'

function FieldSearch({all_field_ids, set_filtered_field_ids}) {


    function handleFieldSearchChange(event){
        const searchString = event.target.value.toLowerCase();
        set_filtered_field_ids(all_field_ids.filter( field => { return field?.label?.toString().toLowerCase().includes(searchString) || field?.value?.toString().toLowerCase().includes(searchString) }))
    }

    return (
        <div className="my-2 mb-4">
            <input type="text" name="field-search" placeholder='Search...' className='form-control' onChange={handleFieldSearchChange} />
        </div>
    )
}

export default FieldSearch