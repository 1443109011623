import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import Navbar from './components/Navbar'
import { Offcanvas } from '../../components'
import Datatable from './components/your_requests/Datatable';

const YourSR = () => {
    
    const { t } = useTranslation();

    return (
        <>
            <Navbar pageName={t("Your Service Requests")} />
            <Offcanvas />

            <div className="page-content">

                <p className="text-center fs-5 my-3">{t("Your Service Requests:")}</p>
                <Datatable />
            </div>

            <ToastContainer 
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark" />
        </>
    )
}

export default YourSR