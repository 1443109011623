import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select'; // Assuming you are using react-select for dropdown
import { requestParametersAddorChange } from '../../../../../features/RequestParametersSlice'
import useFetch from '../../../../../hooks/useFetch'
import { useTranslation } from 'react-i18next'; // If you use i18n for translation

const CustomSelect = ({ onChange, dropLabel, url, multiselect, closeMenuOnSelect }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { data, error, loading } = useFetch({ url });
    const [ label, setLabel] = useState([]);
    const [dropOptions, setDropOptions] = useState([]);

    useEffect(() => {
        if (data) {
            setDropOptions(data.map(e => ({ label: e, value: e })));
            
        }
    }, [data, dropLabel]);

    function handleChange(selectedOptions) {
        if (multiselect) {
            const values = selectedOptions ? selectedOptions.map(option => option.value) : [];
            dispatch(requestParametersAddorChange({ [dropLabel]: values }));
            onChange(values); // Notify parent component of the change
        } else {
            const value = selectedOptions ? selectedOptions.value : null;
            dispatch(requestParametersAddorChange({ [dropLabel]: value }));
            onChange(value); // Notify parent component of the change
        }
    }

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? (state.data.in_fc ? '#4a6c4a' : '#b33a3a') : state.isFocused ? (state.data.in_fc ? '#5e8b5e' : '#d04b4b') : (state.data.in_fc ? '#78b378' : '#e94e4e'),
            ':active': {
                ...provided[':active'],
                backgroundColor: state.isSelected ? (state.data.in_fc ? '#4a6c4a' : '#b33a3a') : (state.data.in_fc ? '#5e8b5e' : '#d04b4b')
            }
        }),
        multiValue: (provided, state) => ({
            ...provided,
            backgroundColor: state.data.in_fc ? '#78b378' : '#e94e4e',
        }),
        multiValueLabel: (provided, state) => ({
            ...provided,
            color: 'white',
        }),
    };

    return (
        <div>
            <label htmlFor="" className="form-label fs-5">{t(dropLabel)}:</label>

            <Select
                placeholder={dropLabel}
                closeMenuOnSelect={closeMenuOnSelect}
                isMulti={multiselect}
                onChange={handleChange}
                options={dropOptions}
                // styles={customStyles} // Apply custom styles
                isClearable
                isLoading={loading}
            />
        </div>
    );
}
// Set default props
CustomSelect.defaultProps = {
    multiselect: false,
};
export default CustomSelect;
