import React, { createContext, useState } from 'react'

export const PrescriptionDataContext = createContext()

function PrescriptionDataProvider({children}) {
    
    // Lifted States
    const [ data, setData ] = useState([]); 
    const [ loading , setLoading ] = useState(false);
    const [ message, setMessage ] = useState("");
    const [ searchType, setSearchType ] = useState("default");  
    const [ next , setNext ] = useState(null);
    const [ prev , setPrev ] = useState(null);
    const [ controllerTypeChangeInfo, setControllerTypeChangeInfo ] = useState(null) 
    const [labelIdMap, setLabelIdMap] = useState({})

    return (
        <PrescriptionDataContext.Provider value={
                { 
                    data, setData, 
                    loading, setLoading, 
                    message, setMessage, 
                    next , setNext, 
                    prev , setPrev, 
                    searchType, setSearchType, 
                    labelIdMap, setLabelIdMap, 
                    controllerTypeChangeInfo, setControllerTypeChangeInfo 
                }}>
            {children}
        </PrescriptionDataContext.Provider>
    )
}

export default PrescriptionDataProvider