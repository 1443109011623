import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import {rxParametersAddorChange} from '../../features/RxParametersSlice'
import { useTranslation } from 'react-i18next'

function ApplicationType() {

    const dispatch = useDispatch() // dispatcher
    const { t } = useTranslation();

    // data for the dropdown
    const [ application_type ] = useState([
        {'value' : 1, 'label': t('Fertilizer')},
        {'value' : 4, 'label': t('Desiccant')},
        {'value' : 3, 'label': t('Fungicide')},
        {'value' : 2, 'label': t('Herbicide')},
        {'value' : 5, 'label': t('Insecticide')},
        {'value' : 9, 'label': t('Manure')},
        {'value' : 6, 'label': t('Plant Growth Regulators')},
        {'value' : 7, 'label': t('Seed')},
        {'value' : 8, 'label' : t('Soil Amendment')}
    ])

    // dropdown value change event handler
    const handleChange = (event) => {
        dispatch( rxParametersAddorChange({ 'ApplicationType': event.value }) )
    }

    return (
        <>
            <Select options={application_type} onChange={handleChange} defaultValue={1} placeholder='Select Application Type'  />
        </>
    )
}

export default ApplicationType