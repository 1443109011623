import axios from 'axios'
import { useContext } from 'react'
import { FileUploaderContext } from '../../../context/FileUploaderContext'
import { useSelector } from 'react-redux'
import { GROWER, FARM, FIELD, SUBFIELD} from '../../../constants'
import { useTranslation } from 'react-i18next'

const Filter = ({urlSearchParams}) => {

  const { t } = useTranslation();
  const {asset_category, season, hub, grower, farm, field} = useSelector(state => state.requestParameters)

  // Context
  const {SET_DATA, setLoading } = useContext(FileUploaderContext);

  // event handler
  function handleFilterClick(event){
    let rootAsset = grower;
    if(asset_category === GROWER)
        rootAsset = grower;
    else if(asset_category === FARM)
        rootAsset = farm;
    else if(asset_category === FIELD)
        rootAsset = field
    else
        rootAsset = field
    
    if(urlSearchParams.current.size === 0){
      return alert("Please make some selections before filtering!")
    }
    if(rootAsset)
      urlSearchParams.current.set("rootAsset", rootAsset);
    fetchData();
  }
  
  // Helper
  async function fetchData(){
    setLoading(true);

    // If searchParams includes only asset_category or if it includes filename or if it category include `client_id` in filter
    for (const key of urlSearchParams.current.keys()) {
      if ( urlSearchParams.current.size === 1 && key === "asset_category" ){
        urlSearchParams.current.set("client_id", localStorage.getItem('client_id'))

      }
      if ( key === "filename" || key === "category"){
        urlSearchParams.current.set("client_id", localStorage.getItem('client_id'))
      }
    }

    try {
      const response = await axios.get(`/api/file_uploader/upload/?${urlSearchParams.current.toString()}`);
      SET_DATA(response.data);
      document.querySelector("#close-filter-offcanvas-end")?.click()
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      { <button className="btn btn-outline-success text-center" onClick={handleFilterClick}>{t("Search")} <i className="fa-solid fa-filter"></i></button>}
    </>
  )
}

export default Filter