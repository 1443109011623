import React, { useRef } from 'react'
import { Accordion } from 'react-bootstrap'
import FieldsTable from './FieldsTable'

const FieldsModule = () => {

    const fieldAccordion = useRef();
    return (
    <Accordion >
        <Accordion.Item className='rounded mb-4 mt-1' ref={fieldAccordion}>
            <Accordion.Header className='custom-header fs-4'>{"Fields Module"}</Accordion.Header>
            <Accordion.Body>
                <FieldsTable fieldAccordion={fieldAccordion} />
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
    )
}

export default FieldsModule