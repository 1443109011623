import { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import axios from "axios";
import LeafComponent from './components/LeafComponent';
import LeafForm from "./components/LeafForm";
import { Spinner } from "react-bootstrap";
import { FARM_COMMAND_HOST } from 'utils/../constants';
import { ToastContainer, toast } from "react-toastify";
import "./styles.css";
import "react-toastify/dist/ReactToastify.css";
import Navbar from '../settings/components/Navbar'
import Offcanvas from 'components/Offcanvas';

const USER_FOR_THE_FIRST_TIME = "USER_FOR_THE_FIRST_TIME";
const EXISTING_USER = "EXISTING_USER";
const CHECKING_USER = "CHECKING_USER";
const CHECKED_USER = "CHECKED_USER";

const LeafIntegration = ({ data }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(CHECKING_USER);
    const [userData, setUserData] = useState({});

    const [searchParams] = useSearchParams();
    const searchToken = searchParams.get("token");
    const searchClientId = searchParams.get("client_id");

    async function checkUser(client_id) {
        setLoading(true);
        try {
            const response = await axios.get(`/leaf/user/?client_id=${client_id}`);
            if (response.status === 200) {
                setUserData(response.data);
                setStatus(EXISTING_USER);
            }
        } catch (error) {
            setStatus(USER_FOR_THE_FIRST_TIME);
            navigate("/leaf-integration/form/", { state: { "token": searchToken } });
        } finally {
            setLoading(false);
        }
    }

    async function checkValidToken(token, clientId) {
        setLoading(true);
        try {
            const response = await axios.get(`${FARM_COMMAND_HOST}/token/?token=${token}`);
            if (response.status === 200) {
                checkUser(clientId);
            }
        } catch (error) {
            toast("Invalid Token");
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        const client_id = localStorage.getItem("client_id");
        if (client_id && status === CHECKING_USER) {
            checkUser(client_id);
        }
    }, [status]);

    useEffect(() => {
        if (searchToken && searchClientId && status === CHECKING_USER) {
            checkValidToken(searchToken, searchClientId);
        }
    }, [searchToken, searchClientId, status]);

    useEffect(() => {
        if (status === EXISTING_USER) {
            console.log("Navigating to /leaf-integration/ with data:", userData);
            navigate("/leaf-integration/", { state: { data: userData } });
        }
    }, [status, navigate, userData]);

    if (loading) {
        return (
            <div className="d-flex vh-100 align-items-center justify-content-center">
                <Spinner />
            </div>
        );
    }

    return (
        <>
            <Navbar pageName={"Third Party Integration"} />
            <Offcanvas />
            {userData && status !== USER_FOR_THE_FIRST_TIME && <LeafComponent data={userData} />}
            {status === USER_FOR_THE_FIRST_TIME && <LeafForm setStatus={setStatus} setUserData={setUserData} />}

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
        </>
    );
}

export default LeafIntegration;