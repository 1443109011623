import {createContext, useEffect, useReducer, useState} from 'react'

const FRequestContext  = createContext()

const reducer = (state, action) => {
    switch(action.type){
        case "addOrChange": 
            return {
                ...state,
                ...action.payload
            }
            
        case "remove":
            const copy = {...state};
            delete copy[action.payload];
            return copy;
        
        case "reset":
            return {}

        default:
            throw new Error("Invalid action")
    }
}

export const FRequestContextProvider = ({children}) => {
    const [frequest, dispatch] = useReducer(reducer, {})

    useEffect(() => {
        console.log(frequest)
    }, [frequest])
    
    return (
        <FRequestContext.Provider value={{frequest, dispatch}}>
            {children}
        </FRequestContext.Provider>
    )
}

export default FRequestContext;