import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { DATATYPES, HARVEST, MERGED } from '../constants'
import { requestParametersAddorChange } from '../../../features/RequestParametersSlice';

const Datatype = ({isMulti = true}) => {
    
    const dispatch = useDispatch();
    const { operation } = useSelector(state => state.requestParameters);

    function handleDatatypeChange(event) {
        if(isMulti){
            const _dataype = event?.map(e => e.value);
            if(_dataype?.length > 0){
                dispatch(requestParametersAddorChange({"datatype": _dataype}));
            } else {
                dispatch(requestParametersAddorChange({"datatype": null}));
            }
        }
        else {
            const _dataype = event;
            if(_dataype){
                dispatch(requestParametersAddorChange({"datatype": _dataype.value}));
            } else {
                dispatch(requestParametersAddorChange({"datatype": null}));
            }
        }
    }
    
    return (
        <>
            {
                operation === HARVEST ?
                <Select 
                    options={[...DATATYPES, {"label": MERGED, "value": MERGED}]} 
                    onChange={handleDatatypeChange}
                    isMulti
                    />
                :
                <Select 
                    options={DATATYPES} 
                    onChange={handleDatatypeChange}
                    isMulti={isMulti}
                    />
            }
            
        </>
    )
}

export default Datatype