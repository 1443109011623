import React, { useState } from 'react'
import HubSelector from './HubSelector';
import GrowerSelector from './GrowerSelector';
import FilenameSearch from './FilenameSearch';
import { useTranslation } from 'react-i18next';

function OffcanvasEnd() {

    // States
    const { t } = useTranslation();
    const [ selected_hub , set_selected_hub ] = useState(null);
    const [ filename, setFilename ] = useState(""); // local state to keep track of filename
    
    return (
      <div className="offcanvas offcanvas-end" tabindex="-5" id="filter-prescription-files" style={{backgroundColor: "#ECECEC"}}>
        
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasResponsiveLabel">Filters</h5>
          <button type="button" id="close-offcanvas-end" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#offcanvas-end"></button>
        </div>
        
        <div className="offcanvas-body">

          {/* Filter By GrowerID */}
          <p className='mb-1'>{t("Search Files by Grower")}:</p>

            {/* Hub Selection */}
            <HubSelector selected_hub={selected_hub} set_selected_hub={set_selected_hub} />

            {/* Grower Selection */}
            {selected_hub && <GrowerSelector selected_hub={selected_hub} setFilename={setFilename} />}

          
            {/* Search by filename */}
            <p className='mb-1 mt-5'>{t("Search File by Filename")}:</p>
            <FilenameSearch  filename={filename} setFilename={setFilename} set_selected_hub={set_selected_hub} />
            

          </div>
      </div>
    )
}

export default OffcanvasEnd