import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux'
import {ticketParametersAddorChange, ticketParameterRemove} from '../../features/TicketParametersSlice'
import {CODA_HOST} from '../../constants'
import FlagSourceFilters from './SelectSourcefile/FlagSourceFilters';
import TableRow from './SelectSourcefile/TableRow';
import ShowMore from './SelectSourcefile/ShowMore';
import { ALL } from './SelectSourcefile/constants';
import { useTranslation } from 'react-i18next';

function SelectSourcefile() {

    const { t } = useTranslation();
    const dispatch = useDispatch() // dispatcher
    
    // Global State
    const ticketParameters = useSelector(state => state.ticketParameters);
    const { datatype, grower_id } = ticketParameters;

    // States
    const [ sourcefiles , setSourcefiles ] = useState([]) // original copy of sourcefiles obtained from the api response
    const [ filteredSourcefiles, setFilteredSourcefiles ] = useState([]) // using which sourcefile selection table is built 
    const [ selectedSourcefileIDs, setSelectedSourcefileIDs ] = useState([]) // maintains selected sourcefile IDs
    const [ loading , setLoading ] = useState(false) // to control loading component visibility
    
    const [ currentFilter, setCurrentFilter ] = useState(ALL); // To set Current Filter
    const [ currentSize, setCurrentSize ] = useState(20) // To Control table size 

    // remove controller params (if exist), reset selected canplugs
    useEffect(() => {
        dispatch(ticketParameterRemove('controller_sourcefile'))
    }, [datatype])

    // fetch sourcefiles when grower changes
    useEffect(() => {
        setLoading(true);
        (async () => {
            const url = `${CODA_HOST}/sourcefile/?grower_id=${grower_id}`
            console.log(`fetching sourcefiles: ${url}`)
            
            const response = await axios.post( "/coda/make_coda_call/" , {"url" : url})
            try {
                const data = response.data?.results;
                setSourcefiles(data)
                setFilteredSourcefiles(data.slice(0,currentSize))
            } catch (error) {
                console.error(error)
            } finally {
                setLoading(false);
            }
        })();
    }, [grower_id])


    // Adding selected sourcefiles to ticket parameter
    useEffect(() => {
        dispatch( ticketParametersAddorChange({ 'controller_sourcefile': selectedSourcefileIDs.join(" , ") }) )
    } , [selectedSourcefileIDs])


    // check box click event handler
    function handleCheckBoxClick(event , id){
        const {checked , value } = event.target;
        if(checked){
            setSelectedSourcefileIDs((prev) => { return [...prev, value] })
        }
        else{
            setSelectedSourcefileIDs((curr) => { return curr.filter((a) => a !== value); })
        }
    }

    return (
        <>
        { 
            (ticketParameters?.farm_id &&  ticketParameters?.field_id && ticketParameters?.runtype && ticketParameters?.datatype.toLowerCase() === "controller") && 
            <div className="my-4 mb-5">
                <p className='fs-4'>{t("Select Sourcefiles you're having issues with")}:</p>

                {/* Filters to filter by flag_source */}
                <FlagSourceFilters 
                    sourcefiles={sourcefiles} setFilteredSourcefiles={setFilteredSourcefiles} 
                    currentFilter={currentFilter} setCurrentFilter={setCurrentFilter}
                    currentSize={currentSize}
                    />

                {loading ? 
                <Spinner className='text-dark m-4' /> : 
                <figure className='wrapper' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto"}}>
                <table className="table table-light table-responsive table-hover shadow">
                    <thead className='table-dark'>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">{t("Filename")}</th>
                        <th scope="col">{t("Agi Status")}</th>
                        <th scope="col">{t("Flag Source")}</th>
                        <th scope="col">{t("File Size")}</th>
                        <th scope="col" className='fw-bolder'>#</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                    filteredSourcefiles.map((sourcefile , key) => {
                        return (
                            <TableRow key={key} index={key}
                                    sourcefile={sourcefile}  
                                    selectedSourcefileIDs={selectedSourcefileIDs} 
                                    handleCheckBoxClick={handleCheckBoxClick}
                                />
                        )
                    })
                    }
                    </tbody>
                </table>
                </figure>
                }
                <ShowMore 
                    sourcefiles={sourcefiles} 
                    setFilteredSourcefiles={setFilteredSourcefiles}
                    currentSize={currentSize} setCurrentSize={setCurrentSize}
                    currentFilter={currentFilter} />
            </div>
        }
        </>
    )
}

export default SelectSourcefile