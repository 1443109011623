import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PageNotFound from '../PageNotFound'
import { FRequestContextProvider } from './contexts/FRequest'
import AddMachinesToGrower from './AddMachinesToGrower'
import YourAssignments from './YourAssignments'
import "bootstrap/dist/css/bootstrap.min.css";

function Page() {

    return (
        <FRequestContextProvider>
            <Routes>
                <Route path='/' element={<AddMachinesToGrower />} /> 
                <Route path='/your_assignments/' element={<YourAssignments />} />
                <Route path='/*' element={<PageNotFound />} /> 
            </Routes>
        </FRequestContextProvider>
    )
}

export default Page