import React, { useState } from 'react'
import Select from 'react-select'

const LAYERS = [
    {"label": "Speed", "value": "Speed"},
    {"label": "Productivity", "value": "Productivity"},
    {"label": "Fuel Usage", "value": "Fuel Usage"},
    {"label": "Moisture", "value": "Moisture"}, 
    {"label": "Elevation", "value": "Elevation"}, 
    {"label": "Overlap", "value": "Overlap"},
    {"label": "Date", "value": "Date"},
]

const DEFAULT = [ {"label": "Speed", "value": "Speed"}, {"label": "Productivity", "value": "Productivity"},{"label": "Fuel Usage", "value": "Fuel Usage"} ]

function FilterLayers() {

    const [filteredLayers, setFilteredLayers] = useState(() => {
        const FILTERED_LAYERS = localStorage.getItem("FILTERED_LAYERS");
        if(FILTERED_LAYERS) return JSON.parse(FILTERED_LAYERS)
        else{
            localStorage.setItem("FILTERED_LAYERS", JSON.stringify(DEFAULT))
            return DEFAULT
        }
    })

    // Event Handler
    function handleChangeSelect(event) {
        setFilteredLayers(event)
        localStorage.setItem("FILTERED_LAYERS", JSON.stringify(event))
    }

    return (
        <div className="w-50 my-3">
        {/* HOST */}
            <p className='fs-5 mb-1'>Filter Layers</p>
            <Select options={LAYERS} value={filteredLayers} isMulti onChange={handleChangeSelect} closeMenuOnSelect={false} />
    </div>
    )
}

export default FilterLayers