import {createContext, useState} from 'react'

export const FileUploaderContext = createContext()


export const FileUpladerProvider = ({children}) => {
    const [ DATA, SET_DATA ] = useState([]);
    const [ COLUMNS, SET_COLUMNS ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    return (
        <FileUploaderContext.Provider value={{DATA, SET_DATA, COLUMNS, SET_COLUMNS, loading, setLoading }}>
            {children}
        </FileUploaderContext.Provider>
    )
}