import {useContext, useState} from 'react'
import {Spinner} from 'react-bootstrap'
import Select from 'react-select'
import { PrescriptionDataContext } from '../context/PrescriptionDataContext'
import useFetch from "../../../../hooks/useFetch"
import {changeControllerType} from '../utils'
import { useTranslation } from 'react-i18next'

const ChangeControllerType = () => {
    
    const { t } = useTranslation();
    const {controllerTypeChangeInfo} = useContext(PrescriptionDataContext);
    
    const [ selected_controller_type , set_selected_controller_type ] = useState(null);
    const [ loading, setLoading ] = useState(false); // api-response loading state 
    const [ message, setMessage ] = useState(""); // api-response message state 

    const {data, error, loading: _loading} = useFetch({url: "/rxprozone/supported_controller_types/"});

    // Event Handlers
    function handleClose(){
        set_selected_controller_type(null)
        setMessage(null)
    }

    return (
        <>
        <div className="modal fade" id={`change-controller-type-modal`} data-bs-backdrop="static" data-bs-keyboard="false">
            <div className="modal-dialog  modal-dialog-centered" role="document" >
                <div className="modal-content">
                    <div className="modal-header text-white" style={{background: "#02559c"}}>
                        <h5 className="modal-title" id="modalTitleId">{t("Change Controller File Type")}</h5>
                        {!loading && <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleClose}></button>}
                    </div>

                    <div className="modal-body">

                        <div>
                            /*<p>{t("Current Controller File Type")}: <b>{"Case Voyager 2"}</b></p> {/* Update when coda-development is done */}
                        </div>

                        <div className="mt-4">
                            <p className='my-0'>{t("Select Controller File Type")}</p>
                            <Select 
                                options={data}
                                value={selected_controller_type}
                                isClearable
                                isLoading={_loading}
                                me
                                onChange={
                                    (e) => {
                                        if(e)
                                            set_selected_controller_type(e);
                                        else
                                            set_selected_controller_type(null);
                                    }
                                }                                                                                  />
                        </div>

                        {
                            loading && <div className="d-flex my-3 justify-content-center">
                                <Spinner />
                            </div> 
                        }
                        
                        {
                            message && <div className="d-flex my-5 justify-content-center">
                                <p className='text-center fs-5 text-black'>{message}</p>
                            </div>
                        }
                    </div>

                    <div className="modal-footer">
                        {!loading && <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={handleClose}>Close</button>}
                        { 
                            selected_controller_type && 
                            <button type="button" className="btn btn-primary" 
                                onClick={(event) => {
                                    changeControllerType({event, prescriptionfile: controllerTypeChangeInfo ,controller_filetype: selected_controller_type?.value, setLoading: setLoading, setMessage: setMessage} )
                                }}
                                >{t("Change")}</button>
                        }
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default ChangeControllerType