import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'

import {requestParametersAddorChange} from '../../features/RequestParametersSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

function HGrower() {

    const { t } = useTranslation();
    
    const dispatch = useDispatch() // dispatch
    const requestParameters = useSelector(state => state.requestParameters);

    const [growers , setGrowers] = useState([]) // to store growers returned from api
    const [show , setShow] = useState(false) // to control visibility of grower selector

    async function fetchGrowers(){
        setShow(false)
        setGrowers([])
        try{
            let response = await axios(`/api/getHGrowers/?hubID=${requestParameters.hub}`);
            setGrowers(response.data.features.map((e) => { return {"value" : e.properties.grower , "label" : e.properties.grower_label}}));
            setShow(true)
        }
        catch(err){
            console.log('Error while fetching growers...')
        }
    }


    useEffect(() => {
        // Fetch Growers, when hub changes
        console.log('fetching growers....')
        setGrowers([]);
        fetchGrowers();
    } , [requestParameters.hub])

    function handleChange(event){
        // Adding grower_id to global store
        dispatch( requestParametersAddorChange({ 'grower_id' : event.value }) )
    }

    return (
        <>
            {show && <Select placeholder={t("Select a Grower")} options={growers} onChange={handleChange} />}
        </>
    )
}

export default HGrower