import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'

import {useDispatch} from 'react-redux'
import {requestParametersAddorChange} from '../features/RequestParametersSlice'
import {ticketParametersAddorChange} from '../features/TicketParametersSlice'
import { useTranslation } from 'react-i18next'

function Seasons() {

    const { t } = useTranslation();
    const dispatch = useDispatch()
    
    // Local State, it stores the seasons returned from api
    const [seasons , setSeasons] = useState([])

    async function fetchSeasons(){
        // Makes api call to fetch seasons, [returned seasons] are set to seasons state.
        try{
            let response = await axios("/api/getSeasons/");
            setSeasons(response.data.map((e) => { return {"value" : e.id , "label" : e.label}}));
        }
        catch(err){
            console.log('Error while fetching seasons')
        }
    }

    useEffect(() => {
        // Fetching seasons.. when it is rendered...
        fetchSeasons();
    }, []); 

    function handleChange(event){
        // Adding selected season to the global state.
        dispatch(requestParametersAddorChange({ season :  event.value, farm: '' , fields: '' , runtype: '' , datatype: ''}))
        dispatch(ticketParametersAddorChange({ 'season' :  event.label, 'season_id': event.value?.toString(), farm: '', farm_id: '' , field: '', field_id: '' , runtype: '' , datatype: '' }))
    }

    return (
        <>
            <Select placeholder={t("Select a season")} options={seasons} onChange={handleChange} />
        </>
    )
}

export default Seasons