import React, { useEffect} from 'react'
import Navbar from './settings/components/Navbar'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

import Offcanvas from '../components/Offcanvas'
import FileUpload from '../components/upload_controller/FileUpload'

import Grower from '../components/upload_controller/Grower'

import Hub from '../components/upload_controller/Hub'
import HGrowers from '../components/upload_controller/HGrowers'

import {useDispatch, useSelector} from 'react-redux'
import { requestParametersAddorChange, requestParametersReset } from '../features/RequestParametersSlice'

import { useTranslation } from 'react-i18next'

const HUB_ALLOWED_USER_ROLES = [
    "Staff",
    "Elvyra Tech",
    "Technical Specialist",
    "Soil Sampler",
    "Agronomist"
]

function UploadController() {
    import('../styles/cch_index.css')

    const dispatch = useDispatch() // dispatcher
    const requestParameters = useSelector(state => state.requestParameters) // global state

    const {t} = useTranslation()

    const location = useLocation() 
    const navigate = useNavigate()

    // Check Previous upload session exist or not!
    useEffect(() => {
        const resumable_session_uri = localStorage.getItem("resumable_session_uri")
        if(resumable_session_uri){ 
            // Session Exist, they were uploading... [redirect to resume_file_upload]
            navigate('/resume_file_upload')
        }
    } , [])

    // reset global state, sets user client_id  & flag_source 
    useEffect(() => {
        dispatch(requestParametersReset())
        dispatch(requestParametersAddorChange({'client_id' : localStorage.getItem("client_id"), 'flag_source' : 'Farm Command'}))
    } , [location])
    
    return (
        <>
            <Navbar pageName={t("Upload Controller Files")} />
            <Offcanvas />

            <div className="row w-100 justify-content-center mx-auto">
                <div className="col-lg-6">
                    <div className="mb-2 my-4">
                        <NavLink to="/instructions"><button className='btn btn-outline-primary mt-2'> <i className="fa-solid fa-circle-info mx-2"></i>{t('Instructions')}</button></NavLink>
                    </div>
                {/* Hub - Growers Component : Rendered Only for Staffs, Elvyra Tech, Technical Specialist, Soil Sampler, Agronomist*/}
                {
                    localStorage.getItem("user_roles")?.split(",")
                    .map( e => HUB_ALLOWED_USER_ROLES.includes(e) )
                    .some( e => e === true )
                    ?
                    <>
                        <div className="mb-2 my-4">
                            <label for="" className="form-label fs-5">{t('Select Hub')}:</label>
                            <Hub />
                        </div>

                        {
                            requestParameters.hub && 
                            <div className="mb-2 my-4"  style={{zIndex: 100}}>
                                <label for="" className="form-label fs-5">{t('Select Grower')}:</label>
                                <HGrowers style={{zIndex: 100}}/>
                            </div> 
                        }
                    </>
                    :
                    <div className="mb-2 my-4"  style={{zIndex: 100}}>
                        <label for="" className="form-label fs-5">{t('Select Grower')}:</label>
                        <Grower style={{zIndex: 100}}/>
                    </div> 
                }

                {
                    ( requestParameters.grower_id ) &&
                    <>
                        <FileUpload />
                    </>
                }
                </div>
                </div>
        </>
    )
}

export default UploadController