import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import { ControllerDataContext } from '../context/ControllerDataContext';
import { CODA_HOST } from '../../../constants';
import { useTranslation } from 'react-i18next';

function GrowerSelector({ selected_grower, set_selected_grower, setFilename }) {

    const { t } = useTranslation();
    // stored growers
    const growers_stored = JSON.parse(localStorage.getItem("growers"));

    // Context
    const {setData, setLoading, setMessage, setNext, setPrev, setSearchType} = useContext(ControllerDataContext)

    // States
    const [ growers , set_growers ] = useState([]);
    const [ isFetching , setIsFetching ] = useState(false);
    const [ inputChangeOn, setInputChangeOn ] = useState(true);

        // # Side Effects
    // Check if growers already exist in LS
    useEffect(() => {
        console.log(growers_stored)
        if(growers_stored){
            let parsed_growers = growers_stored?.map(grower => ({"value" : grower.id , "label" : grower.label}))
            set_growers(parsed_growers)
            setInputChangeOn(false);

            if(growers_stored?.length === 1){
                set_selected_grower(parsed_growers[0])
            }
        }
    }, [])

    // Helpers
    // Fetches growers from backend
    async function fetchGrowers(label){
        setIsFetching(true);
        set_growers([])
        try{
            let response = await axios(`/api/getGrowersByLabel/?label=${label}`);
            set_growers(response.data.map((e) => { return {"value" : e.id , "label" : e.label}}));
        }
        catch(err){
            console.log(err)
            console.log('Error while fetching growers...')
        } finally {
            setIsFetching(false);
        }
    }

    function debounce(func, delay) {
        let timerId;
    
        return function (...args) {
            clearTimeout(timerId);
    
            timerId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    }
    
    const debouncedSearch = debounce(fetchGrowers, 400); 

    // Event Handlers
    function handleInputChange(event){
        // Fetch Growers only if the input string is > 2
        if(event.length > 2){
            debouncedSearch(event)
        }
    }

    async function handleGrowerChange(event){
        set_selected_grower(event)
        const {value: grower_id, label: grower_label} = event;
        setLoading(true);
        try {
            let url = `${CODA_HOST}/sourcefile/?grower_id=${grower_id}`
            let response = await axios.post("/coda/make_coda_call/", {"url": url})
            setSearchType("grower")
            setData(response.data?.results);
            setNext(response.data?.next);
            setPrev(response.data?.previous);
            setMessage(`Controller Files of the Grower: ${grower_label}`);
        } catch (error) {
            console.log("Error on Fetching data", error)
        } finally{
            setLoading(false);
            setFilename("");
        }
    }


    return (
        <div className="mb-3">
            {
                <Select 
                    placeholder={t("Type here to search for Grower")}
                    options={growers} 
                    onInputChange={inputChangeOn ? handleInputChange: () => {}}
                    onChange={handleGrowerChange}
                    value={selected_grower}
                    isLoading={isFetching}
                    styles={{menu: style => ({...style, zIndex: "10"}) }}
                />
            }
        </div>

    )
}

export default GrowerSelector