export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function sortByDateCreated(a, b) {
    return new Date(b.date_created) - new Date(a.date_created);
}
export function sortByLayerType(a, b) {
    const layerTypesOrder = ['Standard', 'Yield Raster', 'Raw Yield', 'Date', 'Vehicle', 'Overlap', 'Productivity', 'Moisture', 'Elevation', 'Speed', 'Fuel Usage'];

    const indexA = layerTypesOrder.indexOf(a.layerType);
    const indexB = layerTypesOrder.indexOf(b.layerType);

    if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
    } else if (indexA !== -1) {
        return -1;
    } else if (indexB !== -1) {
        return 1;
    }
    return 0;
}

export function formatByteToAppropriateSize(bytes){
    if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
    else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
    else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
    else if (bytes > 1)           { bytes = bytes + " bytes"; }
    else if (bytes === 1)          { bytes = bytes + " byte"; }
    else                          { bytes = "0 bytes"; }
    return bytes;
}