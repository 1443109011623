import { useTranslation } from "react-i18next"
import { Spinner } from "react-bootstrap"
import { format } from "date-fns"
import { NavLink } from "react-router-dom"
import { useEffect, useState, useRef} from "react"
import axios from "axios"
import _ from "lodash"
import WidgetCard from "./WidgetCard"
import useFetch from '../../../../hooks/useFetch'
import { FARM_COMMAND_HOST, JIRA_BASE_URL } from "../../../../constants"
import { capitalizeFirstLetter } from "../../../../utils/helpers"
import "../../styles.css"


const RecentUploads = () => {

    const { t } = useTranslation();
    const [labelIdMap, setLabelIdMap] = useState({});
    const fetchedGrowerIds = useRef(new Set());

    const client_id = localStorage.getItem("client_id");
    const {
        data = [],
        error,
        loading
    } = useFetch({url: `/coda/recent_uploads/?client_id=${client_id}`})
    

    async function fetchGrower(grower_id){
        try{
            const response = await axios.get(`${FARM_COMMAND_HOST}/asset/${grower_id}`, {headers: {"Authorization": `Token ${localStorage.getItem("token")}`}});
            if(response.status === 200) {
                const {label} = response.data;
                setLabelIdMap(prev => {return {...prev, [grower_id]: label}})
            }
        } catch(error){
            console.log(`Failed on fetching asset ${error}`)
        }
    }

    useEffect(() => {
        if (!loading){
            const grower_ids = Array.from(new Set(data.map(e => e.grower_id).filter(e => !_.isNull(e))));
            grower_ids.forEach(grower_id => {
                if (!fetchedGrowerIds.current.has(grower_id)) {
                    fetchGrower(grower_id);
                    fetchedGrowerIds.current.add(grower_id);
                }
            });
        }
    }, [data, loading]);

    if(error){
        return (
            <>
                <p>{t("Unable to fetch your recent controller uploads")}</p>
            </>
        )
    }
    
    return (
        <WidgetCard cardTitle={            
        <>
        <span className="me-2">
            <i className="fa-solid fa-folder"></i> 
        </span>
        <NavLink to={"/uploaded_controller_files/"}>
            {t("Your Recent Controller Uploads")}
        </NavLink>
        </> }>
            {
            loading ? <Spinner /> : 
            <>
            <div className="table-responsive">
                <table className="table table-striped table-hover table-borderless align-middle widget-table">
                    <thead className="table-dark">
                        <tr>
                            <th>{t("Filename")}</th>
                            <th>{t("Created On")}</th>
                            <th>{t("Grower")}</th>
                            <th>{t("Status")}</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {
                            data.map((row, index) => {
                                return <TableRow row={row} key={index} index={index} labelIdMap={labelIdMap} />
                            })
                        }
                    </tbody>
                </table>
            </div>
            </>
            }
        </WidgetCard>
    )
}

function TableRow({row, index, labelIdMap}){
    
    const {
        creation_time, agi_status, filename, grower_id
    } = row;
    
    return (
        <tr className="table-secondary px-2">

            <td>
                <span title={filename}>{filename?.substring(0, 20)}</span>
            </td>

            <td>
                <span>
                    { creation_time && format(new Date(creation_time), "dd-MMM-yy") }
                </span>
            </td>

            <td>
                <GrowerLabel grower_id={grower_id} labelIdMap={labelIdMap}/>
            </td>

            <td>
                <span className={`px-3 badge ${agi_status === 'Complete' ? "text-bg-primary" : "text-bg-warning"}`}>
                    {agi_status && capitalizeFirstLetter(agi_status)}
                </span>
            </td>
        </tr>
    )
}

const GrowerLabel = ({grower_id, labelIdMap}) => {

    return (
        <span>
            {Object.keys(labelIdMap).includes(String(grower_id)) ? labelIdMap[grower_id] : grower_id}
        </span>
    )
}

export default RecentUploads