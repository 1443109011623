import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { requestParametersAddorChange, requestParametersReset } from '../../../../features/RequestParametersSlice';
import { useTranslation } from 'react-i18next';

function ByFieldId({visibility}) {
    
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const requestParameters = useSelector(state => state.requestParameters)

    const [assetIDs, set_assetIDs] = useState("")

    // Event Handlers
    function handleAssetIDChange(event){
        set_assetIDs(event.target.value)    
    }

    function handleRuntypeChange(event){
        let runtype = event.target.value;
        let runtypes = [...requestParameters["runtype"]]

        if(!event.target.checked){
            dispatch(requestParametersAddorChange({"runtype": runtypes?.filter(e => e !== runtype)}))
        } 
        else {
            if(!runtypes.includes("harvest") && runtype === "harvest"){
                runtypes.push("harvest");
            }
            if(!runtypes.includes("applied") && runtype === "applied"){
                runtypes.push("applied");
            }
            dispatch(requestParametersAddorChange({"runtype": runtypes}))
        }
    }

    function handleGenerateClick(event){
        dispatch(requestParametersAddorChange({"field_ids": [], "field_labels": []}))
        
        try {
            let asset_ids = assetIDs.split(",").map( e => Number(e)).filter(e => {
                return !isNaN(e);
            }).filter(e => e >= 1);
            dispatch(requestParametersAddorChange({"field_ids": Array.from(new Set(asset_ids))}))
            document.querySelector("#offcanvas-end-close-button")?.click();
        } catch (error) {
            alert("Asset IDs Invalid")
        }
        dispatch(requestParametersAddorChange({"current_field_id": ""}));
    }

    // Side Effects
    useEffect(() => {
        set_assetIDs("")
        dispatch(requestParametersAddorChange({"runtype": ["applied", "harvest"]}));
    }, [visibility])

    return (
        <div className={!visibility ? 'd-none' : ""}>
            {/* Asset ID */}
            <input type="text" className="form-control my-3" placeholder={t('Enter Field ID(s)')} id='assetIDs' value={assetIDs} onChange={handleAssetIDChange} autoComplete='off'/>

            {/* Runtype */}
            <div className="d-flex my-2 justify-content-center ">
                <div className="flex-item mx-2">
                    <input className="form-check-input" type="checkbox" name="runtype" id="applied" value="applied" onChange={handleRuntypeChange} checked={requestParameters?.runtype?.includes("applied")}/>
                    <label className="form-check-label mx-1" for="applied"> {t("Applied")} </label>
                </div>
                
                <div className="flex-item mx-2">
                    <input className="form-check-input" type="checkbox" name="runtype" id="harvest" value="harvest" onChange={handleRuntypeChange} checked={requestParameters?.runtype?.includes("harvest")} />
                    <label className="form-check-label mx-1" for="harvest"> {t("Harvest")} </label>
                </div>
            </div>

            {/* Generate Button */}
            {
                ( assetIDs && requestParameters.runtype && requestParameters.runtype.length >= 1) &&
                <button className="btn btn-outline-success w-100 mt-5" onClick={(event) => {handleGenerateClick(event)}}>Generate</button>
            }
        </div>
    )
}

export default ByFieldId