import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'

import { useDispatch, useSelector } from 'react-redux'
import {  rxParametersAddorChange } from '../../../features/RxParametersSlice'
import { useTranslation } from "react-i18next"

function Farms() {
    
    const { t } = useTranslation();
    // Dispatcher
    const dispatch = useDispatch()

    // Global State
    const rxParameters = useSelector(state => state.rxParameters);
    
    const [ farms , setFarms ] = useState([]) // used to store farms fetched from api call
    const [ show , setShow ] = useState(false) // used to control display of the farms selector

    async function fetchFarms(){
        // makes api call to fetchFarms for the selected grower & selected season
        setShow(false);
        setFarms([]);
        
        try{
            console.log(`/api/getFarms/?growerID=${rxParameters.grower}&seasonID=${rxParameters.season}`)
            let response = await axios(`/api/getFarms/?growerID=${rxParameters.grower}&seasonID=${rxParameters.season}`);
            setFarms(response.data.features.map((e) => { return {"value" : e.properties.farm , "label" : e.properties.farm_label}}));
            setShow(true)
        }
        catch(err){
            console.log('Error while fetching farms...')
        }
    }

    // Fetch farms, when grower/hub/season changes
    useEffect(() => {
        setFarms([]);
        fetchFarms();
    }, [rxParameters.grower , rxParameters.hub, rxParameters.season]);

    function handleChange(event){
        // adding selected farm to the global state.
        let farm_ids = event.map(e => e.value);

        dispatch(rxParametersAddorChange( {'farms': farm_ids }))
    }


    return (
        <>
            {show && <Select placeholder={t("Select farms")} options={farms} onChange={handleChange} isMulti closeMenuOnSelect={false} />}
        </>
    )
}

export default Farms