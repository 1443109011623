import { isGrower, isStaff, isElvyraTech, isJIRAAuthenticated, isIcici } from 'utils/authHelpers';

export const ELVYRA_TECH = "Elvyra Tech";
export const SMART_IMAGERY_GROWER = "Smart Imagery Grower"
export const JIRA_AUTHENTICATED = "JIRA Authenticated"
export const STAFF = "Staff"


// react-grid-layout constants
export const GRID_LARGE_BREAKPOINT = "lg"
export const GRID_SMALL_BREAKPOINT = "sm"
export const GRID_BREAKPOINTS = { [GRID_LARGE_BREAKPOINT]: 800, [GRID_SMALL_BREAKPOINT]: 0 }
export const GRID_COLUMNS = { [GRID_LARGE_BREAKPOINT]: 4, [GRID_SMALL_BREAKPOINT]: 1 }
export const GRID_LAYOUT_ROW_HEIGHT = 50
export const GRID_LAYOUT_OUTER_PADDING = [3, 3]
export const GRID_LAYOUT_TILE_MARGIN = [3, 3]

// Widget constants

// Widgets
export const RECENT_TICKETS = "recent-tickets";
export const RECENT_CONTROLLER_UPLOADS = "recent-controller-uploads";
export const RECENT_SERVICE_REQUESTS = "recent-service-requests";

export const WIDGET_AUTH_MAP = [
    {
        "name": RECENT_TICKETS, "auth": isJIRAAuthenticated(),
        "label": "Recent Tickets"
    },
    {
        "name": RECENT_CONTROLLER_UPLOADS, "auth": (isStaff() || isGrower() || isElvyraTech()) && !isIcici(),
        "label": "Recent Controller Uploads"
    },
    {
        "name": RECENT_SERVICE_REQUESTS, "auth": (isStaff() || isElvyraTech()) && !isIcici(),
        "label": "Recent Service Requests"
    }
]