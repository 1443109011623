import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { IoMdRefresh } from "react-icons/io";
import TableRow from './TableRow';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'

const Datatable = () => {
  const { t } = useTranslation();
  const user_id = localStorage.getItem("client_id");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const location = useLocation();
  const { state } = location;
  const taskCompletedRef = useRef(false);
  const maxPollingTime = 300000; // 60 seconds
  const pollingInterval = 10000; // 5 seconds

  async function fetchData(url) {
    setData([]);
    setLoading(true);
    try {
      const response = await axios.get(url);
      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.log('Error while fetching posted tasks...');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    
    if (state?.task_id) {
        const url = `/reporting/sail/?user_id=${user_id}`;
        const taskStatusUrl = `/reporting/sail/?id=${state.task_id}`;
        const startTime = Date.now();
        const intervalId = setInterval(async () => {
            try {
                const response = await axios.get(taskStatusUrl);
                if (response.data && response.data.length > 0) {
                    if (response.data[0].status === 'completed' && !taskCompletedRef.current) {
                        clearInterval(intervalId);
                        taskCompletedRef.current = true;
                        fetchData(url);
                    } else if (Date.now() - startTime > maxPollingTime) {
                        clearInterval(intervalId);
                        console.log('Max polling time reached. Stopping further checks.');
                    } else if (response.data[0].status === 'failed') {
                        clearInterval(intervalId);
                        console.log('Task failed. Stopping further checks.');
                        fetchData(url);
                    }
                }
            } catch (error) {
                console.log('Error while checking task status...', error);
                clearInterval(intervalId);
            }
            }, pollingInterval);
            return () => {
              clearInterval(intervalId);
              state.task_id = null;
            };
    }
  }, [user_id, state]);

  useEffect(() => {
    const url = `/reporting/sail/?user_id=${user_id}`;
    fetchData(url);    
  }, [user_id]);
  
  if (loading) {
    return (
      <div className="d-flex my-5 justify-content-center">
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <ToastContainer                
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light" />
      <div className="d-flex justify-content-end mb-2">
      <p className='px-3 pt-2'>Reports Ordered newest to oldest</p>
        <button
          className="btn btn-secondary"
          onClick={() => { fetchData(`/reporting/sail/?user_id=${user_id}`); }}
        >
          <IoMdRefresh size={24} />
        </button>
      </div>

      <div className="table-responsive">
        
        <table className="table table-hover shadow">
          <thead className='table-dark'>
            <tr>
              <th className='col-1'>#</th>
              <th className='col-2 hide-mobile'>{t("Created On")}</th>
              <th className='col-2 hide-mobile'>{t("Modified On")}</th>
              <td className='col-3'>{t("Request Details")}</td>
              <th className='col-1'>{t("Status")}</th>
              <th className='col-1 text-center'>{t("Resubmit")}</th>
              <th className='col-1 text-center'>{t("Download")}</th>
            </tr>
          </thead>
          <tbody>
            {data && data.slice().reverse().map((instance, index) => (
              <TableRow instance={instance} key={index} index={index} onResubmit={() => fetchData(`/reporting/sail/?user_id=${user_id}`)} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Datatable;
