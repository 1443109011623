import Select from 'react-select'
import useFetch from '../../../../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { requestParametersAddorChange } from '../../../../../features/RequestParametersSlice'
import { t } from 'i18next'

const Tehsil = ({ onChange }) => {

    const dispatch = useDispatch();
    const { district_id } = useSelector(state => state.requestParameters)

    const { data, error, loading } = useFetch({
        url: `/reporting/sail/tehsil/?district_id=${district_id}`
    })
    
    error && 
        console.log(error)
    
    const [ tehsil, setTehsil ] = useState(null);
    const [ tehsils, setTehsils ] = useState([]);

    useEffect(() => {
        setTehsils( () => (data.map( e => ({"label" : e.tehsil_english, "value": e.tehsil_id, "lgd_code":e.lgd_code}) )))
    }, [data])

    function handleChange(e){
        setTehsil(e);
        if (e) {
            dispatch(requestParametersAddorChange({"tehsil_id": e.value, "tehsil": e.label,  "lgd_code":e.lgd_code}))
            onChange(e.label); // Notify parent component of the change
        } else {
            dispatch(requestParametersAddorChange({"tehsil_id": null, "tehsil": null, "lgd_code":null}))
            onChange(null); // Notify parent component of the change

        }
    }

    return (
        <div>
            <label htmlFor="" className="form-label fs-5">{t('Select Tehsil')}:</label>

            <Select
                value={tehsil}
                placeholder={t("Select a Tehsil")}
                onChange={handleChange}
                options={tehsils}
                isClearable
                isLoading={loading}
                />
        </div>
    )
}

export default Tehsil