import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { rxParametersReset } from '../features/RxParametersSlice'
// Util
import JSZip from 'jszip'

// Components
import Navbar from './settings/components/Navbar'
import Offcanvas from '../components/Offcanvas'
import { Seasons, Hubs, Grower, ApplicationTime, ApplicationType } from '../components/rxprozone_downloader'
import { useTranslation } from 'react-i18next'


function RxProZoneDownloader() {
    
    const { t } = useTranslation();
    const location = useLocation();
    
    const dispatch = useDispatch()

    // Global state
    const rxParameters = useSelector(state => state.rxParameters);

    useEffect(() => {
        console.log('called reset')
        dispatch( rxParametersReset() )
    }, [location])


    // States
    const [ progress , setProgress ] = useState(0);
    const [ errorMessage  , setErrorMessage ] = useState('');
    const [ logMessage, setLogMessage ] = useState('');

    // for the purpose of Test
    useEffect(() => {
        setLogMessage('')
        setErrorMessage('')
        setProgress(0)
    } , [rxParameters]);


    function slugify(value, allow_unicode=false) {
        value = String(value);
        value = value.replace(/[^\w-]/g, ' ');
        value = value.replace(/\s{2,}/g, ' ');
        return value.replace(/[-]+/g, '-').trim();
    }
    
    async function performOperations(event) {
        event.target.classList.add("disabled");

        try{
            let grower_label = '';
            const grower_id = rxParameters.grower;
            const season_id = rxParameters.season;
            const scenario_type = rxParameters?.ApplicationType
            const application_time = rxParameters?.ApplicationTime
        
            const host = "https://processing-api.farmcommand.com";
            const url = "https://zonecalc-service.granduke.net/";

            let asset;
            setLogMessage(prev => prev.concat('New Log: ' + Date().toLocaleString()))
        
            try{
                asset = await fetch(`${host}/asset/${grower_id}?token=${localStorage.getItem('token')}`)
                .then((response) => response.json())
            }
            catch{
                setErrorMessage('\n\nnot a valid Grower ID')
                return
            }

            if (asset.category === 'Grower'){
                grower_label = asset.label
        
                const farms = await fetch(`${host}/asset/?category=Farm&season=${season_id}&rootAsset=${grower_id}&token=${localStorage.getItem('token')}`)
                .then((response) => response.json())
        
                const path = `Clients/${grower_label}/`;
                const final_zip = new JSZip();
        
                let progressBarValue = 0;
                let progressBarFarmValue = 0;
    
                for (const farm of farms) {
                    progressBarFarmValue++;
                    progressBarValue = 0;

                    const farm_label = slugify(farm.label);
                    const farm_path = `${path}${farm_label}/`;

                    // errorBox.insertAdjacentHTML('beforeend', '<br>'+ farm_label+'</br>')
                    setLogMessage(prev => prev.concat(`\n Farm: ${farm_label}`) )

                    const subfields = await fetch(`${host}/asset/?category=Management%20Zones&shape=true&season=${season_id}&rootAsset=${farm.id}&token=${localStorage.getItem('token')}`)
                    .then((response) => response.json());
                
                    for (const subfield of subfields) {
                        const subfield_label = slugify(subfield.label);
                        const field_path = `${farm_path}${subfield_label}/`;
                        // errorBox.insertAdjacentHTML('beforeend', '<br>'+ subfield_label+'</br>')
                        setLogMessage(prev => prev.concat(`\n ${subfield_label}`))
                
                        // add border to structure
                        let border_df;
                        const border_path = `${field_path}Boundaries/ShapefilesForBoundary/`;
                        const appPrefix  = 'temp_extracted';
                        const temp_dir = appPrefix + Math.floor(Math.random() * 10000);
                        console.log(temp_dir)
                        localStorage.setItem(temp_dir, "");
                    
                        try{ 
                            border_df = await fetch(`${host}/spatial/convert/geojson-to-shp/?token=${localStorage.getItem('token')}`, {
                            method: 'POST',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: subfield.shape.shapeData
                            }).then(response => response.blob())
                
                            const content_bor = await border_df.arrayBuffer();
                            const zip_ref_bor = new JSZip();
                            await zip_ref_bor.loadAsync(content_bor);
                
                            for (const filename in zip_ref_bor.files) {
                                const file = zip_ref_bor.files[filename];
                                const file_content = await file.async('arrayBuffer');
                                const dst_file = `${border_path}/${subfield_label}${file.name.slice(-4)}`;            
                                final_zip.file(dst_file, new Uint8Array(file_content));
                            }
                        }catch{
                            // errorBox.insertAdjacentHTML('beforeend', '<br>error downloading border</br>')
                            setErrorMessage('\n Error Downloading Border')
                        }
        
        
                        // add rx to structure
                        let rx_shp;
                        try {
                            console.log(subfield);
                            const scenario = await fetch(`${url}api/v1/scenario/?subfield_id=${subfield.id}&is_active=True&token=${localStorage.getItem('token')}`)
                            .then((response) => response.json());
                            
                            const application = await fetch(`${url}api/v1/scenario/${scenario[0].id}/application/?application_time=${application_time}&application_type=${scenario_type}&token=${localStorage.getItem('token')}`)
                            .then((response) => response.json());
                            
                            rx_shp = await fetch(`${url}api/v1/scenario/${scenario[0].id}/application/rxshapefile/?applications=${application[0].id}&token=${localStorage.getItem('token')}`)
                            .then((response) => response.blob());
                        } catch (error) {
                            // errorBox.insertAdjacentHTML('beforeend', '<br>error downloading RX</br>')
                            console.log(subfield)
                            setErrorMessage( prev => prev.concat(`\n Error downloading RX for ${subfield.label}`) )
                            console.error(error);
                            continue;
                        }

                        const rx_path = `${field_path}VRC/${subfield_label}`;
                        const content = await rx_shp.arrayBuffer();
                        const zip_ref = new JSZip();
                        await zip_ref.loadAsync(content);
                
                        for (const filename in zip_ref.files) {
                            const file = zip_ref.files[filename];
                            if(filename.startsWith("Stripped")){
                            console.log(filename)
                            const rx_content = await file.async('arrayBuffer');
                            const dst_file = `${rx_path}/${subfield_label}${file.name.slice(-4)}`;            
                            final_zip.file(dst_file, new Uint8Array(rx_content));
                            }
                        }

                        localStorage.removeItem(temp_dir);
            
                        progressBarValue++
                        // progressBar.innerHTML = Math.round((((progressBarFarmValue-1) / farms.length)+((progressBarValue / subfields.length) *(progressBarFarmValue / farms.length))) * 100)+ '%';
                        // progressBar.style.width = Math.round((((progressBarFarmValue-1) / farms.length)+((progressBarValue / subfields.length) *(progressBarFarmValue / farms.length))) * 100) + '%';
                        setProgress(Math.round((((progressBarFarmValue-1) / farms.length)+((progressBarValue / subfields.length) *(progressBarFarmValue / farms.length))) * 100))
            }
        }

            final_zip.generateAsync({ type: "blob" }).then(function(content) {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(content);
                a.download = `${grower_label}.zip`;
                a.click();
            })
        }else{
            // errorBox.insertAdjacentHTML('beforeend', '<br>not a valid Grower ID</br>')
            setErrorMessage("\n Not A Valid Grower");
        }
        setLogMessage(prev => prev.concat('\n\n Download Completed.'))
        setProgress(100)
        }
        catch(error){
            console.error(error);
            // errorBox.insertAdjacentHTML('beforeend',  "An error occurred: " + error);
            setErrorMessage("\n An Error Occured " + error)
        }

    }
      

    return (
    <>
        <Navbar pageName={"RxProzone Downloader"}/>
        <Offcanvas />

        <div className="page-content selectors px-4">
            <div className="">
                <div className="mb-2 my-4">
                    <label htmlFor="" className="form-label fs-5  ">{t("Select Season")}:</label>
                    <Seasons />
                </div>

                <div className="mb-2 my-4">
                    <label htmlFor="" className="form-label fs-5  ">{t("Select Hub")}:</label>
                    <Hubs />
                </div>


                { 
                    (rxParameters.season && rxParameters.hub) && 
                    <>
                    <div className="mb-2 my-4">
                            <label htmlFor="" className="form-label fs-5  ">{t("Select Grower")}:</label>
                            <Grower />
                    </div>
                    </>
                }

                {/* true || rxParameters.season &&  rxParameters.grower &&  */}
                {
                    true && 
                    <>
                        <div className="mb-2 my-4">
                            <label htmlFor="" className="form-label fs-5  ">{t("Application Type")}:</label>
                            <ApplicationType />
                        </div>

                        <div className="mb-2 my-4">
                            <label htmlFor="" className="form-label fs-5  ">{t("Application Time")}:</label>
                            <ApplicationTime />
                        </div>
                    </>
                }
                
                <>
                    {
                        progress > 0 && 
                        <div className="my-5 progress progress-bar-striped bg-dark">
                            <div className="progress-bar bg-primary" role="progressbar" style={{width: `${progress}%`}}>{progress}%</div>
                        </div>
                    }
                </>
                
                {
                    ( rxParameters.season && rxParameters.grower && rxParameters.ApplicationType && rxParameters.ApplicationTime )&&
                    <div className="mb-2 my-5">
                        <input type='button' className='my-3 p-2 btn btn-dark w-100' value='Submit' onClick={performOperations} />
                    </div>
                }
                
               
                <div className="">
                    {
                        errorMessage && 
                        <p className="fs-5 text-danger text-center" style={{whiteSpace:'pre-line'}}>{errorMessage}</p>
                    }
                    
                    {
                        logMessage && 
                        <>
                            <p className='fs-5 text-center text-decoration-underline'>{t("LOG")}</p>
                            <p className="fs-5 text-center" style={{whiteSpace:'pre-line'}}>{logMessage}</p>
                        </>
                    }
                </div>

            </div>
        </div>
        </>
    )
}

export default RxProZoneDownloader