import React, { useEffect, useState } from 'react';
import ById from './ById';
import ByFieldId from './ByFieldId';
import { useDispatch } from 'react-redux';
import { requestParametersAddorChange } from '../../../../features/RequestParametersSlice';
import ByGrower from './ByGrower';
import { useTranslation } from 'react-i18next';

function OffcanvasEnd() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [filter_mode, set_filter_mode] = useState("by_id"); // tracks filter mode, default - by_id

    // Event Handler
    function handleFilterModeChange(event) {
        set_filter_mode(event.target.value);
    }

    // Side Effect
    useEffect(() => {
        dispatch(requestParametersAddorChange({ "runtype": ["applied", "harvest"] }));
    }, []);

    return (
        <>
            <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasend" style={{ background: "#EEE" }}>
                <div className="offcanvas-header">
                    <div className="d-flex flex-column w-100">
                        {/* <p className='my-auto'>Filters</p> */}
                        <div className="d-flex mb-3">
                            <button type="button" id="offcanvas-end-close-button" className="ms-auto btn-close" data-bs-dismiss="offcanvas"></button>
                        </div>

                        <div className="d-flex gap-2 justify-content-center">
                            <button onClick={handleFilterModeChange} value="by_grower"
                                className={`btn btn-outline-dark  ${filter_mode === "by_grower" && "active"}`}>{t("Grower")}</button>

                            <button onClick={handleFilterModeChange} value="by_id"
                                className={`btn btn-outline-dark  ${filter_mode === "by_id" && "active"}`}>{t("Asset ID")}</button>

                            <button onClick={handleFilterModeChange} value="by_field_id"
                                className={`btn btn-outline-dark ${filter_mode === "by_field_id" && "active"}`}>{t("Field ID")}</button>
                        </div>
                    </div>
                </div>

                <div className="offcanvas-body d-flex flex-column">
                    <ByGrower visibility={filter_mode === "by_grower"} />
                    <ById visibility={filter_mode === "by_id"} />
                    <ByFieldId visibility={filter_mode === "by_field_id"} />
                </div>
            </div>
        </>
    );
}

export default OffcanvasEnd;
