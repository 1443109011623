import React from 'react'

function ResponseTable({result}) {
  return (
    <>
    <figure className='wrapper' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto"}}>
        <div className="table-responsive w-100">
        <table className="table table-hover shadow-sm">
            <thead className='bg-warning text-white'>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">id</th>
                    <th scope="col">external_id</th>
                    <th scope="col">lat</th>
                    <th scope="col">lon</th>
                    <th scope="col">date_created</th>
                    <th scope="col">date_modified</th>
                    <th scope="col">asset</th>
                    <th scope="col">shape</th>
                    <th scope="col">client</th>
                </tr>
            </thead>
            <tbody>
                
                {
                    result.map((data, index) => {
                        return (
                                <tr>
                                <th scope="col">{index + 1}</th>
                                <td>{data?.id}</td>
                                <td>{data?.external_id}</td>
                                <td>{data?.lat}</td>
                                <td>{data?.lon}</td>
                                <td>{data?.date_created}</td>
                                <td>{data?.date_modified}</td>
                                <td>{data?.asset}</td>
                                <td>{data?.shape}</td>
                                <td>{data?.client}</td>
                            </tr>
                        )
                    })
                }
            </tbody>
        </table>
    </div>
    </figure>
    </>
  )
}

export default ResponseTable