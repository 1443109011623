import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { requestParametersAddorChange } from '../../../features/RequestParametersSlice';
import { useTranslation } from 'react-i18next';

function SelectMachines({grower_id}) {

    const { t } = useTranslation();
    // Dispatcher 
    const dispatch = useDispatch();
    
    // Global State
    const {runtype} = useSelector(state => state.requestParameters);

    // States
    const [applied_canplugs , set_applied_Canplugs] = useState([]) // to store api response of applied canplugs
    const [harvest_canplugs , set_harvest_Canplugs] = useState([]) // to store api response of harvest canplugs
    const [canplugs , set_canplugs] = useState([]) // using which canplug selection table is built 

    const [ selectedImplementIDs, setSelectedImplementIDs ] = useState([]) // maintains selected canplugs
    const [ loading , setLoading ] = useState(true) // to control loading component visibility
    const [ error , setError ] = useState(false) // to control error component visibility


    // fetch canplugs when grower changes
    useEffect(() => {
        // fetching canplugs and assiging it to the state...
        dispatch( requestParametersAddorChange({ 'selectImplementIds': [] }) )
        fetchMachines()
    }, [grower_id])

    // Setting canplugs for the table based on the runtype, when it changes
    useEffect(() => {
            if(runtype === 'applied'){
                console.log('Displaying Applied Cans')
                set_canplugs(applied_canplugs);
            }
            else if(runtype === 'harvest'){
                console.log('Displaying Harvest Cans')
                set_canplugs(harvest_canplugs)
            }
    }, [runtype, applied_canplugs, harvest_canplugs])


    // Adding selected Canplugs to ticket parameter
    useEffect(() => {
        // console.log(selectedImplementIDs)
        dispatch( requestParametersAddorChange({ 'selectImplementIds': selectedImplementIDs}) )
    } , [selectedImplementIDs])

    // Helper
    async function fetchMachines(){
        setLoading(true);
        console.log('fetching canplug')
        try {
            const response = await axios.get(`/api/getImplements/?grower=${grower_id}`)
            if(response.status === 200){
                set_applied_Canplugs(response.data?.appliedImplements)
                set_harvest_Canplugs(response.data?.harvestImplements)
                set_canplugs([...response.data?.appliedImplements]) // display applied cans by default
            }
        } catch (error) {
            setError(true);
            console.log(error);
        } finally{
            setLoading(false);
        }
    }
    // select all button click event handler
    function handleSelectAll(event){
        setSelectedImplementIDs(canplugs.map((e) => {return Number(e.impid);}));
    }

    // deselect all button click event handler
    function handleDeselectAll(event){
        setSelectedImplementIDs([])
    }

    // check box click event handler
    function handleCheckBoxClick(event , impid){
        const {checked , value } = event.target;
        if(checked){
            setSelectedImplementIDs((prev) => { return [...prev, Number(impid)] })
        }
        else{
            setSelectedImplementIDs((curr) => { return curr.filter((a) => a !== Number(impid)); })
        }
    }



    if(error){
        return <ErrorComponent grower_id={grower_id} />
    }

    return (
    <>
    { 
        <>
            <div className="d-flex mb-2">
                <button className='btn btn-outline-dark me-3' onClick={handleSelectAll}>{t("Select All")}</button>
                <button className='btn btn-outline-dark' onClick={handleDeselectAll}>{t("Deselect All")}</button>
            </div>

            {loading ? <Spinner /> : 
            <figure className='wrapper' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto"}}>
                <table className="table table-light table-responsive table-hover shadow">
                    <thead className='table-dark'>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">{t("Implement ID")}</th>
                            <th scope="col">{t("Can ID")}</th>
                            <th scope="col">{t("Make")}</th>
                            <th scope="col">{t("Model")}</th>
                            <th scope="col">{t("Type")}</th>
                            <th scope="col" className='fw-fw-bolder'>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            canplugs.map((canplug , key) => {
                                return (
                                    <TableRow key={key} canplug={canplug} id={key} handleCheckBoxClick={handleCheckBoxClick} selectedImplementIDs={selectedImplementIDs} />
                                )
                            })
                        }
                    </tbody>
                </table>
            </figure>
            }
        </>
    }
    </>
    )
}

// Component for Each row of the canplug table.
function TableRow({canplug , id, handleCheckBoxClick, selectedImplementIDs}){

    const { canid, make, model, type, label} = canplug;
    const impid = Number(canplug?.impid)
    return (
        <>
            <tr>
                <th scope="row"> {id + 1} </th>
                <td>{impid}</td>
                <td>{canid}</td>
                <td>{make}</td>
                <td>{model}</td>
                <td>{type}</td>
                <th scope='row'><input type="checkbox" name="" id="" className='form-check-input' value={label} onChange={(event) => {handleCheckBoxClick(event, impid)}} checked={ selectedImplementIDs.includes(impid) ? 'checked' : ''} style={{padding: "12px"}} /></th>
            </tr>
        </>
    )

}

function ErrorComponent({grower_id}){
    
    const { t } = useTranslation();
    
    return (
        <>
            <div className="my-4 mb-5">
                <p className="text-danger text-center">{t("Error on fetching machines for")} {grower_id}</p>
            </div>
        </>
    )

}


export default SelectMachines