import { useDispatch } from 'react-redux'
import { OPERATIONS } from '../constants'
import Select from 'react-select'
import { requestParametersAddorChange } from '../../../features/RequestParametersSlice';

const Operation = () => {
    
    const dispatch = useDispatch();

    function handleOperationChange(event) {
        dispatch(requestParametersAddorChange({"operation": event.value}))
    }
    
    return (
        <>
            <Select 
                options={OPERATIONS} 
                onChange={handleOperationChange}
                />
        </>
    )
}

export default Operation