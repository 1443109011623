import { useDispatch, useSelector } from 'react-redux'
import { requestParametersAddorChange } from '../../../features/RequestParametersSlice'
import { t } from 'i18next'

const EmailAddress = () => {
    
    const { email_address } = useSelector(state => state.requestParameters);
    const dispatch = useDispatch(); 

    return (
        <div>
            <input 
                id="email-address"
                name="email-address" 
                placeholder={t("Enter Your Email Number")}
                type="email" 
                value={email_address}
                className='form-control'
                onChange={
                    e => dispatch(requestParametersAddorChange({ "email_address": e.target.value }))
                }
                />

        </div>
    )
}

export default EmailAddress