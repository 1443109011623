import axios from 'axios';
import React, { useEffect, useState, useCallback} from 'react'
import { Spinner } from 'react-bootstrap'
import { IoMdRefresh } from "react-icons/io";
import { API_ENDPOINTS } from '../../constants';
import TableRow from './TableRow'
import { useTranslation } from 'react-i18next';

const Datatable = () => {

    const { t } = useTranslation();
    const user_id = localStorage.getItem("client_id");
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [next, setNext] = useState(false);
    const [prev, setPrev] = useState(false);
    const [status, setStatus] = useState('initial'); // Add a status state variable

    const fetchData = useCallback(async (url) => {
        setData([]);
        setLoading(true);
        try {
            const response = await axios.get(url);
            if (response.status === 200) {
                setData(response.data?.results);

                if (document.location.protocol === 'http:') {
                    setPrev(response.data?.previous);
                    setNext(response.data?.next);
                } else if (document.location.protocol === 'https:') {
                    setPrev(response.data?.previous ? response.data.previous.replace("http://", "https://") : false);
                    setNext(response.data?.next ? response.data.next.replace("http://", "https://") : false);
                }
                setStatus('loaded'); // Update status after data is fetched
            } else {
                setStatus('failed'); // Update status if the response is not 200
            }
        } catch (error) {
            console.log('Error while fetching posted tasks...');
            setStatus('failed'); // Update status if there is an error
        } finally {
            setLoading(false);
        }
    }, []);

    const fetchSRs = useCallback(() => {
        try {
            const url = `${API_ENDPOINTS.FETCH_SERVICE_REQUESTS}/?user_id=${user_id}`;
            fetchData(url);
        } catch (error) {
            console.log(error);
            setStatus('failed'); // Update status if there is an error
        }
    }, [user_id, fetchData]);

    useEffect(() => {
        if (status === 'initial') {
            fetchSRs();
        }
    }, [fetchSRs, status]); // Include status in the dependency array

    if (loading) {
        return (
            <div className="d-flex my-5 justify-content-center">
                <Spinner />
            </div>
        );
    } 

    if (status === 'failed') {
        return (
            <div className="d-flex my-5 justify-content-center">
                <p>{t("Failed to fetch data. Please try again later.")}</p>
            </div>
        );
    }
    
    return (
        <>

        <div className="d-flex justify-content-end mb-2">
            {prev && <button className="mx-2 btn btn-primary" onClick={() => {fetchData(prev)}} >{t("Prev")}</button>}
            {next && <button className="mx-2 btn btn-success" onClick={() => {fetchData(next)}} >{t("Next")}</button>}
            <button className="btn btn-secondary" onClick={fetchSRs}> <IoMdRefresh size={24} /> </button>
        </div>

        <div className="table-responsive">
            <table className="table table-hover shadow">
                <thead className='table-dark'>
                    <tr>
                        <th>#</th>
                        <th>SR-ID</th>
                        <th>REQUEST TYPE</th>
                        <th>OPERATION</th>
                        <th>CREATED_ON</th>
                        <th>STATUS</th>
                        <th>DOWNLOAD</th>
                    </tr>
                </thead>
                <tbody>
                        {
                            data && data.map((instance, index) => {
                                return (
                                    <TableRow instance={instance} key={index} index={index} />
                                )
                            })
                        }
                </tbody>
            </table>
        </div>
        </>
    )
}

export default Datatable
