import {Outlet, Navigate, useLocation } from 'react-router-dom'

function JiraAuthenticatedRoutes() {

    const location = useLocation();
    return (
        <>
            { 
                JSON.parse(localStorage.getItem("JIRAAuthenticated")) ?
                    <Outlet /> :
                    <Navigate to='/unauthorized' replace={true} state={location} /> 
            }
        </>
    )
}

export default JiraAuthenticatedRoutes;