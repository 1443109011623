import React, { useEffect, useState } from 'react'
import Navbar from '../settings/components/Navbar'
import Offcanvas from '../../components/Offcanvas'
import {Seasons, Hubs, Farms, ApplicationType, ApplicationTime, SupportedControllerTypes, CheckBoxes} from './components'
import Grower from './components/Growers'
import { useSelector } from 'react-redux'
import "./styles.css"
import Products from './components/Products'
import FileNameModal from './components/FileNameModal'
import { NavLink } from 'react-router-dom'
import {useTranslation} from 'react-i18next'



function GeneratePrescriptionControllerFiles() {
    
    const { t } = useTranslation();
    
    // global state
    const rxParameters = useSelector(state => state.rxParameters);
    const {download_rxs} = rxParameters;

    // Local State
    const [show_products, set_show_products] = useState(false);
    const [ current_farms, set_current_farms ] = useState({});
    const [ farm_ids , set_farm_ids ] = useState([])


    // Side effects
    useEffect(() => {
        if(current_farms !== rxParameters.farms){
            document.querySelector("#btn-submit")?.classList?.remove("disabled");
        }
    }, [rxParameters, current_farms])

    // Event Handlers
    function handleSubmitClick(event){
        window.scrollTo(0,0);
        event.target.classList.add("disabled");
        set_show_products(Math.random());
        set_current_farms(rxParameters.farms);
        set_farm_ids(rxParameters.farms);
    }
    

    return (
        <>
        <Navbar pageName={t('Prescription File Generator')} page="Prescription File Generator" />
        <Offcanvas sidebar={false} />
        <div className="page-content row justify-content-center">
                <div className="col-lg-6">
                    <div className="mx-3 mb-2 my-4">
                        <NavLink to="/instructions"><button className='btn btn-outline-primary mt-2'> <i className="fa-solid fa-circle-info mx-2"></i>{t('Instructions')}</button></NavLink>
                    </div>
                    {/* Season Selector */}
                    <div className="mx-3 mb-2 my-4">
                    <label htmlFor="" className="my-1 form-label fs-5">{t('Select Season')}:</label>
                        <Seasons />
                    </div>

                    {/* Hub Selector */}
                    <div className="mx-3 mb-2 my-4">
                        <label htmlFor="" className="my-1 form-label fs-5">{t('Select Hub')}:</label>
                        <Hubs />
                    </div>

                    {/* Grower Selector */}
                    { 
                        (rxParameters.season && rxParameters.hub) &&
                        <div className="mx-3 mb-2 my-4">
                            <label htmlFor="" className="my-1 form-label fs-5">{t('Select Grower')}:</label>
                            <Grower />
                        </div>
                    }

                    {/* Grower Selector */}
                    { 
                        (rxParameters.season && rxParameters.hub && rxParameters.grower) &&
                        <div className="mx-3 mb-2 my-4">
                            <label htmlFor="" className="my-1 form-label fs-5">{t('Select Farms')}:</label>
                            <Farms />
                        </div>
                    }

                    {/*  */}
                    <div className="mx-3 mb-2 my-4">
                        <label htmlFor="" className="form-label fs-5">{t('Select Controller Filetype')}:</label>
                        <SupportedControllerTypes />
                    </div>

                    {/* ApplicationType & ApplicationTime */}
                    <div className="mx-3 mb-2 my-5">
                        {/* <label htmlFor="" className="form-label fs-5">Select Application Type:</label> */}
                        <ApplicationType />
                    </div>

                    <div className="mx-3 mb-2 my-4">
                        {/* <label htmlFor="" className="form-label fs-5">Select Application Time:</label> */}
                        <ApplicationTime />
                    </div>
                    
                    <div className="mx-3 mb-2 my-5">
                        <CheckBoxes />
                    </div>

                    {
                        (rxParameters.farms && rxParameters.farms.length > 0 && download_rxs) &&
                        <div className="d-flex justify-content-center align-items-center mx-3 mb-2 my-5">
                            <button id="btn-submit" className="btn btn-outline-dark" onClick={(event) => {handleSubmitClick(event)}}>{t("Submit")}</button>
                        </div>
                    }

                    {
                        (rxParameters.farms && rxParameters.farms.length > 0 && !download_rxs) && 
                        <>
                            <FileNameModal />
                            <div className="d-flex justify-content-center align-items-center mx-3 mb-2 my-5">
                                <button id="btn-filename-modal" className="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#generate-file-modal">{t("Generate File")}</button>
                            </div>
                        </>
                    }

            </div>

            {
                (show_products && download_rxs) &&
                <div className="col-lg-6 my-5 ">
                    {/* Products Table */}
                    {<Products farms={farm_ids}/>}

                    {/* Generate File Button */}
                    <div className="d-flex flex-column align-items-center justify-content-center my-5">
                        {
                            ( rxParameters.season  && rxParameters.farms && rxParameters.controller_filetype && rxParameters.ApplicationType && rxParameters.ApplicationTime && rxParameters.input_application_number && rxParameters.input_application_number.length > 0) &&
                            <button id="btn-filename-modal" className="btn btn-outline-dark" data-bs-toggle="modal" data-bs-target="#generate-file-modal">{t("Generate File")}</button>
                        }

                        {
                            <FileNameModal />
                        }

                    </div>

                </div>
            }
        </div>
        </>
    )
}

export default GeneratePrescriptionControllerFiles