import React, { useContext } from 'react'
import { ControllerDataContext } from '../context/ControllerDataContext';
import { CODA_HOST } from '../../../constants';
import axios from 'axios';

function FilenameSearch({filename, setFilename, set_selected_grower}) {

    // Context
    const {setData, setLoading, setMessage, setNext, setPrev, setSearchType } = useContext(ControllerDataContext)

    // Event Handlers
    async function handleSearchByFilename(event){
        try {
            setLoading(true);
            let url = `${CODA_HOST}/sourcefile/?filename=${filename}`
            let response = await axios.post("/coda/make_coda_call/", {"url": url})
            setSearchType("filename")
            setData(response.data?.results);
            setNext(response.data?.next);
            setPrev(response.data?.previous);
            setMessage(`Controller Files with filename: ${filename}`);
        } catch (error) {
            console.log("Error on Fetching data", error)
        } finally{
            setLoading(false)
        }
    }

    return (
        <div className="input-group">
            <input type="text" name="filename" id="filename" placeholder='Enter Filename' className="form-control" autoComplete='off'
            onChange={(event) => {set_selected_grower(null); setFilename(event.target.value)}} 
            value={filename}  />
            <button className="btn btn-dark" onClick={handleSearchByFilename}><i className="fa-solid fa-magnifying-glass"></i></button>
        </div>
    )
}

export default FilenameSearch