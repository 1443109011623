import { useEffect, useState } from "react"
import { Accordion, Spinner } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import {HOW_TO_UPLOAD_MACHINE_DATA_ROOT_PAGE} from '../../constants'

const HowToUploadMachineData = () => {

    const navigate = useNavigate();
    const [ showAccordion, setShowAccordion ] = useState(false);
    
    const [ loading, setLoading ] = useState(false);
    const [ content, setContent ] = useState("");

    // Fetch Content from Confluence Page
    async function fetchContent(){
        setLoading(true);
        try {
            const response = await axios.get(`/api/getConfluencePageHTML/?pageID=${HOW_TO_UPLOAD_MACHINE_DATA_ROOT_PAGE}`);
            if(response.data?.content){
                let _content = response.data?.content;
                setContent(_content)
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    // <a> Click Event
    function handleAnchorClick(event){
        event.preventDefault();
        let href = event.currentTarget.getAttribute('href');
        if(href.split("=").length === 2){
            let moveTo = href.split("=")[1]
            document.getElementById(moveTo)?.scrollIntoView({behavior: "smooth", block: "nearest"});
        } else {
            href = href?.replace("#", "")
        }
        navigate(href);
        setShowAccordion(false)
    };

    useEffect(() => {
        fetchContent();
    }, [])

    useEffect(() => {
        // Attach click event listeners to all anchor tags
        const anchorElements = document.querySelectorAll('a');
        const filteredAnchorelements = Array.from(anchorElements).filter( e => {
            return !Array.from(e.classList).includes("side-btn")
        })
        filteredAnchorelements.forEach((anchor) => {
            anchor.addEventListener('click', handleAnchorClick);
        });
    }, [content])

    // Accordion
    return (
        <Accordion activeKey={showAccordion}>
            <Accordion.Item eventKey={true} className='rounded mt-1 mb-4'>
                <Accordion.Header className='custom-header' onClick={(event) => {setShowAccordion(prev => !prev)}}>How To Upload Machine Data to FarmCommand</Accordion.Header>
                <Accordion.Body>
                    <div className="d-flex flex-column">
                        {loading && <Spinner />}
                        <span dangerouslySetInnerHTML={{__html: content}}></span>
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

export default HowToUploadMachineData;