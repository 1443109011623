import React, { useEffect, useState } from 'react'
import {OffcanvasEnd, DataTable, DataTableForGrower, OffcanvasForGrower} from './components'
import Navbar from '../settings/components/Navbar'
import Offcanvas from '../../components/Offcanvas'
import { useLocation } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux'
import { requestParametersReset } from '../../features/RequestParametersSlice'
import { tempParametersReset } from '../../features/TempParametersSlice';
import { reset, reset_ } from '../../features/RequestSlice';
import ControllerDataProvider from './context/ControllerDataContext';
import { useTranslation } from 'react-i18next';

function UploadedControllerFiles() {
  
  const {t} = useTranslation();
  const user_roles = localStorage.getItem("user_roles");
  const dispatch = useDispatch();
  const location = useLocation()
  const grower_id = location?.state?.grower_id; 
  useEffect(() => {
    dispatch(requestParametersReset())
    dispatch(tempParametersReset())
    dispatch(reset()); dispatch(reset_()); 
  }, [dispatch])

  return (
    <>
        <Navbar pageName={t("Uploaded Controller Files")}/>
        <Offcanvas />

        <div className='page-content px-2'>
          <ControllerDataProvider>
            {
              user_roles?.includes("Grower") ?
                <>
                  <OffcanvasForGrower />
                  <DataTableForGrower />
                </>
                  :
                <>
                  <OffcanvasEnd />
                  <DataTable />
                </>
            }
          </ControllerDataProvider>
        </div>
        <ToastContainer 
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark" />
    </>
  )
}

export default UploadedControllerFiles