import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactCountryFlag from 'react-country-flag';

const getCountryCode = (language) => {
    switch (language) {
        case 'en_US':
            return 'US';
        case 'pt_BR':
            return 'BR';
        default:
            return 'US';
    }
};
function ChangeLanguage({pageName='navbar'}) {
    const { t, i18n } = useTranslation();
    const [preferredLanguage , setPreferredLanguage] = useState('');
    const [countryCode, setCountryCode] = useState(getCountryCode(preferredLanguage));
    
    useEffect(() => {
        if(!localStorage.getItem('language')){ 
            setPreferredLanguage('en_US'); 
        }
        else{
            setPreferredLanguage(localStorage.getItem('language'));
        }
    }, [])
    
    

    function handleLanguageChange(event, language){
        i18n.changeLanguage(language)
        localStorage.setItem('language' , language);
        setPreferredLanguage(language)
        setCountryCode(getCountryCode(language));
    }



    useEffect(() => {
        setCountryCode(getCountryCode(preferredLanguage));
    }, [preferredLanguage]);


    return (
        <>
            {pageName === 'settings' &&
                <span className='me-3 my-auto' style={{fontWeight: 700}}>{t('Preferred Language')}</span>
            }
            <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <ReactCountryFlag countryCode={countryCode} svg style={{ marginRight: '8px', width: '25px', height:'20px'  }} />
            </button>
            <div className="dropdown-menu">
                <li>
                    <span className="dropdown-item" onClick={(e) => { handleLanguageChange(e, 'en_US') }}>
                        <ReactCountryFlag countryCode="US" svg style={{ marginRight: '0px', width: '25px', height:'20px'  }} />
                    </span>
                </li>
                <li>
                    <span className="dropdown-item" onClick={(e) => { handleLanguageChange(e, 'pt_BR') }}>
                        <ReactCountryFlag countryCode="BR" svg style={{ marginRight: '0px', width: '25px', height:'20px'  }} /> 
                    </span>
                </li>
            </div>
        </>
    )
}

export default ChangeLanguage