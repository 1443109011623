import React, { useEffect, useState, useRef  } from 'react';
import { MapContainer, TileLayer, useMap, GeoJSON } from 'react-leaflet';
import { Buffer } from 'buffer';
import 'leaflet/dist/leaflet.css';
import { fromArrayBuffer } from 'geotiff';
import L from 'leaflet';
import { useJsApiLoader } from '@react-google-maps/api';
import { config } from './helper';
import { el } from 'date-fns/locale';

const mapStyles = [
  { "elementType": "labels", "stylers": [ { "visibility": "off" } ] },
  { "featureType": "administrative", "elementType": "geometry", "stylers": [ { "visibility": "off" } ] },
  { "featureType": "administrative.land_parcel", "stylers": [ { "visibility": "off" } ] },
  { "featureType": "administrative.neighborhood", "stylers": [ { "visibility": "off" } ] },
  { "featureType": "poi", "stylers": [ { "visibility": "off" } ] },
  { "featureType": "road", "stylers": [ { "visibility": "off" } ] },
  { "featureType": "road", "elementType": "labels.icon", "stylers": [ { "visibility": "off" } ] },
  { "featureType": "transit", "stylers": [ { "visibility": "off" } ] }
];

const summaryStyle = {
  height: '700px',
  width: '30%',
  float: 'right',
  overflowY: 'scroll',
  padding: '10px',
  boxSizing: 'border-box',
};

const options = {
  mapTypeId: "hybrid",
  styles: mapStyles, // Apply the custom styles
};
const mapContainerStyle = {
  width: "70%",
  height: "100%",
  minHeight: "700px",
  float: 'left',
};

const MapComponent = ({ selectedOperation }) => {
  const [geoTiffBlob, setGeoTiffBlob] = useState(null);
  const [geoTiffName, setGeoTiffName] = useState(null);
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [minValueFromGeoTiff, setMinValueFromGeoTiff] = useState(0);
  const [maxValueFromGeoTiff, setMaxValueFromGeoTiff] = useState(0);
  const [selectedButton, setSelectedButton] = useState(null);

  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  const getColorForValue = (value, min, max, colorSwatch) => {
    const range = max - min;
    const normalizedValue = (value - min) / range;
    const swatchIndex = Math.floor(normalizedValue * (colorSwatch.length - 1));
    const clampedIndex = Math.max(0, Math.min(swatchIndex, colorSwatch.length - 1));
    const [r, g, b] = colorSwatch[clampedIndex];
    return [r, g, b, 255]; // Full opacity
  };
  
  
  const generateValueRanges = (min, max, steps) => {
    const stepSize = (max - min) / steps;
    const ranges = [];
    for (let i = 0; i < steps; i++) {
      const rangeMin = min + i * stepSize;
      const rangeMax = rangeMin + stepSize;
      ranges.push(`${rangeMin.toFixed(1)}-${rangeMax.toFixed(1)}`);
    }
    return ranges;
  };
  
  const pruneOutliers = (data, lowerPercentile = 0, upperPercentile = 95, maxThreshold = null) => {
    const sortedData = [...data].sort((a, b) => a - b);
    const lowerIndex = Math.floor((lowerPercentile / 100) * sortedData.length);
    const upperIndex = Math.ceil((upperPercentile / 100) * sortedData.length) - 1;
    const lowerBound = sortedData[lowerIndex];
    const upperBound = sortedData[upperIndex];
    
    // Use the smaller of the computed upper bound and maxThreshold, if provided
    const finalUpperBound = maxThreshold !== null ? Math.min(upperBound, maxThreshold) : upperBound;
    
    return data.filter(value => value >= lowerBound && value <= finalUpperBound);
  };
  
  const Legend = ({ min, max, geoTiffName}) => {
    const map = useMap();
    const legendRef = useRef(null); // Keep a reference to the legend
    let colorSwatch = config["default"]

    if (config[geoTiffName]) {
      colorSwatch = config[geoTiffName]
    } 
    let valueRanges = generateValueRanges(min, max, colorSwatch.length);
    useEffect(() => {
      // Remove the existing legend if it exists
      if (legendRef.current) {
        map.removeControl(legendRef.current);
      }
  
      const legend = L.control({ position: 'bottomright' });
      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'info legend');
        div.style.padding = '10px';
        div.style.background = 'white';
        div.style.border = '1px solid rgba(0,0,0,0.2)';
        div.innerHTML = `<strong>Legend</strong><br>`;
        
        // Add legend items dynamically
        const rangeStep = (max - min) / colorSwatch.length;
        valueRanges.forEach((range, index) => {
          const color = `rgba(${colorSwatch[index].join(',')}, 1)`;
          div.innerHTML += `<i style="background:${color}; width: 18px; height: 18px; display: inline-block; margin-right: 5px;"></i> ${range}<br>`;
        });
  
        return div;
      };
  
      legend.addTo(map);
      legendRef.current = legend; // Store the new legend reference
  
      // Clean up on unmount or updates
      return () => {
        if (legendRef.current) {
          map.removeControl(legendRef.current);
          legendRef.current = null;
        }
      };
    }, [map, min, max]); // Run effect whenever min or max changes
  
    return null;
  };
    
  // Component to load and overlay the GeoTIFF
  const GeoTIFFOverlay = ({ geoTiffBlob, geoTiffName }) => {
    const map = useMap();
    const overlayRef = useRef(null); // Ref to store the current overlay
  
    useEffect(() => {
      if (!geoTiffBlob && !geoTiffName) return;
      // Remove the existing overlay if it exists
      if (overlayRef.current) {
        map.removeLayer(overlayRef.current);
      }
      const loadGeoTIFF = async () => {
        const reader = new FileReader();
        reader.onload = async (event) => {
          const arrayBuffer = event.target.result;
          const tiff = await fromArrayBuffer(arrayBuffer);
          const image = await tiff.getImage();
          const rasters = await image.readRasters(); // Read all bands data
          const [rBand, gBand, bBand] = rasters; // Assume 3 bands: R, G, B
          let colorSwatch = config["default"]

          if (config[geoTiffName]) {
            colorSwatch = config[geoTiffName]
          } 
          const width = image.getWidth();
          const height = image.getHeight();
          const bounds = image.getBoundingBox();
          let prunedData = rBand
          // if (!gBand || !bBand) {
          //   if (["moisture", "harvestMoisture", "appliedRate", "speed"].includes(geoTiffName)) {
          //       prunedData = pruneOutliers(prunedData, 5, 95);
          //   }
          // }
          
          const min = Math.min(...prunedData);
          const max = Math.max(...prunedData);
          setMinValueFromGeoTiff(min);
          setMaxValueFromGeoTiff(max);

          // If min and max values are the same, reduce colorSwatch to one color
          if (min === max) {
            colorSwatch = [colorSwatch[0]]; // Use the first color in the swatch
          }
          const binCount = colorSwatch.length;
          const binSize = (max - min) / binCount;
          const bins = Array(binCount).fill(0);
  
          // prunedData.forEach(value => {
          //   const binIndex = Math.min(Math.floor((value - min) / binSize), binCount - 1);
          //   bins[binIndex]++;
          // });
  
          // console.log('Min:', min, 'Max:', max, 'Bins:', colorSwatch.length);
  
          const canvas = document.createElement('canvas');
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          const imgData = ctx.createImageData(width, height);

          if (!gBand || !bBand) {
            for (let i = 0; i < prunedData.length; i++) {
                const value = prunedData[i] || 0;
                const binIndex = Math.min(Math.floor((value - min) / binSize), binCount - 1);
                if (binIndex < 0 || binIndex > colorSwatch.length || isNaN(binIndex)) {
                  console.log("Error: Bin index out of range")
                }
                const [r, g, b] = colorSwatch[binIndex];
                imgData.data[i * 4 + 0] = r;
                imgData.data[i * 4 + 1] = g;
                imgData.data[i * 4 + 2] = b;
                imgData.data[i * 4 + 3] = 255; // Full opacity
            }
          } else {
            for (let i = 0; i < width * height; i++) {
                const r = rBand[i] || 0;
                const g = gBand[i] || 0;
                const b = bBand[i] || 0;
                imgData.data[i * 4 + 0] = r; // Red
                imgData.data[i * 4 + 1] = g; // Green
                imgData.data[i * 4 + 2] = b; // Blue
                imgData.data[i * 4 + 3] = 255; // Alpha (fully opaque)
            }
          }
          
          ctx.putImageData(imgData, 0, 0);
          const url = canvas.toDataURL(); // Convert the canvas to an image URL
      
          const latLngBounds = [
              [bounds[1], bounds[0]],
              [bounds[3], bounds[2]],
          ];
      
          try {
              // Create an overlay using the combined RGB image
              const geoTiffOverlay = L.imageOverlay(url, latLngBounds, { opacity: 1 });
              geoTiffOverlay.addTo(map);
              map.fitBounds(latLngBounds);
              overlayRef.current = geoTiffOverlay; // Update the ref to the new overlay
          } catch (error) {
              console.error("Error rendering GeoTIFF overlay:", error);
          }
          
        };
        reader.readAsArrayBuffer(geoTiffBlob);
      };
  
      loadGeoTIFF();
    }, [geoTiffBlob, map]);
  
    return null;
  };
  const renderValue = (key, value) => {
    if (Array.isArray(value)) {
      return (
        <ul>
          {value.map((item, index) => (
            <li key={index} style={{ marginLeft: '15px' }}>{renderValue(key, item)}</li>
          ))}
        </ul>
      );
    } else if (typeof value === 'object' && value !== null) {
      return (
        <div>
          {Object.entries(value).map(([subKey, subValue]) => (
            <div key={subKey} style={{ marginLeft: '15px' }}>
              <strong>{subKey}:</strong> {renderValue(subKey, subValue)}
            </div>
          ))}
        </div>
      );
    } else {
      return <span>{value}</span>;
    }
  };

  useEffect(() => {
    if (selectedOperation && !geoJsonData) {
        setGeoJsonData(selectedOperation.geometry);
    }
  }, [selectedOperation]);

  const handleGeoTiffClick = (encodedData, name) => {
      const binaryData = Uint8Array.from(Buffer.from(encodedData, 'base64'), c => c);
      const blob = new Blob([binaryData], { type: 'image/tiff' });
      setGeoTiffBlob(blob);
      setGeoTiffName(name);
      setSelectedButton(name); // Set the selected button

  };

  if (loadError) {
      return <div>Error loading maps</div>;
  }

  if (!isLoaded || !selectedOperation) {
      return <div></div>;
  }
  
  return (
    <>
      {selectedOperation.length !== 0 && selectedOperation && (
        <>
        <div>
          <div style={{ marginBottom: '10px', overflowX: 'scroll', whiteSpace: 'nowrap' }}>
            {Object.entries(selectedOperation.layers).map(([property, data]) => (
              <button key={property} onClick={() => handleGeoTiffClick(data.geotiff, property)} style={{
                marginRight: '10px',
                backgroundColor: selectedButton === property ? 'lightblue' : 'white', // Change color if selected
              }}>
                {property}
              </button>
            ))}
          </div>
          <MapContainer
            center={[20, 0]}
            zoom={3}
            scrollWheelZoom={true}
            style={mapContainerStyle}
          > 
            <TileLayer
              options={options}
              url={`https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&key=${GOOGLE_MAPS_API_KEY}`}
              attribution='&copy; <a href="https://maps.google.com">Google Maps</a>'
            />
            {geoJsonData && <GeoJSON data={geoJsonData} style={() => ({
              color: "#3388ff", // Border color
              weight: 2,        // Border width
              opacity: 1,       // Border opacity
              fill: false       // No fill
            })} />}
            {geoTiffBlob && geoTiffName && <GeoTIFFOverlay geoTiffBlob={geoTiffBlob} geoTiffName={geoTiffName} />}
            <Legend min={minValueFromGeoTiff} max={maxValueFromGeoTiff} geoTiffName={geoTiffName}/>

          </MapContainer>
        </div>
        <div style={summaryStyle}>
          {selectedOperation.length !== 0 && (
            <div>
            <h3>Selected Operation</h3>
            {Object.entries(selectedOperation.summary).map(([property, data]) => (
                <div key={property} style={{ marginBottom: '10px' }}>
                  <strong>{property}</strong>:
                  <div style={{ marginLeft: '15px' }}>
                    {Array.isArray(data) ? (
                      data.map((item, index) => (
                        
                        <div key={index} style={{ marginBottom: '5px' }}>
                          <span>{JSON.stringify(item, null, 2)}</span>
                        </div>
                      ))
                    ) : typeof data === 'object' && data !== null ? (
                      <pre style={{ margin: 0 }}>{JSON.stringify(data, null, 2)}</pre>
                    ) : (
                      <span>{data}</span>
                    )}
                  </div>
                </div>
              ))}
          </div>
          )}
        </div>
        </>
      )}
    </>
  );
}
export default MapComponent;
