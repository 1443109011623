import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import axios from 'axios'
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


function Submit() {
  
  const { t } = useTranslation();
  const [loading, set_loading] = useState(false); // Loading Component {Spinner}
  const [status, set_status] = useState(false); // Success Message
  const [error_message, set_error_message] = useState(null); // Failure Message

  let REQUEST = [JSON.parse(JSON.stringify(useSelector(state => state.request_season_view)))]
  const sourcefile_id = useSelector(state => state.tempParameters).sourcefile_id;
  const tempParameters = useSelector(state => state.tempParameters);
  const grower_id = tempParameters.grower_id;


  async function handleSubmit(event){
    set_loading(true);
    event.target.classList.add("disabled")
    
    // --- PARSING REQUEST OBJECT --- //
    // Removing Empty objects from by_MD5
    console.log(REQUEST[0][sourcefile_id].by_MD5)
    REQUEST[0][sourcefile_id].by_MD5 = REQUEST[0][sourcefile_id].by_MD5.filter(e => !_.isEmpty(e))  
    
    // Removing Empty objects from default under all season
    REQUEST[0][sourcefile_id].default = REQUEST[0][sourcefile_id].default.filter((e, index) => {
      
      // Removing Empty Objects under current season
      let current_season = Object.keys(e)[0];
      e[current_season].productList = e[current_season].productList.filter(e => !_.isEmpty(e));

      return e[current_season].productList.length !== 0
    })

   
    // Checking with Existing attributes
    if(tempParameters['Sourcefile Data']){
      tempParameters['Sourcefile Data']?.forEach(e => {
        let current_sourcefile_id = Object.keys(e)[0];
        if(sourcefile_id !== current_sourcefile_id){
          REQUEST.push(e);
        }
      })
    }
    console.log(REQUEST);

    // Sending Request
    try {
      const response = await axios.post(`/coda/post_product_details_season_view/${grower_id}`, REQUEST);
      if( response.status === 200 || response.status === 201){
        set_loading(false); set_status(true);
      } else{
        throw new Error('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
      set_error_message("Something Went Wrong!!")
      set_loading(false); set_status(false);
    }
  }

  return (
    <>
        <div className="text-center my-4">
            <button className="btn btn-outline-secondary" onClick={handleSubmit}>{t("Submit")}</button>
        </div>
        
        {/* loading */}
        {
        loading &&
        <div className="d-flex justify-content-center p-3">
            <Spinner className='text-warning' />
        </div>
        }

        {/* Success Message */}
        { status && <p className='my-5 text-center fs-4 text-success animate__animated animate__bounce'>{t("Posted Successfully")}</p>}

        {/* Error Message */}
        {error_message && <p className="text-danger text-center animate__animated animate__bounce">{error_message}</p>}
        
        </>
  )
}

export default Submit