import React from 'react'
import FarmCommandLogoGrey from '../../../assets/images/FarmCommandLogoGrey.png'
import { useNavigate, useLocation } from 'react-router-dom';
import "./styles.css"
import { useContext, useState, useRef } from 'react';
import { useSelector } from 'react-redux'
import AuthContext from '../../../context/AuthProvider'
import {logOut} from '../../../utils/LogOut'
import DashboardSettings from '../../home_page/components/DashboardSettings';
import { useTranslation } from 'react-i18next'
import ChangeLanguage from '../../settings/components/GlobalSettings/ChangeLanguage';
import { FaFilePdf, FaRegFilePdf  } from "react-icons/fa";
import { BsGearFill } from "react-icons/bs"; // Import BsGearFill from react-icons/bs
import { WHITELISTED_USERS } from '../../../constants';
import { IoLogoAppleAr } from "react-icons/io5";
import { FaUserAlt } from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";

// Navbar for Home Page (Dashboard)
function Navbar({pageName, userInfo, user_roles}) {
    
    const { t } = useTranslation();
    const { setAuthStatus } = useContext(AuthContext)
    const navigate = useNavigate();
    const location = useLocation(); // Get the current URL
    const requestParameters = useSelector(state => state.requestParameters);
    const legentToggler = useRef()
    const [ showOptions, setShowOptions ] = useState(false);
    const [ showDashboardSettings, setShowDashboardSettings ] = useState(false);
    // Back button click Event Handler
    function handleBackClick(){
        navigate(-1);
    }

    return (
    <>
        <nav className="navbar navbar-expand-sm navbar-white" style={{background: "#02559c", zIndex: 2}}>
            {/* <button className='btn btn-outline-light mx-md-2 me-3 p-2' onClick={ handleBackClick } title="Back"><i className="fa-solid fa-arrow-left-long"></i></button> */}
            <button className="ms-3 btn btn-outline-light py-2" type="button" data-bs-toggle="offcanvas" href="#offcanvasExample">
                <i className="fa-solid fa-bars"></i>
            </button>
            {pageName === "Dashboard" ? 
                <span className='text-white' style={{marginLeft: "2rem"}}>{t("Welcome")} {localStorage.getItem("displayName")}</span> :
                <span className='text-white' style={{marginLeft: "2rem"}}>{pageName}</span>  
            }
            
            <div className='w-100 position-absolute' style={{zIndex: -1}}>
                <img src={FarmCommandLogoGrey} alt="fc support logo" className="navbar-brand d-none d-lg-block mx-auto" height={25} style={{filter: "brightness(200)"}} />
            </div>
            <div className="ms-auto position-relative">
                <button className='btn btn-light text-light mx-md-2 me-3 p-2' onClick={ handleBackClick } title="Back"><i className="fa-solid fa-arrow-left-long"></i></button>
                { showDashboardSettings && (<DashboardSettings /> )}
                
                {["/"].includes(location.pathname) && 
                    <button className='btn me-2' onClick={() => {setShowDashboardSettings(showDashboard => !showDashboard)}}><BsGearFill color='#FFF' size={20}/></button>
                }
                {(location.pathname).includes("maps") && (requestParameters?.field_ids) &&   
                    <button className="btn btn-outline-light fw-bold mx-3 text-uppercase" id="#legend-toggler" ref={legentToggler} data-bs-toggle="offcanvas" data-bs-target="#legend" style={{zIndex: 100}}>{t("Legend")}</button>
                }
                {(location.pathname).includes("maps") &&    
                    <button className="btn btn-outline-light me-2" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasend" style={{zIndex: 100}}>
                        <i className="fa-solid fa-filter" ></i>
                    </button>
                }
                {(location.pathname).includes("generated_prescription_files") &&
                    <button className="btn btn-outline-light me-2" type="button" data-bs-toggle="offcanvas" href="#filter-prescription-files">
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                }
                

                <button className="btn me-2 text-light" type="button" onClick={() => {setShowOptions(showOptions => !showOptions)}}>
                    <i className="fa-solid fa-ellipsis-v" ></i>
                </button>
            </div>
        </nav>
        
        
        
        {/* Settings, Logout */}
        { showOptions && 
            <div className='settings-menu position-absolute my-2 p-2 shadow-lg bg-light text-dark rounded-2 d-flex flex-column align-items-end slide-down' style={{right: "15px", zIndex: 100}}>
                {/* <button className='btn btn-light text-darkmx-md-2 me-3 p-2' onClick={ handleBackClick } title="Back"><i className="fa-solid fa-arrow-left-long"></i></button> */}
                {(location.pathname).includes("sail")  && 
                    <>
                        <button className='btn btn-light text-dark fw-bold p-2 text-capitalize' onClick={() => { navigate("/reporting/sail/"); } }><FaFilePdf />{t("PDF Generation Form")}</button>
                        <button className='btn btn-light text-dark fw-bold p-2 text-capitalize' onClick={() => { navigate("/reporting/sail/generated_pdfs/"); } }> <FaRegFilePdf /> {t("Generated PDFs")}</button>
                    </>
                }
                {(location.pathname).includes("iris")  && 
                    <>
                        <button className='btn btn-light text-dark fw-bold p-2 text-capitalize' onClick={() => { navigate("/reporting/iris/"); } }><FaFilePdf />{t("PDF Generation Form")}</button>
                        <button className='btn btn-light text-dark fw-bold p-2 text-capitalize' onClick={() => { navigate("/reporting/iris/generated_pdfs/"); } }> <FaRegFilePdf /> {t("Generated PDFs")}</button>
                    </>
                }
                {(location.pathname).includes("service_request") &&
                    <>
                    <button className='btn btn-light text-dark p-2 text-capitalize'  onClick={() => {navigate("/service_request/")}}>  <IoLogoAppleAr /> {t("Service Request")} </button>
                    <button className='btn btn-light text-dark p-2 text-capitalize'  onClick={() => {navigate("/service_request/your_requests/")}}> <FaUserAlt/> {t("Your Service Requests")} </button>
                    
                    { WHITELISTED_USERS.includes(localStorage.getItem("username")) && 
                        <button className='btn btn-light text-dark p-2 text-capitalize'  onClick={() => {navigate("/service_request/admin/")}}><RiAdminFill /> {t("SR Admin")} </button>
                    }
                    </>
                }
                {(location.pathname).includes("file_uploader") &&
                    <>
                     <button className='border-top btn btn-light text-dark fw-bold p-2 text-capitalize' onClick={() => {navigate("/file_uploader/")}}><i className="fa-solid fa-file-arrow-up mx2"></i> {t("Asset File Uploader")} </button>
                     <button className='border-top btn btn-light text-dark fw-bold p-2 text-capitalize' onClick={() => {navigate("/file_uploader/your_uploads/")}}><i className="fa-solid fa-file-import mx2"></i> {t("Your Uploads")} </button>
                    </>
                }
                {(location.pathname).includes("prescription") &&
                    <>
                            <button className='border-top btn btn-light text-dark fw-bold p-2 text-capitalize' onClick={() => {navigate("/generate_prescription_controller_files")}}><i className="fa-solid fa-file-arrow-up mx2"></i> {t("Generate Prescription File")} </button>
                            <button className='border-top btn btn-light text-dark fw-bold p-2 text-capitalize' onClick={() => {navigate("/generated_prescription_files")}}><i className="fa-solid fa-file-import mx2"></i> {t("Files You've Generated")} </button>
                    </>
                }

                {(location.pathname).includes("add_machines_to_grower") &&
                    <>
                            <button className='border-top btn btn-light text-dark fw-bold p-2 text-capitalize' onClick={() => {navigate("/add_machines_to_grower/")}}>
                                <i className="fa-solid fa-code-pull-request"></i> {t("Assign Machines")}
                            </button>
                            <button className='border-top btn btn-light text-dark fw-bold p-2 text-capitalize' onClick={() => {navigate("/add_machines_to_grower/your_assignments/" )}}>
                            <i className="fa-solid fa-clock-rotate-left"></i> {t("Your Prev Assignments")}
                            </button>
                    </>
                }
                {((location.pathname).includes("maps") || user_roles?.includes("Elvyra Tech")) &&
                    <button className='btn btn-light fw-bold p-2' onClick={() => {navigate("/settings/map-settings")}}> <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M0 117.66v346.32c0 11.32 11.43 19.06 21.94 14.86L160 416V32L20.12 87.95A32.006 32.006 0 0 0 0 117.66zM192 416l192 64V96L192 32v384zM554.06 33.16L416 96v384l139.88-55.95A31.996 31.996 0 0 0 576 394.34V48.02c0-11.32-11.43-19.06-21.94-14.86z"></path></svg> {t("FC Lite Settings")} </button>
                }
                <button className='border-top btn btn-light text-dark fw-bold p-2 text-capitalize' onClick={() => {navigate("/settings")}}> <i className="fa fa-gear mx-2"></i> {t("settings")} </button>
                
                <button className='btn btn-light text-dark fw-bold p-2 text-capitalize' onClick={() => {logOut(setAuthStatus)}}> <i className="fa-solid fa-arrow-right-from-bracket mx-2"></i> {t("logout")} </button>
                <ChangeLanguage/>

            </div>
        }

    </>
    )
}

export default Navbar