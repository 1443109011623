import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import {Spinner} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {ALLOWED_IMPLEMENT_TYPE_IDS} from '../../../constants'
import ImplementAttribute from './ImplementAttribute';
import { ResponseContext } from '../../../context/ResponseContext';
import Confirmation from './Confirmation'

const ImplementTable = ({implementAccordion}) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const { grower_id, implements_selected } = useSelector( state => state.requestParameters );
    const {_dispatch} = useContext(ResponseContext)

    async function fetchImplements(){
        setLoading(true);
        try {
            const response = await axios.get(`/api/carbon/getImplements/?grower_id=${grower_id}`);
            if(response.data?.result){
                const _implements = response.data.result;
                const parsedImplements = _implements.reduce((acc, obj) => {
                    const { id, ...rest } = obj;
                    acc[id] = { "id": id, ...rest };
                    return acc;
                }, {});
                _dispatch({ "payload": {"implements" : parsedImplements}, "type": "addOrChange" })
                setData(_implements);
            }
        } catch (error) {
            console.log(error);            
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchImplements();
    }, [])

    return (
        <>
            {
            loading ? <Spinner />
            :
            <figure className='wrapper' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto"}}>
                <table className="table table-hover">
                <thead >
                    <tr className='m-2 header' >
                        <th className='bg-dark text-white'>#</th>
                        <th className='bg-dark text-white'>Name</th>
                        <th className='bg-dark text-white'>Make</th>
                        <th className='bg-dark text-white'>Model</th>
                        <th className='bg-dark text-white'>Implement Type</th>
                        <th className='bg-dark text-white'>Width</th>
                        <th className='bg-dark text-white'>#</th>
                    </tr>
                </thead>

                <tbody>
                    {
                        data.map((row, index) => {
                            return (
                                <>
                                    <ImplementTableRow 
                                        key={index} 
                                        row={row} index={index}  
                                        implements_selected={implements_selected}
                                    />
                                    <ImplementAttribute id={row?.id} data={row} />
                                </>
                            )
                        })
                    }
                </tbody>
                </table>
            </figure>
            }
            { (!loading && data) && <Confirmation implementAccordion={implementAccordion} />}
        </>
    )
}

function ImplementTableRow({ row, index, implements_selected }){
    return (                    
    <>
        <tr className={`${implements_selected ? "table-secondary" : ""}`}>
            <th>{index + 1}</th>
            <td>{row?.name}</td>
            <td>{row?.make}</td>
            <td>{row?.model}</td>
            <td>{row?.implementType}</td>
            <td>{row?.width}</td>
            <td>
                {ALLOWED_IMPLEMENT_TYPE_IDS.includes(row?.implement_type) && 
                <button 
                    className={`
                        btn btn-outline-primary
                        ${implements_selected ? "disabled" : ""} 
                    `}
                    data-bs-toggle="modal" 
                    data-bs-target={`#implement-${row?.id}`}>
                    +
                </button>}
            </td>
        </tr>
    </>
    )
}

export default ImplementTable