import { TbExplicitOff } from "react-icons/tb";
import { WHITELISTED_USERS, ICICI_USERS } from "../constants";

export function isJIRAAuthenticated(){
    try {
        return JSON.parse(localStorage.getItem("JIRAAuthenticated"))
    } catch (error) {
        console.log(error);
        return false;
    }
}

export function isStaff(){
    const user_roles = localStorage.getItem("user_roles")?.split(',') || [];
    const username = localStorage.getItem("username");
    return ( user_roles.includes("Staff") || WHITELISTED_USERS.includes(username) )
}

export function isElvyraTech(){
    const user_roles = localStorage.getItem("user_roles")?.split(',') || [];
    const username = localStorage.getItem("username");
    return ( user_roles.includes("Elvyra Tech") || WHITELISTED_USERS.includes(username) )
}

export function isSmartImageryGrower(){
    const user_roles = localStorage.getItem("user_roles")?.split(',') || [];
    const username = localStorage.getItem("username");
    return ( user_roles.includes("Smart Imagery Grower") || WHITELISTED_USERS.includes(username) )
}

export function isSmartPro(){
    const user_roles = localStorage.getItem("user_roles")?.split(',') || [];
    const username = localStorage.getItem("username");
    return (
        user_roles.includes("Smart Pro") ||
        user_roles.includes("Ag Retail Rep") ||
        user_roles.includes("Account Manager") ||
        user_roles.includes("Regional Admin") ||
        user_roles.includes("Superuser") ||
        user_roles.includes("Operations Manager") || 
        WHITELISTED_USERS.includes(username)
    )
}

export function isSelfServeGrower(){
    const user_roles = localStorage.getItem("user_roles")?.split(',') || [];
    const username = localStorage.getItem("username");
    return (
        user_roles.includes("Self Serve Grower") ||
        WHITELISTED_USERS.includes(username)
    )
}
export function isNSPSelfServeGrower(){
    const user_roles = localStorage.getItem("user_roles")?.split(',') || [];
    const nsp = JSON.parse(localStorage.getItem('isNSPSelfServeGrower'));
    const username = localStorage.getItem("username");
    return (
        nsp || 
        user_roles.includes("Self Serve Grower") ||
        user_roles.includes("Ag Retail Rep") ||
        WHITELISTED_USERS.includes(username)
    )
}

export function isGrower(){
    const user_roles = localStorage.getItem("user_roles")?.split(',') || [];
    const username = localStorage.getItem("username");
    return (
        user_roles.includes("Grower") ||
        user_roles.includes("Direct to Grower") ||
        user_roles.includes("Self Serve Grower") ||
        user_roles.includes("Ag Retail Rep") ||
        WHITELISTED_USERS.includes(username)
    )
}

export function isIcici(){
    const username = localStorage.getItem("username");
    return (
        ICICI_USERS.includes(username)
    )
}