import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'

import {useDispatch, useSelector} from 'react-redux'
import {requestParametersAddorChange} from '../../../../features/RequestParametersSlice'

function Fields() {

    // Dispatcher
    const dispatch = useDispatch()

    // Global State
    const requestParameters = useSelector(state => state.requestParameters)


    const [ subfields , setSubfields ] = useState([]) // to store fields returned by the api call
    const [ show , setShow ] = useState(false) // to control fields selector visibility

    async function fetchFields(){
      // Fetching fields from API, for the selected farm & season
        setShow(false)
        setSubfields([]);

            try{
                let response = await axios(`/api/getSubfields/?fieldID=${requestParameters.field}`);
                setSubfields(response.data?.map((e) => { return {"value" : e.id , "label": e.label}}));
                setShow(true)
            }
            catch(err){
                console.log('Error while fetching fields...')
            }
    }

    // Fetch Fields, when farm/grower/hub/season changes
    useEffect(() => {
        setSubfields([]);
        fetchFields()
    } , [requestParameters.field, requestParameters.farm , requestParameters.grower, requestParameters.hub, requestParameters.season])


    
    function handleChange(event){
        // adding selected fields to the local state
        dispatch(requestParametersAddorChange( { 'rootAsset': event.value, "subfield": event.value, "asset_label": event.label } ))
    }


    return (
        <div className='my-2'>
            { show && 
                <>
                    <Select placeholder="Select a subfield" options={subfields} onChange={handleChange} />
                </>
            }
        </div>
    )
}


export default Fields
