import React, { useEffect, useState } from 'react'
import {Accordion} from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { requestParametersAddorChange, requestParameterRemove } from '../../../features/RequestParametersSlice'

function ControllerParameters() {

    const { t } = useTranslation()

    const dispatch = useDispatch(); // dispatcher

    //  -> List of the controller parameters & its description
    const controller_parameters_json = [
        {
            parameter: "computeYieldWithoutSpeed",
            description: "when converting from kg/s to kg/ha, do not use speed in calculation process. Force area+time method instead"
        },
        {
            parameter: "fakePlantingPoint",
            description: "Pull points from ELS generated from coverage files."
        },
        {
            parameter: "singleProductOverlap",
            description: "isolate a single product to create overlap layers from"
        },
        {
            parameter: "forceVarietyUpload",
            description: "Force generation of the variety layer when uploading to Farm Command."
        }
    ]

    // checkbox change event handler
    function handleCheckBoxChanges(event){
        const { name , checked } = event.target;

        if(checked){
            dispatch(requestParametersAddorChange({[name]: true})); // adding selected controller parameter to the global state
        } else{
            dispatch(requestParameterRemove(name)) // removing unselected controller parameter to the global state
        }
    }

    return (
        <>
        <p className='fs-5 text-decoration-underline'>{t("Controller specific parameters")}</p>
            <Accordion className=''>
              <Accordion.Item eventKey={"controller-parameters"} onClick={null} className=''>
                <Accordion.Header>{t("Controller Parameters")}</Accordion.Header>
                    <Accordion.Body>
                        {
                          controller_parameters_json.map((data) => {
                            return (
                                <>
                                    <div className="accordion-body">
                                        <div className="d-flex rounded p-2  bg-dark text-white">
                                            <div className="form-check fs-5" >
                                                <input className="form-check-input mx-3" type="checkbox" id="" name={data.parameter} onChange={handleCheckBoxChanges}/>
                                                <label className="form-check-label" for="">{data.parameter}</label>
                                            </div>
                                        </div>
                                        <div className=''>
                                            <p className='mt-2 mb-2'><span className=''><strong>*{t("Description")}*:</strong></span> {t(data.description)}</p>
                                        </div>
                                    </div>
                                </>
                            )
                          })
                        }
                        
                        <FilterCardReader />
                        <FilterProduct />
                        <FilterSourceFilename />

                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

function FilterCardReader(){
    
    const { t } = useTranslation();
    const dispatch = useDispatch() // dispatcher
    const [ cardReaders , setCardReaders ] = useState([]) // keeps track of filtered cardReaders

    useEffect(() => {
        if(cardReaders.length !== 0){
            dispatch(requestParametersAddorChange( {"filterCardReader": [...cardReaders]} )) // adding\changing filterCardReader parameter
        }
        else{
            // deleting filterCardReader parm from requestParameters
            dispatch(requestParameterRemove("filterCardReader")) // removing filterCardReader
        }
    },[cardReaders])

    // Check box event handler
    function handleCheckBoxChange(event){
        if(event.target.checked){
            setCardReaders([''])
        }
        else{
            setCardReaders([])
        }
    }

    // + button event handler
    function handleAddCardReader(event){
        event.preventDefault();
        setCardReaders((prev) => {return [...prev , '']})
    }

    // - button event handler
    function handleRemoveCardReader(event,key){
        event.preventDefault();
        setCardReaders(cardReaders.filter((cardReader,k) => {return k !== key}));
    }

    // input field change event handler
    function handleCardReaderChange(event,key){
        event.preventDefault();
        const {name , value} = event.target;

        setCardReaders((prev) => {
            cardReaders[key] = value
            return [...cardReaders]
        })
    }
    

    return (<>
        <div className="accordion-body">
            <div className="d-flex rounded bg-dark text-white flex-column">
                <div className="form-check fs-5 mt-3" >
                    <input className="form-check-input mx-3" type="checkbox" id="" name="filterCardReader" onChange={handleCheckBoxChange}/>
                    <label className="form-check-label" for="">filterCardReader</label>
                </div>
            
                {
                    cardReaders.map((cardReader , key) => {
                        return (
                            <div>
                                <div className="d-flex flex-row p-2 flex w-50 mx-auto">
                                    <input className="flex-item form-control bg-dark text-white rounded mx-auto" type="text" placeholder='Card Reader' value={cardReader} onChange={(event) => {handleCardReaderChange(event , key)}} required />
                                    <button className='flex-item btn btn-danger mx-3' onClick={(event) => {handleRemoveCardReader(event, key)}}>-</button>
                                </div>
                            </div>
                        )})
                }
            
                {(cardReaders.length > 0) && <><button className='d-flex mx-auto my-2 btn btn-info mx-3' onClick={handleAddCardReader}>+</button></>}

                <p className='mt-4 mb-2 mx-3'><span className=''><strong>*{t("Description")}*:</strong></span> {t("Adds to the ElasticSearch filter to include a specified card reader so that it will not be included in the query and therefore will not be added when processing the task")}.</p>
            </div>
        </div>
    </>)
}

function FilterProduct(){
    
    const { t } = useTranslation();
    const dispatch = useDispatch(); // dispatcher 
    const [ products , setProducts ] = useState([]) // state - Keeps track of filtered products

    useEffect(() => {
        if(products.length !== 0){
            dispatch(requestParametersAddorChange({"filterProduct": [...products]})) // adding\changing filterProduct to global state
        }
        else{
             // deleting filterProduct parm from requestParameters
            dispatch(requestParameterRemove("filterProduct")) // removing filterProduct
        }
    },[products])

    // check box change event handler
    function handleCheckBoxChange(event){
        if(event.target.checked){
            setProducts([''])
        }
        else{
            setProducts([])
        }
    }

    // + button click event handler
    function handleAddProduct(event){
        event.preventDefault();
        setProducts((prev) => {return [...prev , '']})
    }

    // - button click event handler
    function handleRemoveProduct(event,key){
        event.preventDefault();
        setProducts(products.filter((cardReader,k) => {return k !== key}));
    }

    // input field change event handler
    function handleProductChange(event,key){
        event.preventDefault();
        const {name , value} = event.target;

        setProducts((prev) => {
            products[key] = value
            return [...products]
        })
    }
    

    return (<>
        <div className="accordion-body">
            <div className="d-flex rounded bg-dark text-white flex-column">
                <div className="form-check fs-5 mt-3" >
                    <input className="form-check-input mx-3" type="checkbox" id="" name="filterProduct" onChange={handleCheckBoxChange}/>
                    <label className="form-check-label" for="filterProduct">filterProduct</label>
                </div>
            
                {
                    products.map((product , key) => {
                        return (
                            <div>
                                <div className="d-flex flex-row p-2 flex w-50 mx-auto">
                                    <input className="flex-item form-control bg-dark text-white rounded mx-auto" type="text" placeholder='Enter Product' value={product} onChange={(event) => {handleProductChange(event , key)}} required />
                                    <button className='flex-item btn btn-danger mx-3' onClick={(event) => {handleRemoveProduct(event, key)}}>-</button>
                                </div>
                            </div>
                        )})
                }
            
                {(products.length > 0) && <><button className='d-flex mx-auto my-2 btn btn-info mx-3' onClick={handleAddProduct}>+</button></>}

                <p className='mt-4 mb-2 mx-3'><span className=''><strong>*{t("Description")}*:</strong></span> {t("Adds to the ElasticSearch filter to include a specified product so that it will not be included in the query and therefore will not be added when processing the task")}.</p>
            </div>
        </div>
    </>)
}

function FilterSourceFilename(){

    const { t } = useTranslation();
    const dispatch = useDispatch(); // dispatcher
    const [ sourceFilenames , setSourceFilenames ] = useState([]) // keeps track of filtered sourceFilenames

    useEffect(() => {
        if(sourceFilenames.length !== 0){
            dispatch(requestParametersAddorChange({"filterSourceFilename": [...sourceFilenames] })) // adding\changing filterSourceFilename from global state
        }
        else{
            // deleting filterSourceFilename from requestParams
            dispatch(requestParameterRemove("filterSourceFilename")) // removing filterSourceFilename 
        }
    },[sourceFilenames])

    // check box change event handler
    function handleCheckBoxChange(event){
        if(event.target.checked){
            setSourceFilenames([''])
        }
        else{
            setSourceFilenames([])
        }
    }

    // + button click event handler
    function handleAddSourceFilename(event){
        event.preventDefault();
        setSourceFilenames((prev) => {return [...prev , '']})
    }

    // - button click event handler
    function handleRemoveSourceFilename(event,key){
        event.preventDefault();
        setSourceFilenames(sourceFilenames.filter((sourcefile, k) => {return k !== key}));
    }

    // input field change event handler
    function handleSourceFilenameChange(event,key){
        event.preventDefault();
        const {name , value} = event.target;

        setSourceFilenames((prev) => {
            sourceFilenames[key] = value
            return [...sourceFilenames]
        })
    }
    

    return (<>
        <div className="accordion-body">
            <div className="d-flex rounded bg-dark text-white flex-column">
                <div className="form-check fs-5 mt-3" >
                    <input className="form-check-input mx-3" type="checkbox" id="" name="filterSourceFilename" onChange={handleCheckBoxChange} required/>
                    <label className="form-check-label" for="filterSourceFilename">filterSourceFilename</label>
                </div>
            
                {
                    sourceFilenames.map((product , key) => {
                        return (
                            <div>
                                <div className="d-flex flex-row p-2 flex w-50 mx-auto">
                                    <input className="flex-item form-control bg-dark text-white rounded mx-auto" type="text" placeholder='Sourcefile' value={product} onChange={(event) => {handleSourceFilenameChange(event , key)}} />
                                    <button className='flex-item btn btn-danger mx-3' onClick={(event) => {handleRemoveSourceFilename(event, key)}}>-</button>
                                </div>
                            </div>
                        )})
                }
            
                {(sourceFilenames.length > 0) && <><button className='d-flex mx-auto my-2 btn btn-info mx-3' onClick={handleAddSourceFilename}>+</button></>}

                <p className='mt-4 mb-2 mx-3'><span className=''><strong>*{t("Description")}*:</strong></span> {t("Removes sourceFilenames from els fetch. Use to exclude bad or unwanted data")}..</p>
            </div>
        </div>
    </>)
}

export default ControllerParameters