import { useDispatch, useSelector } from "react-redux"
import Seasons from "../../../components/Seasons";
import {Growers, Farms, Fields, Subfields} from './Selectors'
import {GROWER, FARM, FIELD, SUBFIELD} from '../constants'

const RootAsset = () => {

    const dispatch = useDispatch();
    const {asset_category, season, hub, grower, farm, field} = useSelector(state => state.requestParameters)


    return (
        <>
            {   
                asset_category === GROWER && <Growers />
            }

            {
                [FARM, FIELD, SUBFIELD].includes(asset_category) && 
                <>
                    <div className="mb-2 mt-4">
                        <Seasons />
                    </div>
                </>
            }

            {
                asset_category === FARM &&
                <>
                    {(season) && <Growers />}
                    {(season && grower) && <Farms /> }
                </>
            }

            {
                asset_category === FIELD &&
                <>
                    { (season ) && <Growers /> }
                    {(season && grower) && <Farms />}
                    {(season && grower && farm ) && <Fields />}
                </>
            }

            {
                asset_category === SUBFIELD &&
                <>
                    { (season ) && <Growers /> }
                    {(season && grower) && <Farms />}
                    {(season && grower && farm ) && <Fields />}
                    {(season && grower && farm && field ) && <Subfields />}
                </>
            }
        </>
    )
}

export default RootAsset