import FarmCommandLogoGrey from '../../../assets/images/FarmCommandLogoGrey.png'

function Navbar({pageName}) {
    return (
    <>
    <nav className="navbar navbar-expand-sm navbar-white" style={{background: "#02559c"}}>
        <button className="ms-3 btn btn-outline-light" type="button"  data-bs-toggle="offcanvas" href="#offcanvasExample">
            <i className="fa-solid fa-bars"></i>
        </button>

        {/* FARMERSEDGE | DPS GUI */}
        <div className='w-100 position-absolute' style={{zIndex: 1}}>
            <img src={FarmCommandLogoGrey} alt="fc support logo" className="navbar-brand d-none d-lg-block mx-auto" height={25} style={{filter: "brightness(200)"}} />
        </div>
        <span className='text-white' style={{marginLeft: "2.4rem"}}>{pageName}</span>

        <button className='btn btn-outline-light ms-auto me-2' data-bs-toggle="offcanvas" data-bs-target="#offcanvas-end" style={{zIndex: 2}} >
            <i className="fa-solid fa-magnifying-glass"></i>
        </button>
    </nav>
    </>
    )
}

export default Navbar