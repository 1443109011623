import axios from 'axios';
import React, { useEffect, useState } from 'react'
import RequestRow from './RequestRow';
import { useTranslation } from 'react-i18next';

function RequestTable({setLoading}) {

    const { t } = useTranslation();
    const [ data, setData ] = useState([])
    const [ error, setError ] = useState(false)

    async function fetchPDFRequests(){
        try {
            setLoading(true);
            const response = await axios.get("/api/assign_machines/");
            if(response.status === 200){
                setData(response.data)
            }
        } catch (error) {
            setError("Error on fetching your assignments")
        }
        finally{
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchPDFRequests();
    }, [])

    return (
        <>
            <div className="table-responsive">
                <table className="table table-striped table-hover table-borderless table-dark align-middle">
                    <thead className="table-dark">
                        <tr>
                            <th>#</th>
                            <th>{t("Request ID")}</th>
                            <th>{t("Grower")}</th>
                            <th>{t("Date Created")}</th>
                            <th>{t("Runtype")}</th>
                            <th>#</th>
                        </tr>
                        </thead>
                        <tbody className="table-secondary">
                            {
                                data.map((request, index) => {
                                    return (
                                    <>
                                        <RequestRow key={index} index={index} request={request} />
                                    </>
                                    )
                                })
                            }
                        </tbody>
                </table>
            </div>
        </>
    )
}

export default RequestTable