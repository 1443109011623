import {  Routes, Route, useLocation,  } from "react-router-dom"
import FileUploaderPage from "./FileUploaderPage"
import UploadedFiles from "./UploadedFilesPage"


const FileUploader = () => {
    console.log(useLocation().pathname)
    return (
        <>
            <Routes>
                <Route path="/" element={<FileUploaderPage />} />
                <Route path="/your_uploads" element={<UploadedFiles />} />
            </Routes>
        </>
    )
}

export default FileUploader