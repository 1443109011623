import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Collapse, Fade, Spinner } from 'react-bootstrap';
import { FARM_COMMAND_HOST } from '../../../../constants';
import UpIcon from '../../../../assets/icons/UpIcon';
import DownIcon from '../../../../assets/icons/DownIcon';
import 'bootstrap/dist/css/bootstrap.css';
import { useTranslation } from 'react-i18next';

const SingleAssignment = ({ expand, setExpand, runtype, taskIDs}) => {
    
    const { t } = useTranslation();

    const TaskIDs = JSON.parse(taskIDs);
    return (
    <>
        <Collapse in={expand} key={taskIDs} >
            <tr>
                <td className='text-white table-active table-dark' colSpan={6} >
                    <table className="table table-primary mb-0"> 
                    <thead> 
                        <tr> 
                            <th>#</th> 
                            <th>{t("Task ID")}</th>
                            <th>{t("Field")}</th> 
                            <th>{t("Status")}</th> 
                            <th>#</th> 
                        </tr> 
                    </thead> 
                    <tbody> 
                        {
                        TaskIDs?.map((taskID, index) => {
                            return <TaskRows key={index} index={index} runtype={runtype} taskID={taskID} expand={expand} />
                        })}
                    </tbody> 
                </table> 
                </td>
            </tr>
        </Collapse>
    </>
    )
}

function TaskRows({index, runtype, taskID, expand}){
    
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false)

    const {field, status} = data;
    async function fetchTaskData(){
        try {
            setLoading(true);
            const response = await axios.get(`${FARM_COMMAND_HOST}/patricia/${runtype}-task/${taskID}`, {headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`
            }})

            if(response.status === 200){
                setData(response.data)        
            }
            else
                throw new Error(`Failed on fetching task info for ${taskID}`)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(expand)
            fetchTaskData()
    }, [expand])

    return (
        <>
        <tr key={index}> 
            <td>{index + 1}</td> 
            <td>{taskID}</td> 
            <td>{field}</td>
            <td>
                {
                    loading ? <Spinner /> : <span>{JSON.stringify(status)}</span>
                }
            </td> 
            <td className='expand'>
                <button className='btn btn-outline-dark' onClick={ () => {setOpen(open => !open)} }>
                    {open ? <UpIcon /> : <DownIcon />}
                </button>
            </td>
        </tr> 
        <TaskDetail data={data} open={open} setOpen={setOpen} />
        </>
    )
}

function TaskDetail({data, open, setOpen}){
    // JSON PREVIEW
    return (
        <Fade in={open} unmountOnExit={true} >
            <tr onDoubleClick={() => {setOpen(false)}}>
                <td className='cursor-pointer bg-success text-white' colSpan={9} >
                    <pre className='mx-5 p-2'>{JSON.stringify(data, null, 2)}</pre>
                </td>
            </tr>
        </Fade>
    )

}
export default SingleAssignment