import {configureStore} from '@reduxjs/toolkit'
import requestParameters from '../features/RequestParametersSlice'
import ticketParameters from '../features/TicketParametersSlice'
import rxParameters from '../features/RxParametersSlice'
import tempParameters from '../features/TempParametersSlice'
import {request_season_view_reducer, request_default_view_reducer} from '../features/RequestSlice'


export const store = configureStore({
    reducer: {
        "request_season_view": request_season_view_reducer,
        "request_default_view": request_default_view_reducer,
        requestParameters: requestParameters,
        ticketParameters: ticketParameters,
        rxParameters: rxParameters,
        tempParameters : tempParameters
    }
})