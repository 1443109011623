import React, { useContext, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'

//Components 
import Navbar from '../settings/components/Navbar'
import Offcanvas from '../../components/Offcanvas'
import Seasons from '../../components/Seasons'

import GrowerByLabel from '../../components/GrowerByLabel'
import Farms from '../../components/Farms'
import Fields from '../../components/Fields'
import Datatype from '../../components/Datatype'
import Runtype from '../../components/Runtype'
import SelectCanplug from '../../components/create_ticket/SelectCanplug'
import SelectSourcefile from '../../components/create_ticket/SelectSourcefile'
import SelectController from '../../components/create_ticket/SelectController'
import NotableBugs from '../../components/create_ticket/NotableBugs'
import Description from '../../components/create_ticket/Description'
import Submit from '../../components/create_ticket/Submit'
import ScreenShotUpload from '../../components/create_ticket/ScreenShotUpload'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ticketParametersReset } from '../../features/TicketParametersSlice'

function CreateTicket() {
  
  const {t} = useTranslation()
  
  const dispatch = useDispatch(); // dispatcher
  const location = useLocation(); // location -> react-router url tracker
  
  // Global State
  const ticketParameters = useSelector(state => state.ticketParameters);


  // reseting ticketParameters
  useEffect( () => {
    dispatch(ticketParametersReset())
  } , [location]);


  return (
    <>
        <Navbar pageName={t('Create Ticket')} />
        <Offcanvas />
        <div className="row w-100 justify-content-center mx-auto">
        <div className="col-lg-6">
      

        {/* <p className="w-100 flex-item title text-uppercase text-center my-3">{t('Create Ticket')}</p>3 */}
          <div className="justify-content-center mb-3 flex-column my-4">
            <div className="mb-2 my-4">
              <NavLink to="/instructions"><button className='btn btn-outline-primary mt-2'> <i className="fa-solid fa-circle-info mx-2"></i>{t('Instructions')}</button></NavLink>
            </div>
            <form>
              
              <div className="mb-2 my-4">
                <label htmlFor="" className="form-label fs-5">{t('Select Season')}:</label>
                <Seasons />
              </div>


            {
              <div className="mb-2 my-4"  style={{zIndex: 100}}>
                <label htmlFor="" className="form-label fs-5  ">{t('Select Grower')}:</label>
                <GrowerByLabel 
                  style={{zIndex: 100}}  
                  backfill={true}
                  />
              </div> 
            }
            
            {
              (ticketParameters.season_id && ticketParameters.grower_id) && 
              <div className="mb-2 my-4 ">
                <label htmlFor="" className="form-label fs-5  ">{t('Select Farm')}:</label>
                <Farms />
              </div> 
            }


            {
              (ticketParameters.season_id && ticketParameters.farm_id && ticketParameters.grower_id) && 
              <div className="mb-2 my-4 ">
                <label htmlFor="" className="form-label fs-5  ">{t('Select Fields')}:</label>
                <Fields />
              </div> 
            }


            {
              ( ticketParameters.season_id && ticketParameters.grower_id &&  ticketParameters.farm_id &&  ticketParameters.field_id ) && 
              <div className="my-4 ">
              <label htmlFor="" className="form-label fs-5 mb-2  ">{t('Select LayerType')}:</label>
                <Runtype />
              </div>
            }

            {
              ( ticketParameters.season_id && ticketParameters.grower_id &&  ticketParameters.farm_id &&  ticketParameters.field_id ) && 
              <div className="my-4 mb-5"> { /* animate__animated animate__fadeInUp */}
              <label htmlFor="" className="form-label fs-5 mb-2  ">{t('Select DataType')}:</label>
                <Datatype />
              </div>
            }

            {
              ( ticketParameters.season_id && ticketParameters.grower_id ) && 
              <div className="mb-5 animate__animated animate__fadeInUp">
                <SelectCanplug />
              </div>
            }

            
            {
              (ticketParameters.season_id && ticketParameters.grower_id &&  ticketParameters.farm_id &&  ticketParameters.field_id && ticketParameters.runtype && ticketParameters.datatype.toLowerCase() === "controller" ) && 
              <div>
                <SelectController />
              </div>
            }

            {
              ( ticketParameters.season_id && ticketParameters.grower_id ) && 
              <div className="mb-5 animate__animated animate__fadeInUp">
                <SelectSourcefile />
              </div>
            }

            {
              (ticketParameters.season_id && ticketParameters.grower_id &&  ticketParameters.farm_id &&  ticketParameters.field_id && ticketParameters.runtype && ticketParameters.datatype ) && 
              <NotableBugs />
            }

            {
              (ticketParameters.season_id && ticketParameters.grower_id &&  ticketParameters.farm_id &&  ticketParameters.field_id && ticketParameters.runtype && ticketParameters.datatype ) && 
              <ScreenShotUpload />
            }

            {
              (ticketParameters.season_id && ticketParameters.grower_id &&  ticketParameters.farm_id &&  ticketParameters.field_id && ticketParameters.runtype && ticketParameters.datatype ) && 
              <Description />
            }

            {
              (ticketParameters.season_id && ticketParameters.grower_id &&  ticketParameters.farm_id &&  ticketParameters.field_id && ticketParameters.runtype && ticketParameters.datatype && ticketParameters?.description_id ) && 
              <Submit /> 
            }
        </form>
        </div>
        </div>
        </div>
    </>
  )
}

export default CreateTicket
