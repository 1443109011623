import { useContext } from "react"
import { ControllerDataContext } from "../context/ControllerDataContext"

const ClientLabel = ({client_id}) => {
    // context
    const {labelIdMap} =  useContext(ControllerDataContext);
    
    return (
        <span>{Object.keys(labelIdMap).includes(String(client_id)) ? labelIdMap[client_id] : client_id}</span>
    )
}

export default ClientLabel