import { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { ControllerDataContext } from "../context/ControllerDataContext";

export const ALL = "ALL"
export const GCS_RAW = "GCS RAW";
export const JOHN_DEERE_INTEGRATION = "John Deere Integration";
export const FARM_COMMAND = "Farm Command";
export const CNHI_INTEGRATION = "CNHi Integration"

const FLAG_SOURCES = [
    {"label": "All", "value": ALL},
    {"label": "Support Portal Uploads", "value": FARM_COMMAND},
    {"label": "GCS RAW", "value": GCS_RAW},
    {"label": "John Deere Integration", "value": JOHN_DEERE_INTEGRATION},
    {"label": "CNHi Integration", "value": CNHI_INTEGRATION},
]
const DEFAULT_FLAG_SOURCE = {"label": ALL, "value": ALL};

const FlagSource = () => {

    const [ flagSource, setFlagSource ] = useState(DEFAULT_FLAG_SOURCE);
    const { prevData, data, setData, searchType } = useContext(ControllerDataContext)

    useEffect(() => {
        setFlagSource(DEFAULT_FLAG_SOURCE);
        prevData.current = data;
    }, [searchType, data, prevData])

    function handleFlagSourceChange(event){
        console.log(prevData)

        setFlagSource(event);
        const cached = prevData.current
        if (!cached) return;
        if (event.value === ALL){
            setData(cached);
        }
        else{
            setData(() => cached?.filter(row => row?.flag_source === event.value));
        }
    }
    
    
    return (
        <>
            <Select 
                options={FLAG_SOURCES}
                value={flagSource}
                onChange={handleFlagSourceChange}
            />
        </>
    )
}

export default FlagSource