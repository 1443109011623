import {Outlet, Navigate, useLocation } from 'react-router-dom'
import { WHITELISTED_USERS } from '../constants'

function AdminAuthorizedRoutes() {

    const location = useLocation();

    return (
        <>
            { 
                WHITELISTED_USERS.includes(localStorage.getItem("username")) ?
                    <Outlet /> :
                    <Navigate to='/unauthorized' replace={true} state={location} /> 
            }
        </>
    )
}

export default AdminAuthorizedRoutes;