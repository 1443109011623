import React from 'react'
import { Farms, Fields, Growers } from '../../Selectors'
import Seasons from '../../../../../components/Seasons'
import { GROWER, FARM, FIELD, SUBFIELD } from '../../../constants'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

function AssetFilter({urlSearchParams, selectedAssetCategory}) {

    const { t } = useTranslation();
    const {asset_category, season, hub, grower, farm, fields} = useSelector(state => state.requestParameters)
    
    return (
        <>
        { selectedAssetCategory &&
        <div className="mt-4">
            <p className="fs-5 my-1">{t("Asset Filter")}</p>
            {   
                selectedAssetCategory === GROWER && <Growers />
            }

            {
                [FARM, FIELD, SUBFIELD].includes(selectedAssetCategory) && 
                <>
                <div className="mb-2">
                    <Seasons />
                </div>
                </>
            }

            {
                selectedAssetCategory === FARM &&
                <>
                    {(season) && <Growers />}
                    {(season && grower) && <Farms /> }
                </>
            }

            {
                selectedAssetCategory === FIELD &&
                <>
                    { (season) && <Growers /> }
                    {(season && grower) && <Farms />}
                    {(season && grower && farm ) && <Fields />}
                </>
            }
        </div> }
        </> 
    )
}

export default AssetFilter