import React, { useEffect, useState, useRef } from 'react';
import Select, { components } from 'react-select';
import useFetch from '../../../../../hooks/useFetch'
import { useDispatch, useSelector } from 'react-redux';
import { requestParametersAddorChange } from '../../../../../features/RequestParametersSlice';
import { useTranslation } from 'react-i18next';

const Khasra = ({ onChange }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { lgd_code } = useSelector(state => state.requestParameters);
    const [khasras, setKhasras] = useState([]);
    const [khasra, setValues] = useState([]);
    const [show, setShow] = useState(false);
    const { data, loading } = useFetch({
        url: `/reporting/sail/khasra/?lgd_code=${lgd_code}`
    });
    const maxSelections = 15; // Set the maximum number of selections
    const selectRef = useRef(null);

    useEffect(() => {
        if (data) {
            const khasraOptions = data.map(e => ({
                "label": e.kid,
                "value": e.field_ids ? e.field_ids : [],
                "in_fc": e.in_fc === null ? false : e.in_fc,
                "parcel_num": e.parcel_num ? e.parcel_num : [],
            }));
            setKhasras(khasraOptions);
            setShow(true);
        } else {
            setShow(false);
        }
    }, [data]);

    useEffect(() => {
        if (selectRef.current) {
            const input = selectRef.current.inputRef;
            if (input) {
                input.focus();
            }
        }
    }, [khasra]);

    function handleChange(selectedOptions) {
        if (selectedOptions.length <= maxSelections) {
            setValues(selectedOptions); // Update khasra state
            const fieldIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
            const parcel_nums = selectedOptions ? selectedOptions.map(option => option.parcel_num) : [];
            const in_fcs = selectedOptions ? selectedOptions.map(option => option.in_fc) : [];
            dispatch(requestParametersAddorChange({
                field_ids: fieldIds,
                parcel_nums: parcel_nums,
                in_fc: in_fcs,
                parcel_status: selectedOptions.map(option => `${option.parcel_num}:${option.in_fc}`),
            }));
            onChange(selectedOptions ? selectedOptions.map(option => option.value) : null);
        } else {
            // If the number of selected options exceeds the maximum, clear the state
            setValues([]);
            dispatch(requestParametersAddorChange({
                field_ids: [],
                parcel_nums: [],
                in_fc: [],
                parcel_status: [],
            }));
            onChange(null);
        }
    }

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? (state.data.in_fc ? '#4a6c4a' : '#b33a3a') : state.isFocused ? (state.data.in_fc ? '#5e8b5e' : '#d04b4b') : (state.data.in_fc ? '#78b378' : '#e94e4e'),
            ':active': {
                ...provided[':active'],
                backgroundColor: state.isSelected ? (state.data.in_fc ? '#4a6c4a' : '#b33a3a') : (state.data.in_fc ? '#5e8b5e' : '#d04b4b')
            }
        }),
        multiValue: (provided, state) => ({
            ...provided,
            backgroundColor: state.data.in_fc ? '#78b378' : '#e94e4e',
        }),
        multiValueLabel: (provided, state) => ({
            ...provided,
            color: 'white',
        }),
    };

    // Custom MenuList component to show message when max selections are reached
    const MenuList = (props) => (
        <components.MenuList {...props}>
            {khasra.length >= maxSelections ? (
                <div className="max-selections-message">
                    {t('You have reached the maximum number of selections. Please remove a selection to add another.')}
                </div>
            ) : (
                props.children
            )}
        </components.MenuList>
    );

    return (
        <>
            {show && (
                <div>
                    <label className="form-label fs-5">{t('Select Khasra')}:</label>
                    <Select
                        placeholder={t("Select khasras")}
                        options={khasras}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        onChange={handleChange}
                        value={khasra}
                        isMulti
                        isLoading={loading}
                        styles={customStyles} // Apply custom styles
                        components={{ MenuList }} // Use custom MenuList component
                        ref={selectRef} // Set a reference to the Select component
                    />
                    <div className="tooltip-alert px-1">
                        <p>{`${khasra.length} / ${maxSelections} ${t('selected')}`}</p>
                    </div>
                </div>
            )}
            {!show && (
                <label className="form-label fs-5">{t('No Khasras Available Yet Analysis Not Complete')}:</label>
            )}
        </>
    );
}

export default Khasra;
