import React, { useContext, useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap'

import {useDispatch} from 'react-redux'
import {requestParametersAddorChange, requestParameterRemove} from '../../../features/RequestParametersSlice'
import { useTranslation } from 'react-i18next'

function WidthChange() {

    const { t } = useTranslation();

    const dispatch = useDispatch() // dispatcher

    const [ vehicles , setVehicles ] = useState([]) //local state - keeps track of vehicles

    useEffect(() => {
        if(vehicles.length === 0){
            // Deleting equipmentInfo from RequestParameters
            dispatch(requestParameterRemove("equipmentInfo")); // deleting equipmentInfo parameter from global state
        }
        else{
            // Constructing equipmentInfo parameter as per the guideline
            const result = { "equipmentInfo" : {} }
            const tempVehicles = [...vehicles]
            tempVehicles.map((e) => { result.equipmentInfo = {...result.equipmentInfo ,  [e.vehicleName] : {'forced': { 'width_m': { "value" : e.widthValue, "units": e.unit}}}} })
            
            dispatch(requestParametersAddorChange(result)) // adding equipmentInfo parameter to the global state
        }
    } , [vehicles])
    

    // + button click event
    function handleAddMore(event){
        event.preventDefault();
        setVehicles((prev) => {return [...prev, {"vehicleName" : '' , "widthValue" : '' , 'unit' : 'ft' }]})
    }

    // input field change event
    function handleChange(event,id){
        event.preventDefault();
        const {name , value} = event.target;
        
        setVehicles((prev) => {
            const result = vehicles.map((e , k) => {
                if(k === id){
                    return {
                        ...e,
                        [name] : value,
                    }
                }
                else{
                    return e;
                }
            });
            return result;
        });
    }

    // - button click event
    function handleRemoveOne(event , id){
        event.preventDefault();
        setVehicles(vehicles.filter((a , key) => key !== id))
    }


  return (
    <>
        <p className='fs-5 text-decoration-underline'>{t("Change width of the vehicles")}</p>
        <Accordion>
        <Accordion.Item eventKey={"width-change"}>
            <Accordion.Header>{t("Width Change")}</Accordion.Header>
                <Accordion.Body>
                    <div className="accordion-body ">
                        {/* Repeatable */}
                        <>
                            {
                            vehicles.map((e, key) => {
                                return (
                                <div className="d-flex rounded p-2 flex-row">
                                    <input type="text" className='mx-1 flex-item form-control' placeholder='Vehicle Name' name='vehicleName' onChange={(event) => {handleChange(event, key)}}  value={e.vehicleName} required />
                                    <input type="number" className='mx-1 flex-item form-control' placeholder='Width Value' name='widthValue' onChange={(event) => {handleChange(event, key)}}  value={e.widthValue} required />
                                    <select name="unit" id="" className='mx-2 form-control' onChange={(event) => {handleChange(event, key)}} value={e.unit}>
                                        <option value="ft">{t("Feet")}</option>
                                        <option value="m">{t("Meters")}</option>
                                    </select>
                                    <button className='btn btn-danger' onClick={(event) => {handleRemoveOne(event , key)}}>-</button>
                                </div> 
                                )
                            })
                            }
                            <button className="btn btn-outline-primary d-flex mx-auto my-3" onClick={handleAddMore}>{t("Add Vehicle")}</button>
                        </>
                        {/* <Vehicles key={1}/> */}
                        <div className=''>
                            <p className='my-3'><span className=''><strong>*{t("Description")}*:</strong></span> {t("To set default swath width values which will override points with unknown swath width recorded in the ELS data")}.</p>
                        </div>
                    </div>
                </Accordion.Body>
        </Accordion.Item>
    </Accordion>
    <br />
    <br />
    </>
  )
}


export default WidthChange;