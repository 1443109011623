import React, { useEffect, useState } from 'react'
import {Accordion} from 'react-bootstrap'

import {useDispatch} from 'react-redux'
import {requestParametersAddorChange ,requestParameterRemove} from '../../../features/RequestParametersSlice'
import { useTranslation } from 'react-i18next';


function CanplugParameters() {

    const { t } = useTranslation(); 
    // Dispatcher
    const dispatch = useDispatch()
    
    //  -> List of the canplug parameters & its description
    const canplug_params = [
        {
            parameter: "fillInZero",
            description: "Attempts to populate zero values from swath width and velocity values.For swath width it treats the 0 values as if they were null and the default value is used. For velocity values the 0 value is replaced with a calculated value using the distance between points and duration."
        },
        {
            parameter: "forceEstimateWidth",
            description: "stimate the equipment width, even if there is a valid width in the data. For per vehicle granularity, use the same parameter in equipmentInfo width settings."
        },
        {
          parameter: "pullPlantingPoints",
          description: "Pull points from ELS that have an operation type of Planting."
        },
    ]

    function handleCheckBoxChanges(event){
        const { name , checked } = event.target;

        if(checked){
            dispatch(requestParametersAddorChange({[name]: true})) // adding chceked canplug parameter to the global request state
            
        } else{
            // delete key
            dispatch(requestParameterRemove(name)) // removing unchecked canplug parameter from the global state
        }
    }

    return (
        <>
        <p className='fs-5 text-decoration-underline'>{t("Canplug specific parameters")}</p>
            <Accordion className=''>
              <Accordion.Item eventKey={"canplug-parameters"} onClick={null} >
                <Accordion.Header>{t("Canplug Parameters")}</Accordion.Header>
                    <Accordion.Body>
                        {
                            canplug_params.map((data) => {
                            return (
                                <>
                                <div className="accordion-body">
                                    <div className="d-flex rounded p-2 bg-dark text-white">
                                        <div className="form-check fs-5" >
                                            <input className="form-check-input mx-3" type="checkbox" id="" name={data.parameter} onChange={handleCheckBoxChanges}/>
                                            <label className="form-check-label" for="">{data.parameter}</label>
                                        </div>
                                    </div>
                                    <div className=''>
                                        <p className='mt-2 mb-2'><span className=''><strong>*{t("Description")}*:</strong></span> {t(data.description)}</p>
                                    </div>
                                </div>
                                </> 
                            )
                          })
                        }
                        
                        <SelectCanplug />
                        <FilterCanplug />

                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    )
}

function SelectCanplug(){

    const { t } = useTranslation(); 
    const dispatch = useDispatch() // dispatcher
    const [ canplugs , setCanplugs ] = useState([]) // state keeps track of selected canplugs

    useEffect(() => {
        if(canplugs.length !== 0){
            dispatch(requestParametersAddorChange({"selectCanPlug" : [...canplugs]})) // adding\changing selectCanPlug parameter 
        }
        else{
            //delete selectCanPlug
            dispatch(requestParameterRemove("selectCanPlug")) // deleting selectCanPlug parameter
        }
    },[canplugs])

    // Checkbox change handler
    function handleCheckBoxChange(event){
        if(event.target.checked){
            setCanplugs([''])
        }
        else{
            setCanplugs([])
        }
    }

    // + button click handler
    function handleAddCanplug(event){
        event.preventDefault();
        setCanplugs((prev) => {return [...prev , '']})
    }

    // - Button click handler
    function handleRemoveCanplug(event,key){
        event.preventDefault();
        setCanplugs(canplugs.filter((canplug,k) => {return k !== key}));
    }

    // input field value change handler
    function handleCanplugChange(event,key){
        event.preventDefault();
        const {name , value} = event.target;

        setCanplugs((prev) => {
            canplugs[key] = value
            return [...canplugs]
        })
    }
    

    return (<>
        <div className="accordion-body">
            <div className="d-flex rounded bg-dark text-white flex-column">
                <div className="form-check fs-5 mt-3" >
                    <input className="form-check-input mx-3" type="checkbox" id="" name="selectCanPlug" onChange={handleCheckBoxChange}/>
                    <label className="form-check-label" for="">selectCanPlug</label>
                </div>
            
                {
                    canplugs.map((canplug , key) => {
                        return (
                            <div>
                                <div className="d-flex flex-row p-2 flex w-50 mx-auto">
                                    <input className="flex-item form-control bg-dark text-white rounded mx-auto" type="text" placeholder='Canplug ID' value={canplug} onChange={(event) => {handleCanplugChange(event , key)}} required />
                                    <button className='flex-item btn btn-danger mx-3' onClick={(event) => {handleRemoveCanplug(event, key)}}>-</button>
                                </div>
                            </div>
                        )})
                }
            
                {(canplugs.length > 0) && <><button className='d-flex mx-auto my-2 btn btn-info mx-3' onClick={handleAddCanplug}>+</button></>}

                <p className='mt-4 mb-2 mx-3'><span className=''><strong>*{t("Description")}*:</strong></span> {t("Include these CanPlugID's in ELS query - reverse of filterCanPlug")}.</p>
            </div>
        </div>
    </>)
}

function FilterCanplug(){

    const { t } = useTranslation();
    const dispatch = useDispatch() // dispatcher
    const [ canplugs , setCanplugs ] = useState([]) // state keeps track of filtered canplugs

    useEffect(() => {
        if(canplugs.length !== 0){
            dispatch(requestParametersAddorChange({"filterCanPlug" : [...canplugs]}))  // adding\changing selectCanPlug parameter 
        }
        else{
            // delete filterCanPlug
            dispatch(requestParameterRemove("filterCanPlug")) // removing filterCanPlug parameter
        }
    },[canplugs])

    // checkbox change event handler
    function handleCheckBoxChange(event){
        if(event.target.checked){
            setCanplugs([''])
        }
        else{
            setCanplugs([])
        }
    }

    // + button click event handler
    function handleAddCanplug(event){
        event.preventDefault();
        setCanplugs((prev) => {return [...prev , '']})
    }

    // - button click event handler
    function handleRemoveCanplug(event,key){
        event.preventDefault();
        setCanplugs(canplugs.filter((canplug,k) => {return k !== key}));
    }

    // input field change event handler
    function handleCanplugChange(event,key){
        event.preventDefault();
        const {name , value} = event.target;

        setCanplugs((prev) => {
            canplugs[key] = value
            return [...canplugs]
        })
    }
    

  return (<>
      <div className="accordion-body">
          <div className="d-flex rounded bg-dark text-white flex-column">
              <div className="form-check fs-5 mt-3" >
                  <input className="form-check-input mx-3" type="checkbox" id="" name="filterCanPlug" onChange={handleCheckBoxChange}/>
                  <label className="form-check-label" for="">filterCanPlug</label>
              </div>
          
              {
                  canplugs.map((canplug , key) => {
                      return (
                          <div>
                              <div className="d-flex flex-row p-2 flex w-50 mx-auto">
                                  <input className="flex-item form-control bg-dark text-white rounded mx-auto" type="text" placeholder='Canplug ID' value={canplug} onChange={(event) => {handleCanplugChange(event , key)}} required />
                                  <button className='flex-item btn btn-danger mx-3' onClick={(event) => {handleRemoveCanplug(event, key)}}>-</button>
                              </div>
                          </div>
                      )})
              }
          
              {(canplugs.length > 0) && <><button className='d-flex mx-auto my-2 btn btn-info mx-3' onClick={handleAddCanplug}>+</button></>}

              <p className='mt-4 mb-2 mx-3'><span className=''><strong>*{t("Description")}*:</strong></span> {t("Remove these CanPlugID's from ELS query - reverse of selectCanPlug")}..</p>
          </div>
      </div>
  </>)
}

export default CanplugParameters