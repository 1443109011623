import React, { useState } from 'react'
import Select from 'react-select'
import { useDispatch } from 'react-redux'
import { requestParametersAddorChange } from '../../../../features/RequestParametersSlice'
import { useTranslation } from 'react-i18next'



function Runtype() {

    const { t } = useTranslation();
    
    const RUNTYPES = [
        {"label": t("Applied"), "value": "applied"}, {"label": t("Harvest"), "value": "harvest"}
    ]

    const [selectedRuntype, setSelectedRuntype] = useState(RUNTYPES[0])
    const dispatch = useDispatch()

    // Event Handlers
    function handleRequestTypeChange(event){
        setSelectedRuntype(event);
        dispatch(requestParametersAddorChange({ "runtype": event.value }))
    }

    return (
        <>
            <Select 
                options={RUNTYPES} placeholder={t("Select a Runtype")}
                value={selectedRuntype}
                onChange={handleRequestTypeChange}
            />
        </>
    )
}

export default Runtype