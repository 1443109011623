import { formatByteToAppropriateSize } from "../../../utils/helpers";

// Component for display rows of the sourcefile table.
export default function TableRow({index, sourcefile, selectedSourcefileIDs, handleCheckBoxClick}){
    const { id, agi_status , filename, flag_source, file_size_raw} = sourcefile;
    return (
        <>
            <tr>
                <th scope="row"> {index + 1} </th>
                <td title={filename}>{filename.substring(0, 25)}</td>
                <td>{agi_status}</td>
                <td>{flag_source}</td>
                <td>{formatByteToAppropriateSize(file_size_raw)}</td>
                <th scope='row'><input type="checkbox" name="" id="" className='form-check-input' value={id} onChange={(event) => {handleCheckBoxClick(event, id)}} checked={ selectedSourcefileIDs.includes(id) ? 'checked' : ''} style={{padding: "12px"}} /></th>
            </tr>
        </>
    )
}