import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { changeAssignedProduct, deleteAssignedProduct, pushToby_MD5_and_productList } from '../../../../features/RequestSlice';
import { tempParametersAddorPushToObject, tempParametersDeleteKeyFromObject  } from '../../../../features/TempParametersSlice';


function TableRow({season_index, id, product, position}) {
    
    const dispatch = useDispatch(); // dispatcher
    const tempParameters = useSelector(state => state.tempParameters); // global state
    const request_default_view = useSelector(state => state.request_default_view);    
    // Values from Global State
    let sourcefile_id = tempParameters.sourcefile_id;
    let chemical_supplies = tempParameters.chemical_supplies;
    let fertilizer_supplies = tempParameters.fertilizer_supplies;
    let seed_supplies = tempParameters.seed_supplies;
    const default_structure = request_default_view[sourcefile_id]?.default?.length;

    const [ supply, set_supply ] = useState([]) // list of supplies either one [chemical, seed, fertilizer]

    const [ selected_supply_type, set_selected_supply_type ]  = useState(null); // To Track selected supply type
    const [ selected_supply, set_selected_supply ] = useState(null); // To Track Selected Supply
    
    const supply_types  = [
        {'value' : 'Chemical & Fertilizer Supply', 'label': 'Chemical & Fertilizer Supply'},
        {'value' : 'Chemical Supply', 'label': 'Chemical Supply'},
        {'value' : 'Fertilizer Supply', 'label': 'Fertilizer Supply'},
        {'value' : 'Seed Supply', 'label': 'Seed Supply'},
    ] // supply types

    useEffect(() => {
        dispatch(
            pushToby_MD5_and_productList({
                sourcefile_id, 
                index: season_index, 
                by_MD5_data: {},
                productList_data: { }
            })
        )
        // Supply Type filter. // Setting default supply type based on Operation
        if(product?.Operation?.toLowerCase() === 'seeding'){
            set_selected_supply_type({'value' : 'Seed Supply', 'label': 'Seed Supply'}); set_supply(seed_supplies)
        }
        else if( product?.Operation?.toLowerCase() === 'application' || product?.Operation?.toLowerCase() === 'asapplied' || product?.Operation?.toLowerCase() === 'plant' || product?.Operation?.toLowerCase() === 'planting' ){
            set_selected_supply_type({'value' : 'Chemical & Fertilizer Supply', 'label': 'Chemical & Fertilizer Supply'}); set_supply([...chemical_supplies, ...fertilizer_supplies])
        }
    }, [])


    // Called when request_default_view changes
    useEffect(() => {
        // Assigning Previously assigned 
        if(tempParameters['Current Sourcefile Data'])
        {
            const filehash_products = Object.keys(tempParameters['Current Sourcefile Data']);
            const current_filehash_product = `${product.FileHash}-${product.Product}`;

            if(filehash_products.includes(current_filehash_product)){
                const supply = tempParameters['Current Sourcefile Data'][current_filehash_product]
                document.getElementById(`row-sv-${position}`).classList.add("prev-assinged");
                set_selected_supply( {"label": supply, "value" : supply} );

                dispatch(changeAssignedProduct({
                    sourcefile_id, 
                    index: season_index, key: id, position: position, 
                    product: product?.Product ,
                    current_filehash: product?.FileHash,
                    assigned_product: supply
                }))
            }
        }
    }, [default_structure])


    // EVENT HANDLER
    // supply type change event handler
    function supplyTypeChange(event){
        set_selected_supply(null);
        set_selected_supply_type(event);

        // remove bgcolor of the row
        document.getElementById(`row-sv-${position}`).classList.remove("assigned"); document.getElementById(`row-sv-${position}`).classList.remove("prev-assinged");

        // Removing assigned product from the global state
        dispatch(deleteAssignedProduct({
            sourcefile_id, 
            index: season_index, key: id, position: position, 
            product: product?.Product 
        }))

        if(event.value === 'Chemical & Fertilizer Supply'){
            set_supply([...chemical_supplies, ...fertilizer_supplies])
        }
        else if(event.value === 'Chemical Supply'){
            set_supply(chemical_supplies)
        }
        else if(event.value === 'Fertilizer Supply'){
            set_supply(fertilizer_supplies)
        }
        else if(event.value === 'Seed Supply'){
            set_supply(seed_supplies)
        }
    }
    
    // EVENT HANDLER
    // supply change event handler  
    // [x] CRUCIAL [x]
    function supplyChange(event){
        set_selected_supply(event); // Update Local State

        // Changing Color of the row if a supply is selected
        document.getElementById(`row-sv-${position}`).classList.remove("prev-assinged");
        document.getElementById(`row-sv-${position}`).classList.add("assigned");

        // Changing assigned product from the global state
        dispatch(changeAssignedProduct({
            sourcefile_id, 
            index: season_index, key: id, position: position, 
            product: product?.Product ,
            current_filehash: product?.FileHash,
            assigned_product: event.label
        }))

        // Storing on Temp, which will help to update detailed view
        dispatch(tempParametersAddorPushToObject({[season_index]: {[product?.Product]: event.label}}))
    }


    return (
    <>
        <tr className="bg-light" id={`row-sv-${position}`}>
            <td>{id + 1}</td>
            <td>{product?.Crop}</td>
            <td>{product?.Product}</td>
            <td>
                <Select 
                    name="supply_type"
                    placeholder='Select Supply Type' 
                    value={selected_supply_type}
                    options={supply_types} 
                    onChange={supplyTypeChange} menuPortalTarget={document.body} /> 
            </td>
            
            <td>
                <Select 
                    name="supply"
                    placeholder='Select Supply' 
                    value={selected_supply}
                    options={supply} 
                    onChange={(event) => {supplyChange(event)}}  menuPortalTarget={document.body} />
            </td>

            <td>{product?.Operation}</td>
            <td>{product?.Field}</td>
            <td>{product?.Grower}</td>
        </tr>
    </>
    )
}

export default TableRow