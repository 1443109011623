export const SP_PAGE_INSTRUCTIONS_ROOT_PAGE = "7613874384"
export const BR_SP_PAGE_INSTRUCTIONS_ROOT_PAGE = "7725350917"
export const HOW_TO_UPLOAD_MACHINE_DATA_ROOT_PAGE = "7612956687"
export const PDF_INSTRUCTIONS_ROOT_PAGE = "7613579413"

export const ELVYRA_TECH_RESTRICTED = ["create-task", "created-tasks", "fc-lite"];
export const JIRA_AUTH_RESTRICTED = ["create-support-ticket"];
export const STAFF_AUTH_RESTRICTED = ["prescription-file-generator"];

export const ICON_CLASS_MAPPING = {
    "home page": `fa-solid fa-house`,
    "submit patricia tasks": `fa-solid fa-arrow-rotate-right`,
    "your patricia tasks": `fa-solid fa-bars-progress`,
    "create support ticket": `fa-solid fa-envelope-open-text`,
    "fc lite": `fa-solid fa-map`,
    "uploaded controller files": `fa-solid fa-folder`,
    "add machines to grower": `fa-solid fa-code-pull-request`,
    "prescription file generator": 'fa-solid fa-recycle',
    "asset file uploader": 'fa-solid fa-file-import',

    // pt_BR
    "página inicial": `fa-solid fa-house`,
    "criar tarefas": `fa-solid fa-arrow-rotate-right`,
    "suas tarefas": `fa-solid fa-bars-progress`,
    "criar ticket de suporte do dps": `fa-solid fa-envelope-open-text`,
    "br_fc lite": `fa-solid fa-map`,
    "arquivos de controlador carregados": `fa-solid fa-folder`,
    "adicionar máquinas ao produtor": `fa-solid fa-code-pull-request`,
    "gerador de arquivo de prescrição": 'fa-solid fa-recycle',
    "carregador de arquivos dos ativos": 'fa-solid fa-file-import',
}