import { Fade } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import { CUSTOMER_REQUEST } from '../../constants'

const SRInfo = ({ 
    open, setOpen, data
}) => {

    const { t } = useTranslation();
    const { customer_request, grower_label, asset_ids, season_id, task_ids } = data;
    return (
        <>
            <Fade in={open} unmountOnExit={true}>
                <tr className="text-start  table-dark fs-5" onDoubleClick={() => {setOpen(false)}}>
                    <td colSpan={7}>
                        <div className="my-3 p-3">
                        <pre><code>{JSON.stringify(data, null, 2)}</code></pre> 
                        </div>
                    </td>
                </tr>
            </Fade>
        </>
    )
}

export default SRInfo