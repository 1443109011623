import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
// import Maps from './Maps'

function FieldNames({field_id}){
    
    const dispatch = useDispatch();
    const requestParameters = useSelector(state => state.requestParameters);
    const { field_ids, current_field_id } = requestParameters;
    
    const [loading, set_loading] = useState(true);
    const [field_name, set_field_name] = useState("")

    // Helpers
    async function fectchFieldName(field_id){
        try {
            const response = await axios.get(`${localStorage.getItem("FARMCOMMAND_HOST")}/asset/${field_id}`, {headers: {"Authorization": `Token ${localStorage.getItem("token")}`}});
            if(response.data?.category === 'Field'){
                set_field_name(`<p class='text-center mt-2 fs-5'>Maps for the <span class='text-primary'>Field</span>: <span class='text-success text-decoration-underline'>${response.data?.label}</span></p>`);
            } else {
                document.getElementById(`btn-${field_id}`).title = `${field_id} is a ${response.data?.category}`;
                document.getElementById(`btn-${field_id}`).classList.add("disabled");
                
                set_field_name(`<p class='text-center mt-2 fs-5'><span class='text-danger text-decoration-underline'>${response.data?.label}</span> is a ${response.data?.category}</p>`);
            }
            set_loading(false);
        } catch (error) {
            document.getElementById(`btn-${field_id}`).title = `${field_id} is invalid`;
            set_field_name(`<p class='text-center mt-2 fs-5 text-primary'>${field_id} <span class='text-danger text-decoration-underline'>is invalid</span></p>`);
            console.log(error);
            set_loading(false);
        }
    }

    // Side Effects
    useEffect(() => {
        document.querySelectorAll(".field-name").forEach(e => e.classList.add("d-none"));
        fectchFieldName(field_id);
    }, [field_ids])

    useEffect(() => {
        // Field Names
        document.querySelectorAll(".field-name").forEach(e => e.classList.add("d-none"));
        document.getElementById(`field-name-${current_field_id}`)?.classList?.remove("d-none");
    }, [current_field_id])

    return (
        <>
            <div className='field-name d-none' id={`field-name-${field_id}`}>
                {
                    loading
                    ? <Spinner /> 
                    : <span dangerouslySetInnerHTML={{__html: field_name}}/>
                }
            </div>
        </>
    )
}
export default FieldNames