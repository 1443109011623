import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { ControllerDataContext } from '../context/ControllerDataContext';
import { useTranslation } from 'react-i18next';
import TableHeader from './TableHeader';
import TableRow from './TableRow'
import FilterButton from './FilterButton'
import { CODA_HOST } from '../../../constants';


function DataTable() {

  const {t} = useTranslation()

  // Constants
  const client_id = localStorage.getItem('client_id');
  const username = localStorage.getItem('username');
  const user_roles = localStorage.getItem("user_roles")?.split(",")
  
  // Context
  const { data, setData, prevData, loading, setLoading, message, setMessage, next , setNext, prev, setPrev, setSearchType } = useContext(ControllerDataContext)

  // States
  const [refresh, setRefresh] = useState(false);

  const fetchData = async (url) => {
    setLoading(true);
    try {
      const response = await axios.post("/coda/make_coda_call/", {"url": url})
      let results = [];
      if(response.data?.results){
        results = response.data.results;
      }
      setData(results);
      prevData.current = results;
      setSearchType("default");
      setNext(response.data?.next);
      setPrev(response.data?.previous);  
      setMessage("Controller Files You Have Uploaded")
    } catch (error) {
      console.log(`Error on fetching data from ${url}`, error)
    }
    finally{
      setLoading(false);
    }
  }
  // Side Effect
  // Refresh Datatable with original data
  useEffect(() => {
    try {
      fetchData(`${CODA_HOST}/sourcefile/?client_id=${client_id}`); // original
    } catch (error) {
      console.log(error)
    }
  } , [refresh, client_id])


  return (
    <>
        {/* Message - changes based on search */}
        <p className='fs-4 text-center mt-4 mb-2'>{t(message)}</p>
        
        {/* User ID, Prev Next Refresh Buttons */}
        <div className="d-flex p-3">
          {
            message === "Controller Files You Have Uploaded" && 
            <p className='fs-5 my-auto'>
              {username ? `${t("Username")}: ${username}` : `User ID: ${client_id}`}
            </p> 
          } 
          
          <div className="ms-auto">
            {prev && <button className="mx-2 btn btn-primary" onClick={() => {fetchData(prev)}} >{t("Prev")}</button>}
            {next && <button className="mx-2 btn btn-success" onClick={() => {fetchData(next)}} >{t("Next")}</button>}
            <button title='Refresh' className="btn btn-secondary" onClick={() => { setRefresh(refresh => !refresh) } }> <i className="fa-solid fa-rotate-right"></i> </button>
            <FilterButton />
          </div>
        </div>

        {/* Data Table */}
        {
          loading ?
            <>
              <div className="d-flex vh-100 align-items-center justify-content-center">
                <Spinner />
              </div>
            </>
            : 
            <figure className='wrapper' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto"}}>
                <table className="table table-hover">
                    <TableHeader />

                    <tbody>
                        {
                          data.map(( sourcefile , index ) => {
                                return (<>
                                    <TableRow data={sourcefile} key={index} id={index} />
                                </>)
                            })
                        }
                    </tbody>
                </table>
            </figure>
        }
    </>
  )
}

export default DataTable