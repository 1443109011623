import React, { useEffect, useState } from 'react'
import { Fade } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Select from 'react-select'

export default function ProductRow({index, product, product_defaults, set_product_defaults}){

    const { t } = useTranslation();

    const [openApplication, set_openApplication] = useState(false); // visibility of 
    const [openProductDefaults, set_openProductDefaults] = useState(false);

    const [isChecked, setIsChecked] = useState(false); // checkbox
    const [product_default, set_product_default] = useState({'defaultRate': '', 'name': product?.name, 'outOfFieldRate': "", 'uom': ''})
    
    const [uom, setUom] = useState({});  // to controll - react-select selected value 
    
    
    useEffect(() => {
        if (product.state === "Liquid"){
            setUom({"label": "gal/ac", "value": "gal/ac"})
            set_product_default(prev => ({...prev, 'uom': "gal/ac"}))
        }
        else {
            setUom({"label": "lb/ac", "value": "lb/ac"})
            set_product_default(prev => ({...prev, 'uom': "lb/ac"}))
        }
    }, []);


    useEffect(() => {
        if(isChecked){
            const updated_product_defaults = product_defaults.map((p, i) => {
                if(i === index) {
                    return product_default;
                } else {
                    return p;
                }
            })
            console.log(updated_product_defaults)
            set_product_defaults(updated_product_defaults);
        } 

    }, [ isChecked, product_default])
    
    // Event Handlers
    function handleCheckBoxChange(event){
        if(event.target.checked){
            setIsChecked(true);
        } else {
            setIsChecked(false);
            const updated_product_defaults = product_defaults.map((p , i) => {
                if( i === index ) {
                    return {};
                } else {
                    return p;
                }
            })
            set_product_defaults(updated_product_defaults);
        }
    }

    function showProductDefaults(event){
        set_openProductDefaults(prev => !prev)
    }

    return (
        <>
            <tr style={{cursor: "pointer"}}>
                <td onClick={showProductDefaults} className='text-center'>{product?.name}</td>
                <td onClick={showProductDefaults} className='text-center'>{product?.formula}</td>
                <td className=''>
                    <div className="d-flex align-items-evenly">
                        <span className='ms-auto'>{product?.applications?.length}</span>
                        <button className='btn btn-outline-dark arrow ms-auto cursor-pointer' onClick={(e) => {set_openApplication(prev => !prev); e.target.classList.toggle("active")}}>{openApplication ? '▲' : '▼'}</button>                        
                    </div>
                    
                </td>
            </tr>

            <Fade in={openProductDefaults} unmountOnExit={true}>
                <tr className='table-danger'>
                <td colSpan={3}>
                    <div className="d-flex gap-2 align-items-center justify-content-center">
                        <input className="p-2 form-check-input" type="checkbox" name="select" id="" onChange={handleCheckBoxChange} checked={isChecked} />
                        
                        <textarea className="form-control" type="text" style={{maxWidth: "200px"}}
                            onChange={(event) => {set_product_default( prev => ({...prev, name: event.target.value}) )}} value={product_default?.name} 
                            name="name" placeholder={t("name")} title='name' autoComplete='off' required />
                        
                        <input className="form-control w-25" type="number" 
                            onChange={event => {set_product_default(prev => ({...prev, defaultRate: event.target.value}))}} value={product_default?.defaultRate} 
                            name="rate" placeholder={t("default rate")} title={t("default rate")} autoComplete='off' required />
                        
                        <input className="form-control w-25" type="number"
                            onChange={(event) => {set_product_default(prev => ({...prev, outOfFieldRate: event.target.value}) )}} value={product_default?.outOfFieldRate} autoComplete='off'
                            name="outOfFieldRate" placeholder={t("outOfFieldRate")} title='outOfFieldRate' />
                        
                        <div title='UOM'>
                            <Select
                                options={[{label: "lb/ac", value: "lb/ac"}, {label: "gal/ac", value: "gal/ac"}]} value={uom}
                                onChange={(event) => {setUom(event); set_product_default(prev => ({...prev, uom: event.value}) )}}
                                />
                        </div>
                    </div>
                </td>
                </tr>
            </Fade>

            {/* Applications */}
            <Fade in={openApplication} unmountOnExit={true}>
                <>
                    <tr className='text-center table-info'>
                        <th>{t("Subfield Name")}</th>
                        <th>{t("Scenario")}</th>
                        <th>{t("Application Time")}</th>
                    </tr>
                    
                    {
                        product?.applications?.map( application => {
                            return ( 
                                <tr className='table-warning text-center'>
                                    <td>{application?.subfield_name}</td>
                                    <td>{application?.scenario_name}</td>
                                    <td>{`App ${application?.application_number}: ${application?.application_time}`}</td>
                                </tr>
                            )
                        })
                    }
                    
                </>
            </Fade>
        </>
    )
}
