import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import ProductRow from './ProductRow'

import {ZONE_CALC_HOST} from '../../../constants'
import { rxParametersAddorChange } from '../../../features/RxParametersSlice';
import { useTranslation } from 'react-i18next';

function Products({farms}) {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    // global state
    const rxParameters = useSelector(state => state.rxParameters)
    
    // local states
    const [ products , set_products] = useState([]);
    const [ loading, set_loading ] = useState(true);
    const [product_defaults, set_product_defaults] = useState([])


    async function fetchFertilizers(season_id, farm_ids){
        set_products([])
        let url = new URL( ZONE_CALC_HOST + "/api/v1/application/products/");
        url.searchParams.append("is_active", true);
        url.searchParams.append("season_id", season_id); // Will Change
        
        farm_ids?.forEach(farm_id => {
            url.searchParams.append("farm_id", farm_id);
        })
        
        try {
            set_loading(true);
            console.log(url.href)
            fetch(url.href.toString(), {method: "GET", headers: {"Authorization": `Token ${localStorage.getItem('token')}`}})
            .then(response => response.json()) 
            .then(json => {
                set_loading(false);
                set_products(json);
            })
            .catch(err => {throw new Error(err)}); 
        } catch (error) {
            console.log(error);
            set_loading(false);
        }
    }

    useEffect(() => {
        dispatch(rxParametersAddorChange({"input_application_number": []}))
        if(rxParameters.season && rxParameters.farms && rxParameters?.farms?.length > 0){
            fetchFertilizers(rxParameters.season, rxParameters.farms)
        }
    }, [farms])


    useEffect(() => {
        if(products.length > 0){
            let application_numbers = new Set();
            products.forEach(product => {
                set_product_defaults(prev => ([...prev, {}]))
                product?.applications?.forEach(application => {
                    application_numbers.add(application.application_number);
                })
            });
            dispatch(rxParametersAddorChange({'input_application_number': Array.from(application_numbers)}))
        }
    }, [products])


    useEffect(() => {
        dispatch(rxParametersAddorChange({"product_defaults": product_defaults}))
    }, [product_defaults])


    return (
        <>
            <div className="mx-3 mb-2 mt-5">
                <label htmlFor="" className="my-1 form-label fs-5">{t('Products')}:</label>
                <div className="table-responsive">
                    <table className="table table-secondary table-striped table-hover table-bordered">
                        <thead className='table-dark text-center'>
                            <tr>
                                <td>{t("Fertilizer Name")}</td>
                                <td>{t("Formula")}</td>
                                <td>{t("Instances")}</td>
                            </tr>
                        </thead>


                        <tbody>
                            {
                                products.map( (product, index) => (
                                    <ProductRow key={index} index={index} product={product} product_defaults={product_defaults} set_product_defaults={set_product_defaults} />
                                ))
                            }
                        </tbody>
                    </table>
                </div>

                {
                    loading && <div className="d-flex align-item-center justify-content-center"><Spinner className='' /></div>
                }


            </div>
        </>
    )
    
}

export default Products