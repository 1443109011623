import React from 'react'
import { useTranslation } from 'react-i18next';
import useFetch from '../../../hooks/useFetch';
import { Spinner } from 'react-bootstrap';
import { JIRA_BASE_URL } from '../../../constants';
import { format } from 'date-fns';

const TicketTable = () => {

    const { t } = useTranslation();

    const {
        data = [],
        error,
        loading
    } = useFetch({url: "/api/dps_tickets/"})

    if(error){
        return (
            <>
                <p>{t("Unable to fetch your recent tickets")}</p>
            </>
        )
    }
    
    return (
        <>
            {
            loading ? 
                <div className="d-flex align-items-center justify-content-center min-vh-100">
                    <Spinner /> 
                </div>
            : 
            <>
            <div className="table-responsive justify-content-center">
                <table className="table table-striped table-hover table-borderless align-middle widget-table">
                    <thead className="table-dark">
                        <tr>
                            <th>{t("Ticket ID")}</th>
                            <th>{t("Summary")}</th>
                            <th>{t("Created On")}</th>
                            <th>{t("Assignee")}</th>
                            <th>{t("Status")}</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        
                        {
                            data.map((row, index) => {
                                return <TableRow row={row} key={index} index={index} />
                            })
                        }
                    </tbody>
                </table>
            </div>
            </>
            }
        </>
    )
}

function TableRow({row, index}){
    
    const {
        key, summary, created, status, assignee
    } = row;
    
    return (
        <>
        <tr className="table-secondary">

            <td className="py-3">
                <a href={`${JIRA_BASE_URL}/servicedesk/customer/portal/7/${key}`}>
                    {key}
                </a>
            </td>
            
            <td>
                <span title={summary}>
                    {summary && summary.replace("Portal Ticket Submission: ", "").substring(0, 50)}
                </span>
            </td>

            <td>
                <span>
                    { created && format(new Date(created), "dd-MMM-yy") }
                </span>
            </td>

            <td>
                <span> { assignee } </span>
            </td>
            
            <td>
            <span className={`px-3 py-2 badge ${['Done', 'Awaiting Approval'].includes(status) ? "text-bg-success" : "text-bg-warning"}`}>
                    {status}
                </span>
            </td>
        </tr>
        </>
    )
}

export default TicketTable
