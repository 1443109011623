import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { useTranslation } from "react-i18next"

function Growers({visibility, setGrowerId}) {
    
    const { t } = useTranslation()
    const [growers , setGrowers] = useState([]) // to store growers returned from api
    const [selected_grower, set_selected_grower] = useState(null) // to keep track of currently selected grower
    const [inputChangeOn, setInputChangeOn] = useState(true);

    async function fetchGrowers(label){
        setGrowers([])
        try{
            let response = await axios(`/api/getGrowersByLabel/?label=${label}`);
            setGrowers(response.data.map((e) => { return {"value" : e.id , "label" : e.label}}));
        }
        catch(err){
            console.log(err)
            console.log('Error while fetching growers...')
        }
    }

    function debounce(func, delay) {
        let timerId;
    
        return function (...args) {
            clearTimeout(timerId);
    
            timerId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    }
    
    const debouncedSearch = debounce(fetchGrowers, 400); 

    // Event Handlers
    function handleChange(event){
        // updating selected_grower local state
        set_selected_grower(event);
    }
    
    function handleInputChange(event){
        // Fetch Growers only if the input string is > 2
        if(event.length > 2){
            debouncedSearch(event)
        }
    }

    // # Side Effects
    // Check if growers already exist in LS
    useEffect(() => {
        const growers_stored = JSON.parse(localStorage.getItem("growers"));
        console.log(growers_stored)
        if(growers_stored){
            let parsed_growers = growers_stored?.map(grower => ({"value" : grower.id , "label" : grower.label}))
            setGrowers(parsed_growers)
            setInputChangeOn(false);

            if(growers_stored?.length === 1){
                set_selected_grower(parsed_growers[0])
            }
        }
    }, [])

    useEffect(() => {
        // Adding grower_id to global store
        if(selected_grower === null){
            setGrowerId(null)
        }
        else{
            setGrowerId(selected_grower?.value)
        }
    }, [selected_grower])

    useEffect(() => {
        set_selected_grower(null)
    }, [visibility])

    return (
        <div className='my-3'>
            <Select placeholder={t("Type here to search for Grower")}
                options={growers} value={selected_grower} onChange={handleChange} 
                onInputChange={inputChangeOn ? handleInputChange: () => {}} 
                isClearable
                />
        </div>
    )
}

export default Growers