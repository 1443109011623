import { useContext, useEffect } from "react";
import RecentTickets from "./RecentTickets"
import RecentUploads from "./RecentUploads"
import { DashboardContext } from "../../context/DashboardContext";
import {
    RECENT_TICKETS ,RECENT_CONTROLLER_UPLOADS, RECENT_SERVICE_REQUESTS
} from '../../constants'
import RecentSRs from "./RecentSRs";
import { isGrower, isStaff } from 'utils/authHelpers'

const WidgetLayout = () => {
    
    const isJiraAuthenticated = JSON.parse(localStorage.getItem("JIRAAuthenticated"));
    const { userWidgets } = useContext(DashboardContext)
    
    return (
    <>
        <div className="widgets-reporting">
            {
                (userWidgets.flatMap(w => w.name)?.includes(RECENT_TICKETS) ) && <RecentTickets />
            }

            {
                (userWidgets.flatMap(w => w.name)?.includes(RECENT_CONTROLLER_UPLOADS)) && <RecentUploads />
            }

            { 
                (userWidgets.flatMap(w => w.name)?.includes(RECENT_SERVICE_REQUESTS)) && <RecentSRs />
            }
        </div>
    </>
    )
}

export default WidgetLayout