import React, { useEffect } from 'react'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux'
import Navbar from '../../settings/components/Navbar'

import {
  OffcanvasEnd, 
  DataTable, 
  DataTableForGrower, 
  OffcanvasForGrower, 
  ProcessingRequests
} from './components'
import Offcanvas from '../../../components/Offcanvas'
import { requestParametersReset } from '../../../features/RequestParametersSlice'
import ControllerDataProvider from './context/PrescriptionDataContext';
import ChangeControllerType from './components/ChangeControllerType';
import { useTranslation } from 'react-i18next';

function GeneratedPrescriptionFiles() {

  const { t } = useTranslation();
  
  const user_roles = localStorage.getItem("user_roles");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestParametersReset())
  }, [])

  return (
    <>
        <Navbar pageName={t("Generated Prescription Files")} page="Generated Prescription Files" />
        <Offcanvas />

        <div className='page-content px-2'>
          <ControllerDataProvider>
            <ProcessingRequests />
            {
              user_roles?.includes("Grower") ?
                <>
                  <OffcanvasForGrower />
                  <DataTableForGrower />
                </>
                  :
                <>
                  <OffcanvasEnd />
                  <DataTable />
                </>
            }
          <ChangeControllerType />
          </ControllerDataProvider>
        </div>

        <ToastContainer 
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark" />
    </>
  )
}

export default GeneratedPrescriptionFiles