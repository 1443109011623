import { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import axios from 'axios'
import PDFAccordion from '../accordion/PDFAccordion'
import HowToUploadMachineData from './HowToUploadMachineData'
import {slugify} from '../../../../utils/slugify'
import { PDF_INSTRUCTIONS_ROOT_PAGE } from '../../constants'

const UploadControllerHelp = () => {
  
  const [ loading, setLoading ] = useState(false)
  const [ PDF_PAGES, SET_PDF_PAGES ] = useState([]);

  async function fetchPDFChildPages(){
    setLoading(true);
    try {
      const response = await axios.get(`/api/getConfluenceDescendant/?pageID=${PDF_INSTRUCTIONS_ROOT_PAGE}`)
      let content = response.data?.content;
      SET_PDF_PAGES(content.map(e => ({ "headerText": e?.title, "slug": slugify(e?.title), "id": e?.id })));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchPDFChildPages()
  }, [])

  return (
    <>
    <TitleText text={"Controller Upload Instructions"} icon={<i className="fa-solid fa-upload"></i>} />
    <div>
        
        <HowToUploadMachineData />
        
        {loading && <Spinner />}

        {
            PDF_PAGES.map((page) => {
                return <PDFAccordion 
                  headerText={page?.headerText}
                  pageID={page?.id}
                  slug={page?.slug}
              />
            })
        }

    </div>
</>
  )
}

export const TitleText = ({text, icon}) => {
  return <p className='fs-4 fw-bold'>{text} <span className="mx-2">{icon}</span> </p>
}

export default UploadControllerHelp