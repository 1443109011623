import React, { useContext, useState } from 'react'
import AuthContext from '../context/AuthProvider'
import FarmCommandLogoGrey from '../assets/images/FarmCommandLogoGrey.png'
import {logOut} from '../utils/LogOut'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

function Navbar({pageName}) {
  const { t } = useTranslation();
  const  {authStatus , setAuthStatus} = useContext(AuthContext)

  const [showOptions, setShowOptions] = useState(false);
  
  return (
    <>
        <nav className="navbar navbar-expand-sm navbar-white" style={{background: "#02559c", zIndex: 2}}>

            <button className="ms-3 btn btn-outline-light py-2" type="button"  data-bs-toggle="offcanvas" href="#offcanvasExample">
              <i className="fa-solid fa-bars"></i>
            </button>

            {/* FARMERSEDGE | DPS GUI */}
            <span className='text-white' style={{marginLeft: "2rem"}}>{pageName}</span>
            <div className='w-100 position-absolute' style={{zIndex: -1}}>
              <img src={FarmCommandLogoGrey} alt="fc support logo" className="navbar-brand d-none d-lg-block mx-auto" height={25} style={{filter: "brightness(200)"}} />
            </div>

            <button className="btn me-2 ms-auto text-light" type="button" onClick={() => {setShowOptions(showOptions => !showOptions)}}>
                  <i className="fa-solid fa-ellipsis-v" ></i>
            </button>
        </nav>
          {
            showOptions && 
            <div 
                className='position-absolute my-2 p-2 shadow-lg bg-light text-dark rounded-2 d-flex flex-column ' 
                style={{right: "15px", zIndex: 100}}
            >
                <Link to={"/settings"} >
                    <button className='btn btn-light  fw-bold p-2'> <i className="fa fa-gear mx-2"></i> {t("Settings")} </button>
                </Link>
                <button className='btn btn-light fw-bold p-2' onClick={() => {logOut(setAuthStatus)}}> <i className="fa-solid fa-arrow-right-from-bracket mx-2"></i> {t("Logout")}</button>
            </div>
        }
    </>
  )
}

export default Navbar