import React from 'react'
import ChangeLanguage from './ChangeLanguage';

function GlobalSettings() {
    return (
        <>  
            <div className='position-relative p-3'>
                {/* Change Language of the Application */}
                <ChangeLanguage  pageName='settings' />
            </div>
        </>
    )
}

export default GlobalSettings