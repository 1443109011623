import React, { useEffect, useState } from 'react'
import {useDispatch} from 'react-redux'
import {rxParametersAddorChange} from '../../../features/RxParametersSlice'
import { useTranslation } from 'react-i18next';

function CheckBoxes() {
    
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [download_rxs_status, set_download_rxs_status] = useState(true);
    const [download_borders_status, set_download_borders_status ] = useState(true);
    const [merge_fields_status, set_merge_fields_status] = useState(true);


    useEffect(() => {
        dispatch(rxParametersAddorChange({"download_rxs": true, "download_borders": true, "merge_fields": true}));
    }, [])

    // Event Handlers
    function handle_download_rx_change(event){
        set_download_rxs_status(prev => !prev);
        dispatch(rxParametersAddorChange({"download_rxs": event.target.checked}))
    }

    function handle_download_border_change(event){
        set_download_borders_status(prev => !prev);
        dispatch(rxParametersAddorChange({"download_borders": event.target.checked}))
    }

    function handle_merge_fields_change(event){
        set_merge_fields_status(prev => !prev);
        dispatch(rxParametersAddorChange({"merge_fields": event.target.checked}));
    }

    return (
        <>
            <div className="d-flex align-items-center justify-content-evenly p-1">
            {/* Download Rxs */}
                <label className="form-check-label text-center">
                <input type="checkbox" className="me-2 p-2 form-check-input" name="download_rxs" id="download_rxs" onChange={handle_download_rx_change} checked={download_rxs_status} />
                    {t("Download Rxs")}
                </label>

                
                {/* Download Borders */}
                <label className="form-check-label text-center">
                <input type="checkbox" className="me-2 p-2 form-check-input" name="download_border" id="download_border" onChange={handle_download_border_change} checked={download_borders_status} />
                    {t("Download Border")}
                </label>

                {/* Merge Fields */}
                <label className="form-check-label">
                <input type="checkbox" className="me-2 p-2 form-check-input" name="merge_fields" id="merge_fields" onChange={handle_merge_fields_change} checked={merge_fields_status} />
                    {t("Merge Fields")}
                </label>
            </div>
        </>
    )
}

export default CheckBoxes