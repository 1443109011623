import React, { useState } from 'react'
import {IMPLEMENT_ATTRIBUTES} from '../../../constants'
import axios from 'axios'

// Imp Attribute Modal
const ImplementAttribute = ({id, data}) => {
    
    const {implementAttribute} = data;
    const implementAttributeArray = IMPLEMENT_ATTRIBUTES.map( attrib =>  (
                            { 
                                "key" : attrib.key, 
                                "value": getAttributeValue(attrib.key, implementAttribute),
                                "label": attrib.label,
                                "type": attrib.type 
                            }
                        ))

    return (
        <div className="modal fade" id={`implement-${id}`} tabindex="-1" data-bs-backdrop="static" role="dialog">
            <div className="modal-dialog modal-dialog-centered modal-fullscreen" role="document">
                <div className="modal-content">
                    <div className="modal-header text-white" style={{backgroundColor: "#02559c"}}>
                        <h5 className="modal-title" id="modalTitleId">Implement Attributes</h5>
                        <button type="button" className="btn btn-light btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div className="modal-body">

                        <div className="container">
                            <AttributeRows id={id} implementAttributeArray={implementAttributeArray} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function AttributeRows({implementAttributeArray, id}){


    const [request, setRequest] = useState([...implementAttributeArray])

    function handleInputChange(event){
        const { name, value } = event.target;
        setRequest((prev) => {
            const copy = [...prev];
            const index = copy.findIndex(r => r.key === name);
            copy[index].value = value;
            return copy;
        })
    }

    function handleUpdateSaveClick(event){
        const parsed_request = request.map(({ type, label, ...rest }) => rest).filter(obj => obj.value);
        console.log(id)
        // try {
        //     axios.post("/api/carbon")
        // } catch (error) {
        //     console.log(error)
        // }
    }

    return (
        <>
            {
                request.map(req => {
                    return (
                    <div className="row my-4">
                        <div className="col-3 d-flex text-end  align-items-center justify-content-end ">
                            <label htmlFor={req.key} className="fw-bold">{req.label}</label>
                        </div>
                        <div className="col-9">
                            { req?.type === "boolean" && 
                                <select name={req.key} value={req.value} onChange={handleInputChange} className='form-control'>
                                    <option value=""></option>
                                    <option value={true}>True</option>
                                    <option value={false}>False</option>
                                </select>
                            }
                            { req?.type === "numeric" &&
                                <input name={req.key} value={req.value}
                                    type='number'
                                    className='form-control' onChange={handleInputChange}
                                    autoComplete='off' />
                            }
                        </div>
                    </div>
                    )
                })
            }
            <div className="d-flex justify-content-center">
                <button className="btn btn-outline-primary mt-4" onClick={handleUpdateSaveClick}>Update/Save</button>
            </div>
        </>
    )
}

// Helpers
function getAttributeValue(key, implementAttribute){
    let result = implementAttribute.filter(a => a.key === key);
    if(result.length === 1){
        return result[0]?.value
    } else {
        return null
    }
}

export default ImplementAttribute