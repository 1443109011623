import React, { useEffect, useState } from 'react'
import TableRow from './TableRow'
import '../style.css'
import { useDispatch, useSelector } from 'react-redux'
import SeasonSelector from './SeasonSelector'
import Submit from './Submit'

function DetailedView({ product_details }) {

  const dispatch = useDispatch()
  
  const [ seasons , set_seasons ] = useState([]);
  const [ parsed_product_details , set_parsed_product_details ] = useState([])

  let result = {}
  let position = -1;
  
  useEffect(() => {
    set_seasons([]);

    let unique_seasons = new Set();
    product_details.forEach(product => {
      let key = JSON.stringify(product.seasons);
      unique_seasons.add(key);

      if( Object.keys(result).includes(key) ){ // Existing season key
            result[key].push( product )
      } 
      else{ // First season key
          result[key] = [product]
      }
    })
    
    unique_seasons.forEach(e => set_seasons(prev => {return [...prev, JSON.parse(e)]}))
    set_parsed_product_details(result);

  }, [])


  return (
    <>
        <div className="w-100">
        {
          seasons.map((season, season_index) => {
            return (
              <>
              <SeasonSelector season={season} index={season_index} />

              <figure className='wrapper' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto"}}>

                <table className="fixed_header table table-hover" style={{resize: "both", overflow:"auto"}} id='detailed-view-table'>
                  <thead className='header text-white' style={{zIndex: -1, backgroundColor: "#02559c"}}>
                      <tr>
                          <th scope="col">#</th>
                          <th scope="col">Date</th>
                          <th scope="col">Crop</th>
                          <th scope='col'>Product</th>
                          <th scope='col'>Supply Type</th>
                          <th scope='col'>Supply</th>
                          <th scope='col'>Operation</th>
                          <th scope="col">Field</th>
                          {/* <th scope="col">Farm</th> */}
                          <th scope="col">Grower</th>
                      </tr>
                  </thead>

                  <tbody>

                  {
                      parsed_product_details[JSON.stringify(season)].map(( product,index ) => {
                      position++;
                      return (
                          <>
                            <TableRow key={index} product={product} index={index} season_index={season_index} position={position} />
                          </>
                      )
                      })
                  }

                  </tbody>
                </table>
              </figure>

              </>
            )
          })
        }
        
        <Submit />

        </div>
    </>
  )
}

export default DetailedView