import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {rxParametersAddorChange} from '../../../features/RxParametersSlice'
import { Fade } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

function ApplicationType() {

    const { t } = useTranslation();
    const dispatch = useDispatch() // dispatcher
    const { download_rxs } = useSelector(state => state.rxParameters);

    const [selected_application_types, set_selected_application_types] = useState([1,2,3,4,5,6,7,8,9]);  // // To track checked application types.

    // data for the table
    const [ application_type ] = useState([
        {'value' : 1, 'label': t('Fertilizer')},
        {'value' : 4, 'label': t('Desiccant')},
        {'value' : 3, 'label': t('Fungicide')},
        {'value' : 2, 'label': t('Herbicide')},
        {'value' : 5, 'label': t('Insecticide')},
        {'value' : 9, 'label': t('Manure')},
        {'value' : 6, 'label': t('Plant Growth Regulators')},
        {'value' : 7, 'label': t('Seed')},
        {'value' : 8, 'label' : t('Soil Amendment')}
    ]);

    const [appear, set_appear] = useState(true);


    useEffect(() => {
        dispatch( rxParametersAddorChange({ 'ApplicationType': selected_application_types}));
    }, [selected_application_types])

    useEffect(() => {
        if(!download_rxs){
            dispatch( rxParametersAddorChange({ 'ApplicationType': application_type.map(e => Number(e.value))}));
            set_selected_application_types([1,2,3,4,5,6,7,8,9])
        }
    }, [application_type, download_rxs])

    // Table Row Component
    function TableRow({id, application_}){

        // Checkbox change event handler
        function handleChange(event){
            
            const {checked , value } = event.target;
            
            if(checked){
                set_selected_application_types(prev => [...prev, Number(value)]);
            }
            else {
                set_selected_application_types(selected_application_types.filter(e => e !== Number(value)));
            }
        }

        return (
            <>
                <tr className={!download_rxs ? "table-secondary" : ""}>
                    <th>{id + 1}</th>
                    <td className=''>{application_.label}</td>
                    <td>
                        <input type='checkbox' className="form-check-input p-2" 
                                name={application_.label} id={application_.value} 
                                value={application_.value} 
                                onChange={
                                    !download_rxs ? null : handleChange
                                } 
                                checked={
                                    !download_rxs ? "checked" : 
                                    selected_application_types.includes(application_.value) ? 'checked' : ''
                                } />
                    </td>
                </tr>
            </>
        )
    }

    // Event Handler
    function handleShowTable(event){
        set_appear(prev => {return !prev});
    }

    return (
        <>
            {/* <Select options={application_type} onChange={handleChange} defaultValue={1} placeholder='Select Application Type'  /> */}
            <figure className='wrapper' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto"}}>
            <table className="table table-light table-responsive table-hover shadow">
                <thead className='table-dark' onClick={handleShowTable}>
                <tr>
                    {
                        appear ? 
                        <>
                            <th scope="col">#</th>
                            <th scope="col">{t("Application Type")}</th>
                            <th scope="col" className='fw-fw-bolder'>#</th>
                        </>
                        : 
                        <th scope="col" className='text-center'>{t("Select Application Type")}</th>
                    }
                </tr>
                </thead>
                    <tbody>
                    {
                        application_type.map((application_ , key) => {
                        return (
                            <Fade in={appear} unmountOnExit={true} appear={appear}>
                                <TableRow key={key} id={key} application_={application_} />
                            </Fade>
                        )
                    })
                    }
                    </tbody>
            </table>
            </figure>
        </>
    )
}

export default ApplicationType