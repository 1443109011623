import { useTranslation } from "react-i18next"
import WidgetCard from "./WidgetCard"
import useFetch from '../../../../hooks/useFetch'
import { Spinner } from "react-bootstrap"
import { format } from "date-fns"
import { JIRA_BASE_URL } from "../../../../constants"
import "../../styles.css"
import { NavLink } from "react-router-dom"

const RecentTickets = () => {

    const { t } = useTranslation();

    const {
        data = [],
        error,
        loading
    } = useFetch({url: "/api/dps_tickets/?limit=5"})
    
    if(error){
        return (
            <>
                <p>{t("Unable to fetch your recent tickets")}</p>
            </>
        )
    }
    
    return (
        <WidgetCard cardTitle={
            <>
                <span className="me-2"><i className="fa-solid fa-envelope-open-text"></i></span>
                <NavLink to={"/your_tickets/"}>
                    {t("Your Recent Tickets")}
                </NavLink>
            </>
        }>
            {
            loading ? <Spinner /> : 
            <>
            <div className="table-responsive">
                <table className="table table-striped table-hover table-borderless align-middle widget-table">
                    <thead className="table-dark">
                        <tr>
                            <th>{t("ID")}</th>
                            <th>{t("Summary")}</th>
                            <th>{t("Created On")}</th>
                            <th>{t("Status")}</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        
                        {
                            data?.map((row, index) => {
                                return <TableRow row={row} key={index} index={index} />
                            })
                        }
                    </tbody>
                </table>
            </div>
            </>
            }
        </WidgetCard>
    )
}

function TableRow({row, index}){
    
    const {
        key, summary, created, status
    } = row;
    
    return (
        <>
        <tr className="table-secondary">

            <td className="">
                <a href={`${JIRA_BASE_URL}/servicedesk/customer/portal/7/${key}`}>
                    {key}
                </a>
            </td>
            
            <td>
                <span title={summary}>
                    {summary.replace("Portal Ticket Submission: ", "").substring(0, 50)}
                </span>
            </td>

            <td>
                <span>
                    { created && format(new Date(created), "dd-MMM-yy") }
                </span>
            </td>
            <td>
            <span className={`px-3 badge ${['Done', 'Awaiting Approval'].includes(status) ? "text-bg-success" : "text-bg-warning"}`}>
                    {status}
                </span>
            </td>
        </tr>
        </>
    )
    
}

export default RecentTickets