import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import FieldSearch from './FieldSearch';
import FieldButtons from './FieldButtons';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { MapsContext } from '../../context/MapsContext';
import { requestParametersAddorChange } from '../../../../features/RequestParametersSlice';

function FieldSelectionLegend() {
    
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const { field_ids, field_labels } =  useSelector(state => state?.requestParameters);
    const [ filtered_field_ids, set_filtered_field_ids ] = useState([])
    
    const all_field_ids = useRef([])

    const {
        assetsLoading
    } = useContext(MapsContext);
    
    useEffect(() => {
        if(field_labels){
            all_field_ids.current = field_ids.map((field_id, index) => ({"label": field_labels[index], "value": field_id}));
        } else {
            all_field_ids.current = field_ids.map(field_id => ({"label": field_id, "value": field_id}))
        }
        
        if (field_ids && field_ids.length >= 1) {
            const first_field_id = field_ids[0];
            dispatch(requestParametersAddorChange({"current_field_id": first_field_id}))
            document.getElementById(`field-name-${first_field_id}`)?.classList?.remove("d-none");
        }

        set_filtered_field_ids(all_field_ids.current)
    }, [field_ids, field_labels])



    return (
        <>
            <div className="offcanvas offcanvas-end" tabindex="-1" id="legend" style={{opacity: "80%", maxWidth: "350px", minWidth: "350px"}}>

                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="Enable both scrolling & backdrop">{t("Legend")}</h5>
                    <button className='btn' data-bs-dismiss="offcanvas"> <i className="fa-solid fa-arrow-right"></i> </button>
                </div>
                
                <div className="offcanvas-body">
                {
                    (assetsLoading) ? <Spinner className='d-block mx-auto' /> :

                    (field_ids && field_ids.length > 0) ?
                    <>
                        <FieldSearch all_field_ids={all_field_ids.current} set_filtered_field_ids={set_filtered_field_ids} />
                        <FieldButtons filtered_field_ids={filtered_field_ids} />
                    </>
                    :
                    <>
                        <p className='text-center text-danger fs-5'>Hey, Please make some selections before coming here</p>
                    </>
                }
                    
                </div>
            </div>
        </>
    )
}

export default FieldSelectionLegend