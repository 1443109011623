import { useState } from "react";
import { useTranslation } from "react-i18next"
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import axios from 'axios'
import { API_ENDPOINTS, HARVEST_APPLIED_PDFS, PAT_REQUESTS } from '../constants'
const Submit = () => {
    
    const { t } = useTranslation();
    const { 
        customer_request, requestedLayers, fields, operation,
        season, grower, grower_label, file_type, datatype, user_id, token
    } = useSelector(state => state.requestParameters);
    
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState("");
    const [ success, setSuccess ] = useState(false);
    

    async function handleSubmit(event) {
        event.target.classList.add("disabled");
        setLoading(true);

        let url = "";
        if(HARVEST_APPLIED_PDFS.includes(customer_request)){
            url = API_ENDPOINTS.PDF_REQUEST;
        } 
        else if(PAT_REQUESTS.includes(customer_request)){
            url = API_ENDPOINTS.PAT_REQUEST;
        }

        try {
            const payload = ValidatedPayload({
                                    operation, requestedLayers, fields, datatype,
                                    file_type, customer_request, user_id, token, url,
                                    grower, grower_label, season
                                });
            if(!payload) return;

            const response = await axios.post(url, payload);
            if([200, 201].includes(response.status)){
                setSuccess(true);
            } else {
                setError("failed creating service request.");
            }
        } catch (error) {
            setError("failed creating service request.");
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="my-5 mx-auto">
            
            {
                loading && <div className="d-flex my-3 justify-content-center">
                    <Spinner />
                </div>
            }
            
            {success && <p className="animate__animated animate__bounce text-center fs-4 text-success my-5">{t("Created Successfully")}</p>}
            {error && <p className="animate__animated animate__bounce text-center fs-4 text-danger my-5">{error}</p>}

            <button className="my-4 btn btn-dark w-100 fs-5" onClick={(event) => {handleSubmit(event)}}>
                {t("Submit")}
            </button>
        </div>
    )
}

function ValidatedPayload({
    fields, operation, customer_request, requestedLayers,
    file_type, datatype, user_id, token, url,
    season, grower, grower_label = "",
}){
    
    let cust_request = customer_request;

    if(!operation)
        return alert("Please select the operation");
    
    if(!customer_request)
        return alert("Please select a request before submitting");

    if([HARVEST_APPLIED_PDFS].includes(customer_request)){
        cust_request = "pdf-request"
    }

    if(HARVEST_APPLIED_PDFS.includes(customer_request) && !datatype)
        return alert("please select atleast one datatype.");

    if(!url)
        return alert("URL is undefined.");

    const env = (document.location.origin === "https://support.farmcommand.com") ? "prod" : "dev";
    const language = localStorage.getItem("language") ? localStorage.getItem("language") : "en-US";
    
    return {
        "asset_ids": fields,
        "operation": operation,
        "requested_layers": requestedLayers,
        "datatype": datatype,
        "file_type": file_type,
        "user_id": user_id,
        "token": token,
        "env": env,
        "language": language,
        "season": season,
        "grower_id": grower,
        "grower_label": grower_label,
        "customer_request": cust_request,
    }
}

export default Submit