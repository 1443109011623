import { useContext } from "react"
import { ControllerDataContext } from "../context/ControllerDataContext"
const GrowerLabel = ({grower_id}) => {

    // context
    const {labelIdMap} =  useContext(ControllerDataContext);
    
    return (
        <span>{Object.keys(labelIdMap).includes(String(grower_id)) ? labelIdMap[grower_id] : grower_id}</span>
    )
}

export default GrowerLabel