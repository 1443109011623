import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'

import {useDispatch} from 'react-redux'
import {requestParametersAddorChange} from '../../../../features/RequestParametersSlice'

function Seasons({visibility}) {

    const dispatch = useDispatch()
    
    const [loading, setLoading] = useState(false)
    const [seasons , setSeasons] = useState([]); // stores the seasons returned from api
    const [selectedSeason, setSelectedSeason] = useState(null) // to track season that has been selected

    async function fetchSeasons(){
        setLoading(true);
        // Makes api call to fetch seasons, [returned seasons] are set to seasons state.
        try{
            let response = await axios("/api/getSeasons/");
            setSeasons(response.data.map((e) => { return {"value" : e.id , "label" : e.label}}));
        }
        catch(err){
            console.log('Error while fetching seasons')
        } finally {
            setLoading(false);
        }
    }

    // Side Effects

    // Initial Render
    useEffect(() => {
        // Fetching seasons.. when it is rendered...
        fetchSeasons();
    }, []); 

    // On Every Visibility change
    useEffect(() => {
        setSelectedSeason(null)
    }, [visibility])

    function handleChange(event){
        // Adding selected season to the local & global state.
        setSelectedSeason(event)
        dispatch(requestParametersAddorChange({ season :  event.value }))
    }

    return (
        <div className='mb-2'>
            <label htmlFor="" className="form-label fs-5">{'Select Season'}:</label>
            <Select 
                placeholder="Select Season" 
                options={seasons} value={selectedSeason} 
                onChange={handleChange}
                isLoading={loading} />
        </div>
    )
}

export default Seasons