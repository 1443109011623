import React, { useState } from 'react'
import Select from 'react-select'
import {PROGRAMS} from '../../constants'
import { useDispatch } from 'react-redux'
import { requestParametersAddorChange } from '../../../../features/RequestParametersSlice'

const Program = () => {

    const dispatch = useDispatch();
    
    const [ selectedProgram, setSelectedProgram ] = useState(null);

    function handleProgramChange(event){
        setSelectedProgram(event)
        const {value} = event; 
        dispatch(requestParametersAddorChange({"program": value}))
    }

    return (
        <div className='mb-2 my-4'>
            <label htmlFor="" className="form-label fs-5">{'Select Program'}:</label>
            <Select 
                placeholder="Select Program" 
                options={PROGRAMS}
                value={selectedProgram} 
                onChange={handleProgramChange} />
        </div>
    )
}

export default Program