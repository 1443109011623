import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { rxParametersAddorChange } from '../../features/RxParametersSlice'
import { useTranslation } from 'react-i18next'

function Grower() {

    
    const { t } = useTranslation();

    // Dispatcher
    const dispatch = useDispatch()
    
    // Global State
    const rxParameters = useSelector(state => state.rxParameters);

    const [growers , setGrowers] = useState('') // used to store growers fetched from api call
    const [show , setShow] = useState(false) // used to control display of grower selector

    async function fetchGrowers(){
        // Makes api call to fetch growers.
        console.log('fetching growers....')
        setShow(false)
        setGrowers([])
        try{
            let response = await axios(`/api/getGrowers/?hubID=${rxParameters.hub}&seasonID=${rxParameters.season}`);
            setGrowers(response.data.features.map((e) => { return {"value" : e.properties.grower , "label" : e.properties.grower_label}}));
            setShow(true)
        }
        catch(err){
            console.log('Error while fetching growers...')
        }
    }

    useEffect(() => {
        // Fetching growers by making api call, for the [selected season & hub]
        setGrowers([]);
        fetchGrowers()
    } , [rxParameters.season, rxParameters.hub])

    function handleChange(event){
        // Adds selected grower to the global state
        dispatch(rxParametersAddorChange( { 'grower' : event.value } ))
    }

    return (
        <>
            {show && <Select placeholder={t("Select a Grower")} options={growers} onChange={handleChange} />}
        </>
    )
}

export default Grower