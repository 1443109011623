import { useDispatch, useSelector } from 'react-redux'
import { requestParametersAddorChange } from '../../../features/RequestParametersSlice'
import { t } from 'i18next'

const PhoneNumber = () => {
    
    const { phone_number } = useSelector(state => state.requestParameters);
    const dispatch = useDispatch(); 

    return (
        <div>
            <input 
                id="phone-number"
                name="phone-number" 
                placeholder={t("Enter Your Phone Number")}
                type="number" 
                value={phone_number}
                className='form-control'
                onChange={
                    e => dispatch(requestParametersAddorChange({ "phone_number": e.target.value }))
                }
                />

        </div>
    )
}

export default PhoneNumber