import React, { useEffect, useState } from 'react'
import {useDropzone} from 'react-dropzone'
import {ProgressBar, Spinner} from 'react-bootstrap'
import { formatSizeUnits } from '../utils/Uploader';
import axios from 'axios';
import ResponseTable from './ResponseTable';
import { useTranslation } from 'react-i18next';

function FileUpload({selectedTerritory}) {

    const { t } = useTranslation();

    const [files, set_files] = useState([]) // Files
    const [error , set_error] = useState(""); // To Display Error Messages
    const [loading, setLoading] = useState(false); // To Control Spinner

    const [ upload_error_message, set_upload_error_message ] = useState(""); // Error message when upload fails 
    const [ percent, set_percent ] = useState(0); // Used to track percent uploaded in Progress Bar
    const [ result, set_result ] = useState([]) // Will be used to render ResponseTable

    // Styling for Dropzone
    const dropzoneStyles = {cursor: "pointer", height: "150px", border: "2px solid rgba(0,0,0,0.3)", padding: "20px 20px", textAlign: "center", display:"flex" , background: "#ffffff"}

    const {getRootProps, getInputProps} = useDropzone({
        onDrop: acceptedFiles => {
            acceptedFiles.forEach((acceptedFile) => {
                if("zip" === acceptedFile.name.split(".").pop()){
                    set_files((prev) => { return [...prev, acceptedFile] })
                } else{
                    set_error(prev => prev + `<br /> <p class="text-danger"> <span class="text-primary">${acceptedFile.name}</span> is not a zip file </p`)
                }
            })
            
        }
    })

    useEffect(() => {
        return () => setTimeout(() => {set_error("")}, 5000)
    }, [error])


    // Remove file button click event handler
    function removeFile(event, index){
        set_files(files.filter((file, _index) => {return index !== _index}))
    }

    // Upload Button Click Event handler
    async function uploadFiles(event){
        setLoading(true);
        event.target.classList.add("disabled");
        document.querySelector('.file-uploader').classList.add("d-none");
        document.querySelectorAll('.remove-file-button').forEach(e => e.classList.add("disabled"));

        for (const file of files) {
            try {
                const response = await axios.post("/smartreporting/smart_reporting_field_uploader/", { "file": file, "territory_id": selectedTerritory }, {headers: {"Content-Type": "multipart/form-data"}} )
                if(response.data?.status){
                    set_result(prev_result => {return [...prev_result, response.data?.result]})
                }
            } catch (error) {
                let error_message = "";
                if(error?.response?.data?.error_message){
                    error_message = error?.response?.data?.error_message;
                }
                set_upload_error_message(prev => prev + `<br /> <p class="text-danger"> <span class="text-primary">${file.name}</span> failed to upload : <span class="text-warning">${error_message}</span> </p>`)
            } finally{
                set_percent(prev => {return prev + ( 100 / files.length) });
            }
        }   
        setLoading(false);
    }

    return (
        <>
        {/* File Uploader (Dropzone Component) */}
        <div className="shadow file-uploader">
            <div className='' {...getRootProps({className: 'dropzone'})} style={ dropzoneStyles }>
                <input {...getInputProps()} />
                <p className='mx-auto my-auto fs-5 opacity-75'>{t("Drop and Drop here or Click to upload the files")}  !</p>
            </div>
        </div>

        {/* Error Message */}
        {
            error && <>
                <p className='my-3 text-danger fs-5' dangerouslySetInnerHTML={{__html: error}}></p>
            </>
        }
        
        {/* Table Rows */}
        {
            files.length > 0 &&
            <div className='my-5'>
            <p className='text-success text-decoration-underline'>{t("Selected files")}:</p>

            <figure className='wrapper' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto"}}>
                <table className="table table-light table-responsive table-hover shadow">
                <thead className='table-dark'>
                    <tr >
                        <th scope="col">#</th>
                        <th scope="col">{t("File Name")}</th>
                        <th scope="col">{t("File Size")}</th>
                        <th scope="col">#</th>
                    </tr>
                </thead>
                <tbody>
                {
                    files.map((file, index) => {
                        return (
                            <>
                                <tr className="align-middle" key={index}>
                                    <td>{index + 1}</td>
                                    <td>{file.name}</td>
                                    <td>{formatSizeUnits(file.size)}</td>
                                    <td><button className="btn btn-sm px-2 btn-outline-danger remove-file-button" onClick={ (event) => removeFile(event, index) }>-</button></td>
                                </tr>      
                            </>
                        )
                    })
                }
                </tbody>
                </table>
            </figure>
            
            {
                loading && <div className="d-flex my-4 justify-content-center"><Spinner /></div>
            }

            {/* Progressbar */}
            {
                percent > 0 &&
                <ProgressBar now={Math.round(percent)} label={`${Math.round(percent)}%`} striped variant='primary' className='my-5' />
            }

            {/* Upload Button */}
            {selectedTerritory && <button className="btn btn-outline-secondary w-100 mx-auto my-5" onClick={uploadFiles}>Upload</button>}
            </div>
        }


        {/* Success message */}
        {
            Math.round(percent) === 100 &&
            <>
                <p className=' animate__animated animate__bounceInDown text-center my-5 fs-5 text-success'>{t("Completed")}!</p>
                {
                    result.length > 0 &&
                    <ResponseTable result={result} />   
                }
            </>
        }
        
        {
            upload_error_message &&
            <>
                <p className='animate__animated animate__bounceInDown text-center my-5 fs-5 text-danger' dangerouslySetInnerHTML={{__html: upload_error_message}}></p>
            </>
        }

        </>
    )
}

export default FileUpload