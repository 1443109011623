import React, { useState } from 'react'
import Navbar from '../settings/components/Navbar'
import Offcanvas from '../../components/Offcanvas'
import FileUpload from './components/FileUpload'
import Territories from './components/Territories'
import { useTranslation } from 'react-i18next'

function SmartReportingFieldUploader() {

  const { t } = useTranslation();
  const  [selectedTerritory, setSelectedTerritory] = useState(null)

  return (
    <>
        <Navbar pageName={"Smart Reporting Field Uploader"} />
        <Offcanvas />
        <div className="row w-100 justify-content-center mx-auto">
        <div className="page-content selectors px-4 my-4">
            <Territories selectedTerritory={selectedTerritory} setSelectedTerritory={setSelectedTerritory} />
            <p className='h3'> {t("Shapefile Upload")} </p>
            <p className='fs-5 my-2'>{t("Please select the shapefiles that you wanna upload")}.</p>
            <p className='fst-italic my-3'>{t("Note: You Can Only Upload .zip file(s)")}</p>
            <FileUpload selectedTerritory={selectedTerritory}/>
        </div>
        </div>
    </>
  )
}

export default SmartReportingFieldUploader