import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import {  changeSeason, changeSeason_, pushToDefault_ } from '../../../../features/RequestSlice';

function SeasonSelector({season, index}) {

    const dispatch = useDispatch();
    const tempParameters = useSelector(state => state.tempParameters);
    const request_season_view = useSelector(state => state.request_season_view);

    let sourcefile_id = tempParameters.sourcefile_id; 

    const [seasons] = useState(season?.map(e => {return {"value" : e.id , "label" : e.label}})) // State for season dropdown
    const [selected_season, set_selected_season] = useState(seasons[0]) // state to track selected season
    
    useEffect(() => {
        dispatch(pushToDefault_({sourcefile_id: sourcefile_id, data: { [selected_season.value]: {"productList": []} }}))
    }, [])

    // When Season on default view changes
    useEffect(() => {
        if(request_season_view[sourcefile_id]?.default.length > 0){
            let current_season_of_default_view = Object.keys(request_season_view[sourcefile_id]?.default[index])[0]
            set_selected_season(seasons.filter(e => e.value === current_season_of_default_view)[0])
        }
    }, [request_season_view])
    
    // Season dropdown change event
    function season_change(event){
        let season_id = event.value;
        set_selected_season(event);

        dispatch(changeSeason({sourcefile_id, index, new_season: season_id}))
        dispatch(changeSeason_({sourcefile_id, index, new_season: season_id}))

    }

    return (
        <div className="mt-3 d-flex text-bg-dark" style={{zIndex: 1}}>
            <span className='p-3 fs-5 text-center my-0'>Select Season: </span>
            <div className="ms-3 me-3 my-auto"> <Select options={seasons} value={selected_season} menuPortalTarget={document.body} onChange={season_change} /> </div>
        </div>
    )
}

export default SeasonSelector