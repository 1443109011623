import { Routes, Route } from 'react-router-dom'
import PdfForm from './pages/PdfForm'
import PageNotFound from '../../PageNotFound'
import GeneratedPdfs from './pages/GeneratedPdfs'

const Sail = () => {
    return (
        <Routes>
            <Route path='/' element={<PdfForm />} /> 
            <Route path='/generated_pdfs/' element={<GeneratedPdfs />} /> 
            <Route path='/*' element={<PageNotFound />} /> 
        </Routes>
        )
}

export default Sail