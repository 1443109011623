// Offcanvas.jsx

import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import FarmCommandSupportLogo from '../../assets/images/FarmCommandLogoGrey.png';
import { allowedPages } from './allowedPages.js'; // Import allowedPages array
import { isElvyraTech, isIcici} from 'utils/authHelpers';

import Category from './Category';
import '../../styles/Offcanvas.css';

function Offcanvas({ sidebar = true }) {
    const { t } = useTranslation();
    return (
        <div className='side-panel'>
            <nav id="sidebarMenu" className="collapse d-none d-md-block sidebar collapse bg-white shadow-lg sticky-top" style={{ width: "4.5rem" }}>
                <div className="position-sticky">
                    <div className="list-group list-group-flush mx-auto">
                        <div className="d-flex p-2" style={{ backgroundColor: "#02559c" }}>
                            <button className="text-decoration-none mx-auto btn text-white w-100 mb-1" type="button" data-bs-toggle="offcanvas" href="#offcanvasExample">
                                <i className="fa-solid fa-bars"></i>
                            </button>
                        </div>
                        <SideBarButton to="/" icon={<i className="fa-solid fa-house"></i>} tooltip="Dashboard" />   
                        { !isIcici() && isElvyraTech() && 
                            <SideBarButton to="/instructions" icon={<i className="fa-solid fa-circle-question"></i>} tooltip="Support Portal Help Guides" />
                        }
                        {/* Render buttons for individual pages */}
                        {allowedPages.map(category => {
                            // Filter the pages that are allowed for the current user
                            const visiblePages = category.pages.filter(page => page.auth);
                            return visiblePages.length > 0 && visiblePages.map(page => (
                                <SideBarButton key={page.key} to={page.route} icon={page.icon} tooltip={t(page.name)} />
                            ));
                        })}
                        
                    </div>
                </div>
            </nav>
            <div className="offcanvas offcanvas-start text-white" tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel" style={{background: "#02559c"}}>
                <div className="offcanvas-header">
                    <img src={FarmCommandSupportLogo} alt="fc support logo" className="navbar-brand d-lg-block mx-auto" height={15} style={{filter: "brightness(200)"}} />
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>

                </div>
                <div className="offcanvas-body bg-light py-1">
                    <OfcButton to="/" icon={<i className="fa-solid fa-house"></i>}>{t("Dashboard")}</OfcButton>
                    { !isIcici() && isElvyraTech() && 
                        <OfcButton to="/instructions" icon={<i className="fa-solid fa-fw fa-circle-question"></i>}>{t("Support Portal Help Guides")}</OfcButton>
                    }
                    {/* Render buttons for individual pages */}
                    {allowedPages.map(category => {
                        // Filter the pages that are allowed for the current user
                        const visiblePages = category.pages.filter(page => page.auth);
                        return visiblePages.length > 0 && (
                            <Category key={category.id} categoryComponent={<><span>{category.icon}</span> {category.name}</>}>
                                {visiblePages.map(page => (
                                    <OfcButton key={page.key} to={page.route} icon={page.icon}>
                                        {t(page.name)}
                                    </OfcButton>
                                ))}
                            </Category>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

function OfcButton({ to, children, icon, className = "", styles }) {
    return (
        <div style={{ ...styles }}>
            <NavLink to={to} className='text-decoration-none mx-auto'>
                <button className={`d-flex align-items-center btn ofc-btn w-100 py-2 my-1 gap-3 ${className}`} data-bs-dismiss="offcanvas">
                    <span>{icon}</span>{children}
                </button>
            </NavLink>
        </div>
    );
}
function SideBarButton({to, tooltip, icon}){
    return (
        <>
            <NavLink to={to} className='text-decoration-none side-btn m-0 p-0 w-100 text-center'>
                <button className='btn py-2 my-1' title={tooltip}>
                    {icon}
                </button>
            </NavLink>
        </>
    )
}

export default Offcanvas;
