import React, { useEffect } from 'react'

import {useDispatch} from 'react-redux'
import {requestParametersAddorChange} from '../features/RequestParametersSlice'
import {ticketParametersAddorChange} from '../features/TicketParametersSlice'
import { useTranslation } from 'react-i18next'


function Datatype() {

  const { t } = useTranslation();
  // Dispatcher
  const dispatch = useDispatch()
  

  useEffect(() => {
    // setting default datatype as canplug to global state
    dispatch(requestParametersAddorChange({'datatype' : "canplug"}))
    dispatch(ticketParametersAddorChange({'datatype' : "Canplug"}))
  } , [])

  function handleChange(event){
    // Adding selected datatype to the global state.
    const selected_datatype = event.target.value;
    dispatch(requestParametersAddorChange({'datatype' : selected_datatype}))
    dispatch(ticketParametersAddorChange({'datatype' : selected_datatype.charAt(0).toUpperCase() + selected_datatype.slice(1)})) // Capitalize first letter
  }

  return (
    <>
      <select name="layerType" id="layerType" className='form-control shadow-lg' onChange={handleChange}>
        <option value="canplug" className='form-control' defaultValue>{t("Canplug")}</option>
        <option value="controller" className='form-control'>{t("Controller")}</option>
      </select>
    </>
  )
}

export default Datatype