import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom'
import FarmCommandLogoGrey from '../../../assets/images/FarmCommandLogoGrey.png'

function Navbar({pageName}) {

    const [showSettings, setShowSettings] = useState(false);
    const { t } = useTranslation();

    return (
        <>
            <nav className="navbar navbar-expand-sm navbar-white-2" style={{background: "#02559c"}}>

                <button className="ms-3 btn btn-outline-light" type="button"  data-bs-toggle="offcanvas" href="#offcanvasExample" style={{zIndex: 100}}>
                    <i className="fa-solid fa-bars"></i>
                </button>

                <span className='text-white py-2' style={{marginLeft: "1.5rem"}}>{pageName}</span>

                {/* FARMERSEDGE | DPS GUI */}
                <div className='w-100 position-absolute' style={{zIndex: 1}}>
                    <img src={FarmCommandLogoGrey} alt="fc support logo" className="navbar-brand d-none d-lg-block mx-auto" height={25} style={{filter: "brightness(200)"}} />
                </div>

                <div className="ms-auto" style={{zIndex: 1000}}>

                <button className="btn me-2 text-light" type="button" onClick={() => {setShowSettings(showSettings => !showSettings)}}>
                    <i className="fa-solid fa-ellipsis-v" ></i>
                </button>

                </div>
            </nav>

            {
                showSettings &&
                <div 
                    className='d-flex flex-column position-absolute align-items-center my-2 p-2 shadow-lg bg-light text-dark rounded-2 text-decoration-none' 
                    style={{right: "15px", zIndex: 100}}
                >
                    <NavLink to="/add_machines_to_grower/" className='btn btn-outline-dark my-2 mx-2'> <i className="fa-solid fa-code-pull-request"></i> {t("Assign Machines")} </NavLink>
                    <NavLink to="/add_machines_to_grower/your_assignments/" className='btn btn-outline-dark my-2 mx-2'> <i className="fa-solid fa-clock-rotate-left"></i> {t("Your Prev Assignments")} </NavLink>
                </div>
            }
            </>
        )
}

export default Navbar