const WidgetCard = ({
    cardTitle = "",
    children
}) => {
    return (
        <>
        <div className="card mb-3 shadow-sm">
            <div className="card-body">
                <h5 className="card-title fw-bold fs-5">{cardTitle}</h5>
                {children}
            </div>
        </div>
        </>
    )
}

export default WidgetCard