import Navbar from '../settings/components/Navbar'
import Offcanvas from '../../components/Offcanvas';
import {  UploadControllerHelp, PageInstructions } from './components/groups';
import './styles.css'
import { useTranslation } from 'react-i18next';

function InstructionsPage() {
    const {t} = useTranslation();
    return (
        <>
            <Navbar pageName={t("Support Portal Help Guides")}/>
            <Offcanvas />
            <p className=" fs-5 text-center mt-4" style={{letterSpacing: "3px"}}>{t("Support Portal Help Guides")}</p>
            <div className="row w-100 justify-content-center mx-auto">
                <div className="col-lg-6">
                    <PageInstructions />                                {/* Page Wise Instructions */}
                    <UploadControllerHelp />                    {/* Controller Upload Instructions  */}
                </div>
            </div> 
        </>
    )
}

export default InstructionsPage