import {ToastContainer} from 'react-toastify'
import Navbar from '../settings/components/Navbar'
import Offcanvas from "../../components/Offcanvas"
import DataTable from './components/DataTable/DataTable'
import FiltersOffcanvas from './components/DataTable/Filters/FiltersOffcanvas'
import { FileUpladerProvider } from './context/FileUploaderContext'
import { useTranslation } from 'react-i18next'

const UploadedFiles = () => {

    const {t} = useTranslation();

    return (
        <>
            <Navbar pageName={t("Your File Uploads")}/>
            <Offcanvas />

            <FileUpladerProvider>
                <div className="page-content px-2">
                    <DataTable />
                </div>

                <FiltersOffcanvas />
            </FileUpladerProvider>

            <ToastContainer 
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark" />
        </>
    )
}

export default UploadedFiles