import axios from 'axios';
import React from 'react'
import { toast } from 'react-toastify'

function ExportCSV() {
    
    async function handleButtonClick(event){
        event.preventDefault();
        try {
            const url = axios.defaults.baseURL + "api/smartreporting/export_csv/"
            fetch(url)
                .then(response => response.blob())
                .then(blob => {
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = url;
                    a.download = "report.csv";
                    document.body.appendChild(a);
                    a.click();    
                    toast("Downloaded...");
                    a.remove();  
                });
        }
        catch (error) {
            console.log(error);
        }
    }
    return (
        <>
            <button className="btn btn-dark" onClick={(e) => {handleButtonClick(e)}}>CSV</button>
        </>
    )
}

export default ExportCSV