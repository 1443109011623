import axios from 'axios';
import React, { useContext, useState } from 'react';
import AuthContext from '../../context/AuthProvider';
import FarmCommandLogoGrey from '../../assets/images/FarmCommandLogoGrey.png';
import BackgroundImage from '../../assets/images/background-image-001.jpg';
import { Alert, Spinner } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import "./styles.css"; // Static import for styles
import {FARM_COMMAND_HOST} from '../../constants'
import { fetch_growers, initializeLayouts } from '../home_page/utils';
import { DashboardContext } from '../../pages/home_page/context/DashboardContext';

function Login() {
  const { t } = useTranslation();
  const { authStatus, setToken, setAuthStatus } = useContext(AuthContext);
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [userInfo , setUserInfo] = useState(false);
  const [preferredLanguage , setPreferredLanguage] = useState('');
  const [userName , setUsername] = useState("");
  const [layoutsInitialized, setLayoutsInitialized] = useState(false); // New state
  const {
        setLayouts, DEFAULT_LAYOUT,
        USER_ALLOWED_WIDGETS, setUserWidgets
    } = useContext(DashboardContext)

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post('/api/login/', formData);
      if (response.data?.status) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("JIRAAuthenticated", String(response.data.JIRAAuthenticated));
        localStorage.setItem("isNSPSelfServeGrower", response.data.isNSPSelfServeGrower);
        setToken(response.data.token);
        setAuthStatus(true);
        await getUserInfo(userInfo);
        // Navigate to and reload the page to ensure the layout is initialized
        window.location.href = "/";
      } else {
        setAuthStatus(false);
        setError("Invalid Credentials");
        setTimeout(() => setError(""), 3000);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }

  async function getUserInfo(userInfo) {
    if (!localStorage.getItem('language')) {
      setPreferredLanguage('en_US');
      localStorage.setItem('language', 'en_US');
    } else {
      setPreferredLanguage(localStorage.getItem('language'));
    }

    if (!userInfo) {
      try {
        setLoading(true);
        const jira_user_response = await axios.get("/api/getUserInfo/");
        if (jira_user_response.data?.userInfo) {
          localStorage.setItem("JIRAAuthenticated", JSON.stringify(true));
        }
        const current_user_response = await axios.get(`${FARM_COMMAND_HOST}/client/currentuser/`, { headers: { "Authorization": `Token ${localStorage.getItem("token")}` } });
        if (current_user_response.status === 200) {
          setUserInfo(current_user_response.data);
          setUsername(`${current_user_response.data?.first} ${current_user_response.data?.last}`);
          localStorage.setItem('client_id', current_user_response.data?.id);
          localStorage.setItem("user_roles", current_user_response.data?.roles);
          localStorage.setItem("username", current_user_response.data?.user?.username);
          localStorage.setItem("first", current_user_response.data?.first);
          localStorage.setItem("last", current_user_response.data?.last);
          localStorage.setItem("displayName", current_user_response.data?.first + ', ' + current_user_response.data?.last);
          initializeLayouts(); // Initialize layouts after successful login
          setLayoutsInitialized(true); // Trigger re-render
        }
      } catch (error) {
        console.log(error);
      } finally {
        fetch_growers();
        setLoading(false);
      }
    }
  };

  if (authStatus && layoutsInitialized) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <div className="d-flex flex-column min-vh-100 align-items-center justify-content-center login-bg mx-auto" style={{ backgroundImage: `url(${BackgroundImage})` }}>
      
        <img src={FarmCommandLogoGrey} className='img d-flex justify-content-center ms-2 mb-4 header-logo' alt="" height={20} />
        <div id="form_elements">
          {error && <Alert variant="danger" className="my-4">{error}</Alert>}
          <div className="form-group form-element mt-5 mx-auto">
            <input id="username" className="form-control" type="text" autoFocus placeholder={t("USERNAME/EMAIL")} name="username" required value={formData.username} onChange={handleChange} />
          </div>
          <div className="form-group form-element my-4 mx-auto">
            <input id="password" className="form-control" type="password" placeholder={t("PASSWORD")} name="password" required value={formData.password} onChange={handleChange} />
          </div>
          {loading ? <div className="d-flex justify-content-center"><Spinner animation="border" className="text-light" /></div> : (
            <div className="form-group form-element mb-5 mt-4 mx-auto d-flex justify-content-center">
            <button className="btn btn-dark login-btn w-100 border-2 shadow-lg" onClick={handleSubmit} type="submit" value="Login" id="login">Login</button>
            </div>
          )}
          <div className="text-white form-element text-center mt-4">
            <small>{t("Having issues logging in, please contact support at:")} <br/>+1 (866) 858-4651</small>
          </div>
          </div>
      </div>
  );
}

export default Login;
