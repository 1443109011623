import { FaFilePdf } from "react-icons/fa";
import { AiFillFilePdf } from "react-icons/ai";
import { BsFileEarmarkPdfFill } from "react-icons/bs";
import { TbEaseInOutControlPoints } from "react-icons/tb";
import { FaFileCsv } from "react-icons/fa6";

export const HARVEST_PDF = "harvest pdf-request";
export const HARVEST_PDF_LABEL = "Harvest PDF Request";
export const APPLIED_PDF = "applied pdf-request";
export const APPLIED_PDF_LABEL = "Applied PDF Request";
export const PROFIT_PDF = "profit-vra";
export const PROFIT_PDF_LABEL = "Profit VRA PDF Request";
export const YIELD_BY_ZONE = "yield-by-zone-csv"
export const YIELD_BY_ZONE_LABEL = "Yield By Zone CSV Report"
export const SHAPEFILE_EXPORT = "shapefile-export"
export const SHAPEFILE_EXPORT_LABEL = "Canplug Shapefile Export"
export const HARVEST_APPLIED_PDFS = [HARVEST_PDF, APPLIED_PDF];
export const PDF_REQEUSTS = [HARVEST_PDF, APPLIED_PDF, PROFIT_PDF]
export const PAT_REQUESTS = [SHAPEFILE_EXPORT, YIELD_BY_ZONE, PROFIT_PDF]

// React Select Options
export const REQUEST_TYPES = [ 
    {"label": HARVEST_PDF_LABEL, "value": HARVEST_PDF, "icon": FaFilePdf },
    {"label": APPLIED_PDF_LABEL, "value": APPLIED_PDF, "icon": AiFillFilePdf},
    {"label": PROFIT_PDF_LABEL, "value": PROFIT_PDF, "icon": BsFileEarmarkPdfFill },
    {"label": YIELD_BY_ZONE_LABEL, "value": YIELD_BY_ZONE, "icon": FaFileCsv },
    {"label": SHAPEFILE_EXPORT_LABEL, "value": SHAPEFILE_EXPORT, "icon": TbEaseInOutControlPoints },
]

export const HARVEST = "harvest";
export const APPLIED = "applied";
export const PROFIT = "profit";

// React Select Options
export const OPERATIONS = [
    {"label": HARVEST.toLocaleUpperCase(), "value": HARVEST},
    {"label": APPLIED.toLocaleUpperCase(), "value": APPLIED},
]

export const CANPLUG = "canplug";
export const CONTROLLER = "controller";
export const MERGED = "merged";

// React Select Options
export const DATATYPES = [
    {"label": CANPLUG, "value": CANPLUG},
    {"label": CONTROLLER, "value": CONTROLLER},
]

export const YIELD_RASTER = "Yield Raster"
export const RAW_YIELD = "Raw Yield"
export const FUEL_USAGE = "Fuel Usage"
export const OVERLAP = "Overlap"
export const DATE = "Date" 
export const PRODUCTIVITY = "Productivity"
export const SPEED = "Speed" 
export const MOISTURE = "Moisture"
export const VEHICLE = "Vehicle" 
export const ELEVATION = "Elevation"
export const STANDARD = "Standard"

export const LAYER_TYPES = [
    YIELD_RASTER,
    RAW_YIELD,
    STANDARD,
    VEHICLE,
    DATE,
    OVERLAP,
    PRODUCTIVITY,
    SPEED,
    MOISTURE,
    ELEVATION,
    FUEL_USAGE,
]

export const FILE_TYPES = {
    PDF: "pdf",
    SHAPEFILE: "shapefile",
    CSV: "csv",
    ZIP: "zip"
}

export const CUSTOMER_REQUEST = {
    PDF_REQUEST: "pdf-request",
    DATA_EXIT: "data-exit",
    SHAPEFILE_EXPORT: "shapefile-export"
}

export const API_ENDPOINTS = {
    PDF_REQUEST: "/servicerequest/pdf_request/",
    PAT_REQUEST: "/servicerequest/pat_request/",
    GENERATE_BLOB_URL: "/servicerequest/generate_blob_url",
    FETCH_SERVICE_REQUESTS: "/servicerequest/fetch_service_requests",
    SR_ADMIN: "/servicerequest/admin",
}

export const STATUS = {
    "COMPLETED": "completed"
}