import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { requestParametersAddorChange } from "../../../../../features/RequestParametersSlice";


const Confirmation = ({fieldAccordion}) => {
    
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { fields_selected } = useSelector(state => state.requestParameters);
    
    const fieldAccordionHeader = fieldAccordion.current?.childNodes[0]?.childNodes[0];

    // Event Handlers
    function checkAccordionHeader(){
        fieldAccordionHeader?.setAttribute("aria-checked" , true);
    }

    function uncheckAccordionHeader(params) {
        fieldAccordionHeader?.setAttribute("aria-checked" , false);
    }

    const handleCheckBoxChange = (event) => {
        const {checked} = event.target;
        dispatch(requestParametersAddorChange({"fields_selected": checked}))
        if(checked) {
            checkAccordionHeader();
        } else {
            uncheckAccordionHeader();
        }
    }
    
    return (
        <div className="d-flex justify-content-center ">
            <input 
                type="checkbox" 
                name="fields-completeness" 
                id="fields-completeness"
                className="form-check-input p-2"
                onChange={handleCheckBoxChange}
                checked={fields_selected ? "checked" : ""}
                    />
            <label htmlFor="fields-completeness" className="ms-3">
                {t("please click on this checkbox when you have completed your fields selection.")}
            </label>
        </div>
    )
}

export default Confirmation