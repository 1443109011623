import React, { useEffect, useState } from 'react';
import Fade from 'react-bootstrap/Fade';
import 'bootstrap/js/dist/collapse'
import { Spinner } from 'react-bootstrap';
import axios from 'axios';
import Navbar from './settings/components/Navbar'
import Offcanvas from '../components/Offcanvas'
import {FARM_COMMAND_HOST} from '../constants'
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';

function CreatedTasks() {

    const {t} = useTranslation()
    // states
    const [tasks , setTasks] = useState([])
    const [prev, setPrev] = useState(false)
    const [next, setNext] = useState(false)
    const [loading, setLoading] = useState(false)

    async function fetchTasks(){
        setTasks([])
        setLoading(true)
        try {
            const response = await axios.get("/api/tasks/");
            console.log(response.data)
            setTasks(response.data?.results);

            if(document.location.protocol === 'http:'){
                setPrev(response.data?.previous); setNext(response.data?.next);
            }
            else if(document.location.protocol === 'https:'){
                (response.data?.previous) ? setPrev(response.data?.previous.replace("http://", "https://")): setPrev(false);
                (response.data?.next) ? setNext(response.data?.next.replace("http://", "https://")) : setNext(false);
            }

            setLoading(false)
        } catch (error) {
            console.log('Error while fetching posted tasks...');
            setLoading(false)
        }
    }

    async function fetchNextOrPrev(url){
        setTasks([])
        setLoading(true)
        try {
            const response = await axios.get(url);
            console.log(response.data)
            setTasks(response.data?.results);

            if(document.location.protocol === 'http:'){
                setPrev(response.data?.previous); setNext(response.data?.next);
            }
            else if(document.location.protocol === 'https:'){
                (response.data?.previous) ? setPrev(response.data?.previous.replace("http://", "https://")): setPrev(false);
                (response.data?.next) ? setNext(response.data?.next.replace("http://", "https://")) : setNext(false);
            }

            setLoading(false)
        } catch (error) {
            console.log('Error while fetching posted tasks...');
            setLoading(false)
        }
    }

    // effects
    useEffect(() => {
        fetchTasks();
    }, [])

    return (
    <>
        <Navbar pageName={t('Your Patricia Tasks')} />
        <Offcanvas />

        <div className="page-content px-3">
            <p className='fs-4 p-3 text-center'>{t("Tasks You have Submitted For Processing")}</p>

            {
                !loading &&
                <div className="d-flex p-3">
                    <p className='fs-5 my-auto'>{t("User ID")}: {localStorage.getItem("client_id")}</p> 
                    <div className="ms-auto">
                        {prev && <button className="mx-2 btn btn-primary" onClick={() => {fetchNextOrPrev(prev)}}>Prev</button>}
                        {next && <button className="mx-2 btn btn-success" onClick={() => {fetchNextOrPrev(next)}}>Next</button>}
                    </div>
                </div>
            }
            
            <figure className='wrapper' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto"}}>
            <table className="table table-hover table-responsive table-dark rounded-3">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{t('Posted Time')}</th>
                    <th scope="col">{t('Run Type')}</th>
                    <th scope="col">{t('Task ID')}</th>
                    {/* <th scope="col">{t('Delete')}</th> */}
                </tr>
                </thead>
                    {loading && <Spinner className='m-2 text-center text-danger'/>}
                <tbody>
                    {
                        tasks.map(( task , index ) => {
                            return (<>
                                <TableRow data={task} key={index} id={index} />
                            </>)
                        })
                    }
                </tbody>
            </table>
            </figure>
        </div>
        <ToastContainer />
    </>
    );

    function TableRow(props){
        const [open , setOpen] = useState(false);
        const [output , setOutput] = useState(false);
        const [loading, setLoading] = useState(false);


        async function handleExpandRow(){
            setOpen((prev) => {return !prev})
            if(output){setLoading(false); return;}
            setLoading(true);
            // fetching output....
            console.log('fetching..')
            const url =`${FARM_COMMAND_HOST}/patricia/${props.data.runtype}-task/${props.data.task_id}`
            const response = await fetch(url , { method: "GET" ,  headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'Authorization': `Token ${localStorage.getItem("token")}`, } })
            const data = await response.json()
            setOutput(data);
            setLoading(false);
        }


        async function handleDelete(task_id){
            console.log('Deleting task ' + task_id)
            const response = await axios.delete(`/api/task/${task_id}`);
            if(response.data?.status){
                toast("Deleted")
                fetchTasks();
            }
        }
        

        return (
            <>
                <tr >
                    <td onClick={handleExpandRow}>{props.id + 1}</td>
                    <td onClick={handleExpandRow}>{props.data.date_posted}</td>
                    <td onClick={handleExpandRow}>{props.data.runtype}</td>
                    <td onClick={handleExpandRow}>{props.data.task_id}</td>
                    {/* <td><button className='btn btn-danger' data-bs-toggle="modal" data-bs-target={`#a${props.data.task_id}`}>-</button></td> */}
                </tr>

                {/* Displays output of harvest/applied task  */}
                <Fade in={open} unmountOnExit={true}>
                    <tr onDoubleClick={() => {setOpen(false)}}>
                        <td colSpan={5}>
                            {loading && <Spinner className='m-4'/>}
                            {output?.field && <pre className='mx-5 p-2'>{`Field ID: ${output?.field}`}</pre> }
                            {output?.data_record_count_controller && <pre className='mx-5 p-2'>{`data_record_count_controller: ${output?.data_record_count_controller}`}</pre> }
                            {output?.data_record_count_canplug && <pre className='mx-5 p-2'>{`data_record_count_canplug: ${output?.data_record_count_canplug}`}</pre> }
                            {output?.notes && <pre className='mx-5 p-2'>notes: {JSON.stringify(JSON.parse(output?.notes), null, 2)}</pre> }
                            {output?.settings && <pre className='mx-5 p-2'>settings: {JSON.stringify(JSON.parse(output?.settings), null, 2)}</pre> }
                            {output?.initialSettings && <pre className='mx-5 p-2'>initialSettings: {JSON.stringify(JSON.parse(output?.initialSettings), null, 2)}</pre> }
                        </td>
                    </tr>
                </Fade>
                {/* Displays the output of harvest/applied task */}


                {/* Delete Confirmation Modal */}
                <>
                <div className="modal fade" id={`a${props.data.task_id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog bg-primary">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5 text-dark" id="exampleModalLabel">{t('Delete Confirmation')}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-black">
                            {t('Do you really wanna delete ???')}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{t('Close')}</button>
                            <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={(event) => {handleDelete(props.data.task_id)}}>{t('Delete')}</button>
                        </div>
                        </div>
                    </div>
                </div>
                </>
            </>
        )
    }
}

export default CreatedTasks