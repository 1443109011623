import React, { createContext, useRef, useState } from 'react'

export const ControllerDataContext = createContext()

function ControllerDataProvider({children}) {
    
    const prevData = useRef([]);

    // Lifted States
    const [ data, setData ] = useState([]); 
    const [ loading , setLoading ] = useState(false);
    const [ message, setMessage ] = useState("");
    const [ searchType, setSearchType ] = useState("default");  
    const [ next , setNext ] = useState(null);
    const [ prev , setPrev ] = useState(null);
    const [labelIdMap, setLabelIdMap] = useState({})

    return (
        <ControllerDataContext.Provider value={{ data, setData, prevData, loading, setLoading, message, setMessage, next , setNext, prev , setPrev, searchType, setSearchType, labelIdMap, setLabelIdMap }}>
            {children}
        </ControllerDataContext.Provider>
    )
}

export default ControllerDataProvider