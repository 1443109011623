import Select, { components } from 'react-select'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { 
    APPLIED, HARVEST, PROFIT, 
    FILE_TYPES, HARVEST_PDF, PROFIT_PDF, PDF_REQEUSTS, HARVEST_APPLIED_PDFS,
    REQUEST_TYPES, SHAPEFILE_EXPORT, YIELD_BY_ZONE, 

} from '../constants'
import { requestParametersAddorChange } from '../../../features/RequestParametersSlice';

const RequestType = () => {
    
    const dispatch = useDispatch();
    const { customer_request } = useSelector(state => state.requestParameters);
    const { t } = useTranslation();
    
    function handleChange(event){
        
        const {value} = event;
        let dispatch_object = {}

        console.log(value)

        // dispatch operation, file_type & customer_request
        if(PDF_REQEUSTS.includes(value)) {
            let operation = HARVEST;
            if(HARVEST_APPLIED_PDFS.includes(value)){
                operation = value === HARVEST_PDF ? HARVEST : APPLIED;
            } else if(value === PROFIT_PDF){
                operation = PROFIT;
            }
            dispatch_object = {"file_type": FILE_TYPES.PDF, "operation": operation};
        } 
        else if ([SHAPEFILE_EXPORT].includes(value)){
            dispatch_object = {"file_type": FILE_TYPES.SHAPEFILE, "operation": null};
        }
        else if ([YIELD_BY_ZONE].includes(value)){
            dispatch_object = {"file_type": FILE_TYPES.CSV, "operation": PROFIT};
        } 
        else {
            dispatch_object = {"file_type": null, "operation": null};
        }
        
        // add customer_request to dispatch_object
        dispatch_object["customer_request"] = value;

        dispatch(requestParametersAddorChange(dispatch_object)); // dispatch to reqParams

    }
    
    return (
        <div className="mb-2 my-4">
            <label htmlFor="" className="form-label fs-5">{t('Select Request Type')}:</label>
            <Select options={REQUEST_TYPES} components={{Option: IconOption}}
                onChange={handleChange}
                />
        </div>
    )
}

const { Option } = components;

function IconOption(props){

    const { t } = useTranslation();

    return (
        <Option {...props}>
            {<props.data.icon size={24} />} <span className='mx-2'>{t(props.data.label)}</span> 
        </Option>
    )
}


export default RequestType