import {createSlice} from '@reduxjs/toolkit'

const initialState = {}

const rxParametersSlice = createSlice({
    initialState: initialState,
    name: 'rxParameters',
    reducers: {
        rxParametersAddorChange : (state , action) => {
            // Adds if the payload is new, changes if it already exist
            return {
                ...state,
                ...action.payload
            }
        },

        rxParameterRemove : (state , action) => {
            // Reletes the payload from the global state.
            const copy = {...state};
            delete copy[action.payload];
            return copy;
        },

        rxParametersReset : (state , action) => {
            // resets the state, sets to empty object.
            return {...initialState}  
        } 
    },
});

export const { rxParametersAddorChange, rxParameterRemove, rxParametersReset } = rxParametersSlice.actions
export default rxParametersSlice.reducer;