import React, { useEffect, useState, useCallback} from 'react'
import Select from 'react-select'
import axios from 'axios'

import {useDispatch, useSelector} from 'react-redux'
import {requestParametersAddorChange} from '../features/RequestParametersSlice'
import {ticketParametersAddorChange} from '../features/TicketParametersSlice'
import { useTranslation } from 'react-i18next'

function Fields() {

    const { t } = useTranslation();

    // Dispatcher
    const dispatch = useDispatch()

    // Global State
    const requestParameters = useSelector(state => state.requestParameters)


    const [ fields , setFields ] = useState([]) // to store fields returned by the api call
    const [ show , setShow ] = useState(false) // to control fields selector visibility
    const [values , setValues] = useState([]) // to track selected fields

    const fetchFields = useCallback(async () => {
      setShow(false);
      setValues([]);
      setFields([]);

      try {
          let response = await axios(`/api/getFields/?farmID=[${requestParameters.farm}]&seasonID=${requestParameters.season}`);
          setFields(response.data?.map((e) => {
              return {
                  "value": e.id,
                  "label": `${e.id} - ${e.label}`
              };
          }));
          setShow(true);
      } catch (err) {
          console.log('Error while fetching fields...');
      }
    }, [requestParameters.farm, requestParameters.season]); // Dependencies of fetchFields

    // Fetch Fields, when farm/grower/hub/season changes
    useEffect(() => {
        fetchFields();
    }, [fetchFields]); // Now fetchFields is a dependency of useEffect

    useEffect(() => {
      // Adding selected fields to the global state
      let fields = values.map(e => e.label)
      let field_ids = values.map(e => e.value)

      dispatch(requestParametersAddorChange( { 'fields': field_ids } )) // For Task
      dispatch(ticketParametersAddorChange( { 'field' : fields.toString(), 'field_id': field_ids?.toString() } )) // For Ticket

    }, [values, dispatch]);

    
    function handleChange(event){
        // adding selected fields to the local state
        setValues(event)
    }


    function handleSelectAll(event){
      // Adding all the fields to the local state...
      event.preventDefault();
      setValues(fields)
    }


    return (
        <>
            { show && 
            <>
              <Select placeholder={t("Select fields")} options={fields} onChange={handleChange} isMulti value={values} closeMenuOnSelect={false} />
              <button onClick={handleSelectAll} className='btn btn-outline-dark mt-3'>Select All</button>
            </>
            }
        </>
    )
}


export default Fields
