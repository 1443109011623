import React , { useState, useEffect } from 'react'
import {useDropzone} from 'react-dropzone'
import file_image from '../../assets/images/file.png'

import { ticketParametersAddorChange, ticketParameterRemove } from '../../features/TicketParametersSlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

function ScreenShotUpload() {

  const { t } = useTranslation();
  // dropzone style
  const dropzoneStyles = {cursor: "pointer", height: "150px", border: "2px solid rgba(0,0,0,0.3)", textAlign: "center", display:"flex" , background: "#ffffff", padding: "20px"}
  
  // State
  const [files, setFiles] = useState([]); // files selected
  const [error , setErrorMessage] = useState("") // to show error message

  // Dispatcher
  const dispatch = useDispatch();

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
    },
    onDrop: acceptedFiles => {
      setFiles((prev) => {
        return [...prev, ...acceptedFiles]
      })
    }
  });

  function formatSizeUnits(bytes){
    if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed(2) + " GB"; }
    else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed(2) + " MB"; }
    else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed(2) + " KB"; }
    else if (bytes > 1)           { bytes = bytes + " bytes"; }
    else if (bytes === 1)          { bytes = bytes + " byte"; }
    else                          { bytes = "0 bytes"; }
    return bytes;
  }

  // error handling
  useEffect(() => {
    if(files.length > 10){
      setErrorMessage("You Can Only Upload A Maximum Of 10 Files only")
      setTimeout(() => {setErrorMessage("")}, 3000)
      setFiles(files.slice(0,10))
    }

    files.map((file) => {
      if(file.size > 10000000){
        setFiles(files.filter(f => f !== file))
        setErrorMessage("Your file is greater than 10MB")
        setTimeout(() => {setErrorMessage("")}, 3000)
      }
    })

    dispatch( ticketParametersAddorChange({"files": files}) )
  } ,[files])
  
  // Style for preview thumbnail
  const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16
  };
  

  // remove button click event handler
  function handleRemoveFile(event, index){
    event.preventDefault()
    setFiles(files.filter((e , key) => { return key !== index}))
  }

  // preview thumbnail component
  const thumbs = files.map((file , index) => {
    let src = URL.createObjectURL(file);
    return (
      <>
        <div className="mx-3 my-2 p-2 flex-item" style={{flex: ""}}>
          <span className='d-block text-white mb-2 text-center '> <button className='btn btn-danger px-1 py-0 align-items-center' style={{cursor: "pointer"}} onClick={(event) => {handleRemoveFile(event, index)}}>Remove</button> </span>
          {
            file.type.includes("image") ?
            <>
                <div className="mx-3 my-3" style={{color: "rgba(0,0,0,0.9)", zIndex: -1}}>
                  <img alt={file.path} src={src} style={{width:"120px" , height: "120px", borderRadius: "20px" }} data-bs-toggle="tooltip"  data-bs-placement="top" title={file.path} onLoad={() => {URL.revokeObjectURL(src)}} />
                </div>
            </> :
            <><img alt={file.path} src={file_image} style={{width:"120px" , height: "120px", borderRadius: "20px" }} data-bs-toggle="tooltip"  data-bs-placement="top" title={file.path} /></>
          }

        </div>
      </>
    )
  });

  
  return (
    <>
        <div className="my-5">
          <p className='h3'>{t("Screenshot Upload")}</p>
          <p className='fs-5 mb-2'>{t("Please provide a screenshot best showing the issue described (this will be uploaded with the support ticket)")}</p>
          <p className='mt-3 mb-4 fst-italic'>{t("Note: 15mb image file limit and a 10 maximum file upload")}</p>

          <div className='' {...getRootProps({className: 'dropzone'})} style={ dropzoneStyles }>
            <input {...getInputProps()} />
            <p className='mx-auto my-auto fs-5 opacity-75'>{t("Drop or paste files here or Click to select upload")}!</p>
          </div>

          {
            error &&
            <p className='text-capitalize my-3 fs-5 text-danger'>{error}</p>
          }

          <aside style={thumbsContainer} className='mb-5'>
            <div className="d-flex flex-wrap">
              {thumbs}
            </div>
          </aside>
    </div>
    </>
  )
}

export default ScreenShotUpload