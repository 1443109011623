export const GROWER = "Grower"
export const FARM = "Farm"
export const FIELD = "Field"
export const SUBFIELD = "Subfield"

export const ROOT_ASSETS = [
    GROWER,
    FARM,
    FIELD,
    SUBFIELD
]

export const FILE_CATEGORIES = [
    "Crop Insurance",
    "Fertilizer Receipts",
    "Harvest Proof",
    "Inoculant Proof",
    "Irrigation Proof",
    "Manure Analysis",
    "Seed Receipts",
    "Tissue Tests",
    "Hail Insurance",
    "Land Title",
    "Landowner Lease Agreement",
    "Other"
]

export const ROOT_ASSETS_OPTIONS = ROOT_ASSETS.map(e => {return {"label": e, "value": e}})
export const FILE_CATEGORIES_OPTIONS = FILE_CATEGORIES.map(e => {return {"label": e, "value": e}})