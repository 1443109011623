import React, { useContext, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthContext from '../context/AuthProvider';
import axios from 'axios';

function ProtectedRoutes() {
    const { authStatus, setAuthStatus } = useContext(AuthContext);
    const [error, setError] = useState(null);

    useEffect(() => {
      const fetchAuthStatus = async () => {
          try {
              const response = await axios.get("/api/isAuthenticated/");
              if (response.data?.status) {
                  setAuthStatus(true);
              } else {
                  setAuthStatus(false);
              }
          } catch (err) {
              console.error('Error fetching authentication status:', err);
              setError(err.message || 'Error with server');
          }
      };
      fetchAuthStatus();
    }, [setAuthStatus]); // Remove the dependency array

    if (error) {
        // Render an error component or handle the error appropriately
        return <div>Error: {error}</div>;
    }

    return (
        <>
            {authStatus ? <Outlet /> : <Navigate to="/login" replace={true} />}
        </>
    );
}

export default ProtectedRoutes;
