import axios from "axios";
import {CODA_HOST, GET_DOWNLOAD_LINK_RAW, DOWNLOAD_GEO_GSON, DOWNLOAD_AGI} from '../../../constants'
import { toast } from "react-toastify";

async function download_raw(event, { id, filename }){
    // fetching output....
    event.preventDefault();
    try {
      const url = `${CODA_HOST}/prescriptionfile/${id}/${GET_DOWNLOAD_LINK_RAW}/`
      const response = await axios.post( "/coda/make_coda_call/" , {"url" : url})
      
      console.log(response.data?.gcs_blob_path)
      
      var link = document.createElement("a");

      link.setAttribute('download', '');
      link.href = response.data?.gcs_blob_path;
      document.body.appendChild(link);
      link.click();
      link.remove();

    } catch (error) {
      console.log(error)
    }
}


async function download_agi(event, {id, filename}){
  event.preventDefault();
  try {

    const response = await axios.get("/coda/get_coda_token/")
    const token = response.data?.coda_token

    if(token){
      const url = `${CODA_HOST}/prescriptionfile/${id}/${DOWNLOAD_AGI}/`
      toast("Downloading AGI File");
      fetch(url , {headers: {"Authorization": `Token ${token}`}})
          .then(response => {
            if(response.status === 200){
              return response.blob();
            }
            else{
              toast("Something Wen't wrong")
              console.log('Something went wrong while downloading agi file')
            }   
          })
          .then(blob => {
              var url = window.URL.createObjectURL(blob);
              var a = document.createElement('a');
              a.href = url;
              a.download = `${filename.replaceAll(".json", "")}.zip`;
              document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
              a.click();    
              toast("Downloaded...");
              a.remove();  //afterwards we remove the element again         
        });
    } else{
      toast("Something Wen't wrong")
      console.log('Something went wrong while downloading agi file')
    }
    

  } catch (error) {
      console.log(error);
  }
}

async function changeControllerType({
  event, prescriptionfile, controller_filetype, setLoading, setMessage
}){
  event.preventDefault();
  event.target.classList.add("disabled");
  setMessage("");
  setLoading(true);

  try{
    const response = await axios.post("/rxprozone/change_controller_filetype/", { "prescription_data": prescriptionfile, "controller_filetype": controller_filetype, "client_id": localStorage.getItem("client_id") })
    if([200, 201].includes(response.status))
      setMessage("Success, Refresh the table to see new entry")
  } catch (error) {
    console.log(error);
    const error_message = error?.response?.data?.error
    if(error_message)
      setMessage(error_message)
    else
      setMessage("Failed on changing controller filetype")
      event.target.classList.remove("disabled")
  } finally {
    setLoading(false)
  }
}


const fetchData = async (url, setData, setNext, setPrev, setLoading) => {
  // Makes CODA Call and update the passed states accordingly
  setLoading(true);
  try {
    const response = await axios.post("/coda/make_coda_call/", {"url": url})
    if(response.data?.results){setData(response.data?.results);}
    setNext(response.data?.next);
    setPrev(response.data?.previous);  
  } catch (error) {
    console.log(`Error on fetching data from ${url}`, error)
  }
  finally{
    setLoading(false);
  }
}


export {download_raw, download_agi, changeControllerType, fetchData}