import axios from "axios";
import { useState } from "react";
import { Fade, Spinner } from "react-bootstrap"

const PointCount = ({
    pointCountData, 
    showPointCount, setShowPointCount,
    loading
}) => {
    
    const datafile_point_count = pointCountData?.data?.datafile_point_count;
    const els_pointdata_count = pointCountData?.data?.els_pointdata_count;
    const isGrower = localStorage.getItem("user_roles").split(",").includes("Grower");
    
    return (
        <Fade in={showPointCount} unmountOnExit={true}>
            <tr className='bg-primary text-white'>
                <td colSpan={11} onDoubleClick={() => { setShowPointCount(false) }}>
                    {loading && <Spinner className='m-4'/>}
                    {pointCountData && 
                    <div className="my-3">
                        
                        { !isGrower && <p className='mx-5 fs-4 text-white'>Datafile Point Count: { datafile_point_count ? datafile_point_count : 0 }</p> }
                        
                        <p className='mx-5 fs-4 text-white'>
                            { isGrower ? "Data Point Count" : "ELS Data Point Count" }
                            : { els_pointdata_count ?  els_pointdata_count : 0 }
                        </p>
                        <p className='mx-5 fs-4 text-white'>Percentage: { ( els_pointdata_count /  datafile_point_count ) * 100 ? ( els_pointdata_count / datafile_point_count ) * 100 : 0 }%</p> 
                    </div>
                    }
                </td>
            </tr>
        </Fade>
    )
}

export default PointCount