import React, { useState } from 'react'
import {Accordion} from 'react-bootstrap'

import { useDispatch } from 'react-redux';
import {requestParameterRemove, requestParametersAddorChange} from '../../../features/RequestParametersSlice'
import { useTranslation } from 'react-i18next';


function AppliedParameters() {
    
    const { t } = useTranslation();
    // dispatcher
    const dispatch = useDispatch();

    // States
    const [paramsChecked , setParamsChecked] = useState([]);
    
    //  Data -> List of all the applied parameters & its description
    const applied_params = [ 
      {
        parameter: "splitByProduct",
        description: "Generates a separate set of equipment layers for each product found on the field (only for Applied run)"
      },
      {
        parameter: "splitPlanter",
        description: "Make split planter variety maps."
      },
      {
        parameter: "splitLayersByVehicle",
        description: "Generates a separate set of equipment layers for each vehicle found on the field"
      },
      {
        parameter: "splitLayers",
        description: "Generates a separate set of equipment layers by picking the best method to split (product/vehicle), or use both."
      },
      {
        parameter: "splitByMultiOrSingleApplication",
        description: "Generates a separate set of equipment layers based on if points belong to single or multi product application run(only for Applied run)."
      },
      {
        parameter: "splitCanplugByImplementType",
        description: "Split layers by canplug implement type (default True for Canplug)"
      },
      {
        parameter: "splitByManagementZone",
        description: "Retrieve borders for each management zone"
      },
      {
        parameter: "splitPlanterProductLayers",
        description: "Used alongside splitPlanter run. Specifies if each applied product should get its own separate layer (only for Applied run)"
      }
    ]


    // Handler for checkbox change
    function handleCheckBoxChanges(event){
        const { name , checked } = event.target;
        
        if(checked){
            setParamsChecked(prev => [...prev, name])
            dispatch(requestParametersAddorChange({[name]: true})) // adding applied parameter to global state
        } else{
            // delete key
            setParamsChecked(prev => {return prev.filter(e => e !== name)})
            dispatch(requestParameterRemove(name)) // Removing applied parameter from global state
        }
    }

    // Handler for Boolean dropdown change
    function hanldeBooleanChange(event , param){
        let bool = event.target.value;
        bool = JSON.parse(bool)
        dispatch(requestParametersAddorChange({[param]: bool})) // changing boolean value of the applied parameter
    }

  return (
    <>
        <p className='fs-5 text-decoration-underline'>{t("Applied Runtype specific parameters")}</p>
            <Accordion className=''>
              <Accordion.Item eventKey={"canplug-parameters"} onClick={null} >
                <Accordion.Header>{t("Applied Parameters")}</Accordion.Header>
                    <Accordion.Body>
                        {
                        applied_params.map((data) => {
                            return (
                                <>
                                    <div className="accordion-body">
                                        <div className="d-flex rounded p-2 my-2 bg-dark text-white">
                                            <div className="form-check fs-5" >
                                                <input className="d-flex flex-column form-check-input mx-2" type="checkbox" id="" name={data.parameter} onChange={handleCheckBoxChanges}/>
                                                <label className="form-check-label" for="">
                                                    <span className='flex-item'>{data.parameter}</span>
                                                </label>
                                            </div>
                                            
                                            {/* appears only if check box is checked */}
                                            
                                            {
                                            paramsChecked.includes(data.parameter) && 
                                            <div className="ms-auto">
                                                <select name="bool" id="" className='btn btn-primary ms-auto' onChange={(event) => {hanldeBooleanChange(event , data.parameter)}}>
                                                    <option className='form-control' value="true">{t("True")}</option>
                                                    <option className='form-control' value="false">{t("False")}</option>
                                                </select>
                                            </div>
                                            }
                                            
                                        </div>
                                        <div className=''>
                                            <p className='mt-2 mb-2'><span className=''><strong>{t("Description")}*:</strong></span> {t(data.description)}</p>
                                        </div>
                                    </div>
                                </> 
                            )
                          })
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
  )
}

export default AppliedParameters