import React, { useEffect } from "react";
import { t } from "i18next";
import Navbar from '../settings/components/Navbar'
import { Farms, Fields, Offcanvas, Seasons } from "../../components";
import GrowerByLabel from "../../components/GrowerByLabel";
import { useDispatch, useSelector } from "react-redux";
import  Submit  from './components/Submit'
import ServiceOffering from "./components/ServiceOffering";
import { requestParametersReset } from "../../features/RequestParametersSlice";
import ContactName from "./components/ContactName";
import EmailAddress from "./components/EmailAddress";
import PhoneNumber from "./components/PhoneNumber";

function TaskFieldRequestForm() {
  const { 
    season, 
    grower,
    farm, 
    fields,
    service_offering,
    contact_name,
    email_address,
    phone_number,

  } = useSelector(
    (state) => state.requestParameters
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestParametersReset());
  }, [])

  return (
    <>
      <Navbar pageName={"Task Field Request Form"} />
      <Offcanvas />

      <div className="pb-5 mb-4 p-2 mx-md-auto page-content page-content selectors">
        <div className="mb-2 my-4">
          <label htmlFor="" className="form-label fs-5">
            {t("Select Season")}:
          </label>
          <Seasons />
        </div>

        {
          <div className="mb-2 my-4" style={{ zIndex: 100 }}>
            <label htmlFor="" className="form-label fs-5  ">
              {t("Select Grower")}:
            </label>
            <GrowerByLabel style={{ zIndex: 100 }} />
          </div>
        }

        {season && grower && (
          <div className="mb-2 my-4 ">
            <label htmlFor="" className="form-label fs-5  ">
              {t("Select Farm")}:
            </label>
            <Farms />
          </div>
        )}

        {season && farm && grower && (
          <div className="mb-2 my-4 ">
            <label htmlFor="" className="form-label fs-5  ">
              {t("Select Fields")}:
            </label>
            <Fields />
          </div>
        )}
        
        {
        season && farm && grower && (
          <div className="mb-2 my-4">
            <label htmlFor="" className="form-label fs-5  ">
              {t("Select a Service Offering")}:
            </label>
            <ServiceOffering />
          </div>
        )}

      {
        season && farm && grower && (
          <div className="mb-2 my-4">
            <label htmlFor="" className="form-label fs-5  ">
              {t("Enter Your Contact Name")}:
            </label>
            <ContactName />
          </div>
        )
      }

      {
        season && farm && grower && contact_name && (
          <div className="mb-2 my-4">
            <label htmlFor="" className="form-label fs-5  ">
              {t("Enter Your Email Address")}:
            </label>
            <EmailAddress />
          </div>
        )
      }

      {
        season && farm && grower && contact_name && email_address && (
          <div className="mb-2 my-4">
            <label htmlFor="" className="form-label fs-5  ">
              {t("Enter Your Phone Nummber")}:
            </label>
            <PhoneNumber />
          </div>
        )
      }


      {
        (
          season && farm && grower && fields && fields?.length > 0 && service_offering 
          && contact_name && email_address && phone_number
        ) && (
          <div className="my-5">
            <Submit />
          </div>
        )
      }
    </div>
    </>
  );
}

export default TaskFieldRequestForm;
