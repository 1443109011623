import React , { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom'
import 'animate.css';

import Navbar from './settings/components/Navbar'
import Offcanvas from '../components/Offcanvas';
import Seasons from '../components/Seasons';
import GrowerByLabel from '../components/GrowerByLabel';
import Farms from '../components/Farms';
import Fields from '../components/Fields';
import Datatype from '../components/Datatype';
import Runtype from '../components/Runtype'

import PruneParameters from '../components/create_task/parameters/PruneParameters';
import WidthChange from '../components/create_task/parameters/WidthChange';
import ControllerParameters from '../components/create_task/parameters/ControllerParameters';
import CanplugParameters from '../components/create_task/parameters/CanplugParameters';
import DebugPointsParameter from '../components/create_task/parameters/DebugPointsParameter';
import Review from '../components/create_task/Review';

import AppliedParameters from '../components/create_task/parameters/AppliedParameters';
import HarvestParameters from '../components/create_task/parameters/HarvestParameters';
import {useTranslation} from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import { requestParametersReset } from '../features/RequestParametersSlice';

function CreateTask() {
  const dispatch = useDispatch() //dispatcher 

  const {t} = useTranslation() 

  const requestParameters = useSelector(state => state.requestParameters); // global state
  
  const location = useLocation();


  useEffect(() => {
    dispatch(requestParametersReset()) // clearing global state
  } , [location])


  return (
  <>
      <Navbar pageName={t('Submit Patricia Tasks')}/>
      <Offcanvas />
      <div className="row w-100 justify-content-center mx-auto">
        <div className="col-lg-6">
    
          <div className="justify-content-center mb-3 flex-column my-4">
            <div className="mb-2 my-4">
              <NavLink to="/instructions"><button className='btn btn-outline-primary mt-2'> <i className="fa-solid fa-circle-info mx-2"></i>{t('Instructions')}</button></NavLink>
            </div>

            <form>
              
              <div className="mb-2 my-4">
                <label htmlFor="" className="form-label fs-5">{t('Select Season')}:</label>
                <Seasons />
              </div>

            {
              <div className="mb-2 my-4"  style={{zIndex: 100}}>
                <label htmlFor="" className="form-label fs-5  ">{t('Select Grower')}:</label>
                <GrowerByLabel style={{zIndex: 100}}/>
              </div> 
            }
            
            {
              (requestParameters.season && requestParameters.grower) && 
              <div className="mb-2 my-4 ">
                <label htmlFor="" className="form-label fs-5  ">{t('Select Farm')}:</label>
                <Farms />
              </div> 
            }


            {
              (requestParameters.season && requestParameters.farm && requestParameters.grower) && 
              <div className="mb-2 my-4 ">
                <label htmlFor="" className="form-label fs-5  ">{t('Select Fields')}:</label>
                <Fields />
              </div> 
            }


            {
              (requestParameters.season &&  requestParameters.grower &&  requestParameters.farm &&  requestParameters.fields) && 
              <div className="my-4 ">
              <label htmlFor="" className="form-label fs-5 mb-2  ">{t('Select LayerType')}:</label>
              <Runtype />
              </div>
            }

            {
              (requestParameters.season && requestParameters.grower &&  requestParameters.farm &&  requestParameters.fields) && 
              <div className="my-4 mb-5"> { /* animate__animated animate__fadeInUp */}
              <label htmlFor="" className="form-label fs-5 mb-2  ">{t('Select DataType')}:</label>
              <Datatype />
              </div>
            }
        
        {
        (requestParameters.season && requestParameters.grower && requestParameters.farm && requestParameters.farm.length > 0 &&  requestParameters.fields.length > 0 && requestParameters.datatype && requestParameters.runtype) && 
            <>

            <div className="mb-4 my-5 animate__animated animate__fadeInUp" style={{zIndex: -99}}>
              <p className='fs-2'>{t('Notable Parameters')}</p>
              <p className='fs-5'>{t('Please review the notable parameters shown below in the expandable dropdowns. If there is a parameter that relates to your issue, Please click the checkbox appropriately to run the task')}</p>
            </div>
            

            <DebugPointsParameter />

            <WidthChange />

            {/* Specific to Controller */}
            {
              (requestParameters.season && requestParameters.grower && requestParameters.farm.length > 0 &&  requestParameters.fields.length > 0 && requestParameters.datatype === "controller" && requestParameters.runtype) && 
              <div className="mb-5 animate__animated animate__fadeInUp">
                <ControllerParameters />
              </div>
            }

            {/* Specific to Canplug */}
            {
              (requestParameters.season && requestParameters.grower && requestParameters.farm.length > 0 &&  requestParameters.fields.length > 0 && requestParameters.datatype === "canplug" && requestParameters.runtype) && 
              <div className="mb-5 animate__animated animate__fadeInUp">
                <CanplugParameters />
              </div>
            }

            {/* Specific to Applied Parameters */}
            {
              (requestParameters.season && requestParameters.grower && requestParameters.farm.length > 0 &&  requestParameters.fields.length > 0 && requestParameters.datatype  && requestParameters.runtype === "applied") && 
              <div className="mb-5 animate__animated animate__fadeInUp">
                <AppliedParameters />
              </div>
            } 

            {/* Specific to Harvest Parameters */}

            {
              (requestParameters.season && requestParameters.grower && requestParameters.farm.length > 0 &&  requestParameters.fields.length > 0 && requestParameters.datatype && requestParameters.runtype  === "harvest") && 
              <div className="mb-5 animate__animated animate__fadeInUp">
                <HarvestParameters />
              </div>
            }

            {
              (requestParameters.season && requestParameters.grower && requestParameters.farm.length > 0 &&  requestParameters.fields.length > 0 && requestParameters.datatype && requestParameters.runtype  === "harvest") && 
              <div className="mb-5 animate__animated animate__fadeInUp">
                <PruneParameters />
              </div>
            }

            <Review />

        </>
        }
          
        </form>
        </div>
        </div>
        </div>
  </>
  )
}

export default CreateTask