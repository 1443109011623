import React, { useMemo, useState } from "react";

// Custom text column filter component
export function TextColumnFilter({ column }) {
    const { filterValue, setFilter } = column;

    return (
        <>
        {filterValue}
        <input
            value={filterValue || ''}
            onChange={(e) => setFilter(e.target.value)}
            placeholder={`Filter ${column.Header}`}
        />
        </>
    );
}

export function DateRangeColumnFilter({ column }) {
    
    const {
        filterValue,
        setFilter,
        preFilteredRows,
    } = column;

    // State for start and end date input values
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    
    const handleFilterChange = () => {
        setFilter([startDate, endDate]);
    };
    
    return (
    <div>
        <input
            type="date"
            onChange={(e) => {
                const selectedDate = new Date(e.target.value).toISOString().replace("Z", "");
                console.log(selectedDate)
                setStartDate(selectedDate);
            }}
            placeholder="dd-mm-yyyy"
        />
        {' - '}
        <input
            type="date"
            onChange={(e) => {
                const selectedDate = new Date(e.target.value).toISOString().replace("Z", "");
                setEndDate(selectedDate);
            }}
            placeholder="dd-mm-yyyy"
        />
        <button className="btn btn-primary" onClick={handleFilterChange}>Apply</button>
    </div>
    );
}