import { useRef, useState } from "react";
import AssetCategory from "./AssetCategory";
import FileCategory from "./FileCategory";
import FilterButton from "./FilterButton";
import AssetFilter from "./AssetFilter";
import FilenameFilter from "./FilenameFilter";
import { useTranslation } from "react-i18next";


const FiltersOffcanvas = () => {
    
    const { t } = useTranslation();
    const urlSearchParams = useRef(new URLSearchParams());
    const [selectedAssetCategory, setSelectedAssetCategory] = useState("")

    return (
        <div className="offcanvas offcanvas-end" tabindex="-5" id="fileupload-filter-offcanvas-end" style={{backgroundColor: "#ECECEC", zIndex: "100001"}}>
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasResponsiveLabel">{t("Filters")}</h5>
                <button type="button" id="close-filter-offcanvas-end" className="btn-close" data-bs-dismiss="offcanvas" data-bs-target="#fileupload-filter-offcanvas-end"></button>
            </div>
            
            <div className="offcanvas-body">
                <AssetCategory urlSearchParams={urlSearchParams} setSelectedAssetCategory={setSelectedAssetCategory} />
                <FileCategory urlSearchParams={urlSearchParams} />
                <AssetFilter urlSearchParams={urlSearchParams} selectedAssetCategory={selectedAssetCategory} />
                <FilenameFilter urlSearchParams={urlSearchParams} />
            </div>

            <div className="offcanvas-footer d-flex align-items-center justify-content-center  my-5">
                <FilterButton urlSearchParams={urlSearchParams} />
            </div>

        </div>
    )
}

export default FiltersOffcanvas