import React, { useContext, useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'
import FRequestContext from '../../contexts/FRequest';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

function Grower() {

    const { t } = useTranslation();

    const [ loading, setLoading ] = useState(false); 
    const [ growers , setGrowers ] = useState([]); // to store growers returned from api
    const [ selected_grower, set_selected_grower ] = useState(null); // to keep track of currently selected grower
    const [ inputChangeOn, setInputChangeOn ] = useState(true);

    // Context
    const {frequest, dispatch} = useContext(FRequestContext)

    async function fetchGrowers(label){
        setLoading(true);
        setGrowers([])
        try{
            let response = await axios(`/api/getGrowersByLabel/?label=${label}`);
            setGrowers(response.data.map((e) => { return {"value" : e.id , "label" : e.label}}));
        }
        catch(err){
            console.log(err)
        } finally {
            setLoading(false);
        }
    }

    function debounce(func, delay) {
        let timerId;
    
        return function (...args) {
            clearTimeout(timerId);
    
            timerId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    }
    
    const debouncedSearch = debounce(fetchGrowers, 400); 

    // Event Handlers
    function handleChange(event){
        // updating selected_grower local state
        if(event === null) {
            dispatch({type: "addOrChange", payload: { f_grower_id : null }  })
        }
        set_selected_grower(event);
    }
    
    function handleInputChange(event){
        // Fetch Growers only if the input string is > 2
        if(event.length > 2){
            debouncedSearch(event)
        }
    }

    // # Side Effects
    // Check if growers already exist in LS
    useEffect(() => {
        const growers_stored = JSON.parse(localStorage.getItem("growers"));
        if(growers_stored){
            let parsed_growers = growers_stored?.map(grower => ({"value" : grower.id , "label" : grower.label}))
            setGrowers(parsed_growers)
            setInputChangeOn(false);
        }
    }, [])


    useEffect(() => {
        // dispatching to global store
        if(selected_grower){
            dispatch({type: "addOrChange", payload: { f_grower_id : selected_grower.value }  });
        }
    }, [selected_grower])

    
    return (
        <>
            <Select 
                placeholder={t("Type here to search for Grower")}
                options={growers} 
                value={selected_grower} 
                onChange={handleChange} 
                onInputChange={inputChangeOn ? handleInputChange: () => {}} 
                isLoading={loading}
                isClearable
                />
        </>
    )
}

export default Grower;