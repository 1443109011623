import React, { useState } from 'react'
import {Accordion , Spinner} from 'react-bootstrap'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import {useSelector} from 'react-redux'
import { useTranslation } from 'react-i18next';

function Review() {

  const { t } = useTranslation();
  // review & submit component
  const requestParameters = useSelector(state => state.requestParameters) // global state 

  const navigate = useNavigate() // navigator
  
  const [ reviewStatus , setReviewStatus ] = useState(false) // to keep track of [is review button is checked or not]
  const [ errorMessage , setErrorMessage ] = useState(false) // to set error message when something goes wrong on creation of task
  const [ loading , setLoading ] = useState(false) // to control visibility of loading component

  // review checkbox change event handler
  function handleCheckBoxChange(event){
    const { checked } = event.target;
    setReviewStatus(checked)
  }

  async function handleSubmit(event){
    // sends parameters to /api/runtask/ => which returns task ids
    // if we get tasks ids => stores them on DB
    // if not there's something wrong with the field.

    event.preventDefault();

    if(reviewStatus){
      setErrorMessage("")
      try {
        setLoading(true);
        const response = await axios.post("/api/runtask/", {...requestParameters, "token": localStorage.getItem("token")});
        const taskIDs  = response.data?.taskIDs;
        console.log(taskIDs); // task ids returned by creation of harvest/applied task

        if(taskIDs.length === 0){
          // if the returned tasks is empty list
          setErrorMessage("Something went wrong while running tasks for the field. Possibly fields might not have tasked")
          setLoading(false)
          return;
        }  
  
        if(taskIDs.length > 0){
          // storing tasks to the db
          const data_to_store_in_db = {
            "taskIDs": taskIDs,
            "runtype": requestParameters.runtype
          }
          console.log(data_to_store_in_db)
          const response_2 = await axios.post("/api/tasks/", data_to_store_in_db)
          console.log(response_2?.data);

          if(response_2.data?.status){
            navigate("/created_tasks/") // navigating to /created_tasks page
          }
          setLoading(false);
        }
      } catch (error) {
        const error_message = error?.response?.data?.detail;
        console.log(error_message)
        if (error_message) {
          setErrorMessage(error_message)  
        } else {
          setErrorMessage("Something went wrong while running tasks for the field. Server is down... ")
        }
          setLoading(false);
      }
    }
    else{
      alert("Please Review the parameters....")
    }
  }

  return (
    <>
    <Accordion className='my-5'>
      <Accordion.Item eventKey={""}  className=''>
        <Accordion.Header>{t("Review")}</Accordion.Header>
            <Accordion.Body>
               <pre className='mx-3 my-2'>{JSON.stringify(requestParameters, null, 2)}</pre>
                <input className="form-check-input mx-3" type="checkbox" id="" name="review" checked={reviewStatus} onChange={handleCheckBoxChange} />
                <label htmlFor="review">{t("Reviewed the parameters")}</label>
            </Accordion.Body>
      </Accordion.Item>
    </Accordion>

    {
      loading && 
      <div className="d-flex justify-content-center align-items-center">
        <Spinner className=' my-4'/>
    </div>
    }

    {
      errorMessage && 
      <div className="d-flex justify-content-center align-items-center">
        <p className='bg-danger text-white p-3 fs-4 rounded-3'>{errorMessage}.</p>
    </div>
    }
    
    {
      (requestParameters.season && requestParameters.grower && requestParameters.farm.length > 0 &&  requestParameters.fields.length > 0 && requestParameters.datatype && requestParameters.runtype) && 
        <input type="submit" value={t("Submit")} className='btn btn-lg btn-dark my-5 w-100' onClick={handleSubmit} />
    }
    </>
  )
}

export default Review