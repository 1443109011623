import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { requestParametersAddorChange, requestParametersReset } from '../../features/RequestParametersSlice'

// Context
import FRequestContext from './contexts/FRequest'

// Components
import Offcanvas from '../../components/Offcanvas'
import Navbar from '../settings/components/Navbar'

// From Selectors
import {FSeasons, FHubs, FGrowers, Runtype} from './components/fselectors'
import {THubs, TGrowers} from './components/tselectors'

import AssignMachines from './components/AssignMachines'
import SelectMachines from './components/SelectMachines'

function AddMachinesToGrower() {

    const {frequest, dispatch} = useContext(FRequestContext)
    const { f_grower_id } = frequest
    const {season, grower, selectImplementIds} = useSelector(state => state.requestParameters);
    const {t} = useTranslation();

    // Local State, it stores the seasons returned from api
    const [ seasons , setSeasons ] = useState([]);
    
    const location = useLocation();
    const _dispatch = useDispatch();

    async function fetchSeasons(){
        try{
            let response = await axios("/api/getSeasons/");
            setSeasons(response.data.map((e) => { return {"value" : e.id , "label" : e.label}}));
        }
        catch(err){
            console.log('Error while fetching seasons')
        }
    }
    

    useEffect(() => {
        // Fetching seasons.. when it is rendered...
        fetchSeasons();
    }, []);

    useEffect(() => {
        _dispatch(requestParametersReset())
        dispatch({"type": "reset"})
        _dispatch(requestParametersAddorChange({ "runtype": "applied" }))
    }, [location])

    // UI
    return (
        <>
            <Navbar pageName={t("Add Machines To Grower")} />
            <Offcanvas sidebar={false} />
            <div className="page-content px-3">
            <div className='d-flex flex-column px-3 w-100 my-3 mb-5'>
                <div className='container-fluid'>
                <div className='row'>
                    {/* Header */}
                    <div className='d-flex fs-4 my-3'>
                        <span className='text-decoration-underline text-uppercase'>{t("from")}</span> 
                        
                        <span className="mx-auto position-relative d-none d-lg-flex" style={{right: "15px"}}><i className="fa-solid fa-arrow-right-long"></i></span>
                        <span className='d-none d-lg-flex text-decoration-underline text-uppercase'>{t("to")}</span> 
                    </div>

                    
                    {/* Assign From */}
                    <div className='col-xl-6 px-2'>
                        <div className="mb-2 my-1">
                            <label htmlFor="" className="form-label fs-5">{t('Select Season')}:</label>
                            <FSeasons seasons={seasons} />
                        </div>

                        {
                            (season) && 
                            <div className="mb-2 my-4"  style={{zIndex: 100}}>
                                <label htmlFor="" className="form-label fs-5  ">{t('Select Grower')}:</label>
                                <FGrowers season={season} />
                            </div> 
                        }

                        {
                            <div className="mb-2 my-4"  style={{zIndex: 100}}>
                                <label htmlFor="" className="form-label fs-5  ">{t('Select Runtype')}:</label>
                                <Runtype style={{zIndex: 100}}/>
                            </div>   
                        }

                        {
                            (season && f_grower_id ) && 
                            <div className="mb-2 my-4"  style={{zIndex: 100}}>
                                <label htmlFor="" className="form-label fs-5  ">{t('Select Machines You Wanna Assign')}:</label>
                                <SelectMachines grower_id={f_grower_id} />
                            </div> 
                        }
                    </div>

                    {/* Assign To */}
                    <div className='col-xl-6 my-5 my-xl-0'>
                        <p className='d-flex d-lg-none fs-4 text-decoration-underline text-uppercase'>{t("to")}</p>
                        <div className="mb-2 my-1">
                            <label htmlFor="" className="form-label fs-5  ">{t('Select Grower')}:</label>
                            <TGrowers    />
                        </div>

                        {
                            (season && grower && selectImplementIds && selectImplementIds?.length > 0) &&
                            <AssignMachines />
                        }
                    </div>
                </div>
                </div>
            </div> 
            </div>
        </>
    )
}

export default AddMachinesToGrower