import { useEffect, useState } from 'react'
import axios from "axios"
import { useTranslation } from 'react-i18next';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { IoIosRefresh } from "react-icons/io";
import { MdDelete } from "react-icons/md";

const ProcessingRequests = () => {

    const client_id = localStorage.getItem("client_id");
    const { t } = useTranslation();
    const [ loading, setLoading ] = useState(false);
    const [ data, setData ] = useState([]);
    const [ refetch, setRefetch ] = useState(false)


    useEffect(() => {
        fetchProcessingRequests();
    }, [refetch])

    async function fetchProcessingRequests(){
        setLoading(true);
        try {
            const response = await axios.get(`/rxprozone/get_prescription_tracker/?user_id=${client_id}`);
            setData(response.data?.result)
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    if(loading){
        return (
            <div className='d-flex justify-content-center my-3'>
                <Spinner />
            </div>
        )
    }

    if(data.length > 0){
    return (
        <div className='my-3'>
            <div className="table-responsive">
                <p className='fs-4 text-center mb-0'>{t("Currently Processing")}:</p>
                <div className="d-flex justify-content-end mb-2">
                    <button className="btn btn-dark text-white" onClick={() => {setRefetch( fetch => (!fetch))}}> 
                        <IoIosRefresh /> 
                    </button>
                </div>

                <table className="table table-hover table-dark shadow">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>{t("Grower Label")}</th>
                            <th>{t("Filename")}</th>
                            <th>{t("Message")}</th>
                            <th>{t("Status")}</th>
                            <th>{t("#")}</th>
                        </tr>
                    </thead>
                    <tbody className="table-secondary">
                        {
                            data.map((row, index) => (
                                <TableRow row={row} index={index} key={index} setRefetch={setRefetch} />
                            ))
                        }
                    </tbody>
                </table>
            </div>
            
        </div>
    )
    } else {
        return (<></>)
    }
}

function TableRow({ row, index, setRefetch }){
    
    const { id, grower_label, filename, status, message } = row;

    async function deleteFailedRequest( event, id ){
        event.target.classList.add("disabled");
        toast("Deleting...");
        try{
            const response = await axios.delete(`/rxprozone/delete_prescription_tracker/?id=${id}`)
            if(response.status === 204){
                toast("Deleted...");
            }
        } catch(error){
            toast("Delete failed...");
        } finally {
            setRefetch(fetch => (!fetch))
        }
    }

    return (
        <tr>
            <td>{ index + 1 }</td> 
            <td>{ grower_label }</td> 
            <td>{ filename }</td> 
            <td>{ message }</td> 
            <td>{ status }</td> 
            <td>
                {
                    ["failed", "failed-on-upload", "done"].includes(status) &&
                    <button className="btn btn-danger" onClick={(e) => {deleteFailedRequest( e, id )}}>
                        <MdDelete />
                    </button>
                }
            </td>
        </tr>
    )
}

export default ProcessingRequests