import Select from 'react-select'
import useFetch from '../../../../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { requestParameterRemove, requestParametersAddorChange } from '../../../../../features/RequestParametersSlice'
import { t } from 'i18next'

const State = ({ onChange }) => {

    const dispatch = useDispatch();
    const { country } = useSelector(state => state.requestParameters)

    const { data, error, loading} = useFetch({
        url: `/reporting/sail/state/?country=${country}`
    })
    
    error && 
        console.log(error)

    const [ state, setState ] = useState(null);
    const [ states, setStates ] = useState([]);

    useEffect(() => {
        setStates( () => (data.map( e => ({"label" : e, "value": e}) )))
    }, [data])

    function handleChange(e){
        setState(e);
        if (e) {
            dispatch(requestParametersAddorChange({"state": e.value}))
            onChange(e.value); // Notify parent component of the change

        } else {
            dispatch(requestParameterRemove("state"))
            onChange(null); // Notify parent component of the change

        }
    }

    return (
        <div>
            <label htmlFor="" className="form-label fs-5">{t('Select State')}:</label>

            <Select
                value={state}
                placeholder={t("Select a state")}
                onChange={handleChange}
                options={states}
                isClearable
                isLoading={loading}
                />
        </div>
    )
}

export default State