import { useContext, useEffect, useState } from "react"
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import axios from 'axios';
import { format } from 'date-fns'
import { useTranslation } from "react-i18next";
import { ResponseContext } from '../../../context/ResponseContext'

const JobsTable = () => {

    const { t } = useTranslation();
    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    const {
        season, grower_id, fields_to_include, fields_selected
    } = useSelector(state => state.requestParameters);

    async function fetchJobs(){
        setLoading(true);
        try {
            const response = await axios.post("/carbon/getJobs/", {
                        "season": season, 
                        "rootAsset": grower_id, 
                        "fields_to_include": fields_to_include,
                    }, {headers: {"Content-Type": "application/json"}});
            if(response.data?.result){
                setData(response.data.result)
            }
            console.log(response.data.result)
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if(fields_selected){
            fetchJobs();
        }
    }, [fields_selected])

    if(fields_selected){
        return (
            <>
                {
                    loading ? <Spinner /> :
                    <figure className='wrapper' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto"}}>
                        <table className="table table-hover">
                        <thead >
                            <tr className='m-2 header' >
                                <th className='bg-dark text-white'>#</th>
                                <th className='bg-dark text-white'>{t("Job Title")}</th>
                                <th className='bg-dark text-white'>{t("Status")}</th>
                                <th className='bg-dark text-white'>{t("Type")}</th>
                                <th className='bg-dark text-white'>{t("Start Date")}</th>
                                <th className='bg-dark text-white'>{t("End Date")}</th>
                                <th className='bg-dark text-white'>{t("Description")}</th>
                                <th className='bg-dark text-white'>{t("Implement")}</th>
                                <th className='bg-dark text-white'>{t("Secondary Implement")}</th>
                                <th className='bg-dark text-white'>#</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                data.map((row, index) => {
                                    return (
                                        <>
                                            <JobTableRow 
                                                key={index} index={index} row={row} 
                                            />
                                        </>
                                    )
                                })
                            }
                        </tbody>
                        </table>
                    </figure>
                }
                {}
            </>
        )
    }
    else {
        return <></>
    }
}

function JobTableRow({index, row}){

    const { title, status, type, start, end, description, implements: _implements } = row;
    let implement = null, secondary = null;
    if(_implements){
        implement = _implements[0]?.implement;
        secondary = _implements[0]?.secondary;
    }

    return (
        <>
        <tr className="align-middle carbon-job-table">
            <td>{index + 1}</td>
            <td>{title}</td>
            <td>{status}</td>
            <td>{type}</td>
            <td>
                {start && 
                    <div className="d-flex flex-column">
                        <div className="fw-bold">{format(new Date(start), "dd MMM yy")} </div>
                        <div>{format(new Date(start), "hh:mm aaa")}</div>
                    </div>
                }
            </td>
            <td>
                {end && 
                    <div className="d-flex flex-column">
                        <div className="fw-bold">{format(new Date(end), "dd MMM yy")} </div>
                        <div>{format(new Date(end), "hh:mm aaa")}</div>
                    </div>
                }
            </td>
            <td>{description}</td>
            <td>{implement ? <ImplementButton implement_id={implement} /> : ""}</td>
            <td>{secondary ? <ImplementButton implement_id={secondary} /> : ""}</td>
        </tr>
    </>
    )
}

function ImplementButton({implement_id}){

    const { storedResponse } = useContext(ResponseContext)
    const fetchedImplements = storedResponse?.implements;

    let implement_name = "", implement_type = "", implement_make = "";
    if(Object.keys(fetchedImplements).includes(String(implement_id))){
        implement_name = fetchedImplements[implement_id]?.name;
        implement_type = fetchedImplements[implement_id]?.implementType;
        implement_make = fetchedImplements[implement_id]?.make;
    }
    
    
    return (
        <>
            { 
            implement_name ?
                <div className="d-flex flex-column text-white text-center">
                    <div className="bg-sp-primary p-2 fw-bold" title={implement_name}>
                        {implement_name}
                    </div>
                    <div className="bg-dark p-2">
                        {implement_type} {implement_make}
                    </div>
                </div> 
                : 
                <></>
            }
        </>
    )
}

export default JobsTable