import React from 'react'
import { Navbar, Offcanvas } from '../../components'
import TicketTable from './components/TicketTable'
import { useTranslation } from 'react-i18next'

const YourTickets = () => {
    
    const { t } = useTranslation();

    return (
        <>
            <Navbar pageName={"Your DPS Tickets"} />
            <Offcanvas />

            <div className="page-content">
                <p className="text-center text-capitalize p-3 fs-4">{t("tickets you have created")}</p>
                <TicketTable />
            </div>
        </>
    )
}

export default YourTickets