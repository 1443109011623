import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'

import {requestParametersAddorChange} from '../features/RequestParametersSlice'
import {ticketParametersAddorChange} from '../features/TicketParametersSlice'

import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

function GrowerByLabel({ backfill = false }) {

    const { t } = useTranslation();
    const dispatch = useDispatch() // dispatch

    const [ loading, setLoading ] = useState(false); 
    const [ growers , setGrowers ] = useState([]); // to store growers returned from api
    const [ selected_grower, set_selected_grower ] = useState(null) // to keep track of currently selected grower
    const [ inputChangeOn, setInputChangeOn ] = useState(true);

    async function fetchGrowers(label){
        setLoading(true);
        setGrowers([])
        try{
            let response = await axios(`/api/getGrowersByLabel/?label=${label}`);
            setGrowers(response.data.map((e) => { return {"value" : e.id , "label" : e.label}}));
        }
        catch(err){
            console.log(err)
        } finally {
            setLoading(false);
        }
    }

    async function backfillAncestors(event){
        try {
            const response = await axios.get(`/api/getAncestors/?asset_id=${event.value}`)
            const data = response.data;
            dispatch(ticketParametersAddorChange({
                "hub": data["Hub"]?.label,
                "hub_id": data["Hub"]?.id.toString(),
                "region": data["Region"]?.label
            }))
        } catch (error) {
            console.error(error);
        }
    }

    function debounce(func, delay) {
        let timerId;
    
        return function (...args) {
            clearTimeout(timerId);
    
            timerId = setTimeout(() => {
                func.apply(this, args);
            }, delay);
        };
    }
    
    const debouncedSearch = debounce(fetchGrowers, 400); 

    // Event Handlers
    function handleChange(event){
        // updating selected_grower local state
        if(event === null) {
            dispatch( requestParametersAddorChange({ 'grower' : null }) )
            dispatch( ticketParametersAddorChange({ 'grower' : null, 'grower_id' : null }) )
        }

        if(backfill)
            backfillAncestors(event)
        set_selected_grower(event);
    }
    
    function handleInputChange(event){
        // Fetch Growers only if the input string is > 2
        if(event.length > 2){
            debouncedSearch(event)
        }
    }

    // # Side Effects
    // Check if growers already exist in LS
    useEffect(() => {
        const growers_stored = JSON.parse(localStorage.getItem("growers"));
        if(growers_stored){
            let parsed_growers = growers_stored?.map(grower => ({"value" : grower.id , "label" : grower.label}))
            setGrowers(parsed_growers)
            setInputChangeOn(false);
        }
    }, [])

    useEffect(() => {
        // dispatching to global store
        if(selected_grower){
            dispatch( requestParametersAddorChange({ 'grower' : selected_grower.value, "farm": null }) )
            dispatch( ticketParametersAddorChange({ 'grower_id' : selected_grower.value.toString(), 'grower' : selected_grower.label, "farm_id": null }) )
        }
    }, [selected_grower])

    
    
    return (
        <>
            <Select 
                placeholder={t("Type here to search for Grower")}
                options={growers} 
                value={selected_grower} 
                onChange={handleChange} 
                onInputChange={inputChangeOn ? handleInputChange: () => {}} 
                isLoading={loading}
                isClearable
                />
        </>
    )
}

export default GrowerByLabel;