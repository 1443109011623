import React, { useContext, useEffect } from 'react'
import { DashboardContext } from '../context/DashboardContext'
import { useTranslation } from 'react-i18next'

const DashboardSettings = () => {

    const {t} = useTranslation();
    
    const {
        layouts, setLayouts, breakpoint, DEFAULT_LAYOUT,
        USER_ALLOWED_WIDGETS, userWidgets, setUserWidgets
    } = useContext(DashboardContext)

    // Event Handler
    function handleResetDashboard(){
        setLayouts(() => ({...DEFAULT_LAYOUT}));
        setUserWidgets(() => ([...USER_ALLOWED_WIDGETS]))
        sessionStorage.setItem("layouts", JSON.stringify(DEFAULT_LAYOUT));
        sessionStorage.setItem("widgets", JSON.stringify(USER_ALLOWED_WIDGETS));
    }

    function handleCheckboxChange(event){
        const {checked, value} = event.target;
        if(checked){
            setLayouts((_layouts) => {
                return {
                    ..._layouts,
                    [breakpoint]: [...layouts[breakpoint], DEFAULT_LAYOUT[breakpoint].find(e => e.i === value)]
                }
            })
        } else {
            setLayouts((_layouts) => {
                return {
                    ..._layouts,
                    [breakpoint]: [...layouts[breakpoint]?.filter(e => e.i !== value)]
                }
            })
        }
    }

    function handleWidgetCheckbox(event){
        const {checked, value} = event.target;
        if(checked){
            setUserWidgets((widget) => {
                return [...widget, USER_ALLOWED_WIDGETS.find(e => e.name === value)]
            })
        }
        else{
            setUserWidgets((widget) => [...widget.filter(e => e.name !== value)])
        }
    }

    useEffect(() => {
        sessionStorage.setItem("widgets", JSON.stringify(userWidgets));
    }, [userWidgets])

    return (
        <>
        <div 
            className='position-absolute my-2 shadow-lg bg-light text-dark rounded-2 d-flex flex-column slide-down' 
            style={{right: '45px', zIndex: '100', top: '3em', width:'max-content'}} >            
            <div className="my-2 p-2 d-flex align-items-center justify-content-center ">
                <button className='btn btn-outline-light reset-dashboard-btn fw-bold text-capitalize' onClick={handleResetDashboard}>{t("reset dashboard")}</button>
            </div>
            <div className="dashboard-split-bar"></div>

            {/* Grid Items */}
            {
                DEFAULT_LAYOUT[breakpoint]?.map(layout => (
                    <>
                        <div className="d-flex align-middle  form-check m-2">
                            <input type="checkbox" className="form-check-input p-2" 
                                value={layout.i}
                                id={`chkbox-${layout.i}`} 
                                checked={layouts[breakpoint]?.flatMap(e => e.i).includes(layout.i)}
                                onChange={handleCheckboxChange}
                                />
                            <label className="form-check-label ms-2" htmlFor={`chkbox-${layout.i}`} >{t(layout.i)}</label>
                        </div>
                    </>
                ) )
            }

            {/* Widgets */}
            <div className="dashboard-split-bar"></div>
            {
                USER_ALLOWED_WIDGETS.map(widget => (
                    <>
                        <div className="d-flex align-middle  form-check m-2">
                            <input type="checkbox" className="form-check-input p-2" 
                                value={widget.name}
                                id={`chkbox-${widget.name}`} 
                                checked={userWidgets?.flatMap(w => w.name)?.includes(widget.name)}
                                onChange={handleWidgetCheckbox}
                                />
                            <label className="form-check-label ms-2" htmlFor={`chkbox-${widget.name}`} >{t(widget.label)}</label>
                        </div>
                    </>
                ) )
            }
        </div>
        </>
    )
}

export default DashboardSettings