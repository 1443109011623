import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Pages
import {
  Login,
  Unauthorized,
  CreateTask,
  CreatedTasks,
  CreateTicket,
  TicketCreated,
  HomePage,
  PageNotFound,
  UploadController,
  UploadedControllerFiles,
  ProductTable,
  SmartReportingFieldUploader,
  RxProZoneDownloader,
  SmartReportingMonitor,
  GeneratePrescriptionFiles,
  GeneratedPrescriptionFiles,
  Maps,
  Settings,
  AddMachinesToGrower,
  FileUploader,
  Instructions,
  ServiceRequest,
  CarbonCapture,
  YourTickets,
  TaskFieldRequestForm,
  LeafIntegration
} from './pages';
import Iris from './pages/reporting/iris';
import Sail from './pages/reporting/sail';
import ResumeFileUpload from './components/upload_controller/ResumeFileUpload';

// Components
import ProtectedRoutes from './utils/ProtectedRoutes';
import AuthenticatedRoute from './utils/AuthenticatedRoute';
import useCSRFTokenCheck from './utils/AuthCSRFTokenCheck';
import { DashboardContextProvider } from './pages/home_page/context/DashboardContext';

// Translations
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import en_US from './translations/en-US.json';
import pt_BR from './translations/pt-BR.json';

// Global CSS
import "./styles/App.css";

i18next
  .use(initReactI18next)
  .init({
    resources: {
      "en_US": {
        translation: en_US
      },
      "pt_BR": {
        translation: pt_BR
      },
    },
    // Set the default language to English.
    lng: localStorage.getItem('language') || 'en_US',
    interpolation: {
      escapeValue: false,
    },
  });

function App() {
  useCSRFTokenCheck(); // Use the CSRF token check hook
  return (
    <DashboardContextProvider>

    <Routes>
      <Route path='/login' element={<Login />} />

      {/* Private Routes */}
      <Route element={<ProtectedRoutes/>}>
        <Route path='/settings/*' element={<Settings />} />
        <Route path='/unauthorized' element={<Unauthorized />} />
        <Route path='*' element={<PageNotFound />} />
        <Route path='/' element={<HomePage />} />

        {/* Authenticated Routes */}
        <Route element={<AuthenticatedRoute authKey="ELVYRA_TECH" />}>
          <Route path='/instructions/*' element={<Instructions />} />
        </Route>

        {/* Patricia Tasks */}
        <Route element={<AuthenticatedRoute authKey="SPT" />}>
          <Route path='/create_task' element={<CreateTask />} />
        </Route>
        <Route element={<AuthenticatedRoute authKey="YPT" />}>
          <Route path='/created_tasks' element={<CreatedTasks />} />
        </Route>

        {/* Smart Reporting */}
        <Route element={<AuthenticatedRoute authKey="SMFU" />}>
          <Route path='/smart_reporting_field_uploader' element={<SmartReportingFieldUploader />} />
          <Route path='/smart_reporting_monitor' element={<SmartReportingMonitor />} />
        </Route>

        {/* Leaf Integration */}
        <Route element={<AuthenticatedRoute authKey="TPI" />}>
          <Route path='/leaf-integration/*' element={<LeafIntegration />} />
        </Route>

        {/* Sail */}
        <Route element={<AuthenticatedRoute authKey="FCR" />}>
          <Route path='/reporting/sail/*' element={<Sail />} />
        </Route>
        {/* Sail */}
        <Route element={<AuthenticatedRoute authKey="FCR" />}>
          <Route path='/reporting/iris/*' element={<Iris />} />
        </Route>

        {/* Support Tickets */}
        <Route element={<AuthenticatedRoute authKey="CST" />}>
          <Route path='/create_ticket' element={<CreateTicket />} />
          <Route path='/your_tickets' element={<YourTickets />} />
          <Route path='/ticket_created' element={<TicketCreated />} />
        </Route>

        {/* Controller Files */}
        <Route element={<AuthenticatedRoute authKey="UCF" />}>
          <Route path='/upload_controller_file' element={<UploadController />} />
          <Route path='/resume_file_upload' element={<ResumeFileUpload />} />
          <Route path='/uploaded_controller_files' element={<UploadedControllerFiles />} />
          <Route path='/product_table' element={<ProductTable />} />
        </Route>

        {/* Maps */}
        <Route element={<AuthenticatedRoute authKey="FCL" />}>
          <Route path='/maps' element={<Maps />} />
        </Route>

        {/* Prescription Files */}
        <Route element={<AuthenticatedRoute authKey="PFG" />}>
          <Route path='/generated_prescription_files' element={<GeneratedPrescriptionFiles />} />
          <Route path='/generate_prescription_controller_files' element={<GeneratePrescriptionFiles />} />
        </Route>

        {/* Staff Only */}
        <Route element={<AuthenticatedRoute authKey="STAFF" />}>
          <Route path='/add_machines_to_grower/*' element={<AddMachinesToGrower />} />
          <Route path='/service_request/*' element={<ServiceRequest />} />
          <Route path='/rxprozone_downloader' element={<RxProZoneDownloader />} />
          <Route path='/carbon_capture/*' element={<CarbonCapture />} />
        </Route>

        <Route element={<AuthenticatedRoute authKey="SMART_PRO" />}>
          <Route path='/task_field_request/*' element={<TaskFieldRequestForm />} />
        </Route>

        <Route element={<AuthenticatedRoute authKey="AFU" />}>
          <Route path='/file_uploader/*' element={<FileUploader />} />
        </Route>
      </Route>
    </Routes>
    </DashboardContextProvider>

  );
}

export default App;