import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Select from 'react-select'

function HubSelector({ selected_hub , set_selected_hub}) {

    const { t } = useTranslation();
    // States
    const [hubs, set_hubs] = useState([]);
    
    // Side Effects
    // On Page Render Fetch Hubs on page render
    useEffect(() => {
        fetchHubs();
    }, [])

    // Helpers
    // Fetches hubs from backend
    async function fetchHubs(){
        try{
            let response = await axios("/api/getHubs/");
            set_hubs( response.data.map( (e) => { return { "value" : e.id, "label" : e.label } }) )
        }
        catch(err){
            console.log('Error while fetching hubs...', + err)
        }
    }


    // Event Handlers
    // On Hub Change Event Handler
    function onHubChange(event){
        // set_growers([]); set_selected_grower([]); set_filename("");
        set_selected_hub(event);
    }



    return (
        <>
        {/* Hub Selection */}
            <div className="mb-3">
                    <Select placeholder={t("Select Hub")} 
                        options={hubs} 
                        onChange={onHubChange}
                        value={selected_hub} 
                        styles={{menu: style => ({...style, zIndex: "10"}) }}
                    />
            </div>
        </>
    )
}

export default HubSelector