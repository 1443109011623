import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux'
import {ticketParametersAddorChange, ticketParameterRemove} from '../../features/TicketParametersSlice'
import { useTranslation } from 'react-i18next'

function SelectCanplug() {

    const { t } = useTranslation();
    const dispatch = useDispatch() // dispatcher
    
    // Global State
    const ticketParameters = useSelector(state => state.ticketParameters);

    // States
    const [applied_canplugs , set_applied_Canplugs] = useState([]) // to store api response of applied canplugs
    const [harvest_canplugs , set_harvest_Canplugs] = useState([]) // to store api response of harvest canplugs
    const [canplugs , set_canplugs] = useState([]) // using which canplug selection table is built 

    const [ selectedCanplugIDs, setSelectedCanplugIDs ] = useState([]) // maintains selected canplugs
    const [ selectedCanplugs, setSelectedCanplugs ] = useState([]) // maintains selected canplug ids
    const [ loading , setLoading ] = useState(true) // to control loading component visibility

    // remove controller params (if exist), reset selected canplugs
    useEffect(() => {
        dispatch(ticketParameterRemove('controller'))
        dispatch(ticketParameterRemove('controller_id'))
        setSelectedCanplugs([])
        setSelectedCanplugIDs([])
    }, [ticketParameters.datatype])

    // fetch canplugs when grower changes
    useEffect(() => {
        // deleting controller data from the ticketParams context
        // fetching canplugs and assiging it to the state...
        setLoading(true);
        (async () => {
            console.log('fetching canplug')
            try {
                const response = await axios.get(`/api/getCanplugs/?grower=${ticketParameters.grower_id}`);
                
                console.log('applied_cans', response.data?.appliedImplements)
                console.log('harvest_cans', response?.data?.harvestImplements)
                
                set_applied_Canplugs(response.data?.appliedImplements)
                set_harvest_Canplugs(response?.data?.harvestImplements)

                set_canplugs(response.data?.appliedImplements); // defaulting to applied canplugs
            } catch (error) {
                setLoading(false);
            }
            setLoading(false);
        })();
    }, [ticketParameters.grower_id])

    // Setting canplugs for the table based on the runtype, when it changes
    useEffect(() => {
        if(ticketParameters.runtype?.toLowerCase() === 'applied'){
            set_canplugs(applied_canplugs);
        }
        else if(ticketParameters.runtype?.toLowerCase() === 'harvest'){
            set_canplugs(harvest_canplugs)
        }
        setLoading(false);
    }, [ticketParameters.runtype])


    // Adding selected Canplugs to ticket parameter
    useEffect(() => {
        dispatch( ticketParametersAddorChange({ 'canplug': selectedCanplugs.toString(), "canplug_id": selectedCanplugIDs.toString() }) )
    } , [selectedCanplugs])


    // select all button click event handler
    function handleSelectAll(event){
        event.preventDefault();
        setSelectedCanplugs(canplugs.map((e) => {return e.label;}));
        setSelectedCanplugIDs(canplugs.map((e) => {return e.impid;}));
    }

    // deselect all button click event handler
    function handleDeselectAll(event){
        event.preventDefault();
        setSelectedCanplugs([])
        setSelectedCanplugIDs([])
    }

    // check box click event handler
    function handleCheckBoxClick(event , impid){
        const {checked , value } = event.target;
        if(checked){
            setSelectedCanplugs((prev) => { return [...prev, value] })
            setSelectedCanplugIDs((prev) => { return [...prev, impid] })
        }
        else{
            setSelectedCanplugs((curr) => { return curr.filter((a) => a !== value); })
            setSelectedCanplugIDs((curr) => { return curr.filter((a) => a !== impid); })
        }
    }

  return (
    <>

    { 
        (ticketParameters?.farm_id &&  ticketParameters?.field_id && ticketParameters?.runtype && ticketParameters?.datatype?.toLowerCase() === "canplug") && 
        <div className="my-4 mb-5">
            <p className='fs-4'>{t("Select a Canplug you are having issues with")}:</p>

            <div className="d-flex mb-3">
                <button className='btn btn-outline-dark me-3' onClick={handleSelectAll}>{t("Select All")}</button>
                <button className='btn btn-outline-dark' onClick={handleDeselectAll}>{t("Deselect All")}</button>
            </div>

            {loading ? 
            <Spinner className='text-dark m-4' /> : 
            <figure className='wrapper' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto"}}>
            <table className="table table-light table-responsive table-hover shadow">
                <thead className='table-dark'>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">{t("ImplementID")}</th>
                    <th scope="col">{t("Can Id")}</th>
                    <th scope="col">{t("Make")}</th>
                    <th scope="col">{t("Model")}</th>
                    <th scope="col">{t("Type")}</th>
                    <th scope="col" className='fw-fw-bolder'>#</th>
                </tr>
                </thead>
                <tbody>
                {
                canplugs.map((canplug , key) => {
                    return (
                        <TableRow key={key} canplug={canplug} id={key} />
                    )
                })
                }
                
                </tbody>
            </table>
            </figure>
            }
        </div>
    }
    </>
  )

    // Component for Each row of the canplug table.
  function TableRow({canplug , id}){
    return (
        <>
            <tr>
                <th scope="row"> {id + 1} </th>
                <td>{canplug.impid}</td>
                <td>{canplug.canid}</td>
                <td>{canplug.make}</td>
                <td>{canplug.model}</td>
                <td>{canplug.type}</td>
                <th scope='row'><input type="checkbox" name="" id="" className='form-check-input' value={canplug.label} onChange={(event) => {handleCheckBoxClick(event, canplug.impid)}} checked={ selectedCanplugs.includes(canplug.label) ? 'checked' : ''} style={{padding: "12px"}} /></th>
            </tr>
        </>
    )
  }
}

export default SelectCanplug