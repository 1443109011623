import React, { useEffect, useState } from 'react'

function FilterComponent({headerGroups, filters, setFilter}) {
    
    const [ allFilters, setAllFilters ] = useState({});
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    useEffect(() => {
        console.log(allFilters)
    }, [allFilters])

    function handleFilterClick(event){
        for (const columnKeyToUpdate in allFilters) {
            const newFilterValue = allFilters[columnKeyToUpdate];
            setFilter(columnKeyToUpdate, newFilterValue);
        }
    }

    function handleResetClick(){
        document.querySelectorAll(".filter-controls").forEach(e => { e.value = "" } )
        // Iterate through the columns and clear their filter values
        Object.keys(allFilters).forEach((column_id) => {
            setFilter(column_id, undefined); // Clear the filter value
        });
        setAllFilters({})
    }
    

    return (
        <div className="offcanvas offcanvas-end" tabindex="-1" id="ofc-filter" style={{backgroundColor: "#ECECEC"}}>
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="staticBackdropLabel">Filters</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas"></button>
            </div>

            <div className="offcanvas-body">
                {
                    headerGroups.map((headerGroup) => (
                        headerGroup.headers.map( column => {
                            return (
                                <>
                                    <span>
                                        { 
                                            column.canFilter ? 
                                            <>
                                                {column?.filter === 'text' && <TextColumnFilter column={column} setAllFilters={setAllFilters} />}
                                                {column?.filter === 'includes' && <SelectColumnFilter column={column} setAllFilters={setAllFilters} />}
                                            </>
                                            : null 
                                        }
                                    </span>
                                </>
                            )
                        })
                    ))
                }
            </div>
            
            <div className="d-flex justify-content-center my-3 gap-3">
                <button className="btn btn-warning" onClick={handleFilterClick}>Filter</button>
                {filters.length > 0 && <button className="btn btn-danger" onClick={handleResetClick}>Reset</button>}
            </div>
            
        </div>
    )
    
}

function TextColumnFilter({ column: { id, Header } , setAllFilters }){

    function handleInputChange(event){
        const value = event.target.value;
        setAllFilters(allFilters => { return {...allFilters, [id]: value} })
    }

    return (
        <>
            <div className='d-flex align-items-center justify-content-center my-3'>
                <label className='fw-bold' style={{minWidth: "100px"}}>{ Header }: </label> 
                <input type="text" className="mx-2 form-control filter-controls" style={{width: '500px'}} onChange={handleInputChange}/>
            </div>
        </>
    )
}

// This is a custom filter UI for selecting
// a unique option from a list
function SelectColumnFilter({ column: { id, preFilteredRows, Header }, setAllFilters }) {
    
    // using the preFilteredRows
    const options = React.useMemo(() => {
        const options = new Set();
        preFilteredRows.forEach(row => {
            if(row.values[id])
                options.add(row.values[id]);
        });
        return [...options.values()];
    }, [id, preFilteredRows]);
    
    return (
    <>
        <div className='d-flex align-items-center justify-content-center my-3'>
            <label className='fw-bold'>{ Header }: </label> 
            <select className='form-control filter-controls'
                onChange={e => {
                    let selected_value = e.target.value;
                    setAllFilters( filters => { return {...filters, [id]: selected_value } } );
                }}
            >
                <option defaultValue={""}></option>
                {
                    options.map((option, i) => (
                        <option key={i} value={option}>
                            {option}
                        </option>
                    ))
                }
            </select>
        </div>
    </>
        );
}
export default FilterComponent