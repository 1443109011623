import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select'; // Assuming you are using react-select for dropdown
import { requestParametersAddorChange } from '../../../../../features/RequestParametersSlice'
import useFetch from '../../../../../hooks/useFetch'
import { useTranslation } from 'react-i18next'; // If you use i18n for translation

const Country = ({ onChange }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { data, error, loading } = useFetch({
        url: "/reporting/sail/country/"
    });
    error && 
        console.log(error)  
    const [countryOptions, setCountryOptions] = useState([]);

    useEffect(() => {
        if (data) {
            setCountryOptions(data.map(e => ({ label: e, value: e })));
        }
    }, [data]);

    function handleChange(e) {
        if (e) {
            dispatch(requestParametersAddorChange({ "country": e.value }));
            onChange(e.value); // Notify parent component of the change
        } else {
            dispatch(requestParametersAddorChange({ "country": null }));
            onChange(null); // Notify parent component of the reset
        }
    }

    return (
        <div>
            <label htmlFor="" className="form-label fs-5">{t('Select Country')}:</label>

            <Select
                placeholder={t("Select a country")}
                onChange={handleChange}
                options={countryOptions}
                isClearable
                isLoading={loading}
            />
        </div>
    );
}

export default Country;
