import React, { useContext, useEffect, useState } from 'react'
import {Accordion} from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { requestParameterRemove, requestParametersAddorChange } from '../../../features/RequestParametersSlice'
import { useTranslation } from 'react-i18next'


function PruneParameters() {

    const { t } = useTranslation();
    const dispatch = useDispatch() // dispatcher

    // Data -> list of harvest prune parameters
    const prune_parameters_json = [
            {
                parameter: "Biological limit (kg/ha)",
            },
            {
                parameter: "start_pass_delay_enabled"
            },
            {
                parameter: "end_pass_delay_enabled"
            },
            {
                parameter: "Speed m/s (Sudduth)",
            },
            {
                parameter: "plant_spacing < 900",
            },
            {
                parameter:"Yield Vol Dr (Sudduth)"
            },
            {
                parameter: "0 < Distance From Previous < 20m",
            },
            {
                parameter: "0 < Duration < 3s"
            },
            {
                parameter: "fuel_usage < (fuel_usage+3*stDev)",
            },
            {
                parameter: "0 <= protein <= 100"
            },
            {
                parameter: "1990 < Epoch < nextYear"
            },
            {
                parameter: "Coverage Length > 0m"
            },
            {
                parameter : "lclzscore"
            },
            {
                parameter: "status > 0 "
            }
    ]

    // Check box change event handler
    function handleCheckBoxChanges(event){
        const {name , checked} = event.target;
        
        if(checked){
            dispatch(requestParametersAddorChange({[name]: false})) // adding checked prune parameter
        }else{
            // deleting unchecked parameter
            dispatch(requestParameterRemove(name))
        }
    }

  return (
    <>
    <p className='fs-5 text-decoration-underline'>{t("Harvest pruning rules")}</p>
    <Accordion>
      <Accordion.Item eventKey={"prune-parameters"} >
        <Accordion.Header>{t("Prune Parameters")}</Accordion.Header>
            <Accordion.Body>
                {
                    prune_parameters_json.map((data) => {
                        return (
                            <div className="d-flex rounded p-2 my-3 bg-dark text-white">
                                <div className="form-check fs-5" >
                                    <input className="form-check-input mx-3" type="checkbox" id={data.id} name={data.parameter} onChange={handleCheckBoxChanges}/>
                                    <label className="form-check-label" htmlFor="debugPts">{data.parameter}</label>
                                </div>
                            </div>
                        )
                    })
                }
            </Accordion.Body>   
        </Accordion.Item>
    </Accordion>
    </>
  )
}

export default PruneParameters