import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Accordion, Spinner } from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux'
import {ticketParametersAddorChange, ticketParameterRemove} from '../../features/TicketParametersSlice'
import { useTranslation } from 'react-i18next';

function NotableBugs() {
  import("../../styles/NotableBugs.css"); // css for notable bug accordion specifically

  const { t } = useTranslation();
  const dispatch = useDispatch() // dispatcher

  const ticketParameters = useSelector(state => state.ticketParameters); // global state

  // states
  const [notableBugs , setNotableBugs] = useState([]) // to store api response 
  const [selectedBugs , setSelectedBugs] = useState([]); // to keep track of selected bugs
  const [loading , setLoading] = useState(true) // to control loading component visibility

  // Effects
  useEffect(() => {
    // Resets notableBugs and makes api call
    setLoading(true);
    setNotableBugs([]);
    
    (async () => {
        try {
          const response = await axios.get(`/api/getNotableBugs/?datatype=${ticketParameters.datatype}&runtype=${ticketParameters.runtype}`)
          setNotableBugs(response.data);
          setLoading(false)
        } catch (error) {
          setLoading(false)
        }
      })();
  }, [ticketParameters.datatype , ticketParameters.runtype])

  useEffect(() => {
    // adding/updating selected notable bugs to ticketParameters
    dispatch( ticketParametersAddorChange({ "link": selectedBugs, "link_id": selectedBugs.toString()}) )

    // deleting notableParams link if nothing is selected
    if(selectedBugs.length === 0){
      dispatch( ticketParameterRemove('link') )
      dispatch( ticketParameterRemove('link_id') )
    }
  },[selectedBugs])


  // Accordion click event handler - changes expanded accordion to yellow
  function handleAccodionClick(event, notableBug){
    const button = document.getElementById(`#${notableBug.key}`).childNodes[0];

    if(selectedBugs.includes(notableBug.key)){
      button.setAttribute("aria-checked" , true)
      console.log('adding checked')
      button.classList.add('checked');
    }
    else{
      button.removeAttribute("aria-checked");
    }
  }

  // check box change event handler
  // changes accordion bg to green if checked, and removes green bg if unchecked 
  function handleCheckBoxChange(event, notableBug){
    const { name , checked, value } = event.target;
    const button = document.getElementById(`#${notableBug.key}`).childNodes[0];

    if(checked){
      button.setAttribute("aria-checked", 'true');
      // button.classList.add('checked');
      const copy = [...selectedBugs]
      copy.push(notableBug.key);
      setSelectedBugs(copy)
    } 
    else{
      button.removeAttribute("aria-checked");
      button.classList.remove('checked')
      const copy = [...selectedBugs];
      
      setSelectedBugs(copy.filter((e) => {
        return (e !== notableBug.key);
      }))
    }
  }

  // Component
  return (
    <>
        <p className='fs-2 mt-4'>{t("Notable Bugs")}</p>
        <p className='fs-5 mt-0'>{t("Please review the notable bugs shown below in the expandable dropdowns. If there is a bug that relates directly to your issue, Please click the checkbox appropriately to link your ticket")}</p>

        {
          loading ? 
          <Spinner  className='my-4 d-flex p-3 mx-4' /> : 
          <Accordion>
          {
              notableBugs.map((notableBug,index) => {

              let description = notableBug.renderedFields.description.replaceAll("/rest/" , "https://farmersedgedev.atlassian.net/rest/");
              description = description.replaceAll("<img", "<img class='img-fluid'");
              
              return (
                <>
                  <Accordion.Item key={index} eventKey={notableBug.key} className='rounded my-1' onClick={(event) => {handleAccodionClick(event, notableBug)}}>
                    
                    <Accordion.Header id={`#${notableBug.key}`}>
                        {notableBug.key} - {notableBug.fields.summary}
                    </Accordion.Header>

                    <Accordion.Body className=''>
                      <div className="d-flex form-check p-2 rounded-2" style={{background: "#d6eef2", border: "1px #ffc107 solid"}}>
                        <input className="form-check-input ms-3 me-3" type="checkbox" value={notableBug.key} onChange={(event) => {handleCheckBoxChange(event , notableBug)}} />
                        <label className="form-check-label" htmlFor="">{t("Check this box if you want to link your ticket (if this bug description matches your issue or is related)")}</label>
                      </div>

                      <div className=' my-4'>
                        <span dangerouslySetInnerHTML={{__html: description}} />
                      </div>

                    </Accordion.Body>
                  </Accordion.Item>
                </>
              )
            })
          }
          </Accordion>
        }
    </>
  )
}

export default NotableBugs