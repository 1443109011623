export const PROGRAMS = [
    {"value": "GREET - Sorgrum", "label": "GREET - Sorgrum"},
    {"value": "OpenLCA - Corn", "label": "OpenLCA - Corn"},
    {"value": "GREET - Corn", "label": "GREET - Corn"},
]

export const ALLOWED_IMPLEMENT_TYPE_IDS = [
    31, 32, 1, 34, 6, 14, 54, 47, 48, 49, 52, 55, 5, 11, 19, 22, 25
]

export const IMPLEMENT_ATTRIBUTES = [
    {"label": "Soil Opener Width", "key": "soil_opener_width", "type": 'numeric'},
    {"label": "Soil Opener Spacing", "key": "soil_opener_spacing", "type": 'numeric'},
    {"label": "Mid Row Band Opener Width", "key": "mid_row_band_opener_width", "type": 'numeric'},
    {"label": "Mid Row Band Opener Spacing", "key": "mid_row_band_opener_spacing", "type": 'numeric'},
    {"label": "Side Row Band Opener Width", "key": "side_row_band_opener_width", "type": 'numeric'},
    {"label": "Side Row and Opener Spacing", "key": "side_row_band_opener_spacing", "type": 'numeric'},
    {"label": "Fertilizer Band Opener Width From Opener", "key": "fertilizer_band_opener_width_from_opener", "type": 'numeric'},
    {"label": "VR Capable", "key": "vr_capable", "type": 'boolean'},
]