import React, { useState } from 'react'
import {Accordion} from 'react-bootstrap'

import {useDispatch} from 'react-redux'
import {requestParametersAddorChange, requestParameterRemove} from '../../../features/RequestParametersSlice'
import { useTranslation } from 'react-i18next'

function HarvestParameters() {

    const { t } = useTranslation();
    const dispatch = useDispatch()

    // States
    const [paramsChecked , setParamsChecked] = useState([]);
    

    //  -> List of the harvest parameters & its description
    const harvest_params = [ 
      {
        parameter: "dataSourceMerge",
        description: "Fetches both canplug and controller data at once and combines them into a single layer."
      },
      {
        parameter: "quantile",
        description: "Equal percentage class breaks"
      },
      {
        parameter: "naturalBreaks",
        description: "Default class break of farmcommand"
      },
      {
        parameter: "useDefaultMoisture",
        description: ""
      },
      {
        parameter: "useSmartRectForYield",
        // description: "Split layers by canplug implement type (default True for Canplug)"
      }
    ]


    // check box change event handler
    function handleCheckBoxChanges(event){
        const { name , checked } = event.target;
        
        if(checked){
            setParamsChecked(prev => [...prev, name])
            dispatch(requestParametersAddorChange({[name]: true})) // adding selected harvest parameter the global state
        } else{
            // delete key
            setParamsChecked(prev => {return prev.filter(e => e !== name)})
            dispatch(requestParameterRemove(name)) // deleting unselected harvest parameter
        }
    }

    // Dropdown change event handler
    function hanldeBooleanChange(event , param){
        let bool = event.target.value;
        bool = JSON.parse(bool)
        dispatch(requestParametersAddorChange({[param]: bool})) // changing boolean value
    }

  return (
    <>
        <p className='fs-5 text-decoration-underline'>Harvest Runtype specific parameters</p>
            <Accordion className=''>
              <Accordion.Item eventKey={"canplug-parameters"} onClick={null} >
                <Accordion.Header>{t("Harvest Parameters")}</Accordion.Header>
                    <Accordion.Body>
                        {
                            harvest_params.map((data , index) => {
                            return (
                                <>
                                    <div className="accordion-body">
                                        <div className="d-flex rounded p-2 my-2 bg-dark text-white">
                                            <div className="form-check fs-5" >
                                                <input className="d-flex flex-column form-check-input mx-2" type="checkbox" id="" name={data.parameter} onChange={handleCheckBoxChanges}/>
                                                <label className="form-check-label" for="">
                                                    <span className='flex-item'>{data.parameter}</span>
                                                </label>
                                            </div>
                                            
                                            {/* appears only if check box is checked */}
                                            {
                                            paramsChecked.includes(data.parameter) && 
                                            <div className="ms-auto">
                                                <select name="bool" id="" className='btn btn-primary ms-auto' onChange={(event) => {hanldeBooleanChange(event , data.parameter)}}>
                                                    <option className='form-control' value="true">{t("True")}</option>
                                                    <option className='form-control' value="false">{t("False")}</option>
                                                </select>
                                            </div>
                                            }
                                            
                                        </div>
                                        <div className=''>
                                            <p className='mt-2 mb-2'><span className=''><strong>*{t("Description")}*:</strong></span> {t(data.description)}</p>
                                        </div>
                                    </div>
                                </> 
                            )
                          })
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
  )
}

export default HarvestParameters