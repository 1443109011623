export const FARM_COMMAND_HOST = "https://processing-api.farmcommand.com"
export const FARM_COMMAND_HOST_QA = "https://qa.granduke.net"
export const ZONE_CALC_HOST = "https://zonecalc-service.granduke.net"
export const JIRA_BASE_URL = "https://farmersedgedev.atlassian.net"

// Coda URLS
export const CODA_HOST = "https://coda.granduke.net"
export const CODA_DEV_HOST = "https://coda-dev.granduke.net"
export const PRESCRIPTION_FILE = "prescriptionfile"
export const GET_DOWNLOAD_LINK_RAW = 'get_download_link_raw'
export const DOWNLOAD_GEO_GSON = "download_geojson"
export const DOWNLOAD_AGI = "download_agi"

// GLOBAL ACCESS USERS 
export const WHITELISTED_USERS = [
    "greg.rogers",
    "saivishnu.kotte",
    "dylan.mcnaughton",
    "charulatha.ravichandran",
    "iffatur.ridwan",
    "james.oliver",
    "shinas.np"
]

// GLOBAL ACCESS USERS 
export const ICICI_USERS = [
    "kundan.gidwani",
    "pankaj.kumawat",
    "ajit.shekhawat",
    "navneet.garg",
    "jidhoo.r",
    "pushpak.jumar",
    "sahoo.alok",
    "pankaj.kumawat"
]