import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import { Spinner } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { rxParametersAddorChange } from '../../../features/RxParametersSlice';
import { useTranslation } from 'react-i18next';

function FileNameModal() {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const rxParameters = useSelector(state => state.rxParameters);
    const dispatch = useDispatch();

    // Local states
    const [loading, set_loading] = useState(false);
    const [error_message, set_error_message] = useState('');
    const [filename, set_filename] = useState("");

    // refs
    const modalCloseBtn = useRef(null);
    
    // event handlers
    async function handleGenerateFile(event){
        event.target.classList.add('disabled');
        console.log('Generating File...');
        set_loading(true);
        set_error_message('')

        try {
            // Parse Request
            const request = {...rxParameters};
            if(Object.keys(request).includes("product_defaults")){
                request["product_defaults"] = request["product_defaults"].filter( product_default => !_.isEmpty(product_default) )
            }
            request["filename"] = `${filename}.json`;
            request["client_id"] = localStorage.getItem("client_id");
            
            // // Sending request to the api
            const response = await axios.post("/rxprozone/generate_prescription_controller_files/", {...request} ,  {headers: { "Content-Type" : "application/json" }} );
            if(response.status === 200 || response.status === 201){
                modalCloseBtn.current.click();
                navigate("/generated_prescription_files/");
            }
        } catch (error) {
            set_error_message('Oops! Something went wrong while generating the file');
            console.log(error);
        } finally {
            event.target.classList.remove('disabled');
            set_loading(false);
        }
    }


    useEffect(() => {
        dispatch(rxParametersAddorChange({"filename": filename}))
    }, [filename])

    return (
        <div className="modal fade" id="generate-file-modal" tabindex="-1" data-bs-backdrop="static" data-bs-keyboard="false" role="dialog">
            <div className="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-sm" role="document">
                <div className="modal-content">
                    <div className="modal-header bg-warning text-white">
                        <h5 className="modal-title" id="modalTitleId">{t("Generate Prescription Controller Files")}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" ref={modalCloseBtn}></button>
                    </div>
                    <div className="modal-body">
                        
                        <p>{t("Please provide file name for the file")}:</p>
                        <input type="text" name="filename" id="" className='form-control' placeholder={t('Enter Filename')} autoComplete='off' required onChange={(e) => {set_filename(e.target.value)}} value={filename ? filename :''}/>

                        
                        {
                            loading && <div className="d-flex my-4 justify-content-center"><Spinner /></div>
                        }

                        {
                            error_message && <p className='text-center text-danger my-4'>{error_message}</p>
                        }

                    </div>
                    <div className="modal-footer">
                        {filename && <button type="button" className="btn btn-outline-primary" onClick={(event) => {handleGenerateFile(event)}}>{t("Generate")}</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FileNameModal