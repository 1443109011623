import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import {rxParametersAddorChange} from '../../features/RxParametersSlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next"

function ApplicationTime() {

    const { t } = useTranslation();
    const dispatch = useDispatch() // dispatcher

    // data for the dropdown
    const [ application_time ] = useState([ 
        {'value' : 4, 'label': t('Seeding Application')},
        {'value' : 1, 'label': t('Pre Plant Fall Application')},
        {'value' : 2, 'label': t('Pre-Plant Spring Application')},
        {'value' : 3, 'label': t('In-Season Application')},
        {'value' : 6, 'label': t('Pre-Plant')},
    ])

    // dropdown change event handler
    const handleChange = (event) => {
        dispatch(rxParametersAddorChange({'ApplicationTime' : event.value}))
    }

    return (
        <>
            <Select options={application_time} onChange={handleChange} placeholder={t('Seeding Application Time')} defaultValue={4} />
        </>
    )
}

export default ApplicationTime