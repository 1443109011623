import axios from "axios";

export async function logOut(setAuthStatus) {
  setAuthStatus(false);
  localStorage.clear(); 
  sessionStorage.clear();
  try {
    const response = await axios.get("/api/logout/");
    console.log("Logging out");

    // Clear cookies based on the domain
    const domain = window.location.hostname;
    document.cookie.split(";").forEach((cookie) => {
        document.cookie = cookie
            .replace(/^ +/, "")
            .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/;domain=${domain}`);
    });

    if(response.data?.status){
      return true;
    }
  } catch (error) {
    console.log('Error while logging out');  
  }
  return false;
}