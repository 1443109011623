import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux"
import { requestParametersAddorChange } from "../../../features/RequestParametersSlice";
import { useEffect, useRef, useState } from "react";
import { MdNavigateNext, MdNavigateBefore } from "react-icons/md";


export const FieldNavigator = ({renderAfter=2000}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const requestParameters = useSelector(state => state.requestParameters);
    
    const [ isWaiting, setIsWaiting ] = useState(true);
    const [current_field_idx, set_current_field_idx] = useState(0);
    
    const {
        field_ids,
        current_field_id
    } = requestParameters;

    const fields_length = field_ids?.length;


    useEffect(() => {
        setTimeout(() => {
            setIsWaiting(false);
        }, renderAfter); 
        set_current_field_idx(field_ids?.findIndex(e => e === current_field_id));
    }, [current_field_id])

    function handlePrevious(){
        setIsWaiting(true);
        dispatch(requestParametersAddorChange({"current_field_id": field_ids[current_field_idx-1]}))
    }

    function handleNext(){
        setIsWaiting(true);
        dispatch(requestParametersAddorChange({"current_field_id": field_ids[current_field_idx+1]}))
    }

    if (isWaiting) {
        return <></>
    }

    return (
        <>
            {
                (current_field_idx > 0) &&
                <button 
                    onClick={handlePrevious}
                    style={{left: 75}}
                    className="position-absolute btn btn-outline-secondary p-2 m-2 d-flex" >
                        <MdNavigateBefore size={24} /> {t("Previous Field")}
                </button>
            }
            
            {
                (current_field_idx < fields_length - 1) &&
                <button 
                    onClick={handleNext}
                    className="position-absolute btn btn-outline-dark p-2 m-2 d-flex" 
                    style={{right: 0}}>
                        {t("Next Field")} <MdNavigateNext size={24} /> 
                </button>
            }
        </>
    )
}
