import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'

import {requestParametersAddorChange} from '../../features/RequestParametersSlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

function Hub() {

    const { t } = useTranslation();
    const dispatch = useDispatch()

    const [ hubs , setHubs ] = useState([]) // state to store hubs returned from api

    async function fetchHubs(){
        try{
            let response = await axios("/api/getHubs/");
            setHubs( response.data.map( (e) => { return { "value" : e.id, "label" : e.label } }) )
          }
        catch(err){
            console.log('Error while fetching hubs...')
        }
    }

    useEffect(() => {
        // fetch hubs, if they weren't fetched
        if(hubs.length === 0){
            fetchHubs();
        }
    }, []);

    function handleChange(event){
        dispatch( requestParametersAddorChange({'hub': event.value, 'grower_id': null}) )
    }

    return (
        <>
            <Select placeholder={t("Select a Hub")} options={hubs} onChange={handleChange}/>
        </>
    )
}

export default Hub