import React from 'react';
import { isElvyraTech, isJIRAAuthenticated, isSmartImageryGrower, isStaff, isSmartPro, isGrower, isIcici, isSelfServeGrower, isNSPSelfServeGrower} from 'utils/authHelpers';
import { FaArrowRotateRight, FaBarsProgress } from "react-icons/fa6";
import { FaTools, FaMap, FaCloudUploadAlt, FaLeaf, FaTicketAlt, FaCloudDownloadAlt} from "react-icons/fa";
import { BsClipboardData } from "react-icons/bs";
import { BiSupport, BiBullseye, BiArchiveOut } from "react-icons/bi";
import { IoIosPaper, IoMdAddCircleOutline } from "react-icons/io";
import { MdFileUpload, MdOutlineContactSupport } from "react-icons/md";

export let allowedPages;

export const PAGE_WIDGET_MAP = [
    { key: "FCR", name: "ICICI Farm Credit Reporting", description: "Generate and download insurance farm credit reports.", route: "/reporting/sail", auth: isElvyraTech() || isIcici(), icon: <FaCloudDownloadAlt />, },
    { key: "IRIS", name: "IRIS Farm Reporting", description: "Generate and download IRIS farm reports.", route: "/reporting/iris", auth: isElvyraTech(), icon: <FaCloudDownloadAlt />, },
    { key: "SPT", name: "Submit Patricia Tasks", description: "Submit data processing request for Field Asset data", route: "/create_task", auth: isElvyraTech(), icon: <FaArrowRotateRight /> },
    { key: "YPT", name: "Your Patricia Tasks", description: "Check processing status on data processing requests already submitted", route: "/created_tasks", auth: isElvyraTech(), icon: <FaBarsProgress /> },
    { key: "TPI", name: "Third Party Integration", description: "Integrate with a third party integration for data management.", route: "/leaf-integration", auth: (isStaff() || isNSPSelfServeGrower()) && !isIcici(), icon: <FaLeaf /> },
    { key: "FCL", name: "FC Lite", description: "FarmCommand Lite version used to visualize layers and basic info in a minimal view", route: "/maps", auth: isStaff(), icon: <FaMap /> },
    { key: "SMFU", name: "Smart Reporting Field Uploader", description: "Upload and task fields for Smart Reporting", route: "/smart_reporting_field_uploader", auth: isSmartImageryGrower(), icon: <BiArchiveOut /> },
    { key: "CST", name: "Create Support Ticket", description: "Having issues with your data or FarmCommand layers? Submit a Data Support request here!", route: "/create_ticket", auth: isJIRAAuthenticated(), icon: <FaTicketAlt /> },
    { key: "CSR", name: "Create Service Request", description: "Generate PDF's, Shapefiles and Yield By Zone CSV", route: "/service_request", auth: isStaff(), icon: <BiSupport /> },
    { key: "TFR", name: "Task Field Request", description: "Task a Growers field asset's for data processing", route: "/task_field_request", auth: (isStaff() || isSmartPro()) && !isIcici(), icon: <IoIosPaper />,  },
    { key: "AMTG", name: "Add Machines To Grower", description: "Have a machine with a CanPlug working on multiple farms? Use this to add the machine to another Grower.", route: "/add_machines_to_grower", auth: isStaff(), icon: <IoMdAddCircleOutline /> },
    { key: "PFG", name: "Prescription File Generator", description: "Generate controller monitor ready prescription files.", route: "/generate_prescription_controller_files", auth: isStaff(), icon: <BiBullseye /> },
    { key: "AFU", name: "Asset File Uploader", description: "Upload Asset information and files storage", route: "/file_uploader", auth: (isStaff() || isNSPSelfServeGrower()) && !isIcici(), icon: <MdFileUpload /> },
    { key: "UCF", name: "Upload Controller Files", description: "Upload machine controller files for processing.", route: "/upload_controller_file", auth: (isStaff() || isGrower()) && !isIcici(), icon: <FaCloudUploadAlt /> },
    { key: "MCF", name: "Uploaded Controller Files", description: "Check processing status on machine controller files already submitted", route: "/uploaded_controller_files", auth: (isStaff() || isGrower()) && !isIcici(), icon: <BsClipboardData /> },
];

export const getAllowedPages = () => {
    return PAGE_WIDGET_MAP.filter(page => page.auth);
};

export const allowedPagesFunction = () => {
    // Create a map for quick lookup of descriptions
    const pageWidgetMap = new Map(PAGE_WIDGET_MAP.map(page => [page.key, page]));
    const filterAuthenticatedPages = (pages) => pages.filter(page => page && page.auth);

    allowedPages = [
        {
            id: 1,
            name: "Internal Tools",
            pages: filterAuthenticatedPages([
                pageWidgetMap.get("FCR"),
                pageWidgetMap.get("IRIS"),
                pageWidgetMap.get("SPT"),
                pageWidgetMap.get("YPT"),
                pageWidgetMap.get("FCL"),
                pageWidgetMap.get("AMTG")
            ]),
            icon: <FaTools />
        },
        {
            id: 2,
            name: "Data Support",
            pages: filterAuthenticatedPages([
                pageWidgetMap.get("CST"),
                pageWidgetMap.get("TFR"),
                pageWidgetMap.get("CSR")
            ]),
            icon: <MdOutlineContactSupport />
        },
        {
            id: 3,
            name: "Data Management",
            pages: filterAuthenticatedPages([
                pageWidgetMap.get("TPI"),
                pageWidgetMap.get("AFU"),
                pageWidgetMap.get("UCF"),
                pageWidgetMap.get("MCF"),
                pageWidgetMap.get("SMFU"),
                pageWidgetMap.get("PFG")
            ]),
            icon: <BsClipboardData />
        }
    ];
    return allowedPages;
}

export default allowedPagesFunction();