import React, { useContext, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import {useSelector} from 'react-redux'
import { useTranslation } from "react-i18next";

function Submit() {
  
  const { t } = useTranslation();
  const ticketParameters = useSelector(state => state.ticketParameters); // global state
  
  const [ errorMessage , setErrorMessage ] = useState("") // to set error message
  const [ loading , setLoading ] = useState(false) // to control visibility of loading component
  
  const navigate = useNavigate() // navigator

  // submit button click handler
  async function handleSubmit(){
    setLoading(true)
    console.log(ticketParameters)

    const files = ticketParameters["files"]

    const data = Object.keys(ticketParameters).filter(key => key !== 'files').reduce((cur , key) =>  { return Object.assign(cur, { [key]: ticketParameters[key] })}, {});
    
    let form_data = new FormData()
    form_data.append("data", JSON.stringify(data))
    
    for(let index=0; index < files.length; index++){
      form_data.append("file" , files[index])
    }

    try {
      const response = await axios.post("/api/createTicket/" , form_data , { headers: { "Content-Type" : "multipart/form-data" }})
      if(response.status === 200 || response.status === 201){
        navigate("/ticket_created" , { state: {
          data : response.data
        } })
      }
      else{
        setErrorMessage(response.data?.message)
        setLoading(false)
      }
    }
    catch (error) {
      console.log(error)
      setLoading(false);
      setErrorMessage("Something wen't wrong while creating DPS ticket")
    }
    setLoading(false);
  }

  return (
    <>
    <div className="d-flex my-3 flex-column">
    {loading ? 
      <Spinner className='text-dark mx-auto my-4' /> : 
      <>
        { errorMessage && <p className='fs-4 mx-auto my-4 text-danger'>{errorMessage}</p> }
        <p className='fs-5'>{t("Please review your ticket before submitting once you are happy click submit to create your new support ticket")}</p>
        <input type="button" value="Submit" className='btn btn-dark fs-4 my-2 w-100' onClick={handleSubmit}/>
      </>
    }
    </div>
    </>
  )
}

export default Submit