import { Leaf, FileUpload } from '@withleaf/leaf-link-react';

const LoadFileUpload = ({leafUserId, apiKey}) => {

    const companyName = "Farmers Edge";
    const FILES_TIME_RANGE = 30;

    return (
        <>
        
        <FileUpload
            isDarkMode={true}
            companyName={companyName}
            companyLogo={"https://fcn-dev.granduke.net/favicon.ico"}
            filesTimeRange={FILES_TIME_RANGE}
            leafUser={leafUserId}
            apiKey={apiKey}
        />
    </>
    );}

export default LoadFileUpload;