import React, { useEffect, useState } from 'react'
import TableRow from './TableRow'
import { useDispatch, useSelector } from 'react-redux'
import SeasonSelector from './SeasonSelector'

import { constructState, pushToDefault } from '../../../../features/RequestSlice'
import { tempParametersAddorChange, tempParametersReset } from '../../../../features/TempParametersSlice'
import Submit from './Submit'
import { useTranslation } from 'react-i18next'


function SeasonView({ product_details }) {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const tempParameters = useSelector(state => state.tempParameters);

  let sourcefile_id = tempParameters.sourcefile_id;
  let result = {};

  const [ parsed_products, set_parsed_products ] = useState({})
  const [ unique_seasons , set_unique_seasons ] = useState([]) // Array of Unique Season objects
  const [ keys , set_keys ] = useState([])

  let position = -1;
  
  // Utils
  // Formating Product
  function formatProduct(product){
    return { "Total_x0020_Acres": product.Total_x0020_Acres, "Crop": product.Crop, "Date": product.Date, "Field": product.Field, "Farm": product.Farm, "Grower": product.Grower, "FileHash": product.FileHash, "FileName": product.FileName, "Operation": product.Operation, "Product": product.Product }
  }

  // to check whether given product already exist for the season
  function findProductAlreadyExist(season_array, product){
    let result = false;
    season_array.forEach( (e) => {
        if(e.Product === product){
            result = true; return;
        }
    })
    return result;
  }


  useEffect(() => {
      // Creating Structure for Unique Product for across all seasons
      product_details.forEach(product => {
        let key = JSON.stringify(product.seasons)

        if( Object.keys(result).includes(key) ){ // Existing season key
            if(!findProductAlreadyExist(result[key], product.Product)){ // check product already exist for this season
                result[key].push( formatProduct(product) ) // Pushing, if product doesn't exist already
            } 
        } 
        else{ // First season key
            result[key] = [formatProduct(product)]
        }
      })

      // Helpers
      set_parsed_products(result);
      set_keys(Object.keys(result));
      set_unique_seasons( Object.keys(result).map( e => JSON.parse(e)) ); // List of Unique Seasons
  }, []);



  return (
    <>
      {
        unique_seasons.map((season, season_index) => {
          return (
            <div className="" key={season_index}>
              <SeasonSelector index={season_index} season={season} sourcefile_id={sourcefile_id}/>
        
              <div className="product-details my-0">
                <figure className='wrapper' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto", zIndex: -1}}>
                    <table className="fixed_header table table-hover">
                    
                    {/* Table Header */}
                    <thead className='header text-white'  style={{zIndex: -99, backgroundColor: "#02559c"}}>
                        <tr >
                            <th scope="col">#</th>
                            <th scope="col">{t("Crop")}</th>
                            <th scope='col'>{t("Product")}</th>
                            <th scope='col'>{t("Supply Type")}</th>
                            <th scope='col'>{t("Supply")}</th>
                            <th scope='col'>{t("Operation")}</th>
                            <th scope="col">{t("Field")}</th>
                            <th scope="col">{t("Grower")}</th>
                        </tr>
                    </thead>

                    {/* Table Body */}
                    <tbody>
                      {
                        parsed_products[keys[season_index]].map( (e, key) => {
                          position++;
                          return (
                            <TableRow season_index={season_index} id={key} key={key} product={e} position={position} />
                          )
                        })
                      }
                    </tbody>

                  </table>
                </figure>
              </div>
            </div>
          )
        })
      }
      {/* Submit Button */}
      <Submit />
    </>
  )
}

export default SeasonView;