import React, { useEffect, useState } from 'react'
import {useDispatch} from 'react-redux'
import {ticketParametersAddorChange} from '../../features/TicketParametersSlice'
import { useTranslation } from 'react-i18next';


function Description() {

  const { t } = useTranslation();
  const dispatch = useDispatch() // dispatcher

  const [ description , setDescription ] = useState('') // to track text on description text area
  const [ count , setCount ] = useState(32767) // to keep track of max_words


  useEffect(() => {
    // adding/updating description to the ticketParams context
    dispatch(ticketParametersAddorChange({ "description": "description", "description_id": description }))
    
    // decreasing the total chars 
    setCount((prev) => { return (32767 - description.length) })
  }, [description])

  // textarea field change event handler
  function handleChange(event){
      const { name, value } = event.target;
      setDescription(value)
  }

  return (
    <>
      <div className="my-4">
          <p className='h3'>{t("Description Details")}</p>
          <p className='fs-5'>{t("Please provide a description of the issue you are experiencing")}</p>
          <textarea className='form-control' name="description" value={description} onChange={handleChange} style={{minHeight: "150px"}} placeholder={t("Description of the issue you are experiencing")} maxLength={32767}></textarea>
          <p className='mt-4 mb-0'>{t("(Maximum characters: 32,767)")}</p>
          
          <p className=''>{t("You have")} 
            <span className='mx-1'><input readOnly type="text" className='' size={5} style={{padding: "1px 2px"}} value={count}/></span> 
          {t("characters left")}.</p>
      </div>
    </>
  )
}

export default Description