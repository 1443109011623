import Select from 'react-select'
import {FILE_CATEGORIES_OPTIONS,} from '../../../constants'
import { useTranslation } from 'react-i18next';

function FileCategory({urlSearchParams}) {

    const { t } = useTranslation();
    function handleChange(event){
        if(event){
            urlSearchParams.current.set("category", event.value);
        }
        else{
            urlSearchParams.current.delete("category");
        }
    }
    
    return (
        <div className="my-4">
            <p className="fs-5 my-1">{t("File Category")}</p>
            <Select 
                options={FILE_CATEGORIES_OPTIONS}
                onChange={handleChange}
                isClearable={true}    
            />
        </div>
    )
}

export default FileCategory