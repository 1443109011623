import axios from 'axios';
import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import {useNavigate} from 'react-router-dom'

function AssignMachines() {

    const { t } = useTranslation();
    const { season, hub, grower, grower_label, runtype, selectImplementIds} = useSelector(state => state.requestParameters);
    
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const navigate = useNavigate();

    async function handleAssignMachinesClick(event){
        event.target.classList.add("disabled")
        setLoading(true);
        try {
            let request = {
                "season": season,
                "hub": hub,
                "grower": grower,
                "farm": [],
                "fields": [grower],
                "datatype": "canplug",
                "runtype": runtype,
                "selectImplementIds": selectImplementIds,
                "token": localStorage.getItem("token")
            }
            setLoading(true);
            const response = await axios.post("/api/runtask/", request);
            const taskIDs  = response.data?.taskIDs;

            // Possible Error Checks
            if(! [200, 201].includes(response.status)){
                setErrorMessage("Something went wrong while running tasks for the field.")
                setLoading(false);
                return;
            }
            if(taskIDs.length === 0){
                // if the returned tasks is empty list
                setErrorMessage("Something went wrong while running tasks for the field.  Possibly all the fields might not have tasked")
                return;
            }  

            console.log(taskIDs); // task ids returned by creation of harvest/applied task
            // Store Task IDs
            if(await StoreTasks( taskIDs )){
                navigate("/add_machines_to_grower/your_assignments/")
            } else {
                setErrorMessage("Error while storing the Tasks")
            }

        } catch (error) {
            console.log(error);
        }
        finally{
            setLoading(false);
        }
    }

    async function StoreTasks( taskIDs ){
        try {
            const response = await axios.post("/api/assign_machines/", {
                runtype,
                grower,
                grower_label,
                "taskIDs": JSON.stringify(taskIDs)
            })
            if( [200, 201].includes(response.status)){
                return true
            }
            return false
        } catch (error) {
            return false
        }
        
    }

    return (
        <>
            {loading && <div className='d-flex justify-content-center my-5'><Spinner /></div>}
            {errorMessage && <div className='d-flex justify-content-center my-5'><p className='text-danger fs-5'> {errorMessage} </p></div>}
            <div className="d-flex align-items-center justify-content-center my-5">
                <button className="btn btn-outline-primary" onClick={handleAssignMachinesClick}>{ t("Assign Machines") }</button>
            </div>
        </>
    )
}

export default AssignMachines