import SparkMD5 from "spark-md5";

export function calculateMD5(file) {
  return new Promise((resolve, reject) => {
    const chunkSize = 1024 * 1024; // 1MB chunks
    const spark = new SparkMD5.ArrayBuffer();
    const reader = new FileReader();
    let offset = 0;

    reader.onload = (event) => {
      const buffer = event.target.result;
      spark.append(buffer);
      offset += buffer.byteLength;

      if (offset < file.size) {
        readChunk(offset);
      } else {
        const hash = spark.end();
        resolve(hash);
      }
    };

    reader.onerror = () => {
      reject(new Error('Failed to calculate MD5 hash'));
    };

    function readChunk(offset) {
      const slice = file.slice(offset, offset + chunkSize);
      reader.readAsArrayBuffer(slice);
    }

    readChunk(0);
  });
}