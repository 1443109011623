import { useNavigate } from "react-router-dom";
import Users from './Users'
import Operations from './Operations'
import MapComponent from './MapComponent';
import { useEffect, useState } from "react";
import { Tabs, Tab } from 'react-bootstrap';
import LoadProviders from './LoadProviders';
import LoadFileUpload from './LoadFileUpload';
import axios from "axios";
import { WHITELISTED_USERS } from '../../../constants';
import { toast } from "react-toastify";

const LeafComponent = ({ data }) => {
    const navigate = useNavigate();
    const [key, setKey] = useState('welcome');
    const [loading, setLoading] = useState(false);

    const apiKey = data?.key;
    const leafUserId = data?.leaf_user_id;
    const [userName , setUsername] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedOperation, setSelectedOperation] = useState(null);
    const [isFileUploaded, setIsFileUploaded] = useState(false);; // Replace with actual condition
    // Define conditions
    const isLeafUserCreated = !!leafUserId;
    const isProviderConnected = !!apiKey; // Example condition
    

    useEffect(() => {
        if(localStorage.getItem('username')){ setUsername(localStorage.getItem('username'))}
    },[]);

    useEffect(() => {
        if (!apiKey || !leafUserId) {
            navigate("/leaf-integration/form/");
        }
    }, [apiKey, leafUserId, navigate]);


    async function checkUploadedFiles(leafUserId) {
        setLoading(true);
        // toast("Loading");

        try {
            const response = await axios.get(`/leaf/files/?leafUserId=${leafUserId}`);
            if (response.status === 200) {
                setIsFileUploaded(response.data);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!isFileUploaded) {
            checkUploadedFiles(leafUserId);
        };
    }, [apiKey, leafUserId, isFileUploaded]);

    return (
    <>
        <div className="container-fluid ">
            <div className="row page-content content-container ">
            <Tabs
            id="controlled-tab"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="custom-tabs mb-0"
            >
            <Tab eventKey="welcome" title="Welcome">
                <div>
                    <h3>Welcome to Farmersedge Leaf Integration!</h3>
                    <ol className="list-group list-group-numbered">
                        <li className={`list-group-item ${isLeafUserCreated ? 'completed' : ''}`}>
                            {isLeafUserCreated && <span className="tick">&#10003;</span>}
                            Create Leaf user filling out the form.
                        </li>
                        <li className={`list-group-item ${isProviderConnected ? 'completed' : ''}`}>
                            {isProviderConnected && <span className="tick">&#10003;</span>}
                            Connect your provider via the leaf connect tab.
                        </li>
                        <li className={`list-group-item ${isFileUploaded ? 'completed' : ''}`}>
                            {isFileUploaded && <span className="tick">&#10003;</span>}
                            Synced integration files or Upload your own controller files via the upload tab.
                        </li>
                    </ol>
                </div>
            </Tab>
            
            {apiKey && leafUserId &&  
                <Tab eventKey="leafConnect" title="Leaf Connect">
                    <LoadProviders leafUserId={leafUserId} apiKey={apiKey}/>  
                </Tab>
            }   
            {apiKey && leafUserId &&  
                <Tab eventKey="fileUpload" title="Controller File Upload">
                    <LoadFileUpload leafUserId={leafUserId} apiKey={apiKey}/>
                </Tab>
            }   
            {userName && WHITELISTED_USERS.includes(userName) &&
                <Tab eventKey="explore" title="Explore">
                        
                        <Users onUserSelect={setSelectedUser}/>
                        <Operations selectedUser={selectedUser} onOperationSelect={setSelectedOperation}/>
                        { selectedOperation && <MapComponent selectedOperation={selectedOperation}/>}

                </Tab>
            }
        </Tabs>
        </div>
        </div>
    </>
    )
}

export default LeafComponent