import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import {Spinner} from 'react-bootstrap'
import {useDispatch, useSelector} from 'react-redux'
import {requestParametersAddorChange} from '../../../../../features/RequestParametersSlice'
import Confirmation from './Confirmation'
import { ResponseContext } from '../../../context/ResponseContext';

const FieldsTable = ({fieldAccordion}) => {

    const dispatch = useDispatch();
    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    const { grower_id, season, fields_selected, fields_to_include } = useSelector(state => state.requestParameters);
    const { _dispatch } = useContext(ResponseContext);

    useEffect(() => {
        dispatch(requestParametersAddorChange({"fields_to_include": []}))
        fetchImplements();
    }, [])

    async function fetchImplements(){
        setLoading(true);
        try {
            const response = await axios.get(`/api/carbon/getFields/?grower_id=${grower_id}&season=${season}`);
            if(response.data?.result){
                const mappedFields = response.data.result?.features?.map((e) => { 
                    return {
                        "id" : e.properties.field, 
                        "label" : e.properties.label,
                        "crop" : e.properties.crop_name_en,
                    }
                });
                setData(mappedFields);
                _dispatch({ "type": "addOrChange", "payload": { "fields" : mappedFields } })
                dispatch(requestParametersAddorChange({"fields_to_include": mappedFields.map(e => e.id)}))
            }
        } catch (error) {
            console.log(error);            
        } finally {
            setLoading(false);
        }
    }

    function handleSelectAll(event){
        const {checked} = event.target;
        if(checked) {
            dispatch(requestParametersAddorChange({"fields_to_include": data.map(e => e?.id)}))
        } else {
            dispatch(requestParametersAddorChange({"fields_to_include": []}))
        }
    }


    return (
        <>
            {
            loading ? <Spinner />
            :
            <figure className='wrapper' style={{display:"flex", overscrollBehaviorX: "contain", overflowX: "auto"}}>
                <table className="table table-hover">
                <thead >
                    <tr className='m-2 header' >
                        <th className='bg-dark text-white'>#</th>
                        <th className='bg-dark text-white'>Field ID</th>
                        <th className='bg-dark text-white'>Field Label</th>
                        <th className='bg-dark text-white'>Crop Name</th>
                        <th className='bg-dark text-white'>
                            {
                                !fields_selected && 
                                    <input type="checkbox" 
                                        name="select-all" id="select-all"
                                        className='form-check-input'
                                        onChange={
                                            fields_selected ? null : handleSelectAll
                                        }
                                        checked={
                                            fields_to_include?.length === data.length ? "checked" : ''
                                        }
                                    /> 
                            }
                        </th>
                    </tr>
                </thead>

                <tbody>
                    {
                        data.map((row, index) => {
                            return (
                                <>
                                    <FieldRow 
                                        key={index} 
                                        row={row} index={index}  
                                    />
                                </>
                            )
                        })
                    }
                </tbody>
                </table>
            </figure>
            }
            { (!loading && data) && <Confirmation fieldAccordion={fieldAccordion} />}
        </>
    )
}

function FieldRow({row, index}){
    
    const dispatch = useDispatch();
    const { fields_to_include, fields_selected } = useSelector(state => state.requestParameters);

    const handleChange = (event, field_id) => {
        const {checked} = event.target;
        if(checked){
            if(fields_to_include)
                dispatch(requestParametersAddorChange({"fields_to_include": [...fields_to_include, field_id]}));
            else
                dispatch(requestParametersAddorChange({"fields_to_include": [ field_id ]}));
        } else {
            dispatch(requestParametersAddorChange({"fields_to_include": fields_to_include?.filter(id => id !== field_id) }));
        }
    }



    return (                    
    <>
        <tr className={fields_selected ? "table-secondary" : ""}>
            <td>{index + 1}</td>
            <td>{row?.id}</td>
            <td>{row?.label}</td>
            <td>{row?.crop}</td>
            <td>
                <input type="checkbox" 
                        name="field-check-box" id="field-selector" 
                        className='form-check-input p-2'
                        onChange={
                            fields_selected ? null : (event) => {handleChange(event, row?.id)}
                        }
                        checked={fields_to_include?.includes(row?.id)}
                        />
            </td>
        </tr>
    </>
    )
}

export default FieldsTable