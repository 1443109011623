import Select from 'react-select'
import {FILE_CATEGORIES_OPTIONS} from '../constants'
import { useDispatch } from 'react-redux'
import { requestParametersAddorChange } from '../../../features/RequestParametersSlice'
import { useTranslation } from 'react-i18next'

const FileCategory = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch()

    function handleFileCategoryChange(event){
        dispatch(requestParametersAddorChange({"category": event.value}))
    }

    return (
        <div className="mb-2 my-4">
            <label className="form-label fs-5">{t("Select File Category")}</label>
            <Select 
                placeholder={t("Select File Category")} 
                options={FILE_CATEGORIES_OPTIONS} 
                onChange={handleFileCategoryChange}
                />
        </div>
    )
}

export default FileCategory
