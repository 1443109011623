import axios from "axios";
import { useEffect, useState } from "react";

const useFetch = ({ url }) => {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [refetch, setRefetch] = useState(false);

    const fetchData = async () => {
        try {
            setLoading(true);
            setError(null); // Clear previous error
            const response = await axios.get(url);
            setData(response.data);
        } catch (error) {
            console.error(error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (url) {
            fetchData();
        }
    }, [url, refetch]); // Added url to dependency array

    return {
        data,
        error,
        loading,
        setRefetch,
    };
};

export default useFetch;
