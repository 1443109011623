import React from 'react'

function GlobalFilter({filter, setFilter}) {
    return (
        <>
            <div className="d-flex align-items-center justify-content-end gap-2 my-3">
                <label htmlFor="" className=''>Search: </label>
                <input type="text" className='form-control' style={{width: "200px"}} value={filter ? filter: ''} onChange={(event) => {setFilter(event.target.value)}}/>
            </div>
        </>
    )
}

export default GlobalFilter