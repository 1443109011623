import React, { useEffect, useState } from 'react'
import { useTable, useGroupBy, useSortBy, useGlobalFilter, usePagination, useFilters } from 'react-table';
import GlobalFilter from './GlobalFilter'
import {fetchData} from './utils'
import Loading from './Loading';
import FilterComponent from './FilterComponent';
import ExportCSV from './ExportCSV';
import ExportExcel from './ExportExcel';

const PAGE_SIZES = [5, 10, 25, 50, 100]

function DataTable() {
    
    import ("./styles.css")

    const [ COLUMNS, SET_COLUMNS ] = useState([]);
    const [DATA, SET_DATA] = useState([])
    const [ KEYS, SET_KEYS ] = useState([]); // Col Header Names
    const [loading, setLoading] = useState(false);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        state:  { globalFilter },
        setGlobalFilter,
        nextPage, canNextPage,
        previousPage, canPreviousPage,
        pageOptions,
        state,
        setPageSize,
        gotoPage,
        setFilter
    } = useTable(
    {
        columns: COLUMNS,
        data: DATA// Extract the data arrays
    },
        useFilters,
        useGroupBy,
        useGlobalFilter,
        useSortBy,
        usePagination,
    );
    const {pageIndex, pageSize, filters} = state;

    useEffect(() => {
        setPageSize(50);
        fetchData(SET_DATA, SET_COLUMNS, SET_KEYS, setLoading);
    }, [])

    useEffect(() => {
        console.log(COLUMNS)
    }, [COLUMNS])

    return (
    <>
    {
        loading ? <Loading /> :
        <div className='px-4 my-2'>

            {/* <pre><code>{JSON.stringify(filters, null, 2)}</code></pre> */}
            <FilterComponent headerGroups={headerGroups} filters={filters} setFilter={setFilter} />

            <div className="d-flex gap-2 align-items-center">
                <ExportCSV />
                <ExportExcel />

                <div className="d-flex align-items-center ms-auto gap-2">
                    <button className="flex-item btn btn-outline-dark rounded"  type="button" data-bs-toggle="offcanvas" data-bs-target="#ofc-filter"><i className="fa-solid fa-sliders"></i> Filters</button>
                    <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>        
                </div>
            </div>

            <div className="table-responsive table-wrapper-scroll-y my-custom-scrollbar">
                <table {...getTableProps()} className="table table-striped align-middle">
                    <thead className=''>
                        {
                        headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()} className='sticky-top pointer'>
                                {
                                    headerGroup.headers.map( column => {
                                        
                                    const key_exist = KEYS.includes(column.Header);

                                    return (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{backgroundColor: `${key_exist ? "rgb(235,175,3)" : "#666666"}`, textAlign: 'center', verticalAlign: "middle"}}  className={key_exist && 'border border-2 border-dark justify-content-center p-2'}>
                                            <span className='text-white p-4 text-center'>
                                                {column.render('Header')}
                                                { !key_exist && <>{column.isSorted ? (column.isSortedDesc ? <i className='sorting_desc'></i> : <i className='sorting_asc'></i>) : <i className='sorting'></i>}</>}
                                            </span>
                                        </th>
                                    )
                                    })
                                }
                            </tr>
                        ))
                        }
                    </thead>

                    <tbody {...getTableBodyProps()} style={{maxHeight: "80vh"}}>
                        {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                <td className='text-center' {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                );
                            })}
                            </tr>
                        );
                        })}
                    </tbody>
                </table>
            </div>

            <div className="d-flex gap-1 align-items-center my-2">
                    
                    {/* Current Page */}
                    <span>Page <strong> {pageIndex + 1} of {pageOptions.length}</strong></span>
                    
                    {/* << Prev Next >> */}
                    <div className="mx-auto d-flex gap-2">
                        <button className="btn btn-secondary" disabled={!canPreviousPage} onClick={() => {gotoPage(0)}}>{'<<'}</button>
                        <button className="btn btn-primary" disabled={!canPreviousPage} onClick={() => {previousPage()}}>Previous</button>
                        <button className="btn btn-success" disabled={!canNextPage} onClick={() => {nextPage()}}>Next</button>
                        <button className="btn btn-secondary" disabled={!canNextPage} onClick={() => {gotoPage(pageOptions.length - 1)}}>{'>>'}</button>
                    </div>

                    {/* Page Size  */}
                    <div>
                    <select className="form-control" defaultValue={pageSize} onChange={(event) => {setPageSize(Number(event.target.value))}}>
                        {PAGE_SIZES.map(pagesize => (
                            <option key={pagesize} value={pagesize}>Show {pagesize} Rows </option>
                        ))}
                    </select>
                    </div>
            </div>
        </div>
    }
    </>
    )
}

export default DataTable