import React from 'react'
import {Offcanvas } from '../../../../components'
import Navbar from '../../../settings/components/Navbar'
import PDFGeneration from '../components/PDFGeneration'
import { ToastContainer } from 'react-toastify'

const PdfForm = () => {
    return (
        <div>
            <Navbar pageName={"Farm Credit Reporting"} />
            <Offcanvas />
            <div className='container'>
                <PDFGeneration />
            </div>
            <ToastContainer                
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light" />
        </div>
    )
}

export default PdfForm