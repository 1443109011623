import axios from "axios"
import {toast} from 'react-toastify'
const Download = ({uuid}) => {

    async function handleDownloadClick(){
        toast("Downloading...")
        try {
            const response = await axios.post("/api/file_uploader/generate_download_link/", {"id": uuid})
            if(response.data?.gcs_blob_url){
                var link = document.createElement("a");
                link.setAttribute('download', '');
                link.href = response.data?.gcs_blob_url;
                document.body.appendChild(link);
                link.click();
                link.remove();
                toast("Downloaded")
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <td className='text-center'>
            <button className="btn btn-outline-primary" onClick={handleDownloadClick}>
                <i className="fa-solid fa-download"></i>
            </button>
        </td>
    )
}

export default Download