import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';


const FieldLabels = ({}) => {
    
    const { t } = useTranslation();
    const [ field_label, set_field_label ] = useState("");

    const requestParameters = useSelector(state => state.requestParameters);
    const { field_ids, field_labels, current_field_id } = requestParameters;

    // Side Effects
    useEffect(() => {
        const idx = field_ids?.findIndex(e => e === current_field_id);
        set_field_label(() => {
            return field_labels?.at(idx) ? field_labels?.at(idx) : "";
        })
    }, [current_field_id])

    return (
        <div className='field-name'>
            <p className='text-center mt-2 fs-5'>
                <span className='text-primary mx-1'>{t("Field Label")}: </span>
                <span className='text-success text-decoration-underline'>{field_label}</span>
            </p>
        </div>
    )
}

export default FieldLabels;