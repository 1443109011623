import { useTranslation } from "react-i18next"
import { FaFilter } from "react-icons/fa6";

const FilterButton = () => {
    const { t } = useTranslation();
    return (
        <>
            <button className="ms-3 btn btn-outline-dark"  data-bs-toggle="offcanvas" data-bs-target="#offcanvas-end">
                <FaFilter /> {t("Filters")} 
            </button>
        </>
    )
}

export default FilterButton