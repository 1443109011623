import { Leaf, Providers } from '@withleaf/leaf-link-react';

const LoadProviders = ({leafUserId, apiKey}) => {

    const companyName = "Farmers Edge";


    return (
        <>
            <Leaf>
                <Providers
                    companyLogoUrl={"https://farmersedge.ca/"}
                    isDarkMode={true}
                    companyName={companyName}
                    companyLogo={"https://fcn-dev.granduke.net/favicon.ico"}
                    leafUser={leafUserId}
                    apiKey={apiKey}
                />
            </Leaf>
        </>
    );}

export default LoadProviders;