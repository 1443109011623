import Select from "react-select"
import {ROOT_ASSETS_OPTIONS} from '../constants'
import { useDispatch } from "react-redux"
import { requestParametersAddorChange } from "../../../features/RequestParametersSlice"
import { useTranslation } from "react-i18next"

const RootAssetCategory = () => {
    
    const { t } = useTranslation();
    const dispatch = useDispatch()

    function handleRootAssetCategoryChange(event){
        dispatch(requestParametersAddorChange({"asset_category": event.value, rootAsset: ''}))
    }

    return (
        <div className="mb-2 my-4 ">
            <label className="form-label fs-5">{t("Select Asset Category")}</label>
            <Select 
                options={ROOT_ASSETS_OPTIONS.map(e => ({"label": t(e.label), "value": t(e.value)}))} 
                placeholder={t("Select Asset Category")}
                onChange={handleRootAssetCategoryChange}
                />
        </div>
    )
}

export default RootAssetCategory