import axios from 'axios';
import React, { useEffect, useState } from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {rxParametersAddorChange} from '../../../features/RxParametersSlice'
import Select from 'react-select'
import { useTranslation } from 'react-i18next';

function SupportedControllerTypes() {

    const { t } = useTranslation()
    const dispatch = useDispatch() // dispatcher
    const rxParameters = useSelector(state => state.rxParameters) // Global State

    const [loading , setLoading] = useState(false); // to control loading component
    const [controllers , setControllers] = useState([]) // to store all controller types received from API Response
    // const [values, setValues] = useState(null);  // to keep track of selected controllers

    useEffect(() => {
        // fetching controller types and storing to state....
        setLoading(true);
        (async () => {
            try {
                const response = await axios.get("/rxprozone/supported_controller_types/")
                setControllers(response.data);
            } catch (error) {
                setLoading(false);
            }
            setLoading(false);
        })();
    }, [])

    // selection event handler
    function handleChange(event){
        dispatch(rxParametersAddorChange({"controller_filetype": event.label}));
    }    

    return (
        <>
                <Select options={controllers} onChange={handleChange} placeholder={t("Select Controller Type")} isLoading={loading} />
        </>
    )
}

export default SupportedControllerTypes