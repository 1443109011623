import Select from 'react-select'
import useFetch from '../../../../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { requestParametersAddorChange } from '../../../../../features/RequestParametersSlice'
import { t } from 'i18next'

const Village = ({ onChange }) => {

    const dispatch = useDispatch();

    const { tehsil_id} = useSelector(state => state.requestParameters)

    const [ village, setVillage ] = useState(null);
    const [ villages, setVillages ] = useState([]);
    
    const { data, error, loading } = useFetch({
        url: `/reporting/sail/village/?tehsil_id=${tehsil_id}`
    })
    error && 
        console.log(error)

    useEffect(() => {
        setVillages( () => (data.map( e => ({"label" : e.village_english, "value": e.lgd_code}) )))
    }, [data])

    function handleChange(e){
        setVillage(e);
        if (e) {
            dispatch(requestParametersAddorChange({"lgd_code": e.value, "village": e.label}))
            onChange(e.label); // Notify parent component of the change
        } else {
            dispatch(requestParametersAddorChange({"lgd_code": null, "village": null}))
            onChange(null); // Notify parent component of the reset
        }
    }

    return (
        <>
        <div>
        <label htmlFor="" className="form-label fs-5">{t('Select Village')}:</label>
            <Select
                value={village}
                placeholder={t("Select a Village")}
                onChange={handleChange}
                isClearable
                options={villages}
                loading={loading}
                />
        </div>
        </>
    )
}

export default Village