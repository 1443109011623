import React, { useState, useEffect } from 'react';
import file_image from '../../assets/images/file.png';
import Navbar from '../Navbar';
import Offcanvas from '../Offcanvas';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ResumeFileUpload() {
  import("../../styles/cch_index.css");
  const navigate = useNavigate();

  const { t } = useTranslation();

  // States
  const [resumable_session_uri, set_resumable_session_uri] = useState(''); // will be fetched from localStorage
  const [sourcefile_id, set_sourcefile_id] = useState(''); // will be fetched from localStorage
  const [file_name, set_file_name] = useState('');
  const [file_size, set_file_size] = useState('');

  const [file, setFile] = useState([]); // user selects

  const [error, setErrorMessage] = useState(''); // error message
  const [PERCENT_UPLOADED, SET_PERCENT_UPLOADED] = useState(0);
  const [loading, setLoading] = useState(false);
  const [NEW_START, SET_NEW_START] = useState(0);

  const CHUNK_SIZE = (256 * 1024) * 10; // GCS Single Chunk Size

  // Dropzone Style
  const dropzoneStyles = { cursor: "pointer", height: "150px", border: "2px solid rgba(0,0,0,0.3)", textAlign: "center", display: "flex", background: "#ffffff", padding: "20px" };
  // Dropzone Settings
  const { getRootProps, getInputProps } = useDropzone({
    // accept: ['.png', '.jpg', '.jpeg'],// accept: ".zip, .tar.gz, .tgz, .tar",
    multiple: false,
    onDrop: acceptedFiles => {
      if (acceptedFiles[0].name !== file_name && acceptedFiles[0].size !== file_size) {
        setErrorMessage("This isn't the file that you were uploading previously");
        return;
      }
      if (acceptedFiles.length === 1) {
        setErrorMessage('');
        setFile(acceptedFiles[0]);
      }
      else {
        setErrorMessage("You Can Upload a maximum of 1 File only");
        setTimeout(() => { setErrorMessage("") }, 3000);
      }
    },
  });

  // Effects
  useEffect(() => {
    // get from localStorage and set to state
    const find_uploaded_status = async (resumable_session_uri) => {
      try {
        await axios.put(resumable_session_uri, '', { headers: { "Content-Range": "bytes */*", } })
      } catch (error) {
        console.log(error);
        if (error?.response?.status === 200 || error?.response?.status === 201 || error?.message === "Network Error") {
          console.log('File that has been uploaded previously has been successfully uploaded');
          setErrorMessage("File that has been uploaded previously has been successfully uploaded");
          SET_PERCENT_UPLOADED(100);
        }
        else if (error?.response?.status === 308) {
          console.log('Previous Upload has been interrupted...');
          const newStart = Number(parseInt(error?.response?.headers['range'].split('-')[1]) + 1);
          SET_NEW_START(newStart);
          SET_PERCENT_UPLOADED((Math.ceil(newStart / CHUNK_SIZE) / Math.ceil((localStorage.getItem("file_size")) / CHUNK_SIZE)) * 100);
          console.log('Need to Resume Previous Upload');
        }
        else if (error?.response?.status === 400 || error?.response?.status === 404) {
          setErrorMessage("resumable session has expired");
          localStorage.removeItem("file_name"); localStorage.removeItem("file_size"); localStorage.removeItem("resumable_session_uri"); localStorage.removeItem("sourcefile_id");
        }
      }
    };

    if (localStorage.getItem("resumable_session_uri")) {
      set_resumable_session_uri(localStorage.getItem("resumable_session_uri"));
      set_file_size(localStorage.getItem('file_size'));
      set_file_name(localStorage.getItem('file_name'));
      find_uploaded_status(localStorage.getItem("resumable_session_uri"));
    } else {
      navigate("/upload_controller_file");
    }
  }, []); // Empty dependency array, as find_uploaded_status is defined inside useEffect

  // handle Resume Upload
  async function handleResume(event) {
    // disable resume button
    event.target.classList.add('disabled');

    let UPLOADED_CHUNKS = Math.ceil(NEW_START / CHUNK_SIZE);
    const TOTAL_CHUNKS_REQUIRED = Math.ceil((file.size) / CHUNK_SIZE);

    for (let i = NEW_START; i < file_size; i += CHUNK_SIZE) {
      const chunk = file.slice(i, i + CHUNK_SIZE);
      const start = i;
      const end = Math.min(i + CHUNK_SIZE - 1, file_size - 1);
      const range = `bytes ${start}-${end}/${file_size}`;

      const chunkHeaders = {
        'Content-Range': range,
      };

      try {
        await axios.put(resumable_session_uri, chunk, { headers: chunkHeaders });
      } catch (error) {
        if (error?.response?.status === 308) {
          UPLOADED_CHUNKS++;
          console.log(UPLOADED_CHUNKS, TOTAL_CHUNKS_REQUIRED);
          SET_PERCENT_UPLOADED((UPLOADED_CHUNKS / TOTAL_CHUNKS_REQUIRED) * 100);
        }
        else {
          console.log('File Uploaded Completely');

          // Acknowledge the upload
          SET_PERCENT_UPLOADED(100);
          localStorage.removeItem("file_name"); localStorage.removeItem("file_size"); localStorage.removeItem("resumable_session_uri"); localStorage.removeItem("sourcefile_id");
          setFile([]);

        }
      }
    }
    console.log('Uploaded Fully...');
  }

  // On cancel upload session button click
  function handleCancelSession(event) {
    if (window.confirm("Are you sure to cancel the session ?")) {
      localStorage.removeItem("file_name"); localStorage.removeItem("file_type"); localStorage.removeItem("file_size"); localStorage.removeItem("resumable_session_uri"); localStorage.removeItem("sourcefile_id");
      navigate("/upload_controller_file");
    }
  }

  return (
    <>
      <Navbar />
      <Offcanvas />

      <div className="w-75 page-content">
        <div className="justify-content-center mb-3 flex-column my-4 mx-5">
          <p className='text-center my-3 fs-4'>{t("Your previous Controller file upload got Interrupted, Please reselect the file to Resume your Upload")}</p>
          <p className='text-center my-2 mb-5 fs-4'>Filename: <span className='text-info'>{file_name}</span></p>  {/* replace file_name state  */}

          {
            !file?.name &&
            <button className="btn btn-outline-danger" id="cancel-button" title='Deletes the session and gets you to uploader screen' onClick={handleCancelSession}> <span className="me-2"><i className="fa-solid fa-trash"></i></span> Cancel Upload Session</button>
          }


          <p className='mt-3 mb-4 fst-italic my-5'>{t("Note: You Can Only Upload A Maximum Of 1 File only")}</p>


          {/* will appear until they select the file  */}
          {
            !file?.name &&
            <div className='' {...getRootProps({ className: 'dropzone' })} style={dropzoneStyles} >
              <input {...getInputProps()} multiple={false} /> {/* accept=".zip, .tar.gz, .tgz, .tar"  */}
              <p className='mx-auto my-auto fs-5 opacity-75'>{t("Drop or paste files here or Click to select upload")}!</p>
            </div>
          }


          {PERCENT_UPLOADED === 100 && <p className='my-5 text-center fs-4 text-success animate__animated animate__bounce'>{t("Uploaded Successfully")}</p>}

          {
            error &&
            <div className="d-flex my-4">
              <p className='text-danger fs-5'>{error}</p>
            </div>
          }

        </div>

        {
          file.length !== 0 &&
          <>
            <div className="d-flex flex-column mx-3 my-2 p-2" style={{ flex: "" }}>
              <>
                <div className="mx-3">
                  <div className="d-flex ">
                    <img className='flex-item' alt="" src={file_image} style={{ width: "120px", height: "120px", borderRadius: "20px" }} data-bs-toggle="tooltip" data-bs-placement="top" title={file.path} />
                  </div>
                  <p>{file.name}</p>
                </div>

                {/* Progress Bar, will appear if upload progress value is between 1-100*/}
                {
                  (PERCENT_UPLOADED > 0 && PERCENT_UPLOADED < 100) &&
                  <div className="progress my-3 mx-3 bg-dark">
                    <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: `${PERCENT_UPLOADED}%` }}>{PERCENT_UPLOADED.toPrecision(4)}%</div>
                  </div>
                }

                {/* Resume Button, will appear only after the right file is selected */}
                {
                  file &&
                  <div className="d-flex my-4 flex-column">
                    <button className='flex-item w-100 my-2 mx-2 btn btn-outline-primary p-2' style={{ cursor: "pointer" }} onClick={handleResume}> <i className="fa-solid fa-cloud-arrow-up"></i> {t("Resume Upload")} </button>
                  </div>
                }

              </>
            </div>
          </>
        }
      </div>
    </>
  );
}

export default ResumeFileUpload;
