import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function Territories({selectedTerritory, setSelectedTerritory}) {

    const { t } = useTranslation();
    // States
    const [territories, setTerritories] = useState([]);  // to store territories fetched from api call
    
    // Effects
    useEffect(() => {
        // fetchTerritories();
    }, [])

    // Helpers
    async function fetchTerritories(){
        // Makes api call to fetch growers.
        console.log('fetching territories....')
        setTerritories([])
        try{
            let response = await axios(`/api/getTerritories/`);
            setTerritories(response.data.map((e) => { return { "value" : e.id , "label" : e.label }}));
        }
        catch(err){
            console.log('Error while fetching territories...')
        }
    }

    // Event Handlers
    function handleChange(event){
        // setSelectedTerritory(event)
        setSelectedTerritory(event.target.value)
    }

    return (
        <div className="mb-5">
            <p className="fs-5 my-1">{t("Enter Territory ID")}:</p>
            {/* <Select className="" options={territories} onChange={handleChange} value={selectedTerritory} /> */}
            <input type="number" 
                placeholder={t("Enter Territory ID")}
                className="form-control"
                onChange={handleChange}
                value={selectedTerritory}
                />
        </div>
    )
}

export default Territories