import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { requestParametersAddorChange } from '../../../features/RequestParametersSlice';
import { toast } from "react-toastify";

function Operations({ selectedUser, onOperationSelect }) {
    const { t } = useTranslation();
    // Dispatcher
    const dispatch = useDispatch();

    // Global State
    const requestParameters = useSelector((state) => state.requestParameters);

    const [operations, setOperations] = useState([]); // used to store operations fetched from api call
    const [show, setShow] = useState(false); // used to control display of grower selector
    const [selectedOperation, setSelectedOperation] = useState(null); // Changed to a single value
    const [error, setError] = useState(null); // used to track errors
    const [loading, setLoading] = useState(false); // used to track loading state
    async function fetchOperations(userId) {
        // Makes api call to fetch Operations.
          setShow(false);
          setOperations([]);
          setError(null); // Reset error state
          setLoading(true);
        try {
          const response = await axios.get('/leaf/get_operations/', {
              params: { user_id: userId },
          });
          if (response.data.length === 0) {
              setOperations(response.data);
              setShow(false);
              setLoading(false);
              toast("No operations found for the selected user.");
          } else {
              setOperations(response.data);
              setShow(true);
              setLoading(false);
          }
        } catch (err) {
          toast("No operations found for the selected user.");
        }
    }

    useEffect(() => {
        if (selectedUser) {
          setOperations([]);
          onOperationSelect([]);
          fetchOperations(selectedUser);
        }
    }, [selectedUser]);

    async function fetchOperation(operation_id) {
        setError(null); // Reset error state
        setLoading(true);
        try {
            const response = await axios.get('/leaf/get_operation_info/', {
                params: { operation_id: operation_id },
            });
            onOperationSelect(response.data);
            setLoading(false);
        } catch (err) {
          setLoading(false);
          onOperationSelect([]);
          toast("No operation data can be retrieved for the selected operation.");
      }
    }

    function handleCheckboxChange(operation) {
        const isSelected = selectedOperation === operation.id;
        const newSelectedOperation = isSelected ? null : operation.id;
        setSelectedOperation(newSelectedOperation);
        dispatch(requestParametersAddorChange({ operation: operation.id, operation_id: operation.id.toString() }));

        if (!isSelected) {
          fetchOperation(operation.id);
        }
    }

  return (
    <>
      {show && (
        <div className="row mt-2 mx-1">
          <table className="table table-bordered table-striped fs-sm">
            <thead className="thead-dark">
              <tr>
                <th>Select</th>
                <th>ID</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Updated Time</th>
                <th>Type</th>
                <th>Files</th>
                <th>Fields</th>
                <th>Providers</th>
                {/* <th>Standard</th> */}
              </tr>
            </thead>
            <tbody>
              {operations.map((operation) => (
                <tr key={operation.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedOperation === operation.id}
                      onChange={() => handleCheckboxChange(operation)}
                    />
                  </td>
                  <td>{operation.id}</td>
                  <td>{operation.startTime}</td>
                  <td>{operation.endTime}</td>
                  <td>{operation.updatedTime}</td>
                  <td>{operation.type}</td>
                  <td>{operation.files.join(', ')}</td>
                  <td>{operation.fields.map((field) => field.id).join(', ')}</td>
                  <td>{operation.providers.join(', ')}</td>
                  {/* <td>{operation.standard}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}   
    </>
  );
}

export default Operations;