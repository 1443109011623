import { Routes, Route } from 'react-router-dom'
import CarbonCaptureForm from './CarbonCaptureForm'
import PageNotFound from '../../pages/PageNotFound'
import {ResponseContextProvider} from './context/ResponseContext'
import './styles.css'

const Page = () => {
    return ( <>
        <ResponseContextProvider>
            <Routes>
                <Route path='/' element={<CarbonCaptureForm />} /> 
                <Route path='/*' element={<PageNotFound />} /> 
            </Routes>
        </ResponseContextProvider>
        </>
    )
}
export default Page;