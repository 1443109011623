import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { PAGE_WIDGET_MAP } from '../components/Offcanvas/allowedPages';
import { isElvyraTech, isStaff, isSmartPro } from 'utils/authHelpers';

const AuthenticatedRoute = ({ authKey }) => {
  let isAuthenticated = false;

  if (authKey === 'ELVYRA_TECH') {
    isAuthenticated = isElvyraTech();
  } else if (authKey === 'STAFF') {
    isAuthenticated = isStaff();
  } else if (authKey === 'SMART_PRO') {
    isAuthenticated = isSmartPro();
  } else {
    const page = PAGE_WIDGET_MAP.find(page => page.key === authKey);
    isAuthenticated = page ? Boolean(page.auth) : false;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/unauthorized" />;
};

export default AuthenticatedRoute;