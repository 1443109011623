import React, { useEffect, useRef, useState } from 'react'
import Growers from './selectors/Growers'
import Seasons from './selectors/Seasons'
import Program from './selectors/Program'
import { Accordion } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { requestParametersAddorChange } from '../../../features/RequestParametersSlice'

const MainPage = () => {

    const accordion = useRef();
    const [ display, setDisplay ] = useState(true);
    
    return (
        <Accordion activeKey={display}>
            <Accordion.Item className={`rounded mb-4 my-3`} eventKey={true} ref={accordion}>
                <Accordion.Header>{"Select: Season - Grower - Program"}</Accordion.Header>
                <Accordion.Body className='border-4 shadow-lg ' data-checked="true" style={{backgroundColor: "#EEEEEE"}}>
                    <div className="selectors my-2">
                        <Seasons />
                        <Growers />
                        <Program />
                        <SetValues accordion={accordion} setDisplay={setDisplay} />
                    </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    )
}

function SetValues({ accordion, setDisplay }){

    const dispatch = useDispatch();

    const requestParameters = useSelector(state => state.requestParameters);
    const { grower_id, grower_label, season, program, } = requestParameters;

    const accordionHeader = accordion.current?.childNodes[0]?.childNodes[0];

    function handleApplyButtonClick(){
        accordionHeader.innerText = `${season} - ${grower_label} - ${program}`;
        accordionHeader.setAttribute("aria-checked" , true);
        setDisplay(false);

        dispatch(requestParametersAddorChange({"default_selected": true}))
    }

    return (
        <div className='d-flex justify-content-center'>
            {(season && grower_id, program) && <button className="btn btn-outline-dark my-3 px-4" onClick={handleApplyButtonClick}>Apply</button>}
        </div>
    )
}

export default MainPage