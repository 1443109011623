import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import axios from 'axios'

import {useDispatch} from 'react-redux'
import { rxParametersAddorChange } from '../../features/RxParametersSlice'
import { useTranslation } from 'react-i18next'

function Hubs() {

    const { t } = useTranslation();
    const dispatch = useDispatch()
    
    // Local state, used to store hubs returned from api call
    const [ hubs , setHubs ] = useState([])

    async function fetchHubs(){
        // Makes api call to fetch hubs
        try{
            let response = await axios("/api/getHubs/");
            setHubs( response.data.map( (e) => { return { "value" : e.id, "label" : e.label } }) )
        }
        catch(err){
            console.log('Error while fetching hubs...')
        }
    }

    useEffect(() => {
        //Fetching hubs, when it is rendered.
        fetchHubs();
    }, []);

    function handleChange(event){
        // adding selected hub to global store
        dispatch(rxParametersAddorChange({ hub: event.value }))
    }


    return (
        <>
             <Select placeholder={t("Select a Hub")} options={hubs} onChange={handleChange}/>
        </>
    )
}

export default Hubs