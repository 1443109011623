import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap';
import { slugify } from '../../../../utils/slugify';
import HTMLAccordion from '../accordion/HTMLAccordion';
import { 
    ELVYRA_TECH_RESTRICTED, JIRA_AUTH_RESTRICTED, STAFF_AUTH_RESTRICTED,
    SP_PAGE_INSTRUCTIONS_ROOT_PAGE, BR_SP_PAGE_INSTRUCTIONS_ROOT_PAGE
} from '../../constants';

const PageInstructions = () => {

    const user_roles = localStorage.getItem("user_roles");
    const isElvyraTech = user_roles.split(",").includes("Elvyra Tech");
    const isStaff = user_roles.split(",").includes("Staff");
    const isJiraAuthenticated = JSON.parse(localStorage.getItem("JIRAAuthenticated"));

    const [ loading, setLoading ] = useState(false)
    const [ childPages, setChildPages ] = useState([])

    const userLanguage = localStorage.getItem("language")
    const INSTRUCTIONS_ROOT_PAGE = (userLanguage === 'pt_BR') ? BR_SP_PAGE_INSTRUCTIONS_ROOT_PAGE : SP_PAGE_INSTRUCTIONS_ROOT_PAGE;

    async function fetchPDFChildPages(){
        setLoading(true);
        try {
            const response = await axios.get(`/api/getConfluenceDescendant/?pageID=${INSTRUCTIONS_ROOT_PAGE}`)
            let content = response.data?.content;
            setChildPages(content.map(e => ({ 
                        "headerText": e?.title.replaceAll("&lt;", "<").replaceAll("&gt;", ">").replaceAll("&quot;", "'").replaceAll("<i class='", "<i class='me-2 "), 
                        "slug": slugify(e?.title), 
                        "id": e?.id 
                })));
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchPDFChildPages()
    }, [])

    return (
        <>
            {loading && <Spinner />}
            {
                childPages.map( childPage => {
                    if(ELVYRA_TECH_RESTRICTED.includes(childPage?.slug) && !isElvyraTech)
                        return <></>
                    else if(STAFF_AUTH_RESTRICTED.includes(childPage?.slug) && !isStaff)
                        return <></>
                    else if(JIRA_AUTH_RESTRICTED.includes(childPage?.slug) && !isJiraAuthenticated)
                        return <></>
                    else
                        return <HTMLAccordion 
                                        headerText={childPage?.headerText}
                                        slug={childPage?.slug}
                                        id={childPage?.id}
                                    />
                })

            }
        </>
    )
}

export default PageInstructions