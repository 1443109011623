import React from 'react'
import { useTranslation } from 'react-i18next'

const PAGE_SIZES = [5, 10, 25, 50, 100];
function TableFooter({
    pageSize, pageIndex, pageOptions, canPreviousPage, canNextPage, gotoPage, previousPage, nextPage, setPageSize
}) {

    const { t } = useTranslation();

    return (
        <>
            <div className="d-md-flex gap-1 align-items-center my-3">
                    {/* Current Page */}
                    <span className='d-flex d-md-inline justify-content-center'>Page:<strong className='mx-1'>  {pageIndex + 1} of {pageOptions.length}</strong></span>
                    
                    {/* << Prev Next >> */}
                    <div className="d-flex justify-content-center my-2 my-md-0 mx-auto gap-2">
                        <button className="btn btn-secondary" disabled={!canPreviousPage} onClick={() => {gotoPage(0)}}>{'<<'}</button>
                        <button className="btn btn-primary" disabled={!canPreviousPage} onClick={() => {previousPage()}}>{t("Previous")}</button>
                        <button className="btn btn-success" disabled={!canNextPage} onClick={() => {nextPage()}}>{t("Next")}</button>
                        <button className="btn btn-secondary" disabled={!canNextPage} onClick={() => {gotoPage(pageOptions.length - 1)}}>{'>>'}</button>
                    </div>

                    {/* Page Size  */}
                    <div className='dropdown'>
                        <button className="d-block mx-auto btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                            {t("Show")} {pageSize} {t("files")} 
                        </button>
                        <ul className="dropdown-menu">
                            {PAGE_SIZES.map(pagesize => (
                                <button className='dropdown-item' 
                                    key={pagesize}
                                    value={pagesize} 
                                    onClick={(event) => {setPageSize(Number(event.target.value))}}>
                                        {t("Show")} {pagesize} {t("Files")}
                                </button>
                            ))}
                        </ul>
                    </div>
            </div>
        </>
    )
}

export default TableFooter