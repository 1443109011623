import React, { useEffect } from 'react'
import Navbar from '../settings/components/Navbar'
import OffcanvasEnd from './components/offcanvas_end/OffcanvasEnd'
import Offcanvas from '../../components/Offcanvas'
import { useDispatch, useSelector } from 'react-redux';
import FieldSelectionLegend from './components/FieldSelectionLegend/FieldSelection';
import FieldNames from './components/FieldNames';
import FieldLabels from './components/FieldLabels';
import Maps from './components/Maps';
import { useTranslation } from 'react-i18next';
import { MapsContextProvider } from './context/MapsContext';
import { FieldNavigator } from './components/FieldNavigator';
// import "./styles.css"

function MapsPage() {
    
    const { t } = useTranslation();
    const requestParameters = useSelector(state => state.requestParameters);
    const { field_ids, field_labels } = requestParameters;


    // Side Effect - Set Default FILTERED_LAYERS & FARMCOMMAND_HOST 
    useEffect(() => {
        if( !localStorage.getItem("FILTERED_LAYERS") ) {
            localStorage.setItem("FILTERED_LAYERS", JSON.stringify([ {"label": "Speed", "value": "Speed"}, {"label": "Productivity", "value": "Productivity"},{"label": "Fuel Usage", "value": "Fuel Usage"} ]))
        }

        if( !localStorage.getItem("FARMCOMMAND_HOST") ) {
            localStorage.setItem("FARMCOMMAND_HOST", "https://processing-api.farmcommand.com");
        }
    }, [])
    

    return (
        <>
        <Navbar pageName={t("Map Manager Lite")} />
        <Offcanvas/>
        <MapsContextProvider>
            
            
            
            
            <OffcanvasEnd /> {/* Offcanvas for Generating */}
            
            <div className="page-content px-3">
            { field_ids && <FieldSelectionLegend /> }
            { (field_ids === undefined) || (field_ids?.length === 0)
                ?
                    <p className='text-center my-2 fs-5'>{t("Map Manager Lite")}</p>
                :
                <>
                
                    <FieldNavigator />
                    
                    {
                        ( (!field_labels || field_labels?.length === 0) && (field_ids) ) ?
                            field_ids.map(field_id => (<FieldNames field_id={field_id} />))
                            :
                            <FieldLabels />
                    }
                    
                    <div className="d-flex flex-column justify-content-center">
                    <Maps key={"Maps"} />
                    </div>
                </>
            }
            </div>
        </MapsContextProvider>
        </>
    )
}

export default MapsPage