import axios from 'axios';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { t } from 'i18next';

function Submit() {

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const { 
    season, 
    grower,
    farm, 
    fields,
    service_offering,
    email_address,
    phone_number,
    contact_name  
  } = useSelector(
    (state) => state.requestParameters
  );

  async function handleSubmit(event){
    setLoading(true);
    event.target.classList.add("disabled");
    try {
      const response = await axios.post("/api/service_offering_request_submit/", {
        season, 
        grower,
        farm, 
        fields,
        service_offering,
        email_address,
        phone_number,
        contact_name  
      })
      if ([200, 201].includes(response.status)){
        setSuccess(true);
      }
    } catch (error) {
      setSuccess(false);
    } finally{
      setLoading(false);
    }
  }

  return (
    <>
      { loading && <Spinner className="d-block mx-auto my-3" />}
      
      <div className="my-3 text-center fs-4">
        { success === true && <p className='text-success'>{t("Successfully Submitted")}</p> }
        { success === false && <p className='text-danger'>{t("Failure on Submission")}</p> }
      </div>

      <input type="button" value="Submit" className='btn btn-dark fs-4 my-2 w-100'
        onClick={handleSubmit}
      />
    </>
  )
}

export default Submit