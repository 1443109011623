import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  
};

const request_season_view = createSlice({
    name: 'request_season_view',
    initialState,

    reducers: {

    reset: (state, action) => {
        return {}
    },

    constructState: (state, action) => {    
        return {
            [action.payload]: {
                "default": [],
                "by_MD5": []
            }
        }
    },
    
    // called from TableRow Component
    pushToby_MD5_and_productList: (state, action) => {
        let sourcefile_id = action.payload.sourcefile_id;
        let index = action.payload.index; // Based on Season
        let current_season = Object.keys(state[sourcefile_id]?.default[index])[0];
        
        let by_MD5_data = action.payload.by_MD5_data;
        let productList_data = action.payload.productList_data;

        // Pushing to by_MD5
        state[sourcefile_id]?.by_MD5?.push(by_MD5_data)

        // Pushing to Product List
        state[sourcefile_id]?.default[index][current_season]?.productList?.push(productList_data);
    },

    // called From Season Selector Component
    pushToDefault: (state, action) => {
      state[action.payload.sourcefile_id]?.default?.push(action.payload.data);
    },


    changeSeason: (state, action) => {
        let sourcefile_id = action.payload.sourcefile_id;
        let indexToRename = action.payload.index;
        let current_season = Object.keys(state[sourcefile_id].default[indexToRename])[0];
        let new_season = action.payload.new_season;
        
        console.log(indexToRename, current_season, new_season)

        const result = state[sourcefile_id].default.map((obj , index) => {
            if (index === indexToRename) {
                const { [current_season]: value, ...rest} = obj;
                return { [new_season]: value, ...rest };
            }
            return obj;
        })
        return {...state, [sourcefile_id]: { default: result, by_MD5: [...state[sourcefile_id]['by_MD5']] }}
    },

    deleteAssignedProduct : (state, action) => {
        let sourcefile_id = action.payload.sourcefile_id;
        let season_index = action.payload.index; // Based on Season
        let key = action.payload.key; // Based on Number of Rows in a season
        let position = action.payload.position; // i-th row.
        let product = action.payload.product;
        let current_season = Object.keys(state[sourcefile_id].default[season_index])[0];

        delete state[sourcefile_id].default[season_index][current_season].productList[key][product];
        state[sourcefile_id].by_MD5[position] = {};   
    },

    changeAssignedProduct : (state, action) => {
        let sourcefile_id = action.payload.sourcefile_id;
        let season_index = action.payload.index; // Based on Season
        let key = action.payload.key; // Based on Number of Rows in a season
        let position = action.payload.position; // i-th row.
        let assigned_product = action.payload.assigned_product;
        let product = action.payload.product;
        let current_filehash = action.payload.current_filehash;
        

        let current_season = Object.keys(state[sourcefile_id].default[season_index])[0];


        // let product = Object.keys(state[sourcefile_id].default[season_index][current_season].productList[key])
        state[sourcefile_id].default[season_index][current_season].productList[key][product] = assigned_product;
        console.log(state[sourcefile_id].by_MD5[position])
        state[sourcefile_id].by_MD5[position] = {[current_filehash]: {"productList": [{[product]: assigned_product}]}}
        // state[sourcefile_id].by_MD5[position][current_filehash].productList[0][product] = assigned_product;
    }

  },
});



const request_default_view = createSlice({
    name: 'request_default_view',
    initialState,

    reducers: {

    reset_ : (state, action) => {
        return {}
    },
        
    constructState_: (state, action) => {
        return {
            [action.payload]: {
                "default": [],
                "by_MD5": []
            }
        }
    },


    // Called from SeasonSelector Component
    pushToDefault_: (state, action) => {
        let sourcefile_id = action.payload.sourcefile_id;
        state[sourcefile_id]?.default?.push(action.payload.data);
    },

    // Called from TableRow component
    pushToby_MD5_and_productList_: (state, action) => {
        let sourcefile_id = action.payload.sourcefile_id;
        let season_index = action.payload.season_index;
        let current_season = Object.keys(state[sourcefile_id]?.default[season_index])[0];
        
        let by_MD5_data = action.payload.by_MD5_data;
        let productList_data = action.payload.productList_data;

        // Pushing to by_MD5
        state[sourcefile_id]?.by_MD5?.push(by_MD5_data)

        // Pushing to Product List
        state[sourcefile_id]?.default[season_index][current_season].productList?.push(productList_data)
    },


    deleteAssignedProduct_ : (state, action) => {
        let sourcefile_id = action.payload.sourcefile_id;
        let season_index = action.payload.season_index; // Based on Season
        let key = action.payload.key; // Based on Number of Rows in a season
        let position = action.payload.position; // i-th row.
        let product = action.payload.product;
        let current_season = Object.keys(state[sourcefile_id].default[season_index])[0];

        delete state[sourcefile_id].default[season_index][current_season].productList[key][product];
        state[sourcefile_id].by_MD5[position] = {};   
    },

    changeAssignedProduct_ : (state, action) => {
        let sourcefile_id = action.payload.sourcefile_id;
        let position = action.payload.position; // i-th row.
        let assigned_product = action.payload.assigned_product;
        let season_index = action.payload.season_index; // n-th season
        let key = action.payload.key; // i-th row in season
        let product = action.payload.product;
        let current_filehash = action.payload.current_filehash; 

        let current_season = Object.keys(state[sourcefile_id]?.default[season_index])[0];
        // let product = Object.keys(state[sourcefile_id].by_MD5[position][current_filehash].productList[0])[0];

        state[sourcefile_id].default[season_index][current_season].productList[key] = {[product] : assigned_product}; 
        state[sourcefile_id].by_MD5[position] = {[current_filehash]: {"productList": [{[product]: assigned_product}] }} ;
    },

    changeSeason_: (state, action) => {
        let sourcefile_id = action.payload.sourcefile_id;
        let indexToRename = action.payload.index;
        let current_season = Object.keys(state[sourcefile_id].default[indexToRename])[0];
        let new_season = action.payload.new_season;
        
        const result = state[sourcefile_id].default.map((obj , index) => {
            if (index === indexToRename) {
                const { [current_season]: value, ...rest} = obj;
                return { [new_season]: value, ...rest };
            }
            return obj;
        })
        return {...state, [sourcefile_id]: { default: result, by_MD5: [...state[sourcefile_id]['by_MD5']] }}
    },


  },
});


// Season View
export const request_season_view_reducer = request_season_view.reducer;
export const { constructState, pushToDefault, pushToby_MD5_and_productList, changeSeason, deleteAssignedProduct, changeAssignedProduct, reset } = request_season_view.actions;


// Default View
// export default request_default_view.reducer;
export const request_default_view_reducer = request_default_view.reducer;
export const {constructState_, deleteAssignedProduct_, changeAssignedProduct_, pushToDefault_, pushToby_MD5_and_productList_, changeSeason_, reset_ } = request_default_view.actions;

