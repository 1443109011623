import { createContext, useState } from 'react'

export const MapsContext = createContext();

export const MapsContextProvider = ({ children }) => {
    
    const [ assetsLoading, setAssetsLoading ] = useState(false);
    const [ error_message, set_error_message ] = useState(false);

    return (
        <MapsContext.Provider value={{
            assetsLoading, setAssetsLoading,
            error_message, set_error_message
        }}>
            {children}
        </MapsContext.Provider>
    )
}