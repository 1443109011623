import {createSlice} from '@reduxjs/toolkit'

const initialState = {  }

const ticketParametersSlice = createSlice({
    name: "ticketParameters",
    initialState: initialState,
    reducers: {
        ticketParametersAddorChange : (state , action) => {
            // Adds if the payload is new, changes if it already exist
            return {
                ...state,
                ...action.payload
            }
        },

        ticketParameterRemove : (state , action) => {
            // Reletes the payload from the global state.
            const copy = {...state};
            delete copy[action.payload];
            return copy;
        },

        ticketParametersReset : (state , action) => {
            // resets the state, sets to empty object.
            return {...initialState}  
        } 
    },
})

export const { ticketParametersAddorChange , ticketParameterRemove , ticketParametersReset } = ticketParametersSlice.actions
export default ticketParametersSlice.reducer;