import React  from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {requestParametersAddorChange} from '../../../../features/RequestParametersSlice'

function FieldButtons({filtered_field_ids}) {

    const dispatch = useDispatch();
    const {current_field_id} = useSelector(state => state.requestParameters);

    // Event Handler
    function handleFieldIDClick(event){
        let field_id = event.target.value;
        dispatch(requestParametersAddorChange({"current_field_id": parseInt(field_id)}))
    }

    return (
        <figure className='w-100 text-center' style={{"overscrollBehaviorX": "contain", overflowX: "auto"}}>
            {
                filtered_field_ids.map( field => (
                    <>
                        <button id={`btn-${field.value}`} 
                            className={`btn btn-field btn-outline-primary m-2 ${field.value === current_field_id && 'active'}`}
                            value={field.value}
                            title={field.value}
                            onClick={(event) => {handleFieldIDClick(event)}}
                        >
                            {field.label ? field.label : field.value}
                        </button>
                    </>
                ))
            }
        </figure>
    )
}

export default FieldButtons