import React from 'react'
import Navbar from '../components/Navbar'
import Offcanvas from '../components/Offcanvas'
import { useTranslation } from 'react-i18next'

function Unauthorized() {
  const {t} = useTranslation()
  return (
    <>
      <Navbar />
      <Offcanvas />
      <div className="d-flex vh-100 align-items-center justify-content-center">
          <p className='fs-4'>{t('Sorry! You are not authorized to access this Page')}.</p>
      </div>
    </>
  )
}

export default Unauthorized