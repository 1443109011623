import React from 'react'
import { useTranslation } from 'react-i18next'

const Disclaimer = () => {
    
    const { t } = useTranslation();

    return (
        <div className=''>
            <h5 className='fst-italic fw-bold'>
                {t("Disclaimer")} :  {t("We only provide CanPlug data for the past 6 months based on our retention policy")}
            </h5>
        </div>
    )
}

export default Disclaimer