import React, { useContext, useEffect } from 'react'
import { PrescriptionDataContext } from '../context/PrescriptionDataContext'
import { FARM_COMMAND_HOST } from '../../../../constants';
import axios from 'axios';
import _ from 'lodash'
import { useTranslation } from 'react-i18next';

function TableHeader() {
    
    const { t } = useTranslation();
    const { data, searchType, setLabelIdMap } = useContext(PrescriptionDataContext);
    
    async function fetchGrower(grower_id){
        try{
            const response = await axios.get(`${FARM_COMMAND_HOST}/asset/${grower_id}`, {headers: {"Authorization": `Token ${localStorage.getItem("token")}`}});
            if(response.status === 200) {
                const {label} = response.data;
                setLabelIdMap(prev => {return {...prev, [grower_id]: label}})
            }
        } catch(error){
            console.log(`Failed on fetching asset ${error}`)
        }
    }

        
    async function fetchClientDetail(client_id){
        try{
            const response = await axios.get(`${FARM_COMMAND_HOST}/client/${client_id}`, {headers: {"Authorization": `Token ${localStorage.getItem("token")}`}});
            if(response.status === 200) {
                const {user} = response.data;
                setLabelIdMap(prev => {return {...prev, [client_id]: user?.username}})
            }
        } catch(error){
            console.log(`Failed on fetching asset ${error}`)
        }
    }


    useEffect(() => {
        setLabelIdMap(() => {return {}}) // Reset LabelIdMap
        if(["filename", "default"].includes(searchType)){
            const grower_ids = Array.from(new Set(data.map(e => e.grower_id).filter(e => !_.isNull(e))));
            grower_ids.forEach(grower_id => fetchGrower(grower_id))
        } else {
            const client_ids = Array.from(new Set(data.map(e => e.client_id).filter(e => !_.isNull(e))));
            client_ids.forEach(client_id => fetchClientDetail(client_id))
        }
    }, [data])
    
    return (
        <>
        <thead  style={{zIndex: "-1"}}>
            <tr className='m-2 header'  style={{zIndex: "-1"}}>
                <th className='bg-dark text-white' scope="col">#</th>
                <th className='bg-dark text-white' scope="col">{t("Creation Time")}</th>
                <th className='bg-dark text-white' scope="col">{t("Filename")}</th>
                <th className='bg-dark text-white' scope="col">{t("Download Raw")}</th>
                {/* <th className='bg-dark text-white' scope="col">Controller File Type</th> */} 
                {/* ^^ should add this once the development on coda is done.. */} 
                
                {
                    ["filename", "default"].includes(searchType) ? 
                        <th className='bg-dark text-white' scope="col">{t("Grower")}</th> 
                        :
                        <th className='bg-dark text-white' scope="col">{t("Client")}</th>
                } 
                
                
                <th className='bg-dark text-white' scope="col">{t("Status Upload")}</th>
                <th className='bg-dark text-white' scope="col">{t("Agi Status")}</th>
                <th className='bg-dark text-white' scope="col"></th>
            </tr>
            </thead>
        </>
    )
}

export default TableHeader