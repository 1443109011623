import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';
import Navbar from './settings/components/Navbar'
import Offcanvas from '../components/Offcanvas'
import { useTranslation } from 'react-i18next';

function TicketCreated() {
  
  const {t} = useTranslation()
  const {state} = useLocation()  
  const [data] = useState(JSON.parse(state.data))

  return (
    <>

        <Navbar pageName={"Tickets Created"} />
        <Offcanvas />
        <div className="d-flex vh-100 flex-column align-items-center justify-content-center">
            <p className='bg-success text-white p-3 rounded-4 text-center fs-3 mb-5'>{t('Your Ticket has been successfully created')}</p> 
            <p className='fs-5 fw-bold'>Your Ticket Id: <span className='fs-5 text-decoration-underline'>{data?.id}</span></p> 
            <p className='fs-5 fw-bold'>Your Ticket Key: <a href={`https://farmersedgedev.atlassian.net/browse/${data?.key}`} className='fs-5 text-decoration-underline'>{data?.key}</a> </p> 
            <p className='my-4 bg-info fs-5 text-center rounded-3 p-3'>{t('DPS Team will work on it, further updates will be provided via e-mail')}</p>
        </div>
    </>
  )
}

export default TicketCreated