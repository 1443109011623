import React, { useContext, useState } from 'react'
import { download_raw, download_geo_json, download_agi } from '../utils';
import { PrescriptionDataContext } from '../context/PrescriptionDataContext';
import {format} from 'date-fns'
import GrowerLabel from '../components/GrowerLabel'
import ClientLabel from './ClientLabel';
import CodaOutput from './CodaOutput';
import { useTranslation } from 'react-i18next';

function TableRow(props) {
  
  const { t } = useTranslation();
  const { searchType, controllerTypeChangeInfo, setControllerTypeChangeInfo } = useContext(PrescriptionDataContext);

  const [open , setOpen] = useState(false);
    
  const { id: prescription_id, filename, creation_time, grower_id, client_id, status_upload, agi_status } = props?.data;

  // Helpers
  // On table row click event
  async function handleExpandRow(){
      setOpen((prev) => {return !prev})
  }

  return (
    <>
          <tr >
            <td className='cursor-pointer bg-dark text-white' onClick={handleExpandRow}>{props.id + 1}</td>
            <td className='cursor-pointer bg-dark text-white' onClick={handleExpandRow}>{format(new Date(creation_time), "MMM dd, yyyy hh:mm")}</td>
            <td className='cursor-pointer bg-dark text-white' title={filename}>{filename?.substring(0, 40)}</td>
            <td className='cursor-pointer bg-dark text-white' title={filename}><button className='btn btn-outline-primary bg-gradient' onClick={(event) => {download_raw(event, props.data)}}>Download Raw</button></td>

            {/* <td className='cursor-pointer bg-dark text-white' onClick={handleExpandRow}>{props.controller_file_type + 1}</td> */}
            {/* ^^ should add this once the development on coda is done.. */} 
            

            {
              ["filename", "default"].includes(searchType) ?
                <td className='cursor-pointer bg-dark text-white' onClick={handleExpandRow}> <GrowerLabel grower_id={grower_id} /> </td> :
                <td className='cursor-pointer bg-dark text-white' onClick={handleExpandRow}> <ClientLabel client_id={client_id} /> </td> 
            }

            <td className='cursor-pointer bg-dark text-white' onClick={handleExpandRow}>{status_upload}</td>
            <td className='cursor-pointer bg-dark text-white' onClick={handleExpandRow}>{String(agi_status)}</td>

            {/* <td className='bg-dark text-white'>  {String(props.data?.client_id) === localStorage.getItem("client_id") && (String(props.data?.agi_status) === 'Complete') && <button className='btn btn-outline-secondary' onClick={show_product_table}>Get Products</button> }  </td> */}

            {/* Download GeoJSONSummary & Download AGI */}
            <td className='bg-dark text-white'>
              {
                String(agi_status) === 'Complete' &&
                <>
                  <button className="btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false"> <i className="fa-solid fa-ellipsis-vertical"></i> </button>
                  <ul className="dropdown-menu">
                    <li><button className="dropdown-item" onClick={(event) => {download_raw(event, props.data)}}>Download RAW</button></li>
                    <li><button className="dropdown-item" onClick={(event) => {download_agi(event, props.data)}}>Download File</button></li>
                    <li>
                        <button type="button" className="dropdown-item"
                            data-bs-toggle="modal" data-bs-target="#change-controller-type-modal"
                            onClick={() => {setControllerTypeChangeInfo(props?.data)}}>
                              {t("Change Controller Type")}
                        </button>
                    </li>
                  </ul>
                </>
              }
            </td>
          </tr>


          {/* Displays Coda Output  */}
          <CodaOutput 
              open={open} setOpen={setOpen}
              data={props.data}
              />
        </>
  )
}

export default TableRow