import { useTranslation } from "react-i18next";
import { ALL } from "./constants";

export default function ShowMore(
    {currentSize, setCurrentSize, sourcefiles, setFilteredSourcefiles,currentFilter}
){
  
    const { t } = useTranslation();
    function handleShowMoreClick(event){
        event.preventDefault();
        setFilteredSourcefiles(sourcefiles.slice(0, currentSize + 20))
        setCurrentSize(currentSize => {return currentSize + 20});
    }

    function handleShowLessClick(event){
        event.preventDefault();
        setFilteredSourcefiles(sourcefiles.slice(0, 20))
        setCurrentSize(20);
    }

    return (
        <>
        {
        currentFilter === ALL &&
        <div className='d-flex justify-content-center align-items-center '>
            {sourcefiles.length > currentSize && <button className="btn btn-outline-primary" onClick={handleShowMoreClick}>{t("Show More")}</button>}
            {!(sourcefiles.length > currentSize) && <button className="btn btn-outline-secondary" onClick={handleShowLessClick}>{t("Show Less")}</button>}
        </div>
        }
        </>
    )
}