import { useTranslation } from "react-i18next"
import { Spinner } from "react-bootstrap"
import { format } from "date-fns"
import { NavLink } from "react-router-dom"
import WidgetCard from "./WidgetCard"
import useFetch from '../../../../hooks/useFetch'
import { capitalizeFirstLetter } from "../../../../utils/helpers"

const RecentSRs = () => {

    const { t } = useTranslation();

    const client_id = localStorage.getItem("client_id");
    const {
        data = [],
        error,
        loading
    } = useFetch({url: `/servicerequest/fetch_service_requests/?user_id=${client_id}&limit=5`})
    
    if(error){
        return (
            <>
                <p>{t("Unable to fetch your recent service requests")}</p>
            </>
        )
    }
    
    return (
        <WidgetCard cardTitle={            
        <>
        <span className="me-2">
            <i className="fa-solid fa-robot"></i> 
        </span>
        <NavLink to={"/service_request/your_requests/"}>
            {t("Your Recent Service Requests")}
        </NavLink>
        </> }>
            {
            loading ? <Spinner /> : 
            <>
            <div className="table-responsive">
                <table className="table table-striped table-hover table-borderless align-middle">
                    <thead className="table-dark">
                        <tr>
                            <th>{t("#")}</th>
                            <th>{t("SR-ID")}</th>
                            <th>{t("Request Type")}</th>
                            <th>{t("Created On")}</th>
                            <th>{t("Grower")}</th>
                            <th>{t("Status")}</th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {
                            data?.results?.map((row, index) => {
                                return <TableRow row={row} key={index} index={index} />
                            })
                        }
                    </tbody>
                </table>
            </div>
            </>
            }
        </WidgetCard>
    )
}

// Helper
function getStatusColorClass(status=""){
    const YET_TO_START = "yet-to-start";
    const PROCESSING = "processing";
    const FAILED = "failed";
    const COMPLETED = "completed";
    
    switch(status){
        case YET_TO_START || PROCESSING:
            return "text-bg-primary"
        case COMPLETED:
            return "text-bg-success"
        case FAILED:
            return "text-bg-danger"
        default:
            return "text-bg-warning"
    }
}

// Table Row Component
function TableRow({row, index, labelIdMap}){
    
    const {
        sr_key, grower_label, creation_time, status, customer_request,
    } = row;
    
    return (
        <>
        <tr className="table-secondary">

            <td> {index + 1} </td>
            <td> {sr_key} </td>
            <td> {customer_request} </td>
            <td>
                <span>
                    { creation_time && format(new Date(creation_time), "dd-MMM-yy") }
                </span>
            </td>
            <td> {grower_label} </td>

            <td>
                <span className={`px-3 py-2 badge ${getStatusColorClass(status)}`}>
                    {status && capitalizeFirstLetter(status)}
                </span>
            </td>
        </tr>
        </>
    )
}

export default RecentSRs